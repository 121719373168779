import React, { useState } from "react";
import { Typography, IconButton, Menu, MenuItem, Box } from "@mui/material";
import { Container, Grid } from "@mui/material";
import { Link } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../../assets/logo.png";

const estiloComun = {
  fontFamily: "Quicksand-Bold",
};

export default function MenuProfesiones() {
  const [menuAnchor, setMenuAnchor] = useState(null);
  const isDesktop = !isMobile;

  const abrirMenu = (evento) => {
    setMenuAnchor(evento.currentTarget);
  };

  const cerrarMenu = () => {
    setMenuAnchor(null);
  };

  return (
    <Grid container>
      <Grid item lg={2} md={2} xs={5}>
        <Box sx={{ mt: 2.2, mb: 1.3 }}>
          <Link href="/" underline="none" color="inherit">
            <img
              src={logo}
              alt="Logo teradomus"
              style={{
                height: isDesktop ? "40px" : "30px",
              }}
            />
          </Link>
        </Box>
      </Grid>

      {isMobile ? (
        <>
          <Grid
            item
            xs={7}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <IconButton
              size="large"
              edge="end"
              color="inherit"
              aria-label="menu"
              onClick={abrirMenu}
            >
              <MenuIcon sx={{ color: "#aeaeae" }} />
            </IconButton>
          </Grid>
          <Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={cerrarMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
          >
            <MenuItem onClick={cerrarMenu} sx={estiloComun}>
              <Link
                href="https://www.teradomus.com"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Para Pacientes
              </Link>
            </MenuItem>
            <MenuItem onClick={cerrarMenu} sx={estiloComun}>
              <Link
                href="https://www.teradomus.com/pro"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Para Profesionales
              </Link>
            </MenuItem>
          </Menu>
        </>
      ) : (
        <>
          <Grid
            item
            lg={10}
            md={10}
            container
            justifyContent="flex-end"
            alignItems="center"
          >
            <Box
              sx={{
                display: "flex",
                gap: 4,
                mr: 4,
                mt: 2,
              }}
            >
              <Link
                href="https://www.teradomus.com"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "24px", md: "24px" },
                    color: "#aeaeae",
                    "&:hover": { color: "#2669A8" },
                  }}
                >
                  Para Pacientes
                </Typography>
              </Link>

              <Link
                href="https://www.teradomus.com/pro"
                style={{ textDecoration: "none" }}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "24px", md: "24px" },
                    color: "#aeaeae",
                    "&:hover": { color: "#2669A8" },
                  }}
                >
                  Para Profesionales
                </Typography>
              </Link>
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
}
