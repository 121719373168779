import React from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselBox = ({ title, icon }) => (
  <Box
    sx={{
      position: "relative",
      borderRadius: 4,
      p: 0.25,
      background: "linear-gradient(45deg, #3f51b5, #9c27b0)",
      boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.2)",
      m: 1,
      "&::before": {
        content: '""',
        position: "absolute",
        top: 2,
        right: 2,
        bottom: 2,
        left: 2,
        borderRadius: 3.5,
        background: "white",
      },
    }}
  >
    <Box
      sx={{
        position: "relative",
        height: 150,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        p: 2,
      }}
    >
      {icon}
      <Typography
        variant="h6"
        sx={{
          color: "#333",
          fontWeight: "bold",
        }}
      >
        {title}
      </Typography>
    </Box>
  </Box>
);

const SimpleCarousel = ({ features }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <>
      <Typography
        sx={{
          fontFamily: "Quicksand-Medium",
          fontSize: "16px",
          color: "#454545",
          flexGrow: 1,
          mt: 2,
          marginBottom: 2,
        }}
      >
        Somos tu copiloto administrativo
      </Typography>
      <Slider {...settings}>
        {features.map((feature, index) => (
          <CarouselBox key={index} title={feature.title} icon={feature.icon} />
        ))}
      </Slider>
    </>
  );
};

export default SimpleCarousel;
