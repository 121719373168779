import * as React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import DescriptionIcon from "@mui/icons-material/Description";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Loader from "../../components/Loader";
import { Grid } from "@material-ui/core";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Select2 from "react-select";
import makeAnimated from "react-select/animated";
import AWS from "aws-sdk";

import callApi from "../../services/callApi";
import { Helmet } from "react-helmet";

export default function Suscribe() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [tipos, setTipos] = React.useState(null);
  const [data, setData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [codigoSalud, setCodigoSalud] = React.useState(null);
  const [region, setRegion] = React.useState(null);
  const [comuna, setComuna] = React.useState([]);
  const [lun, setLun] = React.useState(false);
  const [mar, setMar] = React.useState(false);
  const [mier, setMier] = React.useState(false);
  const [jue, setJue] = React.useState(false);
  const [vie, setVie] = React.useState(false);
  const [sab, setSab] = React.useState(false);
  const [dom, setDom] = React.useState(false);
  const [motoraMuscular, setMotoraMuscular] = React.useState(false);
  const [motoraDeportiva /*setMotoraDeportiva*/] = React.useState(false);
  const [respiratoriaInfantil, setRespiratoriaInfantil] = React.useState(false);
  const [respiratoriaAdulto, setRespiratoriaAdulto] = React.useState(false);
  const [geriatria, setGeriatria] = React.useState(false);
  const [neurologica, setNeurologica] = React.useState(false);
  const [masoterapia /*setMasoterapia*/] = React.useState(false);
  const [pream /*, setPream*/] = React.useState(false);
  const [am, setAm] = React.useState(false);
  const [pm, setPm] = React.useState(false);
  const [postpm /*, setPostpm*/] = React.useState(false);
  const [selectedFile1, setSelectedFile1] = React.useState(null);
  const [selectedFile2, setSelectedFile2] = React.useState(null);
  const [selectedFile3, setSelectedFile3] = React.useState(null);
  const [selectedFile4, setSelectedFile4] = React.useState(null);
  const [checked, setChecked] = React.useState(true);
  const [equipamiento, setEquipamiento] = React.useState(false);
  const [tens, setTens] = React.useState(false);
  const [tensiometro, setTensiometro] = React.useState(false);
  const [us, setUs] = React.useState(false);
  const [bomba, setBomba] = React.useState(false);
  const [fonendoscopio, setFonendoscopio] = React.useState(false);
  const [camilla, setCamilla] = React.useState(false);
  const [saturometro, setSaturometro] = React.useState(false);

  AWS.config.update({
    accessKeyId: "AKIAQS2EYCQAZYP6SQEP",
    secretAccessKey: "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",
    region: "us-east-1",
  });

  const s3 = new AWS.S3();
  s3.config.region = "us-east-1";

  const handleFileInput = (e) => {
    //esta funcion se ejecuta cuando elijo el archivo para subir al s3 desde el front
    if (e.target.files[0].size > 20000000) {
      MySwal.fire("Los archivos deben ser inferior a 20MB.", "", "info");
      return null;
    }
    const folder_name = data.nombre + "_" + data.apellido + "_" + data.celular;
    const fileId = e.currentTarget.id;
    const file = e.target.files[0];

    if (fileId === "file1") {
      uploadFileToS3(file, folder_name, "carnet_frontal");
      setSelectedFile1(file.name);
    } else if (fileId === "file2") {
      uploadFileToS3(file, folder_name, "carnet_reverso");
      setSelectedFile2(file.name);
    } else if (fileId === "file3") {
      uploadFileToS3(file, folder_name, "foto_perfil");
      setSelectedFile3(file.name);
    } else if (fileId === "file4") {
      uploadFileToS3(file, folder_name, "certificado");
      setSelectedFile4(file.name);
    }
    return null;
  };
  const uploadFileToS3 = (file, folder_name, fileId) => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = currentDate.toLocaleString("es-ES", { month: "long" });

    const extension = file.name.split(".").pop();
    const key = `prestadores/${year}/${month}/${folder_name}/${fileId}.${extension}`;

    const params = {
      Bucket: "teradomus-storage-app",
      Key: key,
      Body: file,
    };

    s3.upload(params, (err, data) => {
      if (err) {
        console.error("Error al cargar el archivo en S3:", err);
      }
    });
  };

  React.useEffect(() => {
    setData(location.state.detail);
    makeFilters();
  }, [location]);

  async function makeFilters() {
    const url = "/region_comunas?value=regiones";

    const tipos = await callApi(url);

    if (tipos) {
      setTipos(tipos);
    }
    return null;
  }

  const Input = styled("input")({
    display: "none",
  });

  const handleChangeRegion = (event) => {
    setRegion(event.target.value);
  };

  const handleChangeCodigoSalud = (event) => {
    setCodigoSalud(event.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (
      selectedFile1 == null ||
      selectedFile2 == null ||
      selectedFile3 == null ||
      selectedFile4 == null
    ) {
      MySwal.fire("Debes Adjuntar todos los documentos", "", "info");

      return null;
    }

    if (codigoSalud == null) {
      MySwal.fire("Debes indicar tu código de salud.", "", "info");

      return null;
    }

    if (region === null) {
      MySwal.fire("Debes seleccionar tu region.", "", "info");

      return null;
    }

    if (comuna === []) {
      MySwal.fire("Debes seleccionar al menos una comuna.", "", "info");

      return null;
    }

    if (checked === false) {
      MySwal.fire("Debes aceptar los términos y condiciones.", "", "info");

      return null;
    }

    if (
      motoraMuscular === false &&
      respiratoriaInfantil === false &&
      respiratoriaAdulto === false &&
      geriatria === false &&
      neurologica === false
    ) {
      MySwal.fire("Debes seleccionar al menos una especialidad.", "", "info");

      return null;
    }

    if (
      lun === false &&
      mar === false &&
      mier === false &&
      jue === false &&
      vie === false &&
      sab === false &&
      dom === false
    ) {
      MySwal.fire(
        "Debes seleccionar al menos un día que más te acomode.",
        "",
        "info"
      );

      return null;
    }

    if (am === false && pm === false) {
      MySwal.fire("Debes seleccionar al menos un horario.", "", "info");

      return null;
    }
    setIsLoading(true);

    const inscription = {
      id_inscripcion_inicial: data.pre_id,
      nombre: data.nombre,
      apellido: data.apellido,
      correo: data.correo,
      celular: data.celular,
      codigo_registro_salud: codigoSalud,
      region: region,
      comuna: comuna,
      lunes: lun,
      martes: mar,
      miercoles: mier,
      jueves: jue,
      viernes: vie,
      sabado: sab,
      domingo: dom,
      motoraMuscular: motoraMuscular,
      motoraDeportiva: motoraDeportiva,
      respiratoriaInfantil: respiratoriaInfantil,
      respiratoriaAdulto: respiratoriaAdulto,
      neurologica: neurologica,
      geriatrica: geriatria,
      masoterapia: masoterapia,
      pre_am: pream,
      am: am,
      pm: pm,
      post_pm: postpm,
      s3_folder: data.nombre + "_" + data.apellido + "_" + data.celular,
      camilla: camilla,
      tens: tens,
      us: us,
      equipamiento_ejercicio: equipamiento,
      bomba_aspiracion: bomba,
      fonendoscopio: fonendoscopio,
      saturometro: saturometro,
      tensiometro: tensiometro,
    };

    console.log(inscription);
    setIsLoading(true);
    const result = await callApi("/register", "post", inscription);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      history.push({
        pathname: "/suscribe-done",
      });
    } else {
      console.log(result);
    }
    setIsLoading(false);
  };

  const handleChangeMotoraMuscular = (event) => {
    setMotoraMuscular(event.target.checked);
  };

  /*  const handleChangeMotoraDeportiva = (event) => {
    setMotoraDeportiva(event.target.checked);
  };*/

  const handleChangeRespiratoriaInfantil = (event) => {
    setRespiratoriaInfantil(event.target.checked);
  };

  const handleChangeRespiratoriaAdulto = (event) => {
    setRespiratoriaAdulto(event.target.checked);
  };

  const handleChangeNeurologica = (event) => {
    setNeurologica(event.target.checked);
  };

  const handleChangeGeriatria = (event) => {
    setGeriatria(event.target.checked);
  };

  /*const handleChangeMasoterapia = (event) => {
    setMasoterapia(event.target.checked);
  };*/

  const handleChangeLun = (event) => {
    setLun(event.target.checked);
  };

  const handleChangeMar = (event) => {
    setMar(event.target.checked);
  };

  const handleChangeMier = (event) => {
    setMier(event.target.checked);
  };

  const handleChangeJue = (event) => {
    setJue(event.target.checked);
  };

  const handleChangeVie = (event) => {
    setVie(event.target.checked);
  };

  const handleChangeSab = (event) => {
    setSab(event.target.checked);
  };

  const handleChangeDom = (event) => {
    setDom(event.target.checked);
  };

  /*const handleChangePream = (event) => {
    setPream(event.target.checked);
  };*/

  const handleChangeAm = (event) => {
    setAm(event.target.checked);
  };

  const handleChangePm = (event) => {
    setPm(event.target.checked);
  };

  /*const handleChangePostpm = (event) => {
    setPostpm(event.target.checked);
  };*/

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleChangeCamilla = (event) => {
    setCamilla(event.target.checked);
  };
  const handleChangeTens = (event) => {
    setTens(event.target.checked);
  };
  const handleChangeUs = (event) => {
    setUs(event.target.checked);
  };
  const handleChangeEquipamiento = (event) => {
    setEquipamiento(event.target.checked);
  };
  const handleChangeBomba = (event) => {
    setBomba(event.target.checked);
  };
  const handleChangeFonendoscopia = (event) => {
    setFonendoscopio(event.target.checked);
  };
  const handleChangeSaturometro = (event) => {
    setSaturometro(event.target.checked);
  };
  const handleChangeTensiometro = (event) => {
    setTensiometro(event.target.checked);
  };

  function desc1() {
    if (selectedFile1 == null) {
      return <DescriptionIcon sx={{ ml: 1 }} />;
    }
    return <CheckCircleIcon color="success" sx={{ ml: 1 }} />;
  }

  function desc2() {
    if (selectedFile2 == null) {
      return <DescriptionIcon sx={{ ml: 1 }} />;
    }
    return <CheckCircleIcon color="success" sx={{ ml: 1 }} />;
  }

  function desc3() {
    if (selectedFile3 == null) {
      return <DescriptionIcon sx={{ ml: 1 }} />;
    }
    return <CheckCircleIcon color="success" sx={{ ml: 1 }} />;
  }

  function desc4() {
    if (selectedFile4 == null) {
      return <DescriptionIcon sx={{ ml: 1 }} />;
    }
    return <CheckCircleIcon color="success" sx={{ ml: 1 }} />;
  }

  function renderSelectRegion() {
    if (tipos == null) {
      return (
        <FormControl sx={{ width: "100%", backgroundColor: "white", mt: 3 }}>
          <InputLabel id="regiones">Regiones</InputLabel>
          <Select labelId="regiones" id="regiones">
            <MenuItem value={1}>Selecione una región</MenuItem>
          </Select>
        </FormControl>
      );
    }
    const { regiones } = tipos;

    const mappedTipos = regiones.map((tip) => {
      return (
        <MenuItem key={tip.id_region} value={tip.id_region}>
          {tip.region}
        </MenuItem>
      );
    });

    return (
      <FormControl
        sx={{
          width: "100%",
          backgroundColor: "white",
          mt: 3,
        }}
      >
        <InputLabel id="regiones">Regiones</InputLabel>
        <Select labelId="regiones" id="regiones" onChange={handleChangeRegion}>
          {mappedTipos}
        </Select>
      </FormControl>
    );
  }

  function renderSelectComuna() {
    if (region == null) {
      return (
        <FormControl sx={{ width: "100%", backgroundColor: "white", mt: 3 }}>
          <InputLabel id="comunas">Comunas de región</InputLabel>
          <Select labelId="comunas" id="comunas">
            <MenuItem value={1}>Selecione una región</MenuItem>
          </Select>
        </FormControl>
      );
    }

    const comunas = tipos[region];

    const animatedComponents2 = makeAnimated();
    return (
      <FormControl
        sx={{
          width: "100%",
          backgroundColor: "white",
          mt: 3,
        }}
      >
        <Select2
          closeMenuOnSelect={false}
          components={animatedComponents2}
          isMulti
          options={comunas}
          onChange={setComuna}
        />
      </FormControl>
    );
  }

  const pageTitle =
    "Trabaja con Teradomus: Inscripción para Kinesiólogos Profesionales";
  const pageDescription =
    "Regístrate en Teradomus y ofrece tus servicios de kinesiología a domicilio. Flexibilidad, bajas comisiones y acceso a una amplia red de pacientes.";

  return (
    <>
      <Loader isLoading={isLoading} />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/suscribe" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/suscribe" />
      </Helmet>
      <Container fixed>
        <Typography sx={{ mt: 5 }}>
          <Box sx={{ fontWeight: "bold", fontSize: 22, pl: { xs: 1, lg: 0 } }}>
            Te damos la bienvenida {data.nombre + " " + data.apellido}, estas a
            un paso de atender con Teradomus
          </Box>
        </Typography>
      </Container>
      <Container fixed>
        <Box
          sx={{
            mt: 5,
          }}
        >
          <Typography
            sx={{
              pt: 4,
              pl: { xs: 1, lg: 5 },
              fontWeight: "bold",
              fontSize: 20,
            }}
          >
            Necesitamos los siguientes datos para configurar tu cuenta
          </Typography>
          <FormGroup>
            <Box
              sx={{
                width: "80%",
                mt: 5,
                ml: 6,
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mt: 4 }}
              >
                {desc1()}
                <label htmlFor="file1">
                  <Input id="file1" type="file" onChange={handleFileInput} />
                  <Button
                    component="span"
                    sx={{ textTransform: "none", fontSize: 16, color: "black" }}
                  >
                    Foto cédula identidad de frente
                  </Button>
                </label>
              </Stack>
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mt: 4 }}
              >
                {desc2()}
                <label htmlFor="file2">
                  <Input id="file2" type="file" onChange={handleFileInput} />
                  <Button
                    component="span"
                    sx={{ textTransform: "none", fontSize: 16, color: "black" }}
                  >
                    Foto cédula identidad de reverso
                  </Button>
                </label>
              </Stack>
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mt: 4 }}
              >
                {desc3()}
                <label htmlFor="file3">
                  <Input id="file3" type="file" onChange={handleFileInput} />
                  <Button
                    component="span"
                    sx={{ textTransform: "none", fontSize: 16, color: "black" }}
                  >
                    Foto para tu perfil profesional, de frente con fondo blanco
                  </Button>
                </label>
              </Stack>
              <Divider />
              <Stack
                direction="row"
                alignItems="center"
                spacing={2}
                sx={{ mt: 4 }}
              >
                {desc4()}
                <label htmlFor="file4">
                  <Input id="file4" type="file" onChange={handleFileInput} />
                  <Button
                    component="span"
                    sx={{ textTransform: "none", fontSize: 16, color: "black" }}
                  >
                    Certificado de antecedentes penales
                  </Button>
                </label>
              </Stack>
              <Divider />
              <Box component="form" display="flex" sx={{ mb: 3, pt: 2, ml: 3 }}>
                <Grid container>
                  <Grid item xs={12} md={4}>
                    <TextField
                      sx={{ backgroundColor: "white" }}
                      id="codigo_salud"
                      label="Código registro de salud"
                      variant="outlined"
                      size="small"
                      onChange={handleChangeCodigoSalud}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography sx={{ fontSize: 12 }}>
                      Lo puedes encontrar en el certificado de inscripción
                      otorgadado por la superintendencia de salud.
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Divider />
            </Box>
            <Box
              sx={{
                mt: 5,
                ml: { xs: 1, lg: 6 },
                mb: 5,
              }}
            >
              <Grid container>
                <Grid item xs={12} md={12}>
                  <Typography
                    sx={{
                      pt: 4,
                      fontWeight: "bold",
                      textDecoration: "underline",
                      fontSize: 20,
                    }}
                  >
                    Preferencias, elige donde y cuándo atender
                  </Typography>
                  <Typography sx={{ fontSize: 12 }}>
                    Ocuparemos esta información para priorizar a tus pacientes,
                    al final decides con que tratamiento te comprometes.
                  </Typography>
                </Grid>
                {/* Left Side */}
                <Grid item xs={12} md={5}>
                  {/* Donde */}
                  <Grid item xs={12} md={12}>
                    <Typography
                      sx={{
                        pt: 4,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Donde
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      Solo te enviaremos pacientes de estas comunas.
                    </Typography>
                  </Grid>
                  {/* Select */}
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={5}>
                      <Typography
                        sx={{
                          pt: 4,
                          fontSize: 18,
                        }}
                      >
                        Región
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {renderSelectRegion()}
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={5}>
                      <Typography
                        sx={{
                          pt: 4,
                          fontSize: 18,
                        }}
                      >
                        Comunas
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      {renderSelectComuna()}
                    </Grid>
                  </Grid>
                  {/* Especialidades */}
                  <Grid item xs={12} md={12}>
                    <Typography
                      sx={{
                        pt: 4,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Especialidades
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      Solo te enviaremos pacientes de estas especialidades.
                    </Typography>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={7}>
                      <Typography sx={{ pt: 2, fontSize: 18 }}>
                        Motora: <br /> muscular esquelética
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Switch
                        name="motoramuscular"
                        id="motoramuscular"
                        checked={motoraMuscular}
                        onChange={handleChangeMotoraMuscular}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="flex-end">
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          pt: 2,
                          fontSize: 18,
                        }}
                      >
                        Neurológica
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Switch
                        name="reurologica"
                        id="neurologica"
                        checked={neurologica}
                        onChange={handleChangeNeurologica}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="flex-end">
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          pt: 2,
                          fontSize: 18,
                        }}
                      >
                        Geriatrica
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Switch
                        name="geriatria"
                        id="geriatria"
                        checked={geriatria}
                        onChange={handleChangeGeriatria}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          pt: 2,
                          fontSize: 18,
                        }}
                      >
                        Respiratoria:
                        <br /> {"infantil (IRA)"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Switch
                        name="respiratoriainfantil"
                        id="respiratoriainfantil"
                        checked={respiratoriaInfantil}
                        onChange={handleChangeRespiratoriaInfantil}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    <span style={{ fontSize: "12px" }}>
                      *debe llevar fonendoscopio y <br /> oximetro de pulso a
                      cada sesión.
                    </span>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={7}>
                      <Typography
                        sx={{
                          pt: 2,
                          fontSize: 18,
                        }}
                      >
                        Respiratoria:
                        <br /> {"adulto (ERA)"}
                      </Typography>
                    </Grid>
                    <Grid item xs={5}>
                      <Switch
                        name="respiratoriaadulto"
                        id="respiratoriaadulto"
                        checked={respiratoriaAdulto}
                        onChange={handleChangeRespiratoriaAdulto}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    <span style={{ fontSize: "12px" }}>
                      *debe llevar fonendoscopio y <br /> oximetro de pulso a
                      cada sesión.
                    </span>
                  </Grid>
                </Grid>
                {/* Line */}
                <Grid item xs={12} md={1}>
                  <Box
                    sx={{
                      display: { xs: "none", sm: "block" },
                      mt: 5,
                      borderLeft: "1px solid black",
                      height: "855px",
                    }}
                  ></Box>
                </Grid>
                {/* Right Side */}
                <Grid item xs={12} md={6}>
                  {/* Dias */}
                  <Grid item xs={12} md={12}>
                    <Typography
                      sx={{
                        pt: 4,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Días que más te acomodan
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      Priorizaremos pacientes estos días.
                    </Typography>
                  </Grid>
                  <Box sx={{ ml: { xs: -5, md: 0 }, mr: { xs: 2, md: 0 } }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-around"
                    >
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>lun</span>}
                          labelPlacement="top"
                          onChange={handleChangeLun}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>mar</span>}
                          labelPlacement="top"
                          onChange={handleChangeMar}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>mie</span>}
                          labelPlacement="top"
                          onChange={handleChangeMier}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>jue</span>}
                          labelPlacement="top"
                          onChange={handleChangeJue}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>vie</span>}
                          labelPlacement="top"
                          onChange={handleChangeVie}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>sab</span>}
                          labelPlacement="top"
                          onChange={handleChangeSab}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                      <Grid item xs={1} md={1}>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                          label={<span style={{ fontSize: "18px" }}>dom</span>}
                          labelPlacement="top"
                          onChange={handleChangeDom}
                          sx={{ mt: { xs: 3, lg: 4 }, mb: { xs: 0, lg: 1 } }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                  {/* Horarios */}
                  <Grid item xs={12} md={12}>
                    <Typography
                      sx={{
                        pt: 4,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Horarios que más te acomodan
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      Priorizaremos pacientes en estos horarios.
                    </Typography>
                  </Grid>
                  <Grid container direction="row" alignItems="flex-end">
                    <Grid item xs={4.5}>
                      <Typography
                        sx={{
                          pt: 4,
                          pr: 5,
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        AM | 08:00 - 14:00
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Switch
                        name="am"
                        id="am"
                        checked={am}
                        onChange={handleChangeAm}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                    <Grid item xs={4.5}>
                      <Typography
                        sx={{
                          pt: 4,
                          pr: 5,
                          pl: { xs: 0, lg: 6 },
                          fontSize: 18,
                          textAlign: "right",
                        }}
                      >
                        PM | 14:00 - 20:00
                      </Typography>
                    </Grid>
                    <Grid item xs={1.5}>
                      <Switch
                        name="pm"
                        id="pm"
                        checked={pm}
                        onChange={handleChangePm}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Typography
                      sx={{
                        pt: 6,
                        fontWeight: "bold",
                        fontSize: 20,
                      }}
                    >
                      Equipamiento con el que cuentas
                    </Typography>
                    <Typography sx={{ fontSize: 12 }}>
                      Deberás siempre asistir a la primera sesión de tus
                      pacientes con todo el equipamiento que declares tener. A
                      partir de la segunda sesión, podrás prescindir de ellos si
                      el contexto del paciente lo amerita.
                    </Typography>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Equipamiento básico ejercicio
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones de todas las especialidades.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="equipamiento"
                        id="equipamiento"
                        checked={equipamiento}
                        onChange={handleChangeEquipamiento}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        TENS
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones de todas las especialidades.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="tens"
                        id="tens"
                        checked={tens}
                        onChange={handleChangeTens}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Tensiómetro
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones de todas las especialidades.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="tensiometro"
                        id="tensiometro"
                        checked={tensiometro}
                        onChange={handleChangeTensiometro}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Ultra Sonido
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones motora.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="us"
                        id="us"
                        checked={us}
                        onChange={handleChangeUs}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Saturómetro
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones motora, geriátricas y
                        neurológicas.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="saturometro"
                        id="saturometro"
                        checked={saturometro}
                        onChange={handleChangeSaturometro}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Bomba de aspiración
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones respiratorias infantil y
                        adulto.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="bomba"
                        id="bomba"
                        checked={bomba}
                        onChange={handleChangeBomba}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Fonendoscopio
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones respiratorias infantil y
                        adulto.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="fonendoscopio"
                        id="fonendoscopio"
                        checked={fonendoscopio}
                        onChange={handleChangeFonendoscopia}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                  <Grid container direction="row" alignItems="center">
                    <Grid item xs={10}>
                      <Typography
                        sx={{ pt: 2, fontSize: 16, fontWeight: "bold" }}
                      >
                        Camilla
                      </Typography>
                      <Typography sx={{ fontSize: 12 }}>
                        Aplicable a las atenciones de todas las especialidades.
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Switch
                        name="camilla"
                        id="camilla"
                        checked={camilla}
                        onChange={handleChangeCamilla}
                        inputProps={{ "aria-label": "controlled" }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Grid container alignItems="center">
              <Grid container justifyContent="center">
                <FormControlLabel
                  requited
                  control={<Checkbox defaultChecked />}
                  onChange={handleChangeChecked}
                />
                <Typography sx={{ pt: 4 }}>
                  He leído y acepto{" "}
                  <a href="https://files-subs-teradomus.s3.amazonaws.com/NO_BORRAR/CONDICIONES.pdf">
                    Términos y condiciones
                  </a>
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Typography sx={{ pt: 4 }}>
                  Si en el futuro necesitas modificar algo, comunícate con
                  nosotros
                </Typography>
              </Grid>
              <Grid container justifyContent="center">
                <Button
                  sx={{
                    color: "white",
                    mt: 3,
                    maxWidth: "250px",
                    backgroundColor: "#41758b",
                    placeSelf: "center",
                    mb: 5,
                    fontSize: 20,
                  }}
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Inscríbete
                </Button>
              </Grid>
            </Grid>
          </FormGroup>
        </Box>
      </Container>
    </>
  );
}
