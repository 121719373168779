import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../../assets/images/ICONO_menu.png";
import styles from "./styles.module.scss";
import MenuItem from "@mui/material/MenuItem";

import Menu from "@mui/material/Menu";
import { Link } from "@material-ui/core";

export default function Menus() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const ITEM_HEIGHT = 48;
  return (
    <Box sx={{ flexGrow: 1 }} className={styles.margin}>
      <AppBar
        position="static"
        className={styles.border}
        sx={{ bgcolor: "#41758b" }}
      >
        <Toolbar>
          <Link href="https://teradomus.com">
            <Box
              component="img"
              sx={{ height: 54, borderRadius: "10px" }}
              alt="Logo"
              src={logo}
            />
          </Link>

          <Typography
            variant="h6"
            noWrap
            component="div"
            sx={{
              flexGrow: 1,
              textAlign: "center",
            }}
          >
            Tu salud en cualquier momento y lugar
          </Typography>

          <IconButton
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            onClick={handleClick}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: "30ch",
              },
            }}
          >
            <Link href="https://teradomus.com" underline="none" color="inherit">
              <MenuItem>Inicio</MenuItem>
            </Link>
            <Link
              href="https://files-subs-teradomus.s3.amazonaws.com/NO_BORRAR/FAQ.pdf"
              underline="none"
              color="inherit"
            >
              <MenuItem>Preguntas frecuentes</MenuItem>
            </Link>
            <Link
              href="https://files-subs-teradomus.s3.amazonaws.com/NO_BORRAR/CONDICIONES.pdf"
              underline="none"
              color="inherit"
            >
              <MenuItem>Términos y condiciones</MenuItem>
            </Link>
          </Menu>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
