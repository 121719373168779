import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Box,
  Typography,
  Rating,
  TextField,
  Switch,
  Button,
  Paper,
  CircularProgress,
  Alert,
  Link,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import StarIcon from "@mui/icons-material/Star";
import callApi from "../../services/callApi";
import logo from "../../assets/logo.png";
import { isMobile } from "react-device-detect";
import Loader from "../../components/Loader";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const GradientBox = styled(Box)(({ theme }) => ({
  height: 200,
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(2),
  position: "relative",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
}));

const ImageOverlay = styled("img")({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: "100%",
  maxHeight: "100%",
  objectFit: "contain",
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  maxWidth: 400,
  margin: "auto",
  marginTop: theme.spacing(4),
  padding: theme.spacing(3),
  borderRadius: "16px",
  marginBottom: 50,
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "12px",
    backgroundColor: "#f0f0f0",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "12px",
  padding: theme.spacing(1.5),
  textTransform: "none",
  fontFamily: "Quicksand-Bold",
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  fontSize: "3rem",
  "& .MuiRating-iconFilled": {
    color: "#ffd700",
  },
  "& .MuiRating-iconHover": {
    color: "#ffeb3b",
  },
}));

const EvaluacionForm = () => {
  const { id } = useParams();
  const [evaluacionData, setEvaluacionData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [rating, setRating] = useState(0);
  const [opinion, setOpinion] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [time, setTimeout] = useState("");
  const [hasExistingResponse, setHasExistingResponse] = useState(false);
  const [id_profesional_fantasma, setIdProfesionalFantasma] = useState("");

  useEffect(() => {
    const fetchEvaluacion = async () => {
      setIsLoading(true);
      try {
        const url = `/agenda/evaluacionObtener?id_evaluacion_secreto=${id}`;
        const response = await callApi(url);
        setEvaluacionData(response);
        if (
          response.datos_profesional &&
          response.datos_profesional.id_profesional_fantasma
        ) {
          setIdProfesionalFantasma(
            response.datos_profesional.id_profesional_fantasma
          );
        }
        if (response.estado_respuesta) {
          setRating(response.datos_respuesta.nota);
          setOpinion(response.datos_respuesta.comentario);
          setIsAnonymous(response.datos_respuesta.es_anonima);
          setTimeout(response.datos_respuesta.timestamp);
          setHasExistingResponse(true);
        }
      } catch (error) {
        console.error("Error al obtener la información de evaluación:", error);
        setError("No se pudo cargar la información de evaluación.");
      } finally {
        setIsLoading(false);
      }
    };

    if (id) fetchEvaluacion();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (hasExistingResponse) {
      return;
    }
    setIsLoading(true);
    try {
      const url = "/agenda/evaluacionGuardar";
      const data = {
        id_evaluacion_secreto: id,
        id_profesional_fantasma: id_profesional_fantasma,
        nota: rating,
        comentario: opinion,
        es_anonima: isAnonymous,
      };
      const response = await callApi(url, "post", data);

      if (
        response &&
        response.message === "Evaluación registrada exitosamente"
      ) {
        MySwal.fire("Evaluación enviada con éxito", "", "success");
        setHasExistingResponse(true);
      } else {
        throw new Error("La respuesta de la API no fue la esperada");
      }
    } catch (error) {
      console.error("Error al enviar la evaluación:", error);
      MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) return <Loader isLoading={isLoading} />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!evaluacionData)
    return (
      <Alert severity="warning">
        No se encontró información de evaluación.
      </Alert>
    );

  const { datos_paciente, datos_profesional } = evaluacionData;

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box sx={{ mt: 2.2, mb: 1.3, textAlign: "center" }}>
        <Link href="/" underline="none" color="inherit">
          <img
            src={logo}
            alt="Logo teradomus"
            style={{
              height: !isMobile ? "40px" : "30px",
            }}
          />
        </Link>
      </Box>
      <StyledPaper elevation={3}>
        <Typography
          variant="h5"
          gutterBottom
          sx={{ fontFamily: "Quicksand-Bold", textAlign: "center" }}
        >
          ¿Cómo fue tu experiencia?
        </Typography>

        <GradientBox>
          <ImageOverlay
            src={datos_profesional.foto_url}
            alt={`Foto de ${datos_profesional.nombre}`}
          />
        </GradientBox>

        <Typography
          variant="body1"
          gutterBottom
          sx={{ fontFamily: "Quicksand-Regular", textAlign: "center" }}
        >
          Hola, {datos_paciente.nombre}, cuéntanos cómo fue tu experiencia con{" "}
          {datos_profesional.profesion} {datos_profesional.nombre}
        </Typography>

        <Box my={3} display="flex" flexDirection="column" alignItems="center">
          <Typography
            variant="subtitle1"
            gutterBottom
            sx={{ fontFamily: "Quicksand-Bold" }}
          >
            Valoración global
          </Typography>
          <StyledRating
            name="customized-color"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            disabled={hasExistingResponse}
            icon={<StarIcon fontSize="inherit" />}
            emptyIcon={<StarIcon fontSize="inherit" />}
          />
        </Box>

        <form onSubmit={handleSubmit}>
          <StyledTextField
            fullWidth
            id="opinion"
            label="Escribe tu opinión (Opcional)"
            multiline
            rows={3}
            value={opinion}
            onChange={(e) => setOpinion(e.target.value)}
            placeholder="Opinión de hasta 500 caracteres"
            inputProps={{ maxLength: 500 }}
            margin="normal"
            variant="outlined"
            disabled={hasExistingResponse}
          />

          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            my={2}
            sx={{ backgroundColor: "#f0f0f0", borderRadius: 3, paddingLeft: 1 }}
          >
            <Typography
              variant="body2"
              sx={{ fontFamily: "Quicksand-Regular" }}
            >
              Opinión anónima (Opcional)
            </Typography>
            <Switch
              checked={isAnonymous}
              onChange={() => setIsAnonymous(!isAnonymous)}
              color="primary"
              disabled={hasExistingResponse}
            />
          </Box>

          <StyledButton
            type="submit"
            variant={hasExistingResponse ? "outlined" : "contained"}
            color="primary"
            fullWidth
            disabled={hasExistingResponse}
            sx={{
              backgroundColor: hasExistingResponse ? "transparent" : "#8753b1",
              color: hasExistingResponse ? "#8753b1" : "white",
              borderColor: "#8753b1",
              "&:hover": {
                backgroundColor: hasExistingResponse
                  ? "transparent"
                  : "#6a3d8f",
              },
            }}
          >
            {hasExistingResponse ? "Enviado" : "Enviar opinión"}
          </StyledButton>
        </form>

        <Box mt={2}>
          <Typography
            variant="caption"
            color="textSecondary"
            sx={{ fontFamily: "Quicksand-Regular" }}
          >
            {isAnonymous
              ? "Tus respuestas se guardarán de forma anónima. El profesional y el público en general podrán ver tu respuesta sin tu nombre."
              : "Tus respuestas se guardarán con tu nombre. El profesional y el público en general podrán ver tu respuesta con tu nombre."}
          </Typography>
        </Box>

        {hasExistingResponse && (
          <Box mt={2}>
            <Alert severity="info">
              Ya has enviado una evaluación el dia {time}. Gracias por tu
              opinión.
            </Alert>
          </Box>
        )}
      </StyledPaper>
    </>
  );
};

export default EvaluacionForm;
