import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Container, Grid } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import MenuCliente from '../../components/MenuCliente/MenuCliente';
import Cuadro from '../../components/CuadroSelect/Cuadro';
import { Helmet } from 'react-helmet';



export default function TipoKine() {
  let history = useHistory();
  const location = useLocation();
  const { respuesta1, respuesta2, respuesta3, respuesta4, respuesta5, respuesta6 } = location.state.apiResult;
  const { nombre, apellido, edad, telefono, motivo } = location.state;
  const [textoVisible, setTextoVisible] = useState('');
  const [tipoKinesiologia, setTipoKinesiologia] = React.useState('');
  const [textoCompleto, setTextoCompleto] = useState(false);


  const alternativa = [
    {
      titulo: 'Kinesiología@Motora',
      texto: 'Trabajaremos en fortalecer y recuperar tu sistema músculo-esquelético para potenciar tu motricidad, coordinación y capacidad de movimiento.',
      tipo: 'Kinesiología Motora',
    },
    {
      titulo: 'Kinesiología@Respiratoria',
      texto: 'Trabajaremos en recuperar el sistema respiratorio, con técnicas que mejoran la ventilación pulmonar.',
      tipo: 'Kinesiología Respiratoria',
    },
    {
      titulo: 'Kinesiología@Neurológica',
      texto: 'Trabajaremos en potenciar la movilidad que ha sido afectada por alteraciones del sistema nervioso.',
      tipo: 'Kinesiología Neurológica',
    },
    {
      titulo: 'Kinesiología@Neurológica Infantil',
      texto: 'Trabajaremos en potenciar la movilidad que ha sido afectada por alteraciones del sistema nervioso en niños y niñas.',
      tipo: 'Kinesiología Neurológica Infantil',
    },
    {
      titulo: 'Kinesiología@Respiratoria Infantil',
      texto: 'Trabajaremos en recuperar el sistema respiratorio, con técnicas que mejoran la ventilación pulmonar en niños y niñas.',
      tipo: 'Kinesiología Respiratoria Infantil',
    },
    {
      titulo: 'Kinesiología@Deportiva',
      texto: 'Para deportistas, que buscan recuperarse de lesiones sufridas en el deporte o sus actividades favoritas.',
      tipo: 'Kinesiología Deportiva',
    },
    {
      titulo: 'Kinesiología@Adulto mayor',
      texto: 'Orientado en recuperar la movilidad y aliviar las dolencias asociadas al envejecimiento.',
      tipo: 'Kinesiología Geriatrica',
    },
  ];

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = respuesta4;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
        setTextoCompleto(true); // Marca el texto como completo cuando finalice la escritura
      }
    }, 30);

    return () => {
      clearInterval(interval);
    };
  }, [respuesta4]);

  React.useEffect(() => {
    const tipoEncontrado = alternativa.find((alternativa) => alternativa.tipo === respuesta5);
    if (tipoEncontrado) {
      setTipoKinesiologia(tipoEncontrado.tipo);
    }
  }, [alternativa, respuesta5]);

  function parseTextWithBold(text) {
    const splitText = text.split('**');
    return splitText.map((part, index) => {
      if (index % 2 === 1) {  // Si es un índice impar, es una variable y debe ir en negrita
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  }
  let showMessage = true;

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription = "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";


  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/tipokine" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/tipokine" />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex"/>
      </Helmet>
      <ScrollToTop />
      <MenuCliente />
      <Box
        sx={{
          height: { lg: '300px', xs: '25vh' },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
      </Box>
      <Box
        sx={{
          width: { lg: '60%', xs: '95%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          mt: { lg: -30, xs: -20 }, height: { xs: '520px', lg: '520px' },
          margin: '0 auto', // Añadido para centrar horizontalmente
        }}
      >
        <Container >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid Item xs="12" md="10" lg="10">
              <Typography textAlign={"center"} sx={{
                color: '#2669A8', mt: { xs: 1, lg: 3 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "20px", md: '20px', lg: "26px" }
              }}>
                Tipo de kinesiología
              </Typography>
              <Typography variant="body1" sx={{
                mb: 5,
                mt: { xs: 3, lg: 3 }, fontFamily: 'Quicksand-Regular', fontSize: { xs: "16px", md: '20px', lg: "18px" }
              }}>
                {parseTextWithBold(textoVisible)}
              </Typography>
            </Grid>
          </Grid>
          <Grid md="6" xs="12">
            <React.Fragment>
              {textoCompleto && alternativa.map((opcion, index) => {
                if (opcion.tipo === respuesta5) {
                  return (
                    <React.Fragment key={index}>
                      <Cuadro
                        titulo={opcion.titulo}
                        texto={opcion.texto}
                        tipo={opcion.tipo}
                      />
                      <Container
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          mt: 0,
                        }}
                      >
                        <Box
                          sx={{
                            mt: { xs: 1 }, width: { xs: 'auto', lg: 'auto' }, p: 1, cursor: 'pointer',
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: 'Quicksand-Bold',
                              fontSize: { xs: '12px', md: '16px', lg: '14px' },
                              color: '#a879db',
                            }}
                            onClick={() => {
                              history.push({
                                pathname: "/kinesiologia-a-domicilio/agenda-inteligente/tiposkines",
                                state: {
                                  respuesta1: respuesta1,
                                  respuesta2: respuesta2,
                                  respuesta3: respuesta3,
                                  respuesta5: respuesta5,
                                  respuesta6: respuesta6,
                                  nombre: nombre,
                                  apellido: apellido,
                                  edad: edad, motivo: motivo,
                                  telefono: telefono
                                }
                              });
                            }}
                          >
                            Elegir otro tipo de kinesiología
                          </Typography>
                        </Box>
                      </Container>
                      <Container sx={{
                        display: 'flex',
                        justifyContent: 'center', mt: { xs: 3, lg: 4 }, mb: { xs: 3, lg: 4 }
                      }}>
                        <Button
                          sx={{
                            fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", md: '16px', lg: "16px" },
                            backgroundColor: '#8753b1',
                            '&:hover': { backgroundColor: '#651d87' },
                            borderRadius: 3
                          }}
                          variant="contained" onClick={() => {
                            history.push({
                              pathname: "/kinesiologia-a-domicilio/agenda-inteligente/secciones",
                              state: {
                                respuesta1: respuesta1,
                                respuesta2: respuesta2,
                                respuesta3: respuesta3,
                                respuesta5: respuesta5, motivo: motivo,
                                respuesta6: respuesta6, nombre: nombre, apellido: apellido, edad: edad, telefono: telefono, tipo: respuesta5
                              }
                            });
                          }}
                        >
                          Continuar
                        </Button>
                      </Container>
                    </React.Fragment>
                  );
                }
                return null;
              })}

              {textoCompleto && alternativa.every(opcion => opcion.tipo !== respuesta5) && (
                <Container
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 0,
                  }}
                >
                  <Box
                    sx={{
                      mt: { xs: 1 },
                      width: { xs: 'auto', lg: 'auto' },
                      p: 1, mb: 4,
                      cursor: 'pointer',
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: 'Quicksand-Bold',
                        fontSize: { xs: '14px', md: '16px', lg: '18px' },
                        color: '#a879db',
                      }}
                      onClick={() => {
                        history.push({
                          pathname: "/kinesiologia-a-domicilio/agenda-inteligente/tiposkines",
                          state: {
                            respuesta1: respuesta1,
                            respuesta2: respuesta2,
                            respuesta3: respuesta3,
                            respuesta5: respuesta5,
                            respuesta6: respuesta6,
                            nombre: nombre,
                            apellido: apellido,
                            edad: edad, motivo: motivo,
                            telefono: telefono
                          }
                        });
                      }}
                    >
                      Elegir tipo de kinesiología
                    </Typography>
                  </Box>
                </Container>
              )}
            </React.Fragment>
          </Grid>
        </Container>
      </Box >
    </>
  );
}