import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";
import TelWhat from "../../components/TelWhat/TelWhat";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Loader from "../../components/Loader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";

export default function BotPablo() {
  const comun = [
    "Antofagasta",
    "Valparaíso",
    "Concón",
    "Viña del Mar",
    "Quilpué",
    "Villa Alemana",
    "Concepción",
    "Chiguayante",
    "Penco",
    "San Pedro de La Paz",
    "Talcahuano",
    "Tomé",
    "Santiago",
    "Cerrillos",
    "Cerro Navia",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "Ñuñoa",
    "Pedro Aguirre Cerda",
    "Peñalolén",
    "Providencia",
    "Pudahuel",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "San Joaquín",
    "San Miguel",
    "San Ramón",
    "Vitacura",
    "Puente Alto",
    "San Bernardo",
    "Hualpén",
    "La Serena",
    "Coquimbo",
    "Puerto Montt",
    "Puerto Varas",
    "Temuco",
    "Padre Las Casas",
  ];
  const comunas = comun.sort((a, b) => a.localeCompare(b));

  let history = useHistory();
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const [numero_admin, setIdentificacion] = React.useState("");
  const [telefono, setTelefono] = useState("+56");
  const [valor, setEdad] = React.useState("");
  const [antecedentes, setDiagnostico] = React.useState("");
  const [dia, setCorreo] = React.useState("");
  const [nombre_contacto, setNombrec] = useState("");
  const [hora, setHora] = React.useState("");
  const [numero_sesiones, setSesiones] = useState("");
  const [nombre_paciente, setNombre] = useState("");
  const [motivo, setApellido] = useState("");
  const [direccion, setDireccion] = useState("");
  const [especialidad, setEspecialidad] = useState("");
  const [wait_interval, setinterval] = useState("");
  const [wait_time, setTime] = useState("");
  const [comuna, setComuna] = React.useState(0);

  function handleChangeNombre(event) {
    setNombre(event.target.value);
  }
  function handleChangeNombrec(event) {
    setNombrec(event.target.value);
  }
  function handleChangeApellido(event) {
    setApellido(event.target.value);
  }
  function handleChangeDiagnostico(event) {
    setDiagnostico(event.target.value);
  }
  function handleChangeEspecialidad(event) {
    setEspecialidad(event.target.value);
  }
  function handleChangeIdentificacion(event) {
    // El prefijo siempre está presente
    const prefix = "+56";
    let inputValue = event.target.value;

    // Asegurarse de que el input comience con '+56'
    if (!inputValue.startsWith(prefix)) {
      inputValue = prefix;
    }
    // Remover caracteres no numéricos excepto el '+' y espacios
    inputValue = inputValue.replace(/[^\d+ ]/g, "");
    // Extraer solo los números después del prefijo
    let plainNumbers = inputValue.slice(prefix.length).replace(/\D/g, "");
    // Ejemplo: formatea en grupos de 2-4-4 dígitos
    let formattedNumbers = plainNumbers;
    if (plainNumbers.length > 2) {
      formattedNumbers = plainNumbers.substring(0, 9);
    }
    // Combinar el prefijo con los números formateados
    inputValue = prefix + formattedNumbers.trim();

    // Limita el input a un máximo de 12 dígitos para el número más el prefijo
    if (inputValue.length > prefix.length + 9) {
      inputValue = inputValue.substring(0, prefix.length + 9);
    }
    setIdentificacion(inputValue);
  }
  function handleChangeCorreo(event) {
    setCorreo(event.target.value);
  }
  function handleChangeDireccion(event) {
    setDireccion(event.target.value);
  }
  function handleChangeHora(event) {
    setHora(event.target.value);
  }
  function handleChangeSesiones(event) {
    setSesiones(event.target.value);
  }
  const handleChangeCelular = (event) => {
    // El prefijo siempre está presente
    const prefix = "+56";
    let inputValue = event.target.value;

    // Asegurarse de que el input comience con '+56'
    if (!inputValue.startsWith(prefix)) {
      inputValue = prefix;
    }
    // Remover caracteres no numéricos excepto el '+' y espacios
    inputValue = inputValue.replace(/[^\d+ ]/g, "");
    // Extraer solo los números después del prefijo
    let plainNumbers = inputValue.slice(prefix.length).replace(/\D/g, "");
    // Ejemplo: formatea en grupos de 2-4-4 dígitos
    let formattedNumbers = plainNumbers;
    if (plainNumbers.length > 2) {
      formattedNumbers = plainNumbers.substring(0, 9);
    }
    // Combinar el prefijo con los números formateados
    inputValue = prefix + formattedNumbers.trim();

    // Limita el input a un máximo de 12 dígitos para el número más el prefijo
    if (inputValue.length > prefix.length + 9) {
      inputValue = inputValue.substring(0, prefix.length + 9);
    }
    setTelefono(inputValue);
  };
  function handleChangeEdad(event) {
    setEdad(event.target.value);
  }
  function handleChangeCity(event, newValue) {
    setComuna(newValue);
  }
  function handleChangeTime(event) {
    const inputValue = event.target.value;
    const isValidNumber = /^[0-9\s]+$/i.test(inputValue); // Verificar si la entrada es un número

    if (isValidNumber) {
      const intValue = parseInt(inputValue, 10);
      if (!isNaN(intValue)) {
        setTime(intValue);
      }
    }
  }
  function handleChangeinterval(event) {
    // Convertir el valor del input a un entero
    const intValue = parseInt(event.target.value, 10);
    // Comprobar si el valor es un número entero y actualizar el estado
    if (!isNaN(intValue)) {
      setinterval(intValue);
    }
  }

  async function handleCompleteTreatmentPayment() {
    const all_data = {
      numero_admin: numero_admin,
      especialidad: especialidad,
      comuna: comuna,
      dia: dia,
      hora: hora,
      numero_sesiones: numero_sesiones,
      valor: valor,
      motivo: motivo,
      antecedentes: antecedentes,
      wait_time: wait_time,
      wait_interval: wait_interval,
      nombre_paciente: nombre_paciente,
      nombre_contacto: nombre_contacto,
      numero_contacto: telefono,
      direccion_paciente: direccion,
      derivador: "bot pablo",
    };

    setIsLoading(true);
    const result = await callApi("/bot-pablo", "post", all_data);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      MySwal.fire("Bot en marcha!", "", "success");
    } else {
      console.log(result);
      MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
    }
  }

  return (
    <>
      <ScrollToTop />
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          height: { lg: "300px", xs: "25vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      ></Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: { xs: 5, lg: 15 },
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -30, xs: -20 },
          margin: "0 auto",
          p: { xs: 0, lg: 2 },
        }}
      >
        <FormGroup>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid Item xs="12" md="10" lg="10">
                <Typography
                  textAlign={"center"}
                  sx={{
                    color: "#2669A8",
                    mt: { xs: 1, lg: 3 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "20px", md: "20px", lg: "26px" },
                  }}
                >
                  Solicitar agenda
                </Typography>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 3,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "18px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Numero admin
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      placeholder="+56951121337"
                      type="text"
                      value={numero_admin}
                      onChange={handleChangeIdentificacion}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Especialidad
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    placeholder="Motora"
                    onChange={handleChangeEspecialidad}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "20%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Comuna
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    paddingLeft: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <Autocomplete
                    value={comuna}
                    onChange={handleChangeCity}
                    options={comunas}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            border: "none",
                            outline: "none",
                            background: "transparent",
                            fontFamily: "Quicksand-Regular",
                            color: "#4F4F4F",
                            fontSize: "16px",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Dirección Paciente
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="direccion"
                    value={direccion}
                    name="direccion"
                    onChange={handleChangeDireccion}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Dia
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    placeholder="miercoles 08 de octubre"
                    onChange={handleChangeCorreo}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Hora
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    placeholder="14:00"
                    onChange={handleChangeHora}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Cantidad de Sesiones
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeSesiones}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Valor
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    placeholder="18.125"
                    onChange={handleChangeEdad}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Motivo Consulta
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeApellido}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Antecedentes
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextareaAutosize
                    onChange={handleChangeDiagnostico} // Modificamos el manejador de cambios
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    wait_time
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextareaAutosize
                    placeholder="180"
                    onChange={handleChangeTime} // Modificamos el manejador de cambios
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    wait_interval
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextareaAutosize
                    placeholder="4"
                    onChange={handleChangeinterval} // Modificamos el manejador de cambios
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Nombre Paciente
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextareaAutosize
                    onChange={handleChangeNombre} // Modificamos el manejador de cambios
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Nombre Contacto
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextareaAutosize
                    onChange={handleChangeNombrec} // Modificamos el manejador de cambios
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Número contacto
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                  }}
                >
                  <input
                    value={telefono}
                    name="telefono" // Agrega el atributo 'name'
                    onChange={handleChangeCelular}
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Container>
        </FormGroup>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          gap: { xs: "15px", lg: "30px" },
          justifyContent: "center",
          mt: { xs: 7, lg: 8 },
          mb: { lg: 1 },
        }}
      >
        <Button
          sx={{
            fontFamily: "Quicksand-Bold",
            textTransform: "none",
            width: { xs: "160px", lg: "210px" },
            fontSize: { xs: "14px", md: "16px", lg: "18px" },
            borderRadius: 3,
            pl: { xs: 1, lg: 2 },
            pr: { xs: 1, lg: 2 },
          }}
          onClick={handleCompleteTreatmentPayment}
          variant="contained"
        >
          Solicitar
        </Button>
      </Box>
      <Box sx={{ textAlign: "center", pt: 7 }}>
        <TelWhat />
      </Box>
    </>
  );
}
