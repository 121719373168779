import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import MenuCliente from "../../components/MenuCliente/MenuCliente";
import { Helmet } from "react-helmet";

import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "../../components/Loader";

export default function Ingresada() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const { nombre, apellido, tipo, preciosessions, sessions, consulta } =
    location.state;

  function getRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  const [N, setN] = useState(() => getRandomNumber(2, 5));

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription =
    "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  function getFirstName(fullName) {
    return fullName.split(" ")[0];
  }

  function getFirstLastName(fullLastName) {
    return fullLastName.split(" ")[0];
  }

  const firstName = getFirstName(nombre);
  const firstLastName = getFirstLastName(apellido);

  const textBeforeLink = `✨ ¡Gracias por tu solicitud, **${firstName} ${firstLastName}!** Nuestro modelo de inteligencia artificial está finalizando las coordinaciones con nuestros kinesiólogos. En breves minutos nos contactaremos vía WhatsApp para coordinar el pago e informarte el profesional asignado. ¡Estás en buenas manos!

  ¿Tienes dudas? Escríbenos o llámanos al`;
  const phoneLink = (
    <a
      href="tel:+56933500035"
      style={{ textDecoration: "none", color: "inherit" }}
    >
      {" "}
      +56 9 3350 0035
    </a>
  );

  // Estado para manejar el texto visible hasta el enlace
  const [textoHastaEnlace, setTextoHastaEnlace] = useState("");

  function parseTextWithBold(text) {
    const splitText = text.split("**");
    const elements = splitText.map((part, index) => {
      if (index % 2 === 1) {
        // Si es un índice impar, es una variable y debe ir en negrita
        return <strong key={index}>{part}</strong>;
      } else {
        // Aquí se maneja el texto normal, incluyendo saltos de línea.
        // Partimos cada parte por el salto de línea y los mapeamos a elementos de bloque.
        return part.split("\n").map((line, lineIndex) => (
          // Usamos un fragmento para evitar envolver en un div innecesariamente si no hay un salto de línea
          <React.Fragment key={`${index}-${lineIndex}`}>
            {lineIndex > 0 && <br />}{" "}
            {/* Añadir <br /> para cada nueva línea excepto la primera */}
            {line}
          </React.Fragment>
        ));
      }
    });

    // Aplanar el array para manejar correctamente los saltos de línea
    return elements.flat();
  }

  useEffect(() => {
    let currentIndex = 0;
    let longitudTexto = textBeforeLink.length; // Longitud del texto antes del enlace

    const interval = setInterval(() => {
      if (currentIndex <= longitudTexto) {
        setTextoHastaEnlace(textBeforeLink.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Velocidad de escritura

    return () => {
      clearInterval(interval);
    };
  }, [textBeforeLink]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/ingresada"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/ingresada"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Loader isLoading={isLoading} />
      <ScrollToTop />
      <MenuCliente />
      <Box
        sx={{
          height: { lg: "200px", xs: "25vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      ></Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: 3,
          height: "auto",
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -20, xs: -20 },
          margin: "0 auto", // Añadido para centrar horizontalmente
        }}
      >
        <Typography
          textAlign={"center"}
          sx={{
            color: "#2669A8",
            mt: { xs: 1, lg: 3 },
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "20px", md: "20px", lg: "26px" },
          }}
        >
          Reserva ingresada
        </Typography>
        <Box
          sx={{
            height: "auto",
            width: "80%",
            margin: " 0 auto",
            mb: 5,
            mt: { xs: 3, lg: 5 },
          }}
        >
          <Typography
            sx={{
              mt: { xs: 2, lg: 5 },
              fontFamily: "Quicksand-Regular",
              fontSize: { xs: "18px", md: "20px", lg: "20px" },
            }}
          >
            {parseTextWithBold(textoHastaEnlace)}
            {textoHastaEnlace.length === textBeforeLink.length &&
              phoneLink}{" "}
            {/* Mostrar el enlace solo cuando el texto hasta el enlace esté completo */}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: { lg: "58%", xs: "85%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto", // Añadido para centrar horizontalmente
          padding: 2,
          mt: 6,
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                ml: { xs: 0, md: 2, lg: 2 },
              }}
            >
              <Typography
                sx={{
                  color: "#4F4F4F",
                  mb: { xs: 1, lg: 1 },
                  mt: { xs: 0, lg: 2 },
                  fontFamily: "Quicksand-Bold",
                  fontSize: { xs: "14px", md: "16px", lg: "20px" },
                }}
              >
                Así quedó registrada tu orden:
              </Typography>
              <Typography
                sx={{
                  ml: 2,
                  color: "#4F4F4F",
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "13px", md: "16px", lg: "18px" },
                }}
              >
                <li>
                  {tipo} {consulta}
                </li>
                <li>
                  N° de sesiones: {sessions}, desde $
                  {preciosessions
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  por sesión{" "}
                </li>
                <li>
                  Total a pagar desde $
                  {(sessions * preciosessions)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  hasta en 3 cuotas de $
                  {((sessions * 25000) / 3)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                  , sin intereses
                </li>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              mt: { xs: 2 },
              p: 1,
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                color: "#4F4F4F",
                lineHeight: { xs: 1.2, md: 1.1, lg: 1 }, // Ajusta este valor según tus necesidades
                mt: { xs: 1, lg: 0.5 },
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
              }}
            >
              Primera sesión:{" "}
              <span style={{ color: "#8546b1", fontFamily: "Quicksand-Bold" }}>
                <p></p>
                {location.state.dia} a las {location.state.hora} horas
              </span>
            </Typography>
            <Typography
              sx={{
                mt: { xs: 1.5, lg: 1.5 },
                fontFamily: "Quicksand-Light",
                fontSize: { xs: "13px", md: "16px", lg: "16px" },
                lineHeight: { xs: 1.3, md: 1.1, lg: 1 },
              }}
            >
              Las siguientes sesiones las podrás coordinar con el profesional en
              tu primera visita.
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
