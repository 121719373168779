import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { Button } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";

import Loader from "../../components/Loader";


export default function Saber() {
    const history = useHistory();
    const MySwal = withReactContent(Swal);
    const [isLoading, setIsLoading] = React.useState(false);

    const [nombre, setNombre] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [correo, setCorreo] = React.useState("");
    const [comuna, setComuna] = React.useState(0);

    const handleChangeNombre = (event) => setNombre(event.target.value);
    const handleChangeCelular = (event) => setTelefono(event.target.value);
    const handleChangeCorreo = (event) => setCorreo(event.target.value);
    const handleChangeCity = (event) => setComuna(event.target.value);

    async function handleSubmit(event) {

        if (nombre === "" || telefono === ""
            || correo === "") {
            MySwal.fire("Llena los campos obligatorios!", "", "info");

            return null;
        }
        const formatoValido = /^[\d\s+]+$/.test(telefono);
        const correoValido = /^\S+@\S+\.\S+$/.test(correo);

        if (!formatoValido) {
            MySwal.fire("Ingresa un número telefónico válido", "", "info");
            return null;
        }

        if (!correoValido) {
            MySwal.fire("Ingresa un correo electrónico válido", "", "info");
            return null;
        }
        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript comienzan en 0, por lo que debemos sumar 1
        const year = today.getFullYear();
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes}`;

        const all_data = {
            identificacion: '',
            nombre: nombre,
            apellido: '',
            antecedentes: '',
            diagnostico: '',
            correo: correo,
            celular: telefono,
            edad: 1,
            direccion: '',
            comuna: comuna,
            depto: '',
            info: '',
            dia: formattedDate,
            hora: formattedTime,
            especialidad: "Mas Información",
            cantidad_sesiones: 1,
            derivador: 'teradomus',
        };

        setIsLoading(true);
        const result = await callApi("/receive-solicitud", "post", all_data);
        setIsLoading(false);

        if (result?.statusCode === 200) {
            MySwal.fire("Gracias por su solicitud, pronto nos contactaremos con usted", "", "success");
        } else {
            console.log(result);
            MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
        }
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <Box
                sx={{
                    width: { xs: "85%", lg: "70%" },
                    borderRadius: 8, margin: "auto",
                    mt: { xs: 8, lg: 3 },
                    mb: { xs: 0, md: 0, lg: 5 },
                    p: 3,
                    backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
                }}
            >
                <Container>
                    <Typography
                        sx={{
                            color: 'white',
                            fontFamily: 'Quicksand-Bold',
                            fontSize: { xs: "14px", md: '16px', lg: "16px" },
                        }}
                    >
                        Necesitas más información? Te contactamos!
                    </Typography>
                    <Grid item xs={12} lg={12}>
                        <Typography
                            sx={{
                                color: 'white',
                                pt: 1, fontFamily: 'Quicksand-Regular',
                                fontSize: { xs: "14px", md: '16px', lg: "16px" },
                            }}
                        >
                            Nombre
                        </Typography>
                    </Grid>
                    <Box
                        sx={{
                            borderRadius: 2, // Ajusta el valor según tu preferencia
                            overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                            backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                            p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes

                        }}
                    >
                        <input id="name" name="name"
                            onChange={handleChangeNombre}
                            style={{
                                border: 'none', // Quitamos el borde nativo del input
                                width: '100%', // Ajusta el ancho del input según tus necesidades
                                outline: 'none', // Quitamos el contorno al seleccionar el input
                                background: 'transparent', // Hacemos el fondo transparente
                                fontFamily: 'Quicksand-Regular',
                                color: "#4F4F4F",
                                fontSize: '15px',
                            }}
                        />
                    </Box>
                    <Grid item xs={12} lg={12}>
                        <Typography
                            sx={{
                                color: 'white',
                                pt: 2, fontFamily: 'Quicksand-Regular',
                                fontSize: { xs: "14px", md: '16px', lg: "16px" },
                            }}
                        >
                            Teléfono
                        </Typography>
                    </Grid>
                    <Box
                        sx={{
                            borderRadius: 2, // Ajusta el valor según tu preferencia
                            overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                            backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                            p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                        }}
                    >
                        <input
                            onChange={handleChangeCelular}
                            style={{
                                border: 'none', // Quitamos el borde nativo del input
                                width: '100%', // Ajusta el ancho del input según tus necesidades
                                outline: 'none', // Quitamos el contorno al seleccionar el input
                                background: 'transparent', // Hacemos el fondo transparente
                                fontFamily: 'Quicksand-Regular',
                                color: "#4F4F4F",
                                fontSize: '15px',
                            }}
                        />
                    </Box>

                    <Grid item xs={12} lg={12}>
                        <Typography
                            sx={{
                                color: 'white',
                                pt: 1, fontFamily: 'Quicksand-Regular',
                                fontSize: { xs: "14px", md: '16px', lg: "16px" },
                            }}
                        >
                            Correo
                        </Typography>
                    </Grid>
                    <Box
                        sx={{
                            borderRadius: 2, // Ajusta el valor según tu preferencia
                            overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                            backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                            p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes

                        }}
                    >
                        <input id="email" name="email"
                            onChange={handleChangeCorreo}
                            style={{
                                border: 'none', // Quitamos el borde nativo del input
                                width: '100%', // Ajusta el ancho del input según tus necesidades
                                outline: 'none', // Quitamos el contorno al seleccionar el input
                                background: 'transparent', // Hacemos el fondo transparente
                                fontFamily: 'Quicksand-Regular',
                                color: "#4F4F4F",
                                fontSize: '15px',
                            }}
                        />
                    </Box>
                    <Grid item xs={12} lg={12}>
                        <Typography
                            sx={{
                                color: 'white',
                                pt: 1, fontFamily: 'Quicksand-Regular',
                                fontSize: { xs: "14px", md: '16px', lg: "16px" },
                            }}
                        >
                            Comuna
                        </Typography>
                    </Grid>
                    <Box
                        sx={{
                            borderRadius: 2, // Ajusta el valor según tu preferencia
                            overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                            backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                            p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes

                        }}
                    >
                        <input id="city"
                            name="city"
                            onChange={handleChangeCity}
                            style={{
                                border: 'none', // Quitamos el borde nativo del input
                                width: '100%', // Ajusta el ancho del input según tus necesidades
                                outline: 'none', // Quitamos el contorno al seleccionar el input
                                background: 'transparent', // Hacemos el fondo transparente
                                fontFamily: 'Quicksand-Regular',
                                color: "#4F4F4F",
                                fontSize: '15px',
                            }}
                        />
                    </Box>
                    <Box sx={{
                        display: 'flex',       // Agregar display flex para usar flexbox
                        justifyContent: 'center',  // Centrar horizontalmente el botón
                        alignItems: 'center',      // Centrar verticalmente el botón}}>
                    }}>
                        <Button
                            sx={{
                                fontFamily: 'Quicksand-Bold',
                                backgroundColor: '#626fb2',
                                '&:hover': { backgroundColor: '#651d87' },
                                borderRadius: 4,
                                mt: 3,
                                paddingTop: 0.5,
                                paddingBottom: 0.5,
                                fontSize: { xs: "14px", md: '16px', lg: "16px" },
                                textTransform: 'none',
                            }}
                            onClick={handleSubmit}
                            variant="contained"
                        >
                            Saber más
                        </Button>
                    </Box>
                </Container>
            </Box >
        </>
    );
}