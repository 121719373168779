import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { useHistory } from "react-router-dom";

export default function Cuadro(props) {
  let history = useHistory();
  const tituloDividido = props.titulo.split('@');

  return (
    <Box
      sx={{
        boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
        border: '1px solid rgba(0, 0, 0, 0.04)', color: 'white',
        padding: 1.4, backgroundImage: 'linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))',
        borderRadius: 5,
        margin: '0 auto', // Esto centrará horizontalmente el Box
        height: '130px', width: { xs: '95%', lg: '70%' },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-start'
        }}
      >
        <Typography variant='h2' sx={{ fontSize: { xs: "17px", md: '18px', lg: "20px" }, fontFamily: 'Quicksand-Bold', mt: { xs: 0, md: 1, lg: 1 } }}>
          {tituloDividido[0]} <span style={{ color: 'white' }}>{tituloDividido[1]}</span>
        </Typography>
        <Grid >
          <Typography variant='h3' sx={{ lineHeight: 1.5, fontSize: { xs: "15px", md: '18px', lg: "17px" }, fontFamily: 'Quicksand-Regular', mt: { xs: 2, md: 2.5, lg: 2 } }}>
            {props.texto}
          </Typography>
        </Grid>
      </Box>
    </Box>
  );
}