import React, { useState, useEffect, useRef } from 'react';
import './GoogleMapComponent.css';

function GoogleMapComponent({ onLocationAndComunaSelect }) {
    const [query, setQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [mapVisible, setMapVisible] = useState(false); // Estado para controlar la visibilidad del mapa
    const mapRef = useRef(null);
    const [comuna, setComuna] = useState('');


    const mapStyles = [
        {
            "featureType": "administrative",
            "elementType": "geometry",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        }
    ];

    // Crear una nueva instancia de Google Maps al cargar la página
    useEffect(() => {
        if (selectedLocation) {
            // Crear una nueva instancia de Google Maps
            const map = new window.google.maps.Map(mapRef.current, {
                center: selectedLocation,
                zoom: 17,
                styles: mapStyles, // Agrega esta línea
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false
            });

            // Poner un marcador en la ubicación seleccionada
            new window.google.maps.Marker({
                position: selectedLocation,
                map: map,
                styles: mapStyles, // Agrega esta línea
                mapTypeControl: false,
                fullscreenControl: false,
                streetViewControl: false
            });
        }
    }, [selectedLocation]);

    // Manejar el cambio de la entrada de texto
    // Cuando se hace clic en una sugerencia, se obtienen los detalles de la ubicación
    const handleSuggestionClick = (placeId) => {
        const placesService = new window.google.maps.places.PlacesService(mapRef.current);
        placesService.getDetails({ placeId }, (place, status) => {
            if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setMapVisible(true);
                const location = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                setSelectedLocation(location);

                // Actualizar el campo de entrada con la descripción completa de la ubicación
                const formattedAddressWithoutZip = place.formatted_address.replace(/\d{5}/, '').trim();
                setQuery(formattedAddressWithoutZip);

                // Extraer la comuna
                const comunaComponent = place.address_components.find(
                    component => component.types.includes('administrative_area_level_3') || component.types.includes('locality')
                );
                if (comunaComponent) {
                    const comunaName = comunaComponent.long_name;
                    setComuna(comunaName);
                    if (onLocationAndComunaSelect) {
                        onLocationAndComunaSelect(location, comunaName, formattedAddressWithoutZip);
                    }
                }
            }
        });
        // Limpiar las sugerencias una vez que se haya seleccionado una
        setSuggestions([]);
    };


    // Manejar el cambio de la entrada de texto
    const handleInputChange = (e) => {
        const input = e.target.value;
        setQuery(input);

        // Crear una instancia de AutocompleteService para obtener sugerencias
        const autocomplete = new window.google.maps.places.AutocompleteService();
        autocomplete.getPlacePredictions({
            input,
            componentRestrictions: { country: 'cl' }
        }, (predictions) => {
            setSuggestions(predictions || []);
        });
    };

    return (
        <div className="map-container">
            {/* Campo de entrada para la dirección */}
            <input
                value={query}
                onChange={handleInputChange}
                placeholder="Ingrese su dirección"
                className="address-input"
            />
            <div className="suggestions-container">
                {/* Mostrar la lista de sugerencias */}
                {suggestions.map((suggestion) => (
                    <div
                        key={suggestion.place_id}
                        onClick={() => handleSuggestionClick(suggestion.place_id)}
                        className="suggestion"
                    >
                        {suggestion.description}
                    </div>
                ))}
            </div>
            {/* Contenedor del mapa */}
            <div
                ref={mapRef}
                className={`map ${mapVisible ? '' : 'map-hidden'}`}>
                {/* Agrega la clase 'map-hidden' si mapVisible es false */}
            </div>
        </div>
    );
}

export default GoogleMapComponent;