import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Helmet } from 'react-helmet';

import DosCajasa from "../../components/Adulto/DosCajas/DosCajasa"
import Planes from '../../components/Adulto/Planes/Planes';
import Planescarru from '../../components/Adulto/Planes/Planescarru';
import Saber from '../../components/Adulto/Saber';
import Incluyea from '../../components/Adulto/Incluyea';
import IncluyecarruA from '../../components/Adulto/IncluyecarruA';

import plana1 from "../../assets/adulto/plana1.jpg";
import plana2 from "../../assets/adulto/plana2.png";
import plana3 from "../../assets/adulto/plana3.jpg";
import kinesiologia from "../../assets/newiconos/kinesiologia.png";
import { isMobile } from 'react-device-detect';

export default function PlanA() {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [plana, setPlana] = useState('');


  const pageTitle = "Plan Fortaleza y Movilidad para Adultos Mayores";
  const pageDescription = "Plan Fortaleza y Movilidad: Mejora la fuerza física, equilibrio y movilidad de los adultos mayores, fomentando su independencia y bienestar.";

  const [showPlanes, setShowPlanes] = useState(false);
  const planesRef = useRef(null);

  const handleVerPlanes = () => {
    setShowPlanes(!showPlanes);
  };

  const handlePlanChange = (selectedPlana) => {
    setPlana(selectedPlana);
  };

  useEffect(() => {
    if (showPlanes && planesRef.current) {
      planesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showPlanes]);


  const handleToggleOptions = () => {
    if (!isDesktop) {
      setOptionsOpen(prevState => !prevState);
    }
  };

  useEffect(() => {
    const handleCloseOptions = () => {
      setOptionsOpen(false);
    };

    // Detectar eventos de desplazamiento en el documento
    window.addEventListener('scroll', handleCloseOptions);

    return () => {
      window.removeEventListener('scroll', handleCloseOptions);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/planes-adulto-mayor/fortaleza-y-movilidad" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/planes-adulto-mayor/fortaleza-y-movilidad" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container>
        <Grid container>
          <Grid item lg={0.8} md={1.3}></Grid>
          <Grid item xs={12} lg={6.5} md={6.3}>
            <Typography sx={{ mt: { lg: 5, md: 6, xs: 2 }, color: "#c1c3c5", fontFamily: 'Quicksand-Regular', fontSize: { lg: "16px", md: "15px", xs: "13px" } }}>
              <Link to="/planes-adulto-mayor" style={{ textDecoration: 'none', color: 'inherit' }}>
                Planes Adulto Mayor {''}
              </Link>
              &gt; Plan A
            </Typography>
            <Box sx={{ mb: { xs: 0, md: 0, lg: 10 } }}>
              {isDesktop && (
                <Typography variant='h1' sx={{
                  mt: { lg: 5, md: 6, xs: 3 },
                  fontFamily: 'Quicksand-Bold',
                  fontSize: { lg: "40px", md: "38px", xs: "26px" },
                }}>
                  <span style={{ color: '#1e7aa8' }}>Plan</span> Fortaleza y Movilidad
                </Typography>
              )}
              {isMobile && (
                <Box sx={{
                  borderRadius: '33px',
                  boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.16)', paddingLeft: 4
                }}>
                  <Typography variant='h1' sx={{
                    mt: { lg: 5, md: 6, xs: 3 }, p: 2,
                    fontFamily: 'Quicksand-Regular',
                    fontSize: "20px", paddingBottom: 0
                  }}>
                    <span style={{ color: '#1e7aa8' }}>Plan A</span>
                  </Typography>
                  <Typography variant='h1' sx={{
                    p: 2, paddingTop: 0,
                    fontFamily: 'Quicksand-Bold',
                    fontSize: "22px",
                  }}>
                    Fortaleza y Movilidad
                  </Typography>
                </Box>
              )}
              {isDesktop && (
                <Typography sx={{
                  fontFamily: 'Quicksand-Regular',
                  fontSize: { lg: "20px", md: "18px", xs: "16px" }, mt: { lg: 6, md: 4, xs: 4 }, mb: 2
                }}>
                  Diseñado para mejorar y recuperar la capacidad física del adulto mayor.
                </Typography>
              )}
              {isMobile && (
                <Box sx={{
                  borderRadius: '13px', paddingLeft: 1, paddingRight: 1,
                  boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.16)',
                }}>
                  <Typography sx={{
                    fontFamily: 'Quicksand-Regular',
                    fontSize: "14px", mt: { lg: 6, md: 4, xs: 4 }, mb: 2
                  }}>
                    Diseñado para mejorar y recuperar la capacidad física del adulto mayor.
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} lg={4.3} md={3.4}>
            {isDesktop && (
              <Box sx={{
                mt: { lg: 5 },
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'absolute',
                top: 100, // Alinear con la parte superior del contenedor principal
                right: 0,
              }}
              >
                <img src={plana1} style={{
                  borderTopLeftRadius: '180px', borderBottomLeftRadius: '180px', width: '500px', height: '350px', objectFit: 'cover'
                }}
                  alt="adulto mayor" />
              </Box>
            )}
          </Grid>
        </Grid>
      </Container>
      {isDesktop && (
        <Container>
          <Grid container>
            <Grid item lg={0.2} md={2.3}></Grid>
            <Grid item xs={12} lg={5} md={3.4}>
              <Box sx={{
                mt: { lg: 15 },
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
              }}
              >
                <img src={plana2} style={{ borderRadius: 200, width: '300px', height: '300px', objectFit: 'cover' }}
                  alt="adulto mayor" />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6.1} md={6}>
              <Box>
                <Typography variant='h1' sx={{
                  color: '#1e7aa8',
                  mt: { lg: 10, md: 3, xs: 3 }, fontFamily: 'Quicksand-Bold',
                  fontSize: { lg: "26px", md: "22px", xs: "18px" },
                }}>
                  Descripción del plan
                </Typography>
                <Box sx={{ borderRadius: 3, mt: { lg: 4, md: 3, xs: 2 }, width: "70px", height: "4.5px", bgcolor: '#1e7aa8' }}></Box>
                <Typography sx={{
                  fontFamily: 'Quicksand-Regular', fontSize: { lg: "20px", md: "18px", xs: "16px" },
                  mt: { lg: 4, md: 4, xs: 2 }, mb: 2, textAlign: 'justify'
                }}>
                  Este plan está diseñado para ayudar a los adultos mayores a
                  mantener su independencia y mejorar su calidad de vida a
                  través de programas de ejercicio y movilidad personalizados.
                </Typography>
                <Box sx={{
                  border: '2px solid #a5cadb', display: 'flex', flexDirection: 'row',
                  alignItems: 'center', justifyContent: 'center', borderRadius: 5,
                }}>
                  <img src={kinesiologia} style={{ width: '110px', height: '110px' }} alt="Kinesiologia" />
                  <Typography sx={{
                    fontFamily: 'Quicksand-Bold',
                    fontSize: { lg: "19px", md: "18px", xs: "16px" },
                    color: '#1e7aa8',
                    mt: { lg: 2, md: 4, xs: 4 },
                    mb: 2, p: 0,
                    flex: 1
                  }}>
                    Realizado por profesionales de la salud de las áreas de enfermería y kinesiología, especializados en el manejo de adultos mayores.
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      )}
      {isMobile && (
        <Container>
          <Grid container>
            <Box
              sx={{
                borderRadius: 6,
                boxShadow: '2px 2px 1px 2px rgba(0, 0, 0, 0.14)', p: 2, mt: 4
              }}
            >
              <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
              }}
              >
                <img src={plana2} style={{ borderRadius: 200, width: '200px', height: '200px', objectFit: 'cover' }}
                  alt="adulto mayor" />
              </Box>
              <Grid item xs={12} lg={6.1} md={6}>
                <Box>
                  <Typography variant='h1' sx={{
                    color: '#1e7aa8', textAlign: "center",
                    mt: { lg: 5, md: 3, xs: 3 }, fontFamily: 'Quicksand-Bold',
                    fontSize: "18px",
                  }}>
                    Descripción del plan
                  </Typography>
                  <Typography sx={{
                    fontFamily: 'Quicksand-Regular', fontSize: "14px",
                    mt: 3, mb: 3, textAlign: 'justify'
                  }}>
                    Este plan está diseñado para ayudar a los adultos mayores a
                    mantener su independencia y mejorar su calidad de vida a
                    través de programas de ejercicio y movilidad personalizados.
                  </Typography>
                  <Box sx={{
                    border: '2px solid #a5cadb', display: 'flex', flexDirection: 'row',
                    alignItems: 'center', justifyContent: 'center', borderRadius: 2,
                  }}>
                    <img src={kinesiologia} style={{ marginLeft: -10, width: '80px', height: '80px' }} alt="Kinesiologia" />
                    <Typography sx={{
                      fontFamily: 'Quicksand-Regular',
                      fontSize: "14px",
                      color: '#1e7aa8',
                      mt: 1, ml: -1,
                      mb: 1,
                    }}>
                      Realizado por profesionales de la salud de las áreas de enfermería y kinesiología, especializados en el manejo de adultos mayores.
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Container>
      )}
      {isDesktop && (
        <Incluyea />
      )}
      {isMobile && (
        <Box ref={planesRef} sx={{ bgcolor: "#cdc0e2", paddingBottom: 3 }}>
          <Typography sx={{
            color: "white", textAlign: 'center', fontFamily: 'Quicksand-Bold', mt: 5, p: 2.5,
            fontSize: "20px", justifyContent: "center", alignItems: "center",
          }}>El plan incluye</Typography>
          <IncluyecarruA />
        </Box>
      )}
      <Container>
        <Grid container>
          <Grid item lg={0.81} md={2.3}></Grid>
          <Grid item xs={12} lg={5} md={4}>
            <Typography variant='h1' sx={{
              color: '#1e7aa8', textAlign: isMobile ? "center" : undefined,
              mt: { lg: 10, md: 3, xs: 4 }, fontFamily: 'Quicksand-Bold',
              fontSize: { lg: "30px", md: "22px", xs: "20px" },
            }}>
              Por qué elegirlo
            </Typography>
            <Box sx={{ marginInline: isMobile ? "auto" : undefined, borderRadius: 3, mt: { lg: 5, md: 3, xs: 2 }, width: "70px", height: "4.5px", bgcolor: '#1e7aa8' }}></Box>
            <Typography sx={{
              fontFamily: 'Quicksand-Regular', fontSize: { lg: "18px", md: "18px", xs: "14px" },
              mt: { lg: 3.4, md: 4, xs: 2 }, mb: 2, textAlign: 'justify'
            }}>
              Este plan es ideal para aquellos adultos mayores que buscan mantener o mejorar su fuerza física, equilibrio y movilidad general.
              Es perfecto para aquellos que valoran su independencia y desean mantenerse activos. Incluyendo además la enorme cantidad de beneficios a la salud que trae consigo la práctica de actividad física recurrente.
            </Typography>
            <Box sx={{
              mt: { lg: 3 },
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              overflow: 'hidden',
            }}
            >
              {isMobile && (
                <img src={plana3} style={{ borderRadius: 20, width: '100%', height: '180px', objectFit: 'cover' }}
                  alt="adulto mayor" />
              )}
              {isDesktop && (
                <img src={plana3} style={{ borderRadius: 20, width: '500px', height: '240px', objectFit: 'cover' }}
                  alt="adulto mayor" />
              )}
            </Box>
          </Grid>
          <Grid item lg={0.5} md={2.3}></Grid>
          <Grid item xs={12} lg={5} md={4}>
            <Box
              sx={{
                width: { xs: "95%", lg: "87%" }, height: { xs: "22%", lg: "18%" },
                borderRadius: 4,
                mt: { xs: 4, lg: 8 },
                mb: { xs: 0, md: 0, lg: 5 },
                boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.14)', p: isDesktop ? 3.5 : 1, paddingTop: 2, paddingBottom: 2,
              }}
            >
              <Typography sx={{ fontSize: { lg: "20px", md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
                Profesionales <span style={{ color: '#1e7aa8' }}>certificados</span><p></p>
              </Typography>
              <Typography sx={{ fontSize: { lg: "16px", md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                Plan realizado por profesionales del área de kinesiología y la enfermería, especializados en el cuidado de adultos mayores, certificados por la superintendencia de salud.
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "95%", lg: "87%" }, height: { xs: "22%", lg: "18%" },
                borderRadius: 4,
                mt: 2,
                mb: { xs: 0, md: 0, lg: 5 },
                boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.14)', p: isDesktop ? 3.5 : 1, paddingTop: 2, paddingBottom: 2,
              }}
            >
              <Typography sx={{ fontSize: { lg: "20px", md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
                Programa <span style={{ color: '#1e7aa8' }}>personalizado</span><p></p>
              </Typography>
              <Typography sx={{ fontSize: { lg: "16px", md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                Crearemos un plan de trabajo de acondicionamiento físico y rehabilitación en base a los resultados de las evaluaciones, el contexto y desempeño del adulto mayor.
              </Typography>
            </Box>
            <Box
              sx={{
                width: { xs: "95%", lg: "87%" }, height: { xs: "22%", lg: "18%" },
                borderRadius: 4,
                mt: 2,
                mb: { xs: 0, md: 0, lg: 10 },
                boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.14)', p: isDesktop ? 3.5 : 1, paddingTop: 2, paddingBottom: 2,
              }}
            >
              <Typography sx={{ fontSize: { lg: "20px", md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
                Actividades <span style={{ color: '#1e7aa8' }}>ejercicio y rehabilitación</span><p></p>
              </Typography>
              <Typography sx={{ fontSize: { lg: "16px", md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                Visitas regulares de ejercicio y de
                rehabilitación focalizados en mantener su
                independencia y mejorar su calidad de vida.
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
      <DosCajasa onPlanaChange={handlePlanChange} />
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item lg={11} md={7.7}>
            <Box sx={{ mt: { lg: 6, md: 4, xs: 8 }, textAlign: 'center' }}>
              <Typography
                sx={{
                  color: '#1e7aa8',
                  fontSize: { md: '28px', xs: '18px' },
                  fontFamily: 'Quicksand-Bold',
                }}
              >
                Otros planes
              </Typography>
            </Box>
            <Typography
              sx={{
                mt: 3, mb: { xs: 1, lg: 8 }, textAlign: 'center',
                fontSize: { md: '24px', xs: '16px' },
                fontFamily: 'Quicksand-Regular',
              }}
            >
              Elige el plan que se acomode con tus expectativas y necesidades
            </Typography>
          </Grid>
        </Grid>
        {isDesktop && (
          <Planes />
        )}
        {isMobile && (
          <Planescarru />
        )}
        <Saber />
      </Container >
    </>
  );
}