import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import { Button, Grid } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import { Container } from '@material-ui/core';
import { registerLocale } from "react-datepicker";
import es from 'date-fns/locale/es';
import TelWhat from '../../../components/TelWhat/TelWhat';
import { Helmet } from 'react-helmet';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import Box from '@mui/material/Box';
import HourSelector from '../../../components/Hora/Hora';
import { useEffect } from 'react';
import '../Form4/Datepicker';
import TuOrden from '../../../components/TelWhat/TuOrden';

registerLocale('es', es)

export default function Form4() {
  let history = useHistory();
  const location = useLocation();
  const [tipo] = useState(location.state.tipo);
  const [sessions] = useState(location.state.sessions);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null); // Agrega esta línea

  const handleSelectHour = (hour) => {
    setSelectedValue(hour);
  };

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription = "Selecciona tu dia y hora de atencion para kinesiología a domicilio, Fácil, seguro y a tu medida. Agenda tu bienestar hoy.";


  useEffect(() => {
    const handleStorageChange = () => {
      setSelectedDate(localStorage.getItem('selectedDate'));
    };

    const checkStorageChanges = setInterval(handleStorageChange, 500);

    return () => {
      clearInterval(checkStorageChanges);
    };
  }, []);

  function renderButtomAfter() {
    if (selectedValue == null || localStorage.getItem('selectedDate') == null) {
      return (
        <Button disabled sx={{
          fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", lg: "16px" },
          backgroundColor: '#8753b1',
          '&:hover': { backgroundColor: '#651d87' },
          borderRadius: 3
        }} onClick={() => {
          history.push({
            pathname: "/atencion",
            state: { tipo: tipo },
          });
        }} variant="contained">Continuar
        </Button>
      )
    } else {
      return (
        <Button sx={{
          fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", md: '16px', lg: "16px" },
          backgroundColor: '#8753b1',
          '&:hover': { backgroundColor: '#651d87' },
          borderRadius: 3
        }} onClick={() => {
          history.push({
            pathname: "/kinesiologia-a-domicilio/agenda/quien",
            state: { tipo: tipo, sessions: sessions, dates: selectedDate, hourss: selectedValue, p_date: localStorage.getItem("p_date") },
          });
        }} variant="contained">Continuar
        </Button>
      )
    }
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Box
        sx={{
          height: { lg: '300px', xs: '15vh' },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
        <Container>
          <Box sx={{ ml: { xs: 0, lg: 14 }, color: "white", p: 3, fontFamily: 'Quicksand-Bold', fontSize: { xs: "16px", lg: "22px" } }}>
            <li>{tipo}</li>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          width: { lg: '60%', xs: '96%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          mt: { lg: -25, xs: -5 },
          margin: '0 auto', // Añadido para centrar horizontalmente
        }}
      >
        <Typography textAlign={"center"} sx={{
          color: '#2669A8', mt: { xs: 3, lg: 5 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "16px", md: '20px', lg: "26px" }
        }}>
          Escoge fecha y hora de tu primera sesión
        </Typography>
        <Box sx={{
          width: { lg: '55%', xs: '90%' }, margin: '0 auto', // Añadido para centrar horizontalmente
        }}>
          <Typography textAlign={"center"} sx={{
            border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 3, p: 0.2,
            mt: { xs: 2, lg: 4 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "12px", md: '16px', lg: "16px" }
          }}>
            Horario de atención: Lunes a Domingo 8:00 - 20:00hrs
          </Typography>
          <Typography textAlign={"center"} sx={{
            mt: { xs: 1.5, lg: 2 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "12px", md: '16px', lg: "16px" }
          }}>
            Puedes reagendar tu sesión hasta con un día de anticipación
          </Typography>
        </Box>
        <Container>
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item xs="12" lg="7.1">
              <Box sx={{ backgroundColor: "#f3f6fa", borderRadius: 5, mt: 2, }}>
                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", mb: 43 }}>
                  <date-picker />

                </Box>
                <Box sx={{ mt: 2, pb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: { xs: '10px', lg: '16px' } }}>
                  <Box textAlign="center" sx={{ display: 'inline-block', width: '15px', height: '15px', backgroundColor: '#b0dcf0' }}></Box>
                  <Typography textAlign="center" sx={{ mr: { xs: 1, lg: 2 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "12px", md: '16px', lg: "16px" } }}
                  >Disponible</Typography>
                  <Box textAlign="center" sx={{ display: 'inline-block', width: '15px', height: '15px', backgroundColor: '#1e7aa8' }}></Box>
                  <Typography textAlign="center" sx={{ mr: { xs: 1, lg: 2 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "12px", md: '16px', lg: "16px" } }}>
                    Seleccionado</Typography>
                </Box>
              </Box>
              <Typography textAlign="left" sx={{
                mt: { xs: 2, lg: 2 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "13px", md: '16px', lg: "16px" }
              }}>
                Primera sesión: <span style={{ color: '#8546b1', fontFamily: 'Quicksand-Bold' }}>{selectedDate}</span>
              </Typography>
              <Typography textAlign="left" sx={{
                mt: { xs: 1, lg: 1 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "13px", md: '16px', lg: "16px" }
              }}>
                Selecciona la hora de preferencia para la sesión
              </Typography>
            </Grid>
            <Grid item xs={12} lg={7.1}>
              <Box sx={{
                mt: 2,
              }}>
                <HourSelector onSelectHour={handleSelectHour} />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center', mt: { xs: 3, lg: 5 }, mb: { xs: 3, lg: 5 }
        }}>
          {renderButtomAfter()}
        </Box>
      </Box >
      <Box
        sx={{
          width: { lg: '58%', xs: '85%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto', // Añadido para centrar horizontalmente
          padding: 2, mt: 6

        }}
      >
        <Grid container>
          <TuOrden tipo={tipo} sessions={sessions} />
          <Grid item xs={12} lg={5} sx={{ mt: { xs: 2 }, p: 1, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 4 }}>
            <Typography sx={{
              lineHeight: { xs: 1.2, lg: 1 }, // Ajusta este valor según tus necesidades
              mt: { xs: 1, lg: 0.5 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "14px", lg: "16px" }
            }}>
              Primera sesión: <span style={{ color: '#8546b1', fontFamily: 'Quicksand-Bold' }}>
                <p></p>{selectedDate} a las {selectedValue} horas</span>
            </Typography>

            <Typography sx={{
              mt: { xs: 1.5, lg: 1.5 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "13px", lg: "16px" }, lineHeight: { xs: 1.2, md: 1.1, lg: 1 }
            }}>
              Las siguientes sesiones las podrás coordinar con profesional en tu primera visita.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", pt: 9, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}