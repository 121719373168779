import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Grid } from '@mui/material';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import tick from '../../../assets/newiconos/tick.png'

export default function Planes() {
    const history = useHistory();

    return (
        <>
            <Grid container spacing={4}>
                <Grid item lg={0.81} md={2.3}></Grid>
                <Grid item lg={3.7} xs={12}>
                    <Box
                        sx={{
                            borderRadius: 8,
                            mt: 3,
                            mb: { xs: 0, md: 0, lg: 10 },
                            boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.14)',
                            p: 5,
                        }}
                    >
                        <Typography
                            sx={{
                                ml: { xs: 2, lg: 2 },
                                fontSize: { md: '18px', xs: '16px' },
                                fontFamily: 'Quicksand-Regular',
                                color: '#1e7aa8',
                            }}
                        >
                            Plan A
                        </Typography>
                        <Typography variant='h2'
                            sx={{
                                ml: { xs: 2, lg: 2 },
                                mt: 2,
                                fontSize: { md: '20px', xs: '16px' },
                                fontFamily: 'Quicksand-Bold',
                            }}
                        >
                            Fortaleza y Movilidad
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: 2, ml: -3, backgroundColor: "#f9f9f9",
                                mt: 2, height: "80px", width: "114%",
                                boxShadow: '4px 4px 4px 1px rgba(0, 0, 0, 0.1)', paddingLeft: 1, paddingTop: 1, paddingBottom: 2,
                            }}
                        >
                            <Typography sx={{ fontSize: { lg: "16px", md: "14px", xs: "14px" }, fontFamily: 'Quicksand-Regular' }}>
                                Mejora y recupera la capacidad física del adulto mayor.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular' }}>
                                Evaluación inicial
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                1 a 2 sesiones semanales de fortaleza y movilidad de 1 hora
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                Seguimiento mensual con Familiares
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px', visibility: 'hidden' }} />                            <Typography sx={{ visibility: 'hidden', fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                1 a 2 sesiones semanales de Compañía y bienestar de 3 horas
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: "#c060b9",
                                mt: 6, textAlign: "center",
                                fontSize: { md: '15px', xs: '13px' },
                                fontFamily: 'Quicksand-Regular',
                            }}
                        >
                            Desde $ 140.000/mensual
                        </Typography>
                        <Box sx={{
                            display: 'flex',       // Agregar display flex para usar flexbox
                            justifyContent: 'center',  // Centrar horizontalmente el botón
                            alignItems: 'center',      // Centrar verticalmente el botón}}>
                        }}>
                            <Button
                                sx={{
                                    fontFamily: 'Quicksand-Bold',
                                    backgroundColor: '#8753b1',
                                    '&:hover': { backgroundColor: '#651d87' },
                                    borderRadius: 4,
                                    mt: 2,
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                    fontSize: { lg: "20px", md: "18px", xs: "16px" },
                                    textTransform: 'none',
                                }}
                                onClick={() =>
                                    history.push({
                                        pathname: "/planes-adulto-mayor/fortaleza-y-movilidad",
                                    })}
                                variant="contained"
                            >
                                Saber más
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={3.7} xs={12}>
                    <Box
                        sx={{
                            borderRadius: 8,
                            mt: 3,
                            mb: { xs: 0, md: 0, lg: 10 },
                            boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.14)',
                            p: 5,
                        }}
                    >
                        <Typography
                            sx={{
                                ml: { xs: 2, lg: 3 },
                                fontSize: { md: '18px', xs: '16px' },
                                fontFamily: 'Quicksand-Regular',
                                color: '#1e7aa8',
                            }}
                        >
                            Plan B
                        </Typography>
                        <Typography variant='h2'
                            sx={{
                                ml: { xs: 2, lg: 3 },
                                mt: 2,
                                fontSize: { md: '20px', xs: '16px' },
                                fontFamily: 'Quicksand-Bold',
                            }}
                        >
                            Compañía y Bienestar
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: 2, ml: -3, backgroundColor: "#f9f9f9",
                                mt: 2, height: "80px", width: "114%",
                                boxShadow: '4px 4px 4px 1px rgba(0, 0, 0, 0.1)', paddingLeft: 1, paddingTop: 1, paddingBottom: 2,
                            }}
                        >
                            <Typography sx={{ fontSize: { lg: "16px", md: "14px", xs: "14px" }, fontFamily: 'Quicksand-Regular' }}>
                                Ofrece estimulación mental y social al adulto mayor, además de asistencia básica en sus rutinas de salud.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                Evaluación inicial
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                1 a 2 sesiones semanales de
                                Compañía y bienestar de 3 horas
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                Seguimiento mensual con Familiares
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px', visibility: 'hidden' }} />
                            <Typography sx={{ visibility: 'hidden', fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                1 a 2 sesiones semanales de fortaleza y movilidad de 1 hora
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: "#c060b9",
                                mt: 6, textAlign: "center",
                                fontSize: { md: '15px', xs: '13px' },
                                fontFamily: 'Quicksand-Regular',
                            }}
                        >
                            Desde $ 180.000/mensual
                        </Typography>
                        <Box sx={{
                            display: 'flex',       // Agregar display flex para usar flexbox
                            justifyContent: 'center',  // Centrar horizontalmente el botón
                            alignItems: 'center',      // Centrar verticalmente el botón}}>
                        }}>
                            <Button
                                sx={{
                                    fontFamily: 'Quicksand-Bold',
                                    backgroundColor: '#8753b1',
                                    '&:hover': { backgroundColor: '#651d87' },
                                    borderRadius: 4,
                                    mt: 2,
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                    fontSize: { lg: "20px", md: "18px", xs: "16px" },
                                    textTransform: 'none',
                                }}
                                onClick={() =>
                                    history.push({
                                        pathname: "/planes-adulto-mayor/compañia-y-bienestar",
                                    })}
                                variant="contained"
                            >
                                Saber más
                            </Button>
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={3.7} xs={12}>
                    <Box
                        sx={{
                            borderRadius: 8,
                            mt: 3,
                            mb: { xs: 0, md: 0, lg: 10 },
                            boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.14)',
                            p: 5,
                        }}
                    >
                        <Typography
                            sx={{
                                ml: { xs: 2, lg: 3 },
                                fontSize: { md: '18px', xs: '16px' },
                                fontFamily: 'Quicksand-Regular',
                                color: '#1e7aa8',
                            }}
                        >
                            Plan C
                        </Typography>
                        <Typography variant='h2'
                            sx={{
                                ml: { xs: 2, lg: 3 },
                                mt: 2,
                                fontSize: { lg: '20px', xs: '16px' },
                                fontFamily: 'Quicksand-Bold',
                            }}
                        >
                            Bienestar Integral
                        </Typography>
                        <Box
                            sx={{
                                borderRadius: 2, ml: -3, backgroundColor: "#f9f9f9",
                                mt: 2, height: "80px", width: "114%",
                                boxShadow: '4px 4px 4px 1px rgba(0, 0, 0, 0.1)', paddingLeft: 1, paddingTop: 1, paddingBottom: 2,
                            }}
                        >
                            <Typography sx={{ fontSize: { lg: "16px", md: "14px", xs: "14px" }, fontFamily: 'Quicksand-Regular' }}>
                                Integra ambos planes anteriores, mejorando la capacidad física, mental y social, junto con asistir en las rutinas de salud.
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                Evaluación inicial
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                1 a 2 sesiones semanales de fortaleza y movilidad de 1 hora
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                1 a 2 sesiones semanales de
                                Compañía y bienestar de 3 horas
                            </Typography>
                        </Box>
                        <Box sx={{ mt: 4, ml: -2, mr: -2, gap: "5px", display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            <img src={tick} alt="tick" style={{ height: '20px', marginRight: '10px' }} />
                            <Typography sx={{ fontSize: { md: '15px', xs: '14px' }, fontFamily: 'Quicksand-Regular', }}>
                                Seguimiento mensual con Familiares
                            </Typography>
                        </Box>
                        <Typography
                            sx={{
                                color: "#c060b9",
                                mt: 6, textAlign: "center",
                                fontSize: { md: '15px', xs: '13px' },
                                fontFamily: 'Quicksand-Regular',
                            }}
                        >
                            Desde $ 290.000/mensual
                        </Typography>
                        <Box sx={{
                            display: 'flex',       // Agregar display flex para usar flexbox
                            justifyContent: 'center',  // Centrar horizontalmente el botón
                            alignItems: 'center',      // Centrar verticalmente el botón}}>
                        }}>
                            <Button
                                sx={{
                                    fontFamily: 'Quicksand-Bold',
                                    backgroundColor: '#8753b1',
                                    '&:hover': { backgroundColor: '#651d87' },
                                    borderRadius: 4,
                                    mt: 2,
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                    fontSize: { lg: "20px", md: "18px", xs: "16px" },
                                    textTransform: 'none',
                                }}
                                onClick={() =>
                                    history.push({
                                        pathname: "/planes-adulto-mayor/bienestar-integral",
                                    })}
                                variant="contained"
                            >
                                Saber más
                            </Button>
                        </Box>
                    </Box>
                </Grid>
            </Grid >
        </>
    );
}