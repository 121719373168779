import React from 'react';
import { Box, Typography, Rating } from "@mui/material";
import { Star } from "@mui/icons-material";
import { isMobile } from "react-device-detect";

const RatingDisplay = ({ evaluaciones }) => {
  if (!evaluaciones) return null;

  const rating = parseFloat(evaluaciones.promedio);
  const totalReviews = evaluaciones.cantidad;

  const handleClick = () => {
    const evaluacionesSection = document.getElementById("evaluaciones-section");
    if (evaluacionesSection) {
      evaluacionesSection.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const ratingContent = (
    <Box
      onClick={handleClick}
      sx={{
        display: 'inline-flex',
        alignItems: 'center',
        gap: 0.5,
        cursor: 'pointer',
        '&:hover': { opacity: 0.8 },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Rating
          value={rating}
          readOnly
          precision={0.1}
          size="small"
          emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
        />
      </Box>
      <Typography
        component="span"
        sx={{
          fontFamily: 'Quicksand-Bold',
          fontSize: '16px',
          color: 'text.secondary',
        }}
      >
        {evaluaciones.promedio}
      </Typography>
      <Typography
        component="span"
        sx={{
          color: 'text.secondary',
          fontSize: '16px',
          textDecoration: 'underline',
          fontFamily: 'Quicksand-Regular',
        }}
      >
        ({totalReviews} evaluaciones)
      </Typography>
    </Box>
  );

  return ratingContent;
};

const ProfessionalInfo = ({ kinesiologoInfo }) => {
  if (!kinesiologoInfo || !kinesiologoInfo.perfil) {
    return <Typography>No se encontró información del kinesiólogo.</Typography>;
  }

  const { profesional_nombre_plataforma, profesional_profesion } = kinesiologoInfo.perfil;

  if (isMobile) {
    return (
      <Box>
        <Typography variant="h5" sx={{ ml: 2, fontFamily: "Quicksand-Bold" }}>
          {profesional_nombre_plataforma}
        </Typography>
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular" }}>
          {profesional_profesion}
        </Typography>
        <Box sx={{ ml: 2, mt: 1 }}>
          <RatingDisplay evaluaciones={kinesiologoInfo.evaluaciones} />
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Typography variant="h5" sx={{ ml: 2, fontFamily: "Quicksand-Bold" }}>
        {profesional_nombre_plataforma}
      </Typography>
      <Box sx={{ display: "flex", alignItems: "center", ml: 2 }}>
        <Typography sx={{ fontFamily: "Quicksand-Regular" }}>
          {profesional_profesion}
        </Typography>
        <Box sx={{ ml: 1 }}>
          <RatingDisplay evaluaciones={kinesiologoInfo.evaluaciones} />
        </Box>
      </Box>
    </Box>
  );
};

export default ProfessionalInfo;