import React from 'react';
import { styled } from '@mui/material';

const Line = styled('hr')({
  height: '5px', // Grosor de la línea
  backgroundColor: '#8753b1', // Color de la línea
  border: 'none',
});

function CustomLine() {
  return <Line />;
}

export default CustomLine;
