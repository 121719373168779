import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";
import { Link } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import logo from "../../assets/logo.png";

export default function Form1() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <>
      <Grid container>
        <Grid item lg={2} md={2} xs={5}>
          <Box sx={{ mt: 1.3, mb: 1.3 }}>
            <Link href="/" underline="none" color="inherit">
              <img
                src={logo}
                alt="Logo teradomus"
                style={{
                  height: isDesktop ? "45px" : "30px",
                }}
              />
            </Link>
          </Box>
        </Grid>
        <Grid item lg={7} md={6.5} xs={2}></Grid>

        <Grid item lg={3} md={3} xs={5}>
          <Link href="/pro" underline="none" color="inherit">
            <Typography
              sx={{
                textAlign: "right",
                mt: 3,
                fontFamily: "Quicksand-Bold",
                fontSize: { lg: "20px", md: "20px", xs: "18px" },
                color: "#4669E8",
              }}
            >
              Para profesionales
            </Typography>
          </Link>
        </Grid>
      </Grid>
    </>
  );
}
