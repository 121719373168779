const comunasData = {
  macul: {
    url: "macul",
    comun: "Macul",
    region: "Region Metropolitana",
  },
  vinaDelMar: {
    url: "vina-del-mar",
    comun: "Viña del Mar",
    region: "Region Valparaiso",
  },
  vitacura: {
    url: "vitacura",
    comun: "Vitacura",
    region: "Region Metropolitana",
  },
  santiago: {
    url: "santiago",
    comun: "Santiago",
    region: "Region Metropolitana",
  },
  nunoa: {
    url: "nunoa",
    comun: "Ñuñoa",
    region: "Region Metropolitana",
  },
  providencia: {
    url: "providencia",
    comun: "Providencia",
    region: "Region Metropolitana",
  },
  sanRamon: {
    url: "san-ramon",
    comun: "San Ramón",
    region: "Region Metropolitana",
  },
  laCisterna: {
    url: "la-cisterna",
    comun: "La Cisterna",
    region: "Region Metropolitana",
  },
  quilpue: {
    url: "quilpue",
    comun: "Quilpué",
    region: "Region Valparaiso",
  },
  laSerena: {
    url: "la-serena",
    comun: "La Serena",
    region: "Region Coquimbo",
  },
  puenteAlto: {
    url: "puente-alto",
    comun: "Puente Alto",
    region: "Region Metropolitana",
  },
  sanMiguel: {
    url: "san-miguel",
    comun: "San Miguel",
    region: "Region Metropolitana",
  },
  penalolen: {
    url: "penalolen",
    comun: "Peñalolén",
    region: "Region Metropolitana",
  },
  laGranja: {
    url: "la-granja",
    comun: "La Granja",
    region: "Region Metropolitana",
  },
  estacionCentral: {
    url: "estacion-central",
    comun: "Estación Central",
    region: "Region Metropolitana",
  },
  independencia: {
    url: "independencia",
    comun: "Independencia",
    region: "Region Metropolitana",
  },
  quintaNormal: {
    url: "quinta-normal",
    comun: "Quinta Normal",
    region: "Region Metropolitana",
  },
  sanJoaquin: {
    url: "san-joaquin",
    comun: "San Joaquín",
    region: "Region Metropolitana",
  },
  coquimbo: {
    url: "coquimbo",
    comun: "Coquimbo",
    region: "Region Coquimbo",
  },
  cerrillos: {
    url: "cerrillos",
    comun: "Cerrillos",
    region: "Region Metropolitana",
  },
  elBosque: {
    url: "el-bosque",
    comun: "El Bosque",
    region: "Region Metropolitana",
  },
  laPintana: {
    url: "la-pintana",
    comun: "La Pintana",
    region: "Region Metropolitana",
  },
  maipu: {
    url: "maipu",
    comun: "Maipú",
    region: "Region Metropolitana",
  },
  pedroAguirreCerda: {
    url: "pedro-aguirre-cerda",
    comun: "Pedro Aguirre Cerda",
    region: "Region Metropolitana",
  },
  recoleta: {
    url: "recoleta",
    comun: "Recoleta",
    region: "Region Metropolitana",
  },
  valparaiso: {
    url: "valparaiso",
    comun: "Valparaíso",
    region: "Region Valparaiso",
  },
  villaAlemana: {
    url: "villa-alemana",
    comun: "Villa Alemana",
    region: "Region Valparaiso",
  },
  conchali: {
    url: "conchali",
    comun: "Conchalí",
    region: "Region Metropolitana",
  },
  huechuraba: {
    url: "huechuraba",
    comun: "Huechuraba",
    region: "Region Metropolitana",
  },
  loEspejo: {
    url: "lo-espejo",
    comun: "Lo Espejo",
    region: "Region Metropolitana",
  },
  pudahuel: {
    url: "pudahuel",
    comun: "Pudahuel",
    region: "Region Metropolitana",
  },
  renca: {
    url: "renca",
    comun: "Renca",
    region: "Region Metropolitana",
  },
  loBarnechea: {
    url: "lo-barnechea",
    comun: "Lo Barnechea",
    region: "Region Metropolitana",
  },
  sanBernardo: {
    url: "san-bernardo",
    comun: "San Bernardo",
    region: "Region Metropolitana",
  },
  antofagasta: {
    url: "antofagasta",
    comun: "Antofagasta",
    region: "Region Antofagasta",
  },
  cerroNavia: {
    url: "cerro-navia",
    comun: "Cerro Navia",
    region: "Region Metropolitana",
  },
  loPrado: {
    url: "lo-prado",
    comun: "Lo Prado",
    region: "Region Metropolitana",
  },
  quilicura: {
    url: "quilicura",
    comun: "Quilicura",
    region: "Region Metropolitana",
  },
  concon: {
    url: "concon",
    comun: "Concón",
    region: "Region Valparaiso",
  },
  chiguayante: {
    url: "chiguayante",
    comun: "Chiguayante",
    region: "Region Biobio",
  },
  concepcion: {
    url: "concepcion",
    comun: "Concepción",
    region: "Region Biobio",
  },
  hualpen: {
    url: "hualpen",
    comun: "Hualpén",
    region: "Region Biobio",
  },
  penco: {
    url: "penco",
    comun: "Penco",
    region: "Region Biobio",
  },
  sanPedroDeLaPaz: {
    url: "san-pedro-de-la-paz",
    comun: "San Pedro de La Paz",
    region: "Region Biobio",
  },
  talcahuano: {
    url: "talcahuano",
    comun: "Talcahuano",
    region: "Region Biobio",
  },
  tome: {
    url: "tome",
    comun: "Tomé",
    region: "Region Biobio",
  },
  laFlorida: {
    url: "la-florida",
    comun: "La Florida",
    region: "Region Metropolitana",
  },
  laReina: {
    url: "la-reina",
    comun: "La Reina",
    region: "Region Metropolitana",
  },
  lasCondes: {
    url: "las-condes",
    comun: "Las Condes",
    region: "Region Metropolitana",
  },
};

export default comunasData;
