import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogContent,
  Typography,
  Button,
  TextField,
  Box,
  IconButton,
  styled,
  useTheme,
  Snackbar,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { isMobile } from "react-device-detect";
import callApi from "../../../services/callApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "../../../components/Loader";

const TabButton = styled(Button)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  borderBottom: active ? `2px solid ${theme.palette.primary.main}` : "none",
  borderRadius: 0,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
  },
}));

const WhiteTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: isMobile ? "14px" : "18px",
    fontFamily: "Quicksand-Regular",
  },
  "& .MuiInputLabel-root": {
    fontSize: isMobile ? "14px" : "18px",
    fontFamily: "Quicksand-Regular",
  },
});

const colorChange = keyframes`
  0% { background-color: #3686B1; }
  50% { background-color: #8F58B4; }
  100% { background-color: #3686B1; }
`;

const AnimatedButton = styled(Button)(({ theme }) => ({
  color: "white",
  animation: `${colorChange} 2s infinite`,
}));

const RefinedContactModal = ({ open, onClose }) => {
  const MySwal = withReactContent(Swal);
  const [contactMethod, setContactMethod] = useState("whatsapp");
  const [name, setName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const videoUrl =
    "https://teradomus-storage-public.s3.amazonaws.com/LP+FINAL+FINAL.mp4";

  useEffect(() => {
    setContactInfo("");
  }, [contactMethod]);

  const handleContactInfoChange = (e) => {
    const value = e.target.value;
    if (contactMethod === "whatsapp") {
      let formattedValue = value.replace(/[^\d+]/g, "");
      if (formattedValue.startsWith("+")) {
        formattedValue = formattedValue.slice(0, 13);
      } else {
        formattedValue = formattedValue.slice(0, 11);
      }
      setContactInfo(formattedValue);
    } else {
      setContactInfo(value);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !contactInfo) {
      MySwal.fire({
        title: "Por favor, complete todos los campos",
        icon: "warning",
        customClass: {
          container: "my-swal",
        },
      });
      return;
    }

    setIsLoading(true);
    try {
      const apiData = {
        nombre: name,
        [contactMethod]: contactInfo,
      };

      const result = await callApi(
        "/teradomus/landingPageFormularioInicial",
        "post",
        apiData
      );

      setIsLoading(false);

      if (result?.statusCode === 200) {
        MySwal.fire({
          title: "¡Gracias! Nos pondremos en contacto pronto.",
          icon: "success",
          customClass: {
            container: "my-swal",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            onClose();
          }
        });
      } else {
        throw new Error("Respuesta inesperada del servidor");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error al enviar el formulario:", error);
      MySwal.fire({
        title:
          "Hubo un error al enviar el formulario. Por favor, intente nuevamente.",
        icon: "error",
        customClass: {
          container: "my-swal",
        },
      });
    }
  };
  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        maxWidth={isMobile ? false : "lg"}
        fullWidth
        PaperProps={{
          style: {
            borderRadius: isMobile ? 8 : 16,
            margin: isMobile ? 0 : 32,
            width: isMobile ? "95%" : "auto",
            maxWidth: isMobile ? "100%" : "lg",
          },
        }}
      >
        <DialogContent
          sx={{
            p: isMobile ? 1.5 : 3,
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            position: "relative",
          }}
        >
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: isMobile ? 0 : 0,
              top: isMobile ? 0 : 0,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <img
              src="/cerrar.svg"
              alt="Cerrar"
              style={{
                width: "22px",
                height: "22px",
                filter:
                  "brightness(0) saturate(100%) invert(50%) sepia(0%) saturate(0%) hue-rotate(153deg) brightness(91%) contrast(93%)",
              }}
            />
          </IconButton>

          {isMobile && (
            <Box
              sx={{
                width: "100%",
                mb: 3,
                mt: 3,
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {" "}
              <video
                width="100%"
                height="auto"
                controls
                autoPlay
                borderRadius="5px"
              >
                <source src={videoUrl} type="video/mp4" />
                Su navegador no soporta el tag de video.
              </video>
            </Box>
          )}

          <Box
            sx={{
              width: isMobile ? "100%" : "50%",
              pr: isMobile ? 0 : 4,
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Box>
              <Typography
                variant="h2"
                sx={{
                  mb: 2,
                  fontSize: isMobile ? "20px" : "30px",
                  fontFamily: "Quicksand-Bold",
                }}
              >
                ¡Queremos ser tu aliado!
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  fontFamily: "Quicksand-Regular",
                  fontSize: isMobile ? "14px" : "18px",
                }}
              >
                Nos ocupamos de tus tareas administrativas para que puedas
                enfocarte en lo más importante
              </Typography>
              <Typography
                variant="h5"
                sx={{
                  mb: 1,
                  fontFamily: "Quicksand-Bold",
                  fontSize: isMobile ? "16px" : "24px",
                }}
              >
                Hablemos hoy
              </Typography>
              <Typography
                sx={{
                  mb: 1,
                  fontFamily: "Quicksand-Regular",
                  fontSize: isMobile ? "14px" : "18px",
                }}
              >
                ¿por dónde conversamos?
              </Typography>
              <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
                <TabButton
                  onClick={() => setContactMethod("whatsapp")}
                  active={contactMethod === "whatsapp"}
                >
                  WhatsApp
                </TabButton>
                <TabButton
                  onClick={() => setContactMethod("email")}
                  active={contactMethod === "email"}
                >
                  E-mail
                </TabButton>
              </Box>
            </Box>
            <Box
              component="form"
              onSubmit={handleSubmit}
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <WhiteTextField
                fullWidth
                variant="outlined"
                label="Nombre"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <Box sx={{ position: "relative" }}>
                <WhiteTextField
                  fullWidth
                  variant="outlined"
                  type={contactMethod === "email" ? "email" : "tel"}
                  label={
                    contactMethod === "email"
                      ? "Correo electrónico"
                      : "Número de WhatsApp"
                  }
                  value={contactInfo}
                  onChange={handleContactInfoChange}
                  inputProps={{
                    inputMode:
                      contactMethod === "whatsapp" ? "numeric" : "email",
                  }}
                />
                <AnimatedButton
                  type="submit"
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: "50%",
                    transform: "translateY(-50%)",
                    borderRadius: 2,
                    textTransform: "none",
                  }}
                >
                  ✨ Conversemos
                </AnimatedButton>
              </Box>
            </Box>
          </Box>

          {!isMobile && (
            <Box
              sx={{
                width: "50%",
                pl: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  bgcolor: "black",
                  borderRadius: 2,
                  mr: 2,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {" "}
                <video width="100%" height="auto" controls autoPlay>
                  <source src={videoUrl} type="video/mp4" />
                  Su navegador no soporta el tag de video.
                </video>
              </Box>
            </Box>
          )}
        </DialogContent>
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 9999,
            }}
          >
            <Loader isLoading={true} />
          </Box>
        )}
      </Dialog>
    </>
  );
};

export default RefinedContactModal;
