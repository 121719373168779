import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { isDesktop, isMobile } from 'react-device-detect';
import { Link } from "@material-ui/core";
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import { Helmet } from 'react-helmet';

import Somos from "../../assets/blog/somos_teradomus.jpg";
import Astronauta from "../../assets/blog/astronauta.jpg";


export default function BlogSomos() {
  const history = useHistory();
  const pageTitle = "Teradomos news: enterate de lo ultimo para tu salud";
  const pageDescription = "Aprende sobre Teradomus, un equipo de expertos en salud y tecnología dedicados a ofrecer servicios de rehabilitación innovadores y personalizados.";


  const handleAgendaOnline = () => {
    history.push("/kinesiologia-a-domicilio/agenda-inteligente/inicio");
  };

  const handleAgendarPorTelefono = () => {
    window.location.href = 'tel:56996906773';
  };
  return (
    <>
          <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/blog/somos" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://www.teradomus.com/blog/somos" />
      </Helmet>
      <Container>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Link href="/blog"
              underline="none"
              color="inherit">
              <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 2 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
                ← Volver al listado de artículos
              </Typography>
            </Link>
          </Grid>
          {isDesktop && (
            <Grid item md={12} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 2, xs: 2 } }}>
                <img src={Somos} style={{ width: 'auto', height: isMobile ? '200px' : '380px' }} alt='logo' />
              </Box>
            </Grid>)}
          <Grid item md={12} xs={12}>
            <Typography variant='h2' sx={{ mt: { md: 4, xs: 2 }, fontSize: { md: "22px", xs: "18px" }, fontFamily: 'Quicksand-Bold' }}>
            Teradomus, un equipo trabajando por tu rehabilitación.
            </Typography>
          </Grid>
          <Grid item md={5} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 2, xs: 2 } }}>
              <img src={Astronauta} style={{ width: 'auto', height: isMobile ? '200px' : '380px' }} alt='logo' />
            </Box>
          </Grid>
          <Grid item md={7} xs={12}>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              Teradomus nace durante el primer semestre del 2023 con una misión, resolver las fricciones en el mundo de la Salud, específicamente aquellas a las que se enfrentan los pacientes y kinesiólogos al momento de solicitar y realizar una atención de kinesiología. En palabras simples, conectamos a los pacientes con el kinesiólogo ideal para su tratamiento.
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 4, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              En julio del 2023 Teradomus fue seleccionado dentro del programa Build de Startup Chile, programa bajo el alero de Corfo y el Gobierno de Chile que potencia emprendimientos de alto impacto de base tecnológica, para que usen a Chile como plataforma para escalar globalmente.
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 4, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              Actualmente Teradomus está formado por un equipo de profesionales de la salud, ingeniería y tecnología que, en conjunto suman más de 20 años de experiencia. El conjunto de las habilidades y capacidades de nuestro equipo ha sido esencial para lograr las metas y avances de estos últimos meses. Hoy contamos con servicios de varias especialidades de kinesiología, capacidad de atender de forma presencial y remota, además de estar presentes en más de 50 comunas de las regiones de Santiago, Valparaíso, Biobío, Coquimbo y Antofagasta.
            </Typography>
          </Grid>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            En Teradomus, no solo nos centramos en la calidad y seguridad de los tratamientos de kinesiología, sino que también buscamos innovar en cómo se entregan estos servicios. Nuestra apuesta es: integrar tecnología e inteligencia artificial para transformar la experiencia tanto de pacientes como de kinesiólogos.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            ¿Qué significa esto en la práctica?
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Para los pacientes, esto se traduce tener acceso a una atención de salud personalizada y eficiente, adaptándonos a sus horarios y necesidades, estén donde estén. Para los kinesiólogos, es una oportunidad de mejorar su entorno laboral, ofreciéndoles herramientas que les permitan enfocarse en lo que mejor saben hacer, cuidar de sus pacientes.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            ¿Por qué incorporar tecnología?
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Este enfoque tecnológico es nuestra respuesta a las necesidades de la comunidad. La atención de kinesiología que ofrecemos va más allá de los tratamientos y ejercicios: es crear un entorno en el que cada paciente pueda experimentar una recuperación que sea no solo rápida y segura, sino también profundamente humanizada y personalizada a sus necesidades.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Da el Siguiente Paso con Teradomus
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            ¿Interesado en un servicio de rehabilitación que se ajusta a tus necesidades? Para pacientes y kinesiólogos, la solución es simple: Teradomus. Contáctanos hoy para comenzar. Tu camino hacia una rehabilitación más personalizada y efectiva empieza aquí.
          </Typography>
        </Grid >
        <Box sx={{ mt: 5, display: 'flex', justifyContent: 'center', gap: { lg: '35px', md: '15px', xs: "10px" } }}>
          <Button
            variant="contained"
            sx={{
              paddingLeft: { lg: 3, md: 1, xs: 0.5 },
              paddingRight: { lg: 3, md: 3, xs: 0.5 },
              fontFamily: 'Quicksand-Bold',
              fontSize: { lg: "20px", md: "18px", xs: "15px" },
              textTransform: 'none',
              borderRadius: 9,
              backgroundColor: '#8753b1',
              '&:hover': { backgroundColor: '#651d87' }
            }}
            onClick={handleAgendaOnline}
          >
            Agenda Online
          </Button>
          <Button
            variant="contained"
            sx={{
              paddingLeft: { lg: 3, md: 1, xs: 0.5 },
              paddingRight: { lg: 3, md: 3, xs: 0.5 },
              fontFamily: 'Quicksand-Bold',
              fontSize: { lg: "20px", md: "18px", xs: "15px" },
              textTransform: 'none',
              borderRadius: 9,
              backgroundColor: '#8753b1',
              '&:hover': { backgroundColor: '#651d87' }
            }}
            onClick={handleAgendarPorTelefono}
          >
            Agendar por teléfono
          </Button>
        </Box>
        <Link href="/blog"
          underline="none"
          color="inherit">
          <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 5 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
            ← Volver al listado de artículos
          </Typography>
        </Link>
      </Container >
    </>
  );
}