import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Container, Grid, Typography } from '@mui/material';
import TelWhat from '../../TelWhat/TelWhat';
import { Button } from '@mui/material';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../../services/callApi";
import './Switch.css';

import Loader from "../../Loader";

export default function DosCajasc({ onPlanaChange }) {
    const [isSelected, setIsSelected] = useState(false); // false = 1, true = 2
    const [isSelected2, setIsSelected2] = useState(false); // false = 1, true = 2

    let price;
    let box1Text1;
    if (isSelected === false && isSelected2 === false) { price = "$290.000"; box1Text1 = "8 sesiones mensuales (2 semanales)"; }
    else if (isSelected === false && isSelected2 === true) { price = "$440.000"; box1Text1 = "12 sesiones mensuales (3 semanales)"; }
    else if (isSelected === true && isSelected2 === false) { price = "$390.000"; box1Text1 = "12 sesiones mensuales (3 semanales)"; }
    else if (isSelected === true && isSelected2 === true) { price = "$540.000"; box1Text1 = "16 sesiones mensuales (4 semanales)"; }

    const [box1Color, setBox1Color] = useState('linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))');
    const [box1TextColor, setBox1TextColor] = useState('white');

    const selectedPrice = useState(price);

    const MySwal = withReactContent(Swal);
    const [isLoading, setIsLoading] = React.useState(false);
    const [nombre, setNombre] = React.useState("");
    const [telefono, setTelefono] = React.useState("");
    const [correo, setCorreo] = React.useState("");
    const [comuna, setComuna] = React.useState(0);

    const handleChangeNombre = (event) => setNombre(event.target.value);
    const handleChangeCelular = (event) => setTelefono(event.target.value);
    const handleChangeCorreo = (event) => setCorreo(event.target.value);
    const handleChangeCity = (event) => setComuna(event.target.value);

    const handleBox1Click = () => {
        setBox1Color('linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))');
        setBox1TextColor('white');

        // Actualiza el precio seleccionado al hacer clic en la primera caja
        onPlanaChange({ box1Text1 });
    };

    async function handleSubmit(event) {

        if (nombre === "" || telefono === ""
            || correo === "") {
            MySwal.fire("Llena los campos obligatorios!", "", "info");

            return null;
        }
        const formatoValido = /^[\d\s+]+$/.test(telefono);
        const correoValido = /^\S+@\S+\.\S+$/.test(correo);

        if (!formatoValido) {
            MySwal.fire("Ingresa un número telefónico válido", "", "info");
            return null;
        }

        if (!correoValido) {
            MySwal.fire("Ingresa un correo electrónico válido", "", "info");
            return null;
        }

        const today = new Date();
        const day = String(today.getDate()).padStart(2, '0');
        const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses en JavaScript comienzan en 0, por lo que debemos sumar 1
        const year = today.getFullYear();
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');

        const formattedDate = `${day}/${month}/${year}`;
        const formattedTime = `${hours}:${minutes}`;

        const all_data = {
            identificacion: '',
            nombre: nombre,
            apellido: '',
            antecedentes: selectedPrice,
            diagnostico: '',
            correo: correo,
            celular: telefono,
            edad: 1,
            direccion: '',
            comuna: comuna,
            depto: '',
            info: '',
            dia: formattedDate,
            hora: formattedTime,
            especialidad: 'plan c',
            cantidad_sesiones: 1,
            derivador: 'teradomus',
        };

        setIsLoading(true);
        const result = await callApi("/receive-solicitud", "post", all_data);
        setIsLoading(false);

        if (result?.statusCode === 200) {
            MySwal.fire("Gracias por su solicitud, pronto nos contactaremos con usted", "", "success");
        } else {
            console.log(result);
            MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
        }
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <Box sx={{
                backgroundImage: 'linear-gradient(to right, #eef2f6, #f7f5f6)', mt: 5
            }}>
                <Typography variant='h3' sx={{
                    color: '#1e7aa8', textAlign: "center",
                    paddingTop: { xs: 4, lg: 6 }, fontFamily: 'Quicksand-Bold',
                    fontSize: { lg: "30px", md: "22px", xs: "18px" },
                }}>
                    Simula tu plan
                </Typography>
                <Typography sx={{ mt: { xs: 2, lg: 3 }, color: '#aeaeaf', textAlign: "center", fontSize: { lg: "18px", md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Regular' }}>
                    Elige la cantidad de sesiones semanales y paga mensualmente
                </Typography>
                <Box sx={{
                    width: { xs: "85%", lg: "63%" },
                    borderRadius: 8, margin: "auto",
                    mt: { xs: 5, lg: 6 }, boxShadow: '8px 8px 8px 2px rgba(0, 0, 0, 0.16)',
                    mb: { xs: 0, md: 0, lg: 10 },
                    p: 3, bgcolor: "white"
                }}>
                    <Container>
                        <Typography sx={{
                            mb: { xs: 2, lg: 1 }, textAlign: "center", color: '#1e7aa8', mt: { xs: 2, lg: 2 },
                            fontSize: { lg: "18px", md: "16px", xs: "14px" }, fontFamily: 'Quicksand-Regular'
                        }}>
                            Número de sesiones de <b>ejercicio y rehabilitación</b> a la semana
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '30px', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#1e7aa8', fontSize: { lg: "40px", md: "26px", xs: "23px" }, fontFamily: 'Quicksand-Bold' }}>
                                1</Typography>
                            <div className="switch-box" onClick={() => setIsSelected(!isSelected)}>
                                <div className={isSelected ? "circle right" : "circle left"}></div>
                            </div>
                            <Typography sx={{ color: '#1e7aa8', fontSize: { lg: "40px", md: "26px", xs: "23px" }, fontFamily: 'Quicksand-Bold' }}>
                                2</Typography>
                        </Box>
                        <Typography sx={{
                            mt: { xs: 2, lg: 3 }, mb: { xs: 2, lg: 1 }, color: '#1e7aa8', textAlign: "center",
                            fontSize: { lg: "18px", md: "16px", xs: "14px" }, fontFamily: 'Quicksand-Regular'
                        }}>
                            Número de sesiones <b> sociales y recreativas</b> a la semana
                        </Typography>
                        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '30px', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography sx={{ color: '#1e7aa8', fontSize: { lg: "40px", md: "26px", xs: "23px" }, fontFamily: 'Quicksand-Bold' }}>
                                1</Typography>
                            <div className="switch-box" onClick={() => setIsSelected2(!isSelected2)}>
                                <div className={isSelected2 ? "circle right" : "circle left"}></div>
                            </div>
                            <Typography sx={{ color: '#1e7aa8', fontSize: { lg: "40px", md: "26px", xs: "23px" }, fontFamily: 'Quicksand-Bold' }}>
                                2</Typography>
                        </Box>
                        <Grid
                            container
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item xs={11} lg={5}>
                                <Box
                                    sx={{
                                        mt: { xs: 4, lg: 4 }, mb: { xs: 3, lg: 3 },
                                        display: 'flex', height: { xs: "90px", lg: "120px" }, margin: 'auto',
                                        alignItems: 'center', width: { xs: "100%", lg: "100%" },
                                        justifyContent: "center",
                                        backgroundColor: box1Color === 'white' ? box1Color : undefined, // Si es blanco, usar backgroundColor
                                        backgroundImage: box1Color !== 'white' ? box1Color : undefined, // Si no es blanco, usar backgroundImage
                                        flexDirection: "column", borderRadius: '13px',
                                        boxShadow: '8px 8px 8px 2px rgba(0, 0, 0, 0.16)',
                                    }}
                                    onClick={handleBox1Click}
                                >
                                    <Typography sx={{
                                        fontFamily: 'Quicksand-Bold',
                                        fontSize: { xs: "16px", md: '16px', lg: "20px" }, color: box1TextColor
                                    }}>
                                        {price}
                                    </Typography>
                                    <Typography sx={{
                                        mt: 1,
                                        mb: { xs: 2, lg: 2 }, fontFamily: 'Quicksand-Regular', fontSize: { xs: "14px", md: '16px', lg: "20px" }, color: box1TextColor
                                    }}>
                                        {box1Text1}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <Box
                    sx={{
                        width: { xs: "85%", lg: "63%" },
                        borderRadius: 8, margin: "auto",
                        mt: { xs: 8, lg: 8 },
                        mb: { xs: 0, md: 0, lg: 10 },
                        p: 3, bgcolor: "white", boxShadow: '8px 8px 8px 2px rgba(0, 0, 0, 0.16)'
                    }}
                >
                    <Container>
                        <Typography
                            sx={{
                                color: '#1e7aa8', textAlign: "center",
                                fontFamily: 'Quicksand-Bold',
                                fontSize: { xs: "14px", md: '16px', lg: "20px" },
                            }}
                        >
                            Para contratar dejanos tus datos y te contactaremos
                        </Typography>
                        <Grid item xs={12} lg={12}>
                            <Typography
                                sx={{
                                    pt: 1, fontFamily: 'Quicksand-Light',
                                    fontSize: { xs: "14px", md: '16px', lg: "18px" },
                                }}
                            >
                                Nombre
                            </Typography>
                        </Grid>
                        <Box
                            sx={{
                                borderRadius: 2, // Ajusta el valor según tu preferencia
                                overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                                backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                            }}
                        >
                            <input id="name" name="name"
                                onChange={handleChangeNombre}
                                style={{
                                    border: 'none', // Quitamos el borde nativo del input
                                    width: '100%', // Ajusta el ancho del input según tus necesidades
                                    outline: 'none', // Quitamos el contorno al seleccionar el input
                                    background: 'transparent', // Hacemos el fondo transparente
                                    fontFamily: 'Quicksand-Regular',
                                    color: "#4F4F4F",
                                    fontSize: '16px',
                                }}
                            />
                        </Box>
                        <Grid item xs={12} lg={12}>
                            <Typography
                                sx={{
                                    pt: 2, fontFamily: 'Quicksand-Light',
                                    fontSize: { xs: "14px", md: '16px', lg: "18px" },
                                }}
                            >
                                Teléfono
                            </Typography>
                        </Grid>
                        <Box
                            sx={{
                                borderRadius: 2, // Ajusta el valor según tu preferencia
                                overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                                backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                            }}
                        >
                            <input
                                onChange={handleChangeCelular}
                                style={{
                                    border: 'none', // Quitamos el borde nativo del input
                                    width: '100%', // Ajusta el ancho del input según tus necesidades
                                    outline: 'none', // Quitamos el contorno al seleccionar el input
                                    background: 'transparent', // Hacemos el fondo transparente
                                    fontFamily: 'Quicksand-Regular',
                                    color: "#4F4F4F",
                                    fontSize: '16px',
                                }}
                            />
                        </Box>

                        <Grid item xs={12} lg={12}>
                            <Typography
                                sx={{
                                    pt: 1, fontFamily: 'Quicksand-Light',
                                    fontSize: { xs: "14px", md: '16px', lg: "18px" },
                                }}
                            >
                                Correo
                            </Typography>
                        </Grid>
                        <Box
                            sx={{
                                borderRadius: 2, // Ajusta el valor según tu preferencia
                                overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                                backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes

                            }}
                        >
                            <input id="email" name="email"
                                onChange={handleChangeCorreo}
                                style={{
                                    border: 'none', // Quitamos el borde nativo del input
                                    width: '100%', // Ajusta el ancho del input según tus necesidades
                                    outline: 'none', // Quitamos el contorno al seleccionar el input
                                    background: 'transparent', // Hacemos el fondo transparente
                                    fontFamily: 'Quicksand-Regular',
                                    color: "#4F4F4F",
                                    fontSize: '16px',
                                }}
                            />
                        </Box>
                        <Grid item xs={12} lg={12}>
                            <Typography
                                sx={{
                                    pt: 1, fontFamily: 'Quicksand-Light',
                                    fontSize: { xs: "14px", md: '16px', lg: "18px" },
                                }}
                            >
                                Comuna
                            </Typography>
                        </Grid>
                        <Box
                            sx={{
                                borderRadius: 2, // Ajusta el valor según tu preferencia
                                overflow: 'hidden', // Para recortar los bordes redondeados del TextField
                                backgroundImage: 'linear-gradient(to bottom, #e7eff5, #f2ebf6)',
                                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes

                            }}
                        >
                            <input id="city"
                                name="city"
                                onChange={handleChangeCity}
                                style={{
                                    border: 'none', // Quitamos el borde nativo del input
                                    width: '100%', // Ajusta el ancho del input según tus necesidades
                                    outline: 'none', // Quitamos el contorno al seleccionar el input
                                    background: 'transparent', // Hacemos el fondo transparente
                                    fontFamily: 'Quicksand-Regular',
                                    color: "#4F4F4F",
                                    fontSize: '16px',
                                }}
                            />
                        </Box>
                        <Box sx={{
                            display: 'flex',       // Agregar display flex para usar flexbox
                            justifyContent: 'center',  // Centrar horizontalmente el botón
                            alignItems: 'center',      // Centrar verticalmente el botón}}>
                        }}>
                            <Button
                                sx={{
                                    fontFamily: 'Quicksand-Bold',
                                    borderRadius: 2,
                                    mt: 3,
                                    paddingTop: 0.5,
                                    paddingBottom: 0.5,
                                    fontSize: { xs: "14px", md: '16px', lg: "20px" },
                                    textTransform: 'none',
                                }}
                                onClick={handleSubmit}
                                variant="contained"
                            >
                                Quiero contratar el servicio
                            </Button>
                        </Box>
                    </Container>
                </Box >
                <Box sx={{ mt: { xs: 8, lg: 8 }, mb: { xs: 1, lg: 2 }, textAlign: "center" }}>
                    <TelWhat />
                </Box>
            </Box>
        </>
    );
}
