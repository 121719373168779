import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  useMediaQuery,
  useTheme,
  Modal,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  styled,
  Grid,
} from "@mui/material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { isMobile } from "react-device-detect";
import { styled as muiStyled } from "@mui/material/styles";
import RefinedContactModal from "./ModalVideo";

import kineportada from "../../../assets/profesionales/foto_felipe_arriba_final.png";
import email from "../../../assets/profesionales/foto_email.png";
import wasap from "../../../assets/profesionales/foto_whatsapp.png";
import AnimatedNotifications from "./KinePortada";

const BlurredBackground = styled(Box)(({ theme }) => ({
  position: "fixed",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.5)",
  backdropFilter: "blur(10px)",
  zIndex: theme.zIndex.modal,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

const ModalContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[24],
  borderRadius: 16,
  padding: theme.spacing(4),
  width: "90%",
  maxWidth: isMobile ? 600 : 1000,
  maxHeight: "90vh",
  overflowY: "auto",
  position: "relative",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
    height: "100%",
    borderRadius: 0,
    maxHeight: "100vh",
  },
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: 50,
  textTransform: "none",
  fontFamily: "Quicksand-Bold",
  padding: "6px 16px",
}));

const BackButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(2),
  left: theme.spacing(2),
  color: theme.palette.primary.main,
  borderColor: theme.palette.primary.main,
  borderRadius: 20,
  textTransform: "none",
  fontFamily: "Quicksand-Regular",
  "&:hover": {
    backgroundColor: "rgba(135, 83, 177, 0.04)",
  },
}));
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  maxWidth: 300,
  margin: "0 auto",
  marginBottom: theme.spacing(2),
  borderRadius: 30,
  padding: 3,
  gap: 10,
  "& .MuiToggleButtonGroup-grouped": {
    margin: 0,
    border: 0,
    "&:not(:first-of-type)": {
      borderRadius: 30,
    },
    "&:first-of-type": {
      borderRadius: 30,
    },
  },
}));

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(1),
  fontFamily: "Quicksand-Bold",
  textTransform: "none",
  borderRadius: 30,
  color: "#000",
  "&.Mui-selected": {
    backgroundImage:
      "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))",
    color: theme.palette.common.white,
  },
  "&:not(.Mui-selected)": {
    border: "1px solid #000",
  },
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledTextField = muiStyled(TextField)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: "20px",
    color: theme.palette.primary.main,
    marginBottom: 10,
    fontFamily: "Quicksand-Bold",

    backgroundColor: "#f7f7f7",
    "& fieldset": {
      borderColor: "transparent",
    },
    "&:hover fieldset": {
      borderColor: "transparent",
    },
    "&.Mui-focused fieldset": {
      borderColor: "transparent",
      boxShadow: "0 0 0 2px rgba(133, 70, 173, 0.2)",
    },
  },
  "& .MuiOutlinedInput-input": {
    padding: "5px 10px",
  },
  "& .MuiInputLabel-outlined": {
    transform: "translate(20px, 16px) scale(1)",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(20px, -6px) scale(0.75)",
  },
}));

const GradientBorderButton = styled(Button)(({ theme }) => ({
  position: "relative",
  isolation: "isolate",
  padding: "8px 20px",
  color: "#216BA8",
  borderRadius: "30px",
  textTransform: "none",
  "&::before": {
    content: '""',
    position: "absolute",
    top: -2,
    left: -2,
    right: -2,
    bottom: -2,
    borderRadius: "inherit",
    padding: "3px",
    background: "linear-gradient(45deg, #3f51b5, #9c27b0)",
    WebkitMask:
      "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
    WebkitMaskComposite: "xor",
    maskComposite: "exclude",
    zIndex: -1,
  },
}));

const GradientContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  overflow: "hidden",
  "&::after": {
    content: '""',
    position: "absolute",
    left: 0,
    right: 0,
    bottom: 0,
    height: "50%",
    background: "linear-gradient(to top, white, transparent)",
    pointerEvents: "none",
  },
}));

const KinesiologoIntro = () => {
  const theme = useTheme();

  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Grid container spacing={3} alignItems="center">
        <Grid item xs={12} md={6.4}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            component="h1"
            sx={{
              fontFamily: "Quicksand-Bold",
              fontSize: isMobile ? "20px" : "30px",
              mt: isMobile ? 10 : 10,
              color: "#454545",
            }}
          >
            Sabemos lo desafiante que es ser un {""}
            <span className="rainbow-text">
              profesional de salud independiente.{" "}
            </span>
          </Typography>
          <Typography
            variant="h2"
            sx={{
              mb: 4,
              fontSize: isMobile ? "20px" : "30px",
              fontFamily: "Quicksand-Bold",
              color: "#454545",
            }}
          >
            {" "}
            ¡Queremos ser tu aliado!
          </Typography>
          {!isMobile && (
            <Typography
              sx={{
                mb: 3,
                fontFamily: "Quicksand-Regular",
                fontSize: isMobile ? "16px" : "20px",
              }}
            >
              Nos ocupamos de tus tareas administrativa para que puedas
              enfocarte en lo más importante,
              <Box
                component="span"
                sx={{
                  color: theme.palette.primary.main,
                  fontWeight: "bold",
                  display: "block",
                }}
              >
                el bienestar de tus pacientes.
              </Box>
            </Typography>
          )}
          {isMobile && (
            <Typography
              sx={{
                mb: 3,
                fontFamily: "Quicksand-Medium",
                fontSize: isMobile ? "16px" : "20px",
                color: "#454545",
              }}
            >
              Nos ocupamos de tus tareas administrativa para que puedas
              enfocarte en lo más importante,{" "}
              <span
                style={{
                  color: theme.palette.primary.main,
                  fontFamily: "Quicksand-Bold",
                }}
              >
                el bienestar de tus pacientes.
              </span>
            </Typography>
          )}

          <Box sx={{ display: "flex", gap: theme.spacing(2), mt: 5 }}>
            <StyledButton
              variant="contained"
              onClick={handleOpenModal}
              sx={{
                backgroundColor: "#216BA8",
              }}
            >
              ¡Quiero saber más!
            </StyledButton>
            <GradientBorderButton
              variant="outlined"
              startIcon={<PlayCircleOutlineIcon />}
              onClick={handleOpenModal}
            >
              Ver video
            </GradientBorderButton>
          </Box>
        </Grid>
        {!isMobile && (
          <Grid item md={5.6}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                overflow: "hidden",
                position: "relative",
                height: "100%",
                mt: 10,
                pl: 5,
                background: "transparent",
              }}
            >
              <GradientContainer sx={{ width: "100%", height: "100%" }}>
                <img
                  src={kineportada}
                  style={{
                    width: "90%",
                    height: "90%",
                    objectFit: "cover",
                    display: "block",
                  }}
                  alt="adulto mayor"
                />
                <AnimatedNotifications />
              </GradientContainer>
            </Box>
          </Grid>
        )}
      </Grid>
      <RefinedContactModal open={openModal} onClose={handleCloseModal} />
    </Box>
  );
};

export default KinesiologoIntro;
