import React, { useState } from "react";
import { Box, Typography, useMediaQuery, useTheme } from "@mui/material";
import SimpleCarousel from "./SimpleCarousel";
import SimpleCarouselPC from "./SimpleCarouselPC";

const PatientCareFeatures = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const features = [
    { title: "Crea tu presencia online, atrae nuevos pacientes" },
    { title: "Controla y automatiza tu agenda, olvídate del caos" },
    { title: "Envía recordatorios por WhatsApp, asegura la asistencia" },
    { title: "Automatiza tus cobros, deja de perseguir a tus pacientes" },
  ];

  return (
    <Box sx={{ mt: { xs: 15, md: 15 }, mb: 4, borderRadius: 2 }}>
      <Box sx={{ width: isMobile ? "100%" : "70%" }}>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: isMobile ? "20px" : "30px",
            color: "#454545",
          }}
        >
          Brindar una buena atención es tu trabajo, déjanos el resto a nosotros.{" "}
        </Typography>
      </Box>
      {isMobile ? (
        <SimpleCarousel features={features} />
      ) : (
        <>
          <SimpleCarouselPC features={features} />
        </>
      )}
    </Box>
  );
};

export default PatientCareFeatures;
