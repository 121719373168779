import React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import logoMercadoPago from "../../assets/Recursos/mercadopago.png";

const BlueCheckbox = styled(Checkbox)(({ theme }) => ({
  color: theme.palette.grey[400],
  "&.Mui-checked": {
    color: "#1976d2",
  },
  "& .MuiSvgIcon-root": {
    fontSize: 28,
  },
}));

const PaymentMethodBox = () => {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        mb: 2,
        paddingTop: 1,
        paddingBottom: 1,
        border: "1px solid #e0e0e0",
        borderRadius: 2,
        width: "100%",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <img
          src={logoMercadoPago}
          alt="MercadoPago Logo"
          style={{ height: "24px", marginRight: "12px" }}
        />
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            fontSize: "14px",
            textAlign: "right",
          }}
        >
          Tarjeta de crédito o débito
        </Typography>
      </Box>
      <BlueCheckbox checked={true} disabled />
    </Box>
  );
};

export default PaymentMethodBox;
