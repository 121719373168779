import Routes from "./routes/Routes";
import { StylesProvider } from "@material-ui/styles";
import { MuiThemeProvider, createTheme } from "@material-ui/core";

const theme = createTheme({
  overrides: {
    MUIDataTable: {
      responsiveScrollMaxHeight: {
        maxHeight: "80vh!important",
      },
    },

    MuiPickersCalendarHeader: {
      iconButton: {
        color: "red",
      },
    },

    MuiPickersDay: {
      daySelected: {
        backgroundColor: "#FF0000",
        "&:hover": {
          backgroundColor: "#FF0000",
        },
      },
      current: {
        color: "#FF0000",
      },
    },

    MuiAppBar: {
      colorPrimary: {
        backgroundColor: "#FF0000",
      },
    },

    MUIDataTableBodyCell: {
      root: {
        backgroundColor: "#FFF",
        fontSize: "0.86rem",
      },
    },

    MUIDataTableFilter: {
      root: {
        minWidth: "300px",
      },
    },

    MuiToolbar: {
      root: {
        color: "#FFF",
      },
    },

    MuiIconButton: {
      root: {
        color: "#FFF",
      },
    },

    MuiInputBase: {
      root: {
        color: "#FFF",
      },
    },

    MuiAccordion: {
      root: {
        backgroundColor: "#FFF",
        color: "#000",
      },
    },

    MuiCheckbox: {
      root: {
        color: "white",
      },
    },

    MuiPaper: {
      elevation0: {
        height: "35vh",
      },
    },
  },

  palette: {
    primary: {
      main: "#ff0000",
    },
    secondary: {
      main: "#ffffff",
    },
  },
  MuiCheckbox: {
    color: "white",
  },
});

function App() {
  return (
    <>
      <MuiThemeProvider theme={theme}>
        <StylesProvider>
          <Routes />
        </StylesProvider>
      </MuiThemeProvider>
    </>
  );
}

export default App;
