import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Container, Grid, Typography } from "@mui/material";
import TelWhat from "../../TelWhat/TelWhat";
import { Button } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../../services/callApi";

import Loader from "../../Loader";

export default function DosCajasb({ onPlanaChange }) {
  const [box1Text] = useState("$ 180.000/ mes");
  const [box1Text1] = useState("4 sesiones mensuales (1 semanal)");
  const [box1Color, setBox1Color] = useState(
    "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
  );
  const [box1TextColor, setBox1TextColor] = useState("white");

  const [box2Text] = useState("$ 340.000/ mes");
  const [box2Text2] = useState("8 sesiones mensuales (2 semanales)");
  const [box2Color, setBox2Color] = useState("white");
  const [box2TextColor, setBox2TextColor] = useState("#aeaeaf");

  const [selectedPrice, setSelectedPrice] = useState(box1Text);

  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const [nombre, setNombre] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [comuna, setComuna] = React.useState(0);

  const handleChangeNombre = (event) => setNombre(event.target.value);
  const handleChangeCelular = (event) => setTelefono(event.target.value);
  const handleChangeCorreo = (event) => setCorreo(event.target.value);
  const handleChangeCity = (event) => setComuna(event.target.value);

  const handleBox1Click = () => {
    setBox1Color(
      "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
    );
    setBox1TextColor("white");
    setBox2Color("white"); // Resetear el fondo de la caja 2
    setBox2TextColor("#aeaeaf");

    // Actualiza el precio seleccionado al hacer clic en la primera caja
    setSelectedPrice(box1Text);
    onPlanaChange("4 sesiones");
  };

  const handleBox2Click = () => {
    setBox2Color(
      "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
    );
    setBox2TextColor("white");
    setBox1Color("white"); // Resetear el fondo de la caja 1
    setBox1TextColor("#aeaeaf");

    // Actualiza el precio seleccionado al hacer clic en la segunda caja
    setSelectedPrice(box2Text);
    onPlanaChange("8 sesiones");
  };

  async function handleSubmit(event) {
    if (nombre === "" || telefono === "" || correo === "") {
      MySwal.fire("Llena los campos obligatorios!", "", "info");

      return null;
    }
    const formatoValido = /^[\d\s+]+$/.test(telefono);
    const correoValido = /^\S+@\S+\.\S+$/.test(correo);

    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return null;
    }

    if (!correoValido) {
      MySwal.fire("Ingresa un correo electrónico válido", "", "info");
      return null;
    }

    const today = new Date();
    const day = String(today.getDate()).padStart(2, "0");
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Los meses en JavaScript comienzan en 0, por lo que debemos sumar 1
    const year = today.getFullYear();
    const hours = String(today.getHours()).padStart(2, "0");
    const minutes = String(today.getMinutes()).padStart(2, "0");

    const formattedDate = `${day}/${month}/${year}`;
    const formattedTime = `${hours}:${minutes}`;

    const all_data = {
      identificacion: "",
      nombre: nombre,
      apellido: "",
      antecedentes: selectedPrice,
      diagnostico: "",
      correo: correo,
      celular: telefono,
      edad: 1,
      direccion: "",
      comuna: comuna,
      depto: "",
      info: "",
      dia: formattedDate,
      hora: formattedTime,
      especialidad: "plan b",
      cantidad_sesiones: 1,
      derivador: "teradomus",
    };

    setIsLoading(true);
    const result = await callApi("/receive-solicitud", "post", all_data);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      MySwal.fire(
        "Gracias por su solicitud, pronto nos contactaremos con usted",
        "",
        "success"
      );
    } else {
      console.log(result);
      MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Box
        sx={{
          backgroundImage: "linear-gradient(to right, #eef2f6, #f7f5f6)",
          mt: 5,
        }}
      >
        <Typography
          variant="h3"
          sx={{
            color: "#1e7aa8",
            textAlign: "center",
            paddingTop: 6,
            fontFamily: "Quicksand-Bold",
            fontSize: { lg: "30px", md: "22px", xs: "18px" },
          }}
        >
          Valores del plan
        </Typography>
        <Box
          sx={{
            marginInline: "auto",
            borderRadius: 3,
            mt: { lg: 4, md: 3, xs: 2 },
            mb: { lg: 4, md: 3, xs: 2 },
            width: "70px",
            height: "4.5px",
            bgcolor: "#1e7aa8",
          }}
        ></Box>
        <Typography
          sx={{
            mb: { xs: 1, lg: 4 },
            textAlign: "center",
            fontSize: { lg: "18px", md: "16px", xs: "13px" },
            fontFamily: "Quicksand-Regular",
          }}
        >
          Elige la cantidad de sesiones semanales y paga mensualmente
        </Typography>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            gap="40px"
          >
            <Grid item xs={12} lg={3.5}>
              <Box
                sx={{
                  mt: { xs: 3, lg: 4 },
                  mr: { xs: 1, lg: 3 },
                  display: "flex",
                  height: "120px",
                  alignItems: "center",
                  width: { xs: "100%", lg: "110%" },
                  justifyContent: "center",
                  backgroundColor:
                    box1Color === "white" ? box1Color : undefined, // Si es blanco, usar backgroundColor
                  backgroundImage:
                    box1Color !== "white" ? box1Color : undefined, // Si no es blanco, usar backgroundImage
                  flexDirection: "column",
                  borderRadius: "13px",
                  boxShadow: "8px 8px 8px 2px rgba(0, 0, 0, 0.16)",
                }}
                onClick={handleBox1Click}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "14px", md: "16px", lg: "20px" },
                    color: box1TextColor,
                  }}
                >
                  {box1Text}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 2,
                    fontFamily: "Quicksand-Regular",
                    fontSize: { xs: "14px", md: "16px", lg: "20px" },
                    color: box1TextColor,
                  }}
                >
                  {box1Text1}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={3.5}>
              <Box
                sx={{
                  mt: { xs: 0, lg: 4 },
                  ml: { xs: 0, lg: 3 },
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:
                    box2Color === "white" ? box2Color : undefined, // Si es blanco, usar backgroundColor
                  backgroundImage:
                    box2Color !== "white" ? box2Color : undefined, // Si no es blanco, usar backgroundImage
                  flexDirection: "column",
                  borderRadius: "13px",
                  width: { xs: "100%", lg: "110%" },
                  boxShadow: "8px 8px 8px 2px rgba(0, 0, 0, 0.16)",
                  height: "120px",
                }}
                onClick={handleBox2Click}
              >
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "14px", md: "16px", lg: "20px" },
                    color: box2TextColor,
                  }}
                >
                  {box2Text}
                </Typography>
                <Typography
                  sx={{
                    mt: 1,
                    mb: 2,
                    fontFamily: "Quicksand-Regular",
                    fontSize: { xs: "14px", md: "16px", lg: "20px" },
                    color: box2TextColor,
                  }}
                >
                  {box2Text2}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            width: { xs: "85%", lg: "63%" },
            borderRadius: 8,
            margin: "auto",
            mt: { xs: 8, lg: 8 },
            mb: { xs: 0, md: 0, lg: 10 },
            p: 3,
            bgcolor: "white",
          }}
        >
          <Container>
            <Typography
              sx={{
                color: "#1e7aa8",
                textAlign: "center",
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "14px", md: "16px", lg: "20px" },
              }}
            >
              Para contratar dejanos tus datos y te contactaremos
            </Typography>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  pt: 1,
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "14px", md: "16px", lg: "18px" },
                }}
              >
                Nombre
              </Typography>
            </Grid>
            <Box
              sx={{
                borderRadius: 2, // Ajusta el valor según tu preferencia
                overflow: "hidden", // Para recortar los bordes redondeados del TextField
                backgroundImage: "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
              }}
            >
              <input
                id="name"
                name="name"
                onChange={handleChangeNombre}
                style={{
                  border: "none", // Quitamos el borde nativo del input
                  width: "100%", // Ajusta el ancho del input según tus necesidades
                  outline: "none", // Quitamos el contorno al seleccionar el input
                  background: "transparent", // Hacemos el fondo transparente
                  fontFamily: "Quicksand-Regular",
                  color: "#4F4F4F",
                  fontSize: "16px",
                }}
              />
            </Box>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  pt: 2,
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "14px", md: "16px", lg: "18px" },
                }}
              >
                Teléfono
              </Typography>
            </Grid>
            <Box
              sx={{
                borderRadius: 2, // Ajusta el valor según tu preferencia
                overflow: "hidden", // Para recortar los bordes redondeados del TextField
                backgroundImage: "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
              }}
            >
              <input
                onChange={handleChangeCelular}
                style={{
                  border: "none", // Quitamos el borde nativo del input
                  width: "100%", // Ajusta el ancho del input según tus necesidades
                  outline: "none", // Quitamos el contorno al seleccionar el input
                  background: "transparent", // Hacemos el fondo transparente
                  fontFamily: "Quicksand-Regular",
                  color: "#4F4F4F",
                  fontSize: "16px",
                }}
              />
            </Box>

            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  pt: 1,
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "14px", md: "16px", lg: "18px" },
                }}
              >
                Correo
              </Typography>
            </Grid>
            <Box
              sx={{
                borderRadius: 2, // Ajusta el valor según tu preferencia
                overflow: "hidden", // Para recortar los bordes redondeados del TextField
                backgroundImage: "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
              }}
            >
              <input
                id="email"
                name="email"
                onChange={handleChangeCorreo}
                style={{
                  border: "none", // Quitamos el borde nativo del input
                  width: "100%", // Ajusta el ancho del input según tus necesidades
                  outline: "none", // Quitamos el contorno al seleccionar el input
                  background: "transparent", // Hacemos el fondo transparente
                  fontFamily: "Quicksand-Regular",
                  color: "#4F4F4F",
                  fontSize: "16px",
                }}
              />
            </Box>
            <Grid item xs={12} lg={12}>
              <Typography
                sx={{
                  pt: 1,
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "14px", md: "16px", lg: "18px" },
                }}
              >
                Comuna
              </Typography>
            </Grid>
            <Box
              sx={{
                borderRadius: 2, // Ajusta el valor según tu preferencia
                overflow: "hidden", // Para recortar los bordes redondeados del TextField
                backgroundImage: "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
              }}
            >
              <input
                id="city"
                name="city"
                onChange={handleChangeCity}
                style={{
                  border: "none", // Quitamos el borde nativo del input
                  width: "100%", // Ajusta el ancho del input según tus necesidades
                  outline: "none", // Quitamos el contorno al seleccionar el input
                  background: "transparent", // Hacemos el fondo transparente
                  fontFamily: "Quicksand-Regular",
                  color: "#4F4F4F",
                  fontSize: "16px",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex", // Agregar display flex para usar flexbox
                justifyContent: "center", // Centrar horizontalmente el botón
                alignItems: "center", // Centrar verticalmente el botón}}>
              }}
            >
              <Button
                sx={{
                  fontFamily: "Quicksand-Bold",
                  borderRadius: 2,
                  mt: 3,
                  paddingTop: 0.5,
                  paddingBottom: 0.5,
                  fontSize: { xs: "14px", md: "16px", lg: "20px" },
                  textTransform: "none",
                }}
                onClick={handleSubmit}
                variant="contained"
              >
                Quiero contratar el servicio
              </Button>
            </Box>
          </Container>
        </Box>
        <Box
          sx={{
            mt: { xs: 8, lg: 8 },
            mb: { xs: 1, lg: 2 },
            textAlign: "center",
          }}
        >
          <TelWhat />
        </Box>
      </Box>
    </>
  );
}
