import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Container, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import tick from '../../../assets/newiconos/tick.png'

const SLIDE_SETTINGS = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
};

const Planescarru = () => {
    const history = useHistory();

    const [planSeleccionado, setPlanSeleccionado] = useState('');

    const planesData = [
        {
            title: 'Plan A',
            subtitle: 'Fortaleza y Movilidad',
            description: 'Ofrece sesiones de kinesiología enfocadas en la fuerza, resistencia, equilibrio y movilidad del adulto mayor.',
            item1: 'Evaluación inicial',
            item2: '1 a 2 sesiones semanales de fortaleza y movilidad de 1 hora',
            item3: 'Seguimiento mensual con Familiares',
            item4: '',
            precio: 'Desde $ 140.000/mensual',
            link: "/planes-adulto-mayor/fortaleza-y-movilidad",
        },
        {
            title: 'Plan B',
            subtitle: 'Compañía y Bienestar',
            description: 'Ofrece estimulación mental y social al adulto mayor, además de asistencia básica en sus rutinas de salud.',
            item1: 'Evaluación inicial',
            item2: '1 a 2 sesiones semanales de Compañía y bienestar de 3 horas',
            item3: 'Seguimiento mensual con Familiares',
            item4: '',
            precio: 'Desde $ 180.000/mensual',
            link: "/planes-adulto-mayor/compañia-y-bienestar",
        },
        {
            title: 'Plan C',
            subtitle: 'Bienestar Integral',
            description: 'Integra ambos planes anteriores, mejorando la capacidad física, mental y social, junto con asistir en las rutinas de salud. ',
            item1: 'Evaluación inicial',
            item2: '1 a 2 sesiones semanales de fortaleza y movilidad de 1 hora',
            item3: '1 a 2 sesiones semanales de Compañía y bienestar de 3 horas',
            item4: 'Seguimiento mensual con Familiares',
            precio: 'Desde $ 290.000/mensual',
            link: "/planes-adulto-mayor/bienestar-integral",
        }
    ];


    const renderPlan = (index, title, subtitle, description, item1, item2, item3, item4, precio, link) => (
        <Container key={index}>
            <Box
                sx={{
                    width: 'auto',
                    height: '455px',
                    bgcolor: 'white',
                    borderRadius: '13px',
                    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.16)',
                    display: 'flex',
                    flexDirection: 'column', mt: 2,
                    mb: 1,
                    padding: '20px',
                }}
            >
                <Typography style={{ alignItems: 'center', textAlign: 'center', fontSize: '17px', fontFamily: 'Quicksand-Regular', color: '#1e7aa8' }}>
                    {title}
                </Typography>
                <Typography variant='h2' style={{ alignItems: 'center', textAlign: 'center', fontSize: '15px', fontFamily: 'Quicksand-Bold', }}>
                    {subtitle}
                </Typography>
                <Box
                    sx={{
                        borderRadius: 2, backgroundColor: "#f9f9f9",
                        width: "110%", ml: -2, overflow: 'hidden', mt: 2, height: "90px",
                        boxShadow: '4px 4px 4px 1px rgba(0, 0, 0, 0.1)', paddingLeft: 1, paddingTop: 1, paddingBottom: 2,
                    }}
                >
                    <Typography style={{

                        marginTop: 7, textAlign: "left", fontSize: '15px',
                        fontFamily: 'Quicksand-Regular', lineHeight: 1.3, paddingLeft: 10, paddingRight: 10,
                    }}>
                        {description}
                    </Typography>
                </Box>
                {[item1, item2, item3, item4].map((item, i) => (
                    <Typography key={i} style={{ marginTop: 15, textAlign: "left", fontSize: '15px', fontFamily: 'Quicksand-Regular', lineHeight: 1.3, paddingLeft: 10, paddingRight: 10 }}>
                        {item !== '' ?
                            <Box sx={{ ml: -2, mr: -2, display: 'flex', alignItems: 'center' }}>
                                <img src={tick} alt="tick" style={{ height: '15px', marginRight: '15px' }} />
                                <span>{item}</span>
                            </Box>
                            :
                            <Box style={{ height: '40px' }} />
                        }
                    </Typography>
                ))}

                <Typography style={{ textAlign: 'center', margin: 10, fontSize: '15px', fontFamily: 'Quicksand-Regular', color: '#c060b9' }}>
                    {precio}
                </Typography>
                <Button
                    style={{
                        borderRadius: 20, marginTop: '10px', fontFamily: 'Quicksand-Bold',
                        backgroundColor: '#8753b1', color: 'white', marginLeft: 50, marginRight: 50
                    }}
                    onClick={() => {
                        // Paso 2: Establece la variable planSeleccionado con el plan elegido.
                        setPlanSeleccionado(title);
                        history.push({
                            pathname: link,
                        });
                    }}
                >
                    Saber más
                </Button>
            </Box>
        </Container>
    );

    return (
        <Slider {...SLIDE_SETTINGS}>
            {planesData.map((plan, index) =>
                renderPlan(index, plan.title, plan.subtitle, plan.description, plan.item1, plan.item2, plan.item3, plan.item4, plan.precio, plan.link)
            )}
        </Slider>
    );
};

export default Planescarru;
