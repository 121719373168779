import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Container, Grid } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import atencionparamia from "../../../assets/newiconos/individualazul.png";
import atencionparaotroa from "../../../assets/newiconos/otroazul.png";
import atencionparami from "../../../assets/newiconos/atencionindividual.png";
import atencionparaotro from "../../../assets/newiconos/otroatendido.png";
import TelWhat from '../../../components/TelWhat/TelWhat';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';
import TuOrden from '../../../components/TelWhat/TuOrden';


export default function Form5() {
  let history = useHistory();
  const location = useLocation();
  const [tipo] = React.useState(location.state.tipo);
  const [sessions] = React.useState(location.state.sessions);

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription = "Selecciona para quién es la consulta para ti o para otro paciente. Fácil, seguro y a tu medida. Agenda tu bienestar hoy.";

  const [box1Icon, setBox1Icon] = useState(atencionparami);
  const [box1Text, setBox1Text] = useState('Para mi');
  const [box1Color, setBox1Color] = useState('');
  const [box1TextColor, setBox1TextColor] = useState('#aeaeaf');

  const [box2Icon, setBox2Icon] = useState(atencionparaotro);
  const [box2Text, setBox2Text] = useState('Para otro');
  const [box2Color, setBox2Color] = useState('');
  const [box2TextColor, setBox2TextColor] = useState('#aeaeaf');

  const [isOptionSelected, setIsOptionSelected] = useState(false);

  const handleBox1Click = () => {
    if (box1Icon === atencionparami) {
      setBox1Icon(atencionparamia);
      setBox1Text('Para mi');
      setBox1Color('#c7dee9');
      setBox1TextColor('#1e7aa8');

      setBox2Icon(atencionparaotro);
      setBox2Text('Para otro');
      setBox2Color('');
      setBox2TextColor('#aeaeaf');
    } else {
      setBox1Icon(atencionparami);
      setBox1Text('Para mi');
      setBox1Color('');
      setBox1TextColor('#aeaeaf');
    }
    setIsOptionSelected(true);
  };

  const handleBox2Click = () => {
    if (box2Icon === atencionparaotro) {
      setBox2Icon(atencionparaotroa);
      setBox2Text('Para otro');
      setBox2Color('#c7dee9');
      setBox2TextColor('#1e7aa8');

      setBox1Icon(atencionparami);
      setBox1Text('Para mi');
      setBox1Color('');
      setBox1TextColor('#aeaeaf');
    } else {
      setBox2Icon(atencionparaotro);
      setBox2Text('Para otro');
      setBox2Color('');
      setBox2TextColor('#aeaeaf');

      setBox1Icon(atencionparami);
      setBox1Text('Para mi');
      setBox1Color('');
      setBox1TextColor('#aeaeaf');
    }
    setIsOptionSelected(true);
  };
  function renderButtonAfter() {
    const handleContinuarClick = () => {
      if (box1Icon === atencionparami && box2Icon === atencionparaotro) {
        // Si ambos checkboxes están seleccionados, no hacer nada
        return;
      } else if (box1Icon === atencionparami) {
        // Si solo el primer checkbox está seleccionado, redirigir a una página
        history.push({
          pathname: "/kinesiologia-a-domicilio/agenda/para-otro",
          state: { tipo: tipo, sessions: sessions, dates: location.state.dates, hourss: location.state.hourss, p_date: location.state.p_date },
        });
      } else if (box2Icon === atencionparaotro) {
        // Si solo el segundo checkbox está seleccionado, redirigir a otra página
        history.push({
          pathname: "/kinesiologia-a-domicilio/agenda/para-mi",
          state: { tipo: tipo, sessions: sessions, dates: location.state.dates, hourss: location.state.hourss, p_date: location.state.p_date },
        });
      }
    };

    return (
      <Button
        disabled={!isOptionSelected || (box1Icon === atencionparami && box2Icon === atencionparaotro)}
        sx={{
          fontFamily: 'Quicksand-Bold',
          fontSize: { xs: "14px", md: '16px', lg: "16px" },
          backgroundColor: '#8753b1',
          '&:hover': { backgroundColor: '#651d87' },
          borderRadius: 3
        }}
        variant="contained"
        onClick={handleContinuarClick}
      >
        Continuar
      </Button>
    );
  }
  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Box
        sx={{
          height: { lg: '300px', xs: '15vh' },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
        <Container>
          <Box sx={{ ml: { xs: 0, lg: 14 }, color: "white", p: 3, fontFamily: 'Quicksand-Bold', fontSize: { xs: "16px", lg: "22px" } }}>
            <li>{tipo}</li>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          width: { lg: '60%', xs: '95%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          mt: { lg: -25, xs: -5 },
          margin: '0 auto',
        }}
      >
        <Typography textAlign={"center"} sx={{
          color: '#2669A8', mt: { xs: 3, lg: 5 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "16px", md: '20px', lg: "26px" }
        }}>
          ¿Para quién estás agendando?
        </Typography>
        <Box sx={{
          width: { lg: '45%', xs: '85%' }, margin: '0 auto',
        }}>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={5} lg={3.5}>
            <Box
              sx={{
                mt: { xs: 3, lg: 4 }, mr: { xs: 1, lg: 3 },
                display: 'flex',
                alignItems: 'center',
                backgroundColor: box1Color,
                flexDirection: "column", borderRadius: '13px',
                boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.16)',
              }}
              onClick={handleBox1Click}
            >
              <img src={box1Icon} alt="Icono" style={{ width: '65%' }} />
              <Typography sx={{ mt: { xs: -1, lg: -2 }, mb: 2, fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", md: '16px', lg: "20px" }, color: box1TextColor }}>{box1Text}</Typography>
            </Box>
          </Grid>
          <Grid item xs={5} lg={3.5}>
            <Box
              sx={{
                mt: { xs: 3, lg: 4 }, ml: { xs: 1, lg: 3 },
                display: 'flex',
                alignItems: 'center',
                backgroundColor: box2Color,
                flexDirection: "column",
                borderRadius: '13px',
                boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.16)',
              }}
              onClick={handleBox2Click}
            >
              <img src={box2Icon} alt="Icono" style={{ width: '65%' }} />
              <Typography sx={{ mt: { xs: -1, lg: -2 }, mb: 2, fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", md: '16px', lg: "20px" }, color: box2TextColor }}>{box2Text}</Typography>
            </Box>
          </Grid>
        </Grid>
        <Container sx={{
          display: 'flex',
          justifyContent: 'center', mt: { xs: 3, lg: 5 }, mb: { xs: 3, lg: 5 }
        }}>
          {renderButtonAfter()}
        </Container>
      </Box >
      <Box
        sx={{
          width: { lg: '58%', xs: '85%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto',
          padding: 2, mt: 6

        }}
      >
        <Grid container>
        <TuOrden tipo={tipo} sessions={sessions}/>
          <Grid item xs={12} lg={5} sx={{ mt: { xs: 2 }, p: 1, border: '1px solid rgba(0, 0, 0, 0.2)', borderRadius: 4 }}>
            <Typography sx={{
              lineHeight: { xs: 1.2, md: 1.1, lg: 1 }, // Ajusta este valor según tus necesidades
              mt: { xs: 1, lg: 0.5 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "14px", md: '16px', lg: "16px" }
            }}>
              Primera sesión: <span style={{ color: '#8546b1', fontFamily: 'Quicksand-Bold' }}>
                <p></p>{location.state.dates} a las {location.state.hourss} horas
              </span>
            </Typography>
            <Typography sx={{lineHeight: { xs: 1.2, md: 1.1, lg: 1 },
              mt: { xs: 1.5, lg: 1.5 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "13px", md: '16px', lg: "16px" }
            }}>
              Las siguientes sesiones las podrás coordinar con el profesional en tu primera visita.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", pt: 9, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}
