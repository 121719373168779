import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Container, Grid } from "@mui/material";
import TelWhat from "../../../components/TelWhat/TelWhat";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import CuadroSelect from "../../../components/CuadroSelect/CuadroSelect";
import Linea from "../../../components/Linea/Linea";
import Faq from "../../../components/Faq/Faq";
import { Helmet } from "react-helmet";
import MenuAzul from "../../../components/MenuCliente/MenuAzul";

import motora from "../../../assets/newimages/kinesiologiamotora.png";
import mejoresprecios from "../../../assets/newiconos/mejoresprecios.png";

export default function Form1() {
  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription =
    "Elige tu servicio de Kinesiología a domicilio: Motora, Respiratoria, Neurológica, Infantil, Deportiva, Adultos Mayores y Masoterapia. Recupera tu bienestar.";

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Box
        sx={{
          backgroundImage: `
      linear-gradient(to bottom, 
        rgba(38, 105, 168, 0.9) 0%,
        rgba(33, 107, 168, 0.8) 25%,
        rgba(87, 88, 171, 0.8) 45%,
        rgba(133, 70, 173, 0.6) 80%,
        rgba(255, 255, 255, 0.6) 100%)`,
        }}
      >
        <MenuAzul />
        <Container>
          <Grid container>
            <Grid item lg={1} md={2.3}></Grid>
            <Grid item xs={12} lg={6.5} md={6.3}>
              <Box sx={{ mb: { xs: 0, md: 0, lg: 17 } }}>
                <Typography
                  variant="h1"
                  sx={{
                    mt: { lg: 10, md: 6, xs: 3 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "40px", md: "38px", xs: "26px" },
                    color: "white",
                  }}
                >
                  <b>Kinesiología a domicilio</b>
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Regular",
                    fontSize: { lg: "20px", md: "18px", xs: "16px" },
                    color: "white",
                    mt: { lg: 4, md: 4, xs: 4 },
                    mb: 2,
                  }}
                >
                  Te atendemos donde y cuando más te acomode, en{" "}
                  <b>Teradomus</b> nos adaptamos a ti.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={4.5} md={3.4}>
              <Box
                sx={{
                  mt: { lg: 5 },
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <img
                  src={motora}
                  style={{
                    borderRadius: 30,
                    width: "360px",
                    height: "230px",
                    objectFit: "cover",
                  }}
                  alt="motora"
                />
                <Box
                  sx={{
                    bgcolor: "rgba(255, 255, 255, 0.7)",
                    position: "absolute",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "360px",
                    height: "145px",
                  }}
                >
                  <img
                    src={mejoresprecios}
                    alt="mejores precios"
                    style={{ width: "80px", height: "80px" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontFamily: "Quicksand-Bold",
                      color: "#1e7aa8",
                    }}
                  >
                    Desde $20.000
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      fontFamily: "Quicksand-Regular",
                      color: "#1e7aa8",
                      lineHeight: 1.1,
                    }}
                  >
                    por sesión
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            borderRadius: "0 40px 40px 0",
            width: { md: "70%", lg: "70%", xs: "90%" },
            boxShadow: "3px 3px 5px rgba(0, 0, 0, 0.14)",
            backgroundColor: "#FFFFFF",
          }}
        >
          <Container sx={{ mt: { xs: 5, md: 0, lg: 0 }, mb: { xs: 5, lg: 7 } }}>
            <Grid container>
              <Grid item xs={12} md={12} lg={12}>
                <Box
                  sx={{
                    textAlign: { lg: "right", xs: "left" },
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      mt: 2,
                      mb: { md: 2, xs: 2 },
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "13px", md: "18px", lg: "20px" },
                    }}
                  >
                    Elige el tipo de kinesiología que necesitas:{" "}
                    <b>Conoce nuestras especialdades</b>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <Container>
        <Grid container>
          <Grid md="4" xs="12">
            <CuadroSelect
              titulo={"Kinesiología@Motora"}
              texto={
                "Trabajaremos en fortalecer y recuperar tu sistema músculo-esquelético para potenciar tu motricidad, coordinación y capacidad de movimiento."
              }
              tipo={"Kinesiología motora"}
            />
          </Grid>
          <Grid md="4" xs="12">
            <CuadroSelect
              titulo={"Kinesiología@Respiratoria"}
              texto={
                "Trabajaremos en recuperar el sistema respiratorio, con técnicas que mejoran la ventilación pulmonar."
              }
              tipo={"Kinesiología respiratoria"}
            />
          </Grid>
          <Grid md="4" xs="12">
            <CuadroSelect
              titulo={"Kinesiología@Neurológica"}
              texto={
                "Trabajaremos en potenciar la movilidad que ha sido afectada por alteraciones del sistema nervioso."
              }
              tipo={"Kinesiología neurologica"}
            />
          </Grid>
          <Grid md="4" xs="12">
            <CuadroSelect
              titulo={"Kinesiología@Respiratoria infantil"}
              texto={
                "Trabajaremos en recuperar el sistema respiratorio, con técnicas que mejoran la ventilación pulmonar en niños y niñas."
              }
              tipo={"Kinesiología respiratoria infantil"}
            />
          </Grid>
          <Grid md="4" xs="12">
            <CuadroSelect
              titulo={"Kinesiología@Adulto mayor"}
              texto={
                "Orientado en recuperar la movilidad y aliviar las dolencias asociadas al envejecimiento."
              }
              tipo={"Kinesiología geriatrica"}
            />
          </Grid>
        </Grid>
      </Container>
      <Box
        sx={{
          mt: { xs: 5, lg: 8 },
          mb: { xs: 5, lg: 10 },
          textAlign: "center",
        }}
      >
        <TelWhat />
      </Box>
      <Box sx={{ mb: { xs: 5, lg: 7 }, textAlign: "center" }}>
        <Linea />
      </Box>
      <Container>
        <Container sx={{ mb: { xs: 3, md: 6, lg: 8 } }}>
          <Faq />
        </Container>
      </Container>
    </>
  );
}
