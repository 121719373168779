import React, { useEffect, useState } from "react";
import { Skeleton } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import callApi from "../../services/callApi";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { isMobile } from "react-device-detect";
import axios from "axios";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";

function Payment() {
  const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
  const [idPago, setIdPago] = useState(null);
  const [amount, setAmount] = useState(0);
  const [paymentData, setPaymentData] = useState(null);
  const [preference, setPreference] = useState(null);
  const [validate, setValidate] = useState(0);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const paymentId = searchParams.get("payment_id");
    const preferenceId = searchParams.get("preference_id");

    setIdPago(paymentId);
    setPreference(preferenceId);

    if (paymentId && preferenceId) {
      validate_pay(paymentId, preferenceId);
      getPrePayment(preferenceId);
    }
  }, []);

  async function validate_pay(paymentId, preferenceId) {
    if (paymentId === "1") return null;

    try {
      const response = await axios({
        method: "get",
        url: `https://api.mercadopago.com/v1/payments/${paymentId}`,
        headers: {
          Authorization: `Bearer ${ACCESS_TOKEN}`,
          "Content-Type": "application/json",
        },
      });

      const { status, transaction_amount, additional_info } = response.data;
      setAmount(transaction_amount);

      if (status === "approved") {
        setValidate(1);
        await putPayment(
          paymentId,
          preferenceId,
          transaction_amount,
          additional_info.items[0].quantity
        );
      }
    } catch (error) {
      console.error("Error validating payment:", error);
    }
  }

  async function getPrePayment(preferenceId) {
    if (!preferenceId) return null;

    try {
      const payment = await callApi(
        `/agenda/agendarExitoPago?preference_id=${preferenceId}`
      );
      setPaymentData(payment.data);
    } catch (error) {
      console.error("Error fetching pre-payment data:", error);
    }
  }

  async function putPayment(id_pago, preference, monto, quantity) {
    const putPaymentData = {
      id: preference,
      monto: monto,
      id_mercadopago: id_pago,
      id_preference: preference,
      cant_sesiones: quantity,
    };

    try {
      await callApi(`/puyment?id=${preference}`, "post", putPaymentData);
    } catch (error) {
      console.error("Error updating payment:", error);
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString + "T00:00:00");
    const days = [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ];
    const months = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    const dayName = days[date.getDay()];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];

    return `${dayName} ${day} de ${month}`;
  };

  const textseciones =
    "✨ Gracias por su reserva, hemos enviando un correo de confirmación al correo del paciente y del profesional.";

  const [textoVisible, setTextoVisible] = useState("");
  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = textseciones;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 25);
    return () => {
      clearInterval(interval);
    };
  }, [textseciones]);

  const transformModalidad = (modalidad) => {
    switch (modalidad) {
      case "consultorio":
        return "Consulta presencial";
      case "domicilio":
        return "Consulta a domicilio";
      case "online":
        return "Consulta online";
      default:
        return modalidad;
    }
  };

  return (
    <>
      <ScrollToTop />
      <img
        src={logoImage}
        alt="Teradomus Logo"
        style={{
          width: isMobile ? "150px" : "200px",
          height: "auto",
          paddingTop: "15px",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
          padding: 15,
        }}
      />

      <Box
        sx={{
          width: isMobile ? "95%" : "40%",
          mx: "auto",
          bgcolor: "#fbfafe",
          boxShadow: 1,
          marginTop: 2,
          borderRadius: 2,
          p: isMobile ? 2 : 3,
          paddingLeft: isMobile ? 0 : 10,
          paddingRight: isMobile ? 0 : 10,
        }}
      >
        <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light" }}>
          Agendaste con:
        </Typography>
        {paymentData ? (
          <>
            <Typography
              sx={{
                ml: 2,
                mt: -0.5,
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "20px", md: "20px", lg: "22px" },
              }}
            >
              {paymentData.profesional_nombre_plataforma}
            </Typography>
            <Typography
              sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
            >
              {paymentData.profesional_profesion}
            </Typography>
            <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light", mt: 1 }}>
              El servicio de:
            </Typography>
            <Typography
              sx={{
                ml: 2,
                mt: -0.5,
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "20px", md: "20px", lg: "22px" },
              }}
            >
              {paymentData.servicio_nombre}
            </Typography>
            <Typography
              sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
            >
              {paymentData.servicio_precio}
            </Typography>
            <Typography
              sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
            >
              {transformModalidad(paymentData.servicio_modalidad)}
            </Typography>
            <Typography
              sx={{
                ml: 2,
                color: "#8546b1",
                fontFamily: "Quicksand-Bold",
                mt: -0.5,
              }}
            >
              {formatDate(paymentData.fecha_agenda)} - {paymentData.hora_agenda}
            </Typography>
          </>
        ) : (
          <>
            <Skeleton animation="wave" height={30} width="80%" sx={{ ml: 2 }} />
            <Skeleton animation="wave" height={20} width="60%" sx={{ ml: 2 }} />
            <Skeleton animation="wave" height={30} width="70%" sx={{ ml: 2 }} />
            <Skeleton animation="wave" height={20} width="40%" sx={{ ml: 2 }} />
            <Skeleton animation="wave" height={20} width="50%" sx={{ ml: 2 }} />
            <Skeleton animation="wave" height={25} width="30%" sx={{ ml: 2 }} />
            <Skeleton animation="wave" height={25} width="50%" sx={{ ml: 2 }} />
          </>
        )}
      </Box>

      <Box
        sx={{
          width: { lg: "50%", xs: "95%" },
          backgroundColor: "white",
          height: isMobile ? "150px" : "110px",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: 3, xs: 2 },
          margin: "0 auto",
          mb: 5,
        }}
      >
        <Typography
          variant="body1"
          textAlign={"center"}
          sx={{
            mb: 3,
            mt: { xs: 3, lg: 3 },
            fontFamily: "Quicksand-Regular",
            fontSize: { xs: "16px", md: "20px", lg: "18px" },
          }}
        >
          {textoVisible}
        </Typography>
      </Box>
    </>
  );
}

export default Payment;
