import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Grid } from "@material-ui/core";
import { useLocation, useHistory } from "react-router-dom";
import TelWhat from '../../../components/TelWhat/TelWhat';
import { Button } from "@mui/material";
import title from "../../../assets/icons/title.png";
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react'
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
initMercadoPago(process.env.REACT_APP_PUBLIC_TOKEN, { locale: 'es-CL' });

function Form9() {
  const location = useLocation();
  const preference = location.state.pref;
  const price = location.state.price;
  const habil = location.state.habil;
  let history = useHistory();

  function renderText() {
    if (location.state.sessions === 1) {
      return (<Typography variant="p">
        ¡Gracias por confiar en nuestro servicio de atención a domicilio de kinesiología!
        <br></br><b>Tu pedido de {location.state.sessions} atencion</b> está a punto de ser confirmado.
        <br></br><b>La primera atención será programada para el {location.state.dia} a las {location.state.hora} horas</b>.
      </Typography>)
    }
    else {
      return (<Typography variant="p">
        ¡Gracias por confiar en nuestro servicio de atención a domicilio de kinesiología!
        <br></br><b>Tu pedido de {location.state.sessions} atenciones</b> está a punto de ser confirmado.<br></br>
        <br></br><b>La primera atención será programada para el {location.state.dia} a las {location.state.hora} horas</b>,
        <br></br>mientras que las siguientes {location.state.sessions - 1} las podrás coordinar directamente con nosotros o con el profesional en tu primera atención.
      </Typography>)
    }
  }
  return (
    <>
      <ScrollToTop />
      <Grid container direction="row" justifyContent="center">
        <Box
          component="img"
          sx={{
            width: "150px"
          }}
          src={title}
        />
      </Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={8}>
          <Box
            sx={{
              borderRadius: 6,
              mb: 12,
            }}
          >
            <Grid item>
              <Box textAlign="center" sx={{ m: 3, fontSize: 19 }}>
                {renderText()}
              </Box>
            </Grid>
            <Grid item>
              <Box textAlign="center" sx={{ m: 3, fontSize: 19 }}>
                <Typography class="row">
                  Una vez que recibamos el pago, nuestro equipo se contactará contigo para resolver cualquier duda e indicarte los siguientes pasos.
                </Typography>{" "}
              </Box>
            </Grid>

            <Box>
              <Box textAlign="center" sx={{ m: 3, fontSize: 19 }}>
                <Typography class="row">
                  Total de tu compra: ${(location.state.sessions * price).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}
                  <br></br><b> Paga en hasta en 12 cuotas de ${(location.state.sessions * price / 12).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}, sin intereses</b>
                  <br></br>{habil === 1 ? 'El precio total de tu compra está calculado solo con sesiones en días hábiles. Si una sesión ocurre en día festivo, habrá un costo adicional de $5.000 por sesión que será cobrado más adelante por nuestro equipo de atención al cliente' : ''}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: "center", pt: 2 }}>
              <Grid container direction="row"
                justifyContent="center"
              >
                <Grid item xs="12" md="3">
                  <Button sx={{ mt: 2, backgroundColor: "#2d3277", borderRadius: 5, padding: "10px 24px" }} onClick={() => {
                    history.push({
                      pathname: "/form-solicitud",
                      state: { tipo: location.state.tipo, sessions: location.state.sessions, dates: location.state.dia, hourss: location.state.hora },
                    });
                  }} variant="contained">Anterior
                  </Button>
                </Grid>
                <Grid item xs="10" md="3">
                  <Wallet initialization={{ preferenceId: preference }} customization={{
                    texts: {
                      action: 'pay',
                      valueProp: 'security_safety',
                    }, visual: {
                      borderRadius: '25px',
                      buttonBackground: "blue",
                      hideValueProp: true
                    },
                  }} />
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ textAlign: "center", pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}

export default Form9;
