import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid, TextField } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import mas from "../../assets/icons/mas.png";
import menos from "../../assets/icons/menos.png";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";

const isValidText = (text) => text.length >= 2;
const isValidRut = (rut) => rut.length >= 9;
const isValidEmail = (email) =>
  email.includes("@") && email.includes(".") && email.length >= 5;
const isValidPhone = (phone) => phone.length === 9;

export default function Sesiones() {
  let history = useHistory();
  const location = useLocation();
  const [sessions, setSessions] = React.useState(1);
  const pageTitle = "Teradomus";
  const pageDescription =
    "Kinesiología: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  const disponibilidad = location.state.disponibilidad;

  const [nombre, setNombre] = useState("");
  const [apellido, setApellido] = useState("");
  const [rut, setRut] = useState("");
  const [email, setEmail] = useState("");
  const [telefono, setTelefono] = useState("");
  const [direccion, setDireccion] = useState("");
  const [comentarios, setComentarios] = useState("");

  function handleChangeSessionsMenos() {
    if (sessions === 1) {
      return null;
    }
    setSessions(sessions - 1);
  }

  function handleChangeSessionsMas() {
    if (sessions === 20) {
      return null;
    }
    setSessions(sessions + 1);
  }

  const textseciones =
    "✨ ¿Cuántas sesiones de kinesiología con el profesional " +
    location.state.titulo +
    " necesitas?";

  const [textoVisible, setTextoVisible] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = textseciones;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 25);
    return () => {
      clearInterval(interval);
    };
  }, [textseciones]);

  const handleSubmit = () => {
    if (
      isValidText(nombre) &&
      isValidText(apellido) &&
      isValidRut(rut) &&
      isValidEmail(email) &&
      isValidPhone(telefono) &&
      direccion.trim() !== ""
    ) {
      history.push({
        pathname: "/pro/perfil/calendario",
        state: {
          titulo: location.state.titulo,
          profesion: location.state.profesion,
          sesiones: sessions,
          celukine: location.state.celukine,
          id_kinesiologo: location.state.id_kinesiologo,
          id_servicios: location.state.id_servicios,
          servicio_nombre: location.state.id_servicio_nombre,
          servicio_duracion: location.state.id_servicio_duracion,
          servicio_precios: location.state.id_servicio_precios,
          disponibilidad: disponibilidad,
          nombre,
          apellido,
          rut,
          email,
          telefono,
          direccion,
          comentarios,
        },
      });
    } else {
      alert("Por favor, complete todos los campos requeridos correctamente.");
    }
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/secciones"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/secciones"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid Item xs={12} md={10}>
          <img
            src={logoImage}
            alt="Teradomus Logo"
            style={{
              width: isMobile ? "150px" : "200px",
              height: "auto",
              paddingTop: "15px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              padding: 15,
            }}
          />
          <Box
            sx={{
              width: isMobile ? "94%" : "48%",
              mx: "auto",
              bgcolor: "#fbfafe",
              boxShadow: 1,
              marginTop: 2,
              borderRadius: 2,
              p: isMobile ? 2 : 4,
              paddingLeft: isMobile ? 0 : 10,
              paddingRight: isMobile ? 0 : 10,
            }}
          >
            <Typography sx={{ mb: 2, ml: 2, fontFamily: "Quicksand-Regular" }}>
              Estas agendando con:
            </Typography>
            <Typography
              variant="h5"
              sx={{ ml: 2, fontFamily: "Quicksand-Bold" }}
            >
              {location.state.servicio_nombre}
            </Typography>
            <Typography
              variant="h5"
              sx={{ ml: 2, fontFamily: "Quicksand-Bold" }}
            >
              {location.state.titulo}
            </Typography>
            <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular" }}>
              {location.state.profesion}
            </Typography>
          </Box>
          <Typography
            textAlign={"center"}
            sx={{
              color: "#007FFF",
              mt: { xs: 1, lg: 3 },
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "20px", md: "20px", lg: "26px" },
            }}
          >
            ¿Cuántas sesiones necesitas?
          </Typography>
        </Grid>
        <Box
          sx={{
            width: { lg: "50%", xs: "95%" },
            backgroundColor: "white",
            borderRadius: 3,
            boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
            border: "1px solid rgba(0, 0, 0, 0.04)",
            mt: { lg: 3, xs: 2 },
            margin: "0 auto",
            mb: 5,
          }}
        >
          <Typography
            variant="body1"
            textAlign={"center"}
            sx={{
              mb: 3,
              mt: { xs: 3, lg: 3 },
              fontFamily: "Quicksand-Regular",
              fontSize: { xs: "16px", md: "20px", lg: "18px" },
            }}
          >
            {textoVisible}{" "}
          </Typography>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Box
              sx={{
                border: "1px solid rgba(0, 0, 0, 0.2)",
                height: { xs: "100px", lg: "120px" },
                borderRadius: 3,
                mt: 4,
                width: { lg: "280px", xs: "170px" },
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                component="img"
                sx={{
                  mr: { xs: 8, lg: 15 },
                  borderRadius: 2,
                  width: "40px",
                  "&:hover": {
                    filter: "brightness(70%)",
                  },
                }}
                src={menos}
                onClick={handleChangeSessionsMenos}
              />
              <Typography
                sx={{
                  position: "absolute",
                  color: "#2669A8",
                  fontFamily: "Quicksand-Bold",
                  fontSize: { xs: "40px", md: "50px", lg: "60px" },
                }}
              >
                {sessions}
              </Typography>
              <Box
                component="img"
                sx={{
                  ml: { xs: 8, lg: 15 },
                  borderRadius: 2,
                  width: "40px",
                  "&:hover": {
                    filter: "brightness(70%)",
                  },
                }}
                src={mas}
                onClick={handleChangeSessionsMas}
              />
            </Box>
          </Box>

          <Container sx={{ mt: 4 }}>
            <TextField
              fullWidth
              label={isValidText(nombre) ? "Nombre" : "Nombre*"}
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: isValidText(nombre) ? "#2669A8" : "#4F4F4F",
                  fontFamily: isValidText(nombre)
                    ? "Quicksand-Bold"
                    : "Quicksand-Regular",
                },
              }}
            />
            <TextField
              fullWidth
              label={isValidText(apellido) ? "Apellido" : "Apellido*"}
              value={apellido}
              onChange={(e) => setApellido(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: isValidText(apellido) ? "#2669A8" : "#4F4F4F",
                  fontFamily: isValidText(apellido)
                    ? "Quicksand-Bold"
                    : "Quicksand-Regular",
                },
              }}
            />
            <TextField
              fullWidth
              label={isValidRut(rut) ? "RUT" : "RUT*"}
              value={rut}
              onChange={(e) => setRut(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: isValidRut(rut) ? "#2669A8" : "#4F4F4F",
                  fontFamily: isValidRut(rut)
                    ? "Quicksand-Bold"
                    : "Quicksand-Regular",
                },
              }}
            />
            <TextField
              fullWidth
              label={isValidEmail(email) ? "Email" : "Email*"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: isValidEmail(email) ? "#2669A8" : "#4F4F4F",
                  fontFamily: isValidEmail(email)
                    ? "Quicksand-Bold"
                    : "Quicksand-Regular",
                },
              }}
            />
            <TextField
              fullWidth
              label={isValidPhone(telefono) ? "Teléfono" : "Teléfono*"}
              value={telefono}
              onChange={(e) => setTelefono(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: isValidPhone(telefono) ? "#2669A8" : "#4F4F4F",
                  fontFamily: isValidPhone(telefono)
                    ? "Quicksand-Bold"
                    : "Quicksand-Regular",
                },
              }}
            />
            <TextField
              fullWidth
              label={direccion.trim() !== "" ? "Dirección" : "Dirección*"}
              value={direccion}
              onChange={(e) => setDireccion(e.target.value)}
              sx={{
                mb: 2,
                "& .MuiInputLabel-root": {
                  color: direccion.trim() !== "" ? "#2669A8" : "#4F4F4F",
                  fontFamily:
                    direccion.trim() !== ""
                      ? "Quicksand-Bold"
                      : "Quicksand-Regular",
                },
              }}
            />
            <TextField
              fullWidth
              label="Comentarios"
              value={comentarios}
              onChange={(e) => setComentarios(e.target.value)}
              multiline
              rows={4}
              sx={{ mb: 2 }}
            />
          </Container>

          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: { xs: 3, lg: 5 },
              mb: { xs: 3, lg: 5 },
            }}
          >
            <Button
              sx={{
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
                fontFamily: "Quicksand-Bold",
                backgroundColor: "#8753b1",
                "&:hover": { backgroundColor: "#651d87" },
                borderRadius: 3,
              }}
              variant="contained"
              onClick={handleSubmit}
            >
              Continuar
            </Button>
          </Container>
        </Box>
      </Grid>
    </>
  );
}
