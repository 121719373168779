import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";

export default function Principal() {
  return (
    <>
      <Container>
        <Box
          sx={{
            mt: { xs: 5, lg: 7 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 1,
              fontSize: { lg: "28px", md: "16px", xs: "20px" },
              fontFamily: "Quicksand-Bold",
              color: "#1e7aa8",
            }}
          >
            Precios
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mb: { xs: 2, lg: 2 },
              mt: 1,
              fontSize: { lg: "18px", md: "16px", xs: "16px" },
              fontFamily: "Quicksand-Regular",
              color: "#1e7aa8",
            }}
          >
            Realiza tu reserva de sesiones kinesiológicas en paquetes con
            beneficios
          </Typography>
        </Box>
        <Box sx={{ width: "100%", margin: "auto", color: "#1e7aa8" }}>
          <Grid container>
            <Grid item xs={12} lg={3}>
              <Box
                sx={{
                  width: "auto",
                  height: "auto",
                  borderRadius: 4,
                  mb: 7,
                  mt: 1,
                  boxShadow: "6px 6px 6px 4px rgba(0, 0, 0, 0.14)",
                  p: { xs: 1, lg: 1 },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    p: 1,
                    justifyContent: "space-between", // Esto separa los hijos al máximo dentro del contenedor
                    alignItems: "center", // Alineación vertical
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 1,
                      mt: 1,
                      fontSize: { lg: "18px", md: "16px", xs: "16px" },
                      fontFamily: "Quicksand-Bold",
                    }}
                  >
                    Solicita <br />1 sesión día hábil
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mb: 1,
                      mt: 1,
                      fontSize: { lg: "20px", md: "16px", xs: "16px" },
                      fontFamily: "Quicksand-Bold",
                    }}
                  >
                    $25.000 <br />
                    <span style={{ color: "#aeaeae" }}>por sesión</span>
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    p: 1,
                    justifyContent: "space-between", // Esto separa los hijos al máximo dentro del contenedor
                    alignItems: "center", // Alineación vertical
                  }}
                >
                  <Typography
                    variant="h2"
                    sx={{
                      mb: 1,
                      mt: 1,
                      fontSize: { lg: "18px", md: "16px", xs: "16px" },
                      fontFamily: "Quicksand-Bold",
                    }}
                  >
                    Solicita y Paga<br></br> 10 o más sesiones:
                  </Typography>
                  <Typography
                    variant="h3"
                    sx={{
                      mb: 1,
                      mt: 1,
                      fontSize: { lg: "20px", md: "16px", xs: "16px" },
                      fontFamily: "Quicksand-Bold",
                    }}
                  >
                    $23.000 <br />
                    <span style={{ color: "#aeaeae" }}>por sesión</span>
                  </Typography>
                </Box>
                <Typography
                  variant="h3"
                  sx={{
                    mb: { xs: 1, lg: 10 },
                    mt: 2,
                    fontSize: { lg: "18px", md: "16px", xs: "14px" },
                    fontFamily: "Quicksand-Regular",
                    textAlign: "center",
                    color: "black",
                  }}
                >
                  Los días festivos, sábados y domingos tienen un recargo
                  adicional de $5.000 por sesión.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
}
