import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import CustomCalendar from "./CalendarioPerfil";
import HourSelector from "../../components/Hora/HoraPerfil";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";
import Loader from "../../components/Loader";

export default function Calendario() {
  let history = useHistory();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState(null);
  const [availableHours, setAvailableHours] = useState([]);
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);

  const pageTitle = "Teradomus";
  const pageDescription =
    "Kinesiología: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";
  const handleDateChange = (date, hours) => {
    setSelectedDate(date);
    setAvailableHours(hours);
    setSelectedHour(null);
  };

  const handleSelectHour = (hour) => {
    setSelectedHour(hour);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString + "T00:00:00");

    const days = [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ];
    const months = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    const dayName = days[date.getDay()];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${dayName} ${day} de ${month} ${year}`;
  };
  const fecha_formateada = formatDate(selectedDate);

  const handleAgendarClick = () => {
    history.push({
      pathname: "/pro/perfil/datos",
      state: {
        ...location.state,
        dates: selectedDate,
        hourss: selectedHour,
        fecha_formateada: fecha_formateada,
      },
    });
  };

  function renderButtomAfter() {
    if (selectedHour == null || selectedDate == null) {
      return (
        <Button
          disabled
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", lg: "16px" },
            backgroundColor: "#8753b1",
            "&:hover": { backgroundColor: "#651d87" },
            borderRadius: 3,
          }}
          variant="contained"
        >
          Continuar
        </Button>
      );
    } else {
      return (
        <Button
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", md: "16px", lg: "16px" },
            backgroundColor: "#8753b1",
            "&:hover": { backgroundColor: "#651d87" },
            borderRadius: 3,
          }}
          onClick={handleAgendarClick}
          variant="contained"
        >
          Continuar
        </Button>
      );
    }
  }

  const textseciones = "✨Selecciona el día y hora de tu sesión.";

  const [textoVisible, setTextoVisible] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = textseciones;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 25);
    return () => {
      clearInterval(interval);
    };
  }, [textseciones]);

  const handleGoBack = () => {
    history.goBack();
  };

  const transformModalidad = (modalidad) => {
    switch (modalidad) {
      case "consultorio":
        return "Consulta presencial";
      case "domicilio":
        return "Consulta a domicilio";
      case "online":
        return "Consulta online";
      default:
        return modalidad;
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/calendario"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/calendario"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item xs={12} md={10}>
          <img
            src={logoImage}
            alt="Teradomus Logo"
            style={{
              width: isMobile ? "150px" : "200px",
              height: "auto",
              paddingTop: "15px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              padding: 15,
            }}
          />
          <Box
            sx={{
              width: isMobile ? "95%" : "48%",
              mx: "auto",
              bgcolor: "#fbfafe",
              boxShadow: 1,
              marginTop: 2,
              borderRadius: 2,
              p: isMobile ? 2 : 3,
              paddingLeft: isMobile ? 0 : 10,
              paddingRight: isMobile ? 0 : 10,
            }}
          >
            <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light" }}>
              Estas agendando con:
            </Typography>
            <Typography
              sx={{
                ml: 2,
                mt: -0.5,
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "20px", md: "20px", lg: "22px" },
              }}
            >
              {location.state.titulo}
            </Typography>
            <Typography
              sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
            >
              {location.state.profesion}
            </Typography>
            <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light", mt: 1 }}>
              El servicio de:
            </Typography>
            <Typography
              sx={{
                ml: 2,
                mt: -0.5,
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "20px", md: "20px", lg: "22px" },
              }}
            >
              {location.state.servicio_nombre}
            </Typography>
            <Typography
              sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
            >
              {location.state.servicio_precios}
            </Typography>
            <Typography
              sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
            >
              {transformModalidad(location.state.servicio_modalidad)}
            </Typography>
          </Box>
          <Typography
            textAlign={"center"}
            sx={{
              color: "#007FFF",
              mt: { xs: 1, lg: 3 },
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "20px", md: "20px", lg: "26px" },
            }}
          >
            ¿Cuándo necesitas tu atención?
          </Typography>
        </Grid>
      </Grid>
      <Box
        sx={{
          width: { lg: "50.4%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.12)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: 3, xs: 2 },
          margin: "0 auto",
          mb: 5,
        }}
      >
        <Typography
          variant="body1"
          textAlign={"center"}
          sx={{
            mb: 3,
            mt: { xs: 3, lg: 3 },
            fontFamily: "Quicksand-Regular",
            fontSize: { xs: "16px", md: "20px", lg: "18px" },
          }}
        >
          {textoVisible}{" "}
        </Typography>
        <Container maxWidth="lg">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: isMobile ? "100%" : "70%",
                mt: 2,
              }}
            >
              <Box
                sx={{
                  borderRadius: 5,
                  backgroundColor: "#f3f6fa",
                  overflow: "hidden",
                }}
              >
                <CustomCalendar
                  disponibilidad={location.state.disponibilidad}
                  onDateChange={handleDateChange}
                />
                <Box
                  sx={{
                    mt: 2,
                    pb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: { xs: "10px", lg: "16px" },
                  }}
                >
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#b0dcf0",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Quicksand-Bold",
                      fontSize: { xs: "12px", md: "16px" },
                    }}
                  >
                    Disponible
                  </Typography>
                  <Box
                    sx={{
                      display: "inline-block",
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#1e7aa8",
                    }}
                  />
                  <Typography
                    sx={{
                      fontFamily: "Quicksand-Bold",
                      fontSize: { xs: "12px", md: "16px" },
                    }}
                  >
                    Seleccionado
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{
                  mt: 2,
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "13px", md: "16px" },
                }}
              >
                <span
                  style={{ fontFamily: "Quicksand-Bold", color: "#8546b1" }}
                >
                  {selectedDate ? formatDate(selectedDate) : ""}
                </span>
              </Typography>
              <Typography
                sx={{
                  mt: 1,
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "13px", md: "16px" },
                }}
              >
                Horarios disponibles:
              </Typography>
              <Box sx={{ mt: 2 }}>
                <HourSelector
                  availableHours={availableHours}
                  onSelectHour={handleSelectHour}
                />
              </Box>
            </Box>
          </Box>
        </Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { xs: 3, lg: 3 },
            mb: { xs: 3, lg: 2 },
          }}
        >
          {renderButtomAfter()}
        </Box>
        <Button
          onClick={handleGoBack}
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", md: "16px", lg: "16px" },
            color: "#007FFF",
            "&:hover": { backgroundColor: "rgba(135, 83, 177, 0.1)" },
            borderRadius: 3,
            textTransform: "none",
          }}
        >
          Volver
        </Button>
      </Box>
    </>
  );
}
