import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import { keyframes } from "@mui/system";

import email from "../../../assets/profesionales/foto_email.png";
import wasap from "../../../assets/profesionales/foto_whatsapp.png";

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const NotificationBox = ({ icon, title, message, delay, position }) => {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timer);
  }, [delay]);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.9)",
        borderRadius: "12px",
        padding: "8px 12px",
        boxShadow: "0 2px 10px rgba(0,0,0,0.1)",
        position: "absolute",
        ...position,
        opacity: show ? 1 : 0,
        transform: show ? "translateY(0)" : "translateY(100px)",
        transition: "opacity 0.8s ease-out, transform 0.8s ease-out",
        mr: 1,
      }}
    >
      <img
        src={icon}
        alt={title}
        style={{
          width: "40px",
          height: "40px",
          marginRight: "10px",
          marginTop: "5px",
        }}
      />
      <Box sx={{ flex: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="subtitle2"
            sx={{
              fontFamily: "Calibri, sans-serif",
              fontSize: "16px",
              fontWeight: "bold",
              color: "#111111",
              lineHeight: 1.2,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="caption"
            sx={{
              fontFamily: "Calibri, sans-serif",
              fontSize: "14px",
              color: "#7F7F7F",
            }}
          >
            Ahora
          </Typography>
        </Box>
        <Typography
          variant="body2"
          sx={{
            fontFamily: "Calibri, sans-serif",
            fontSize: "14px",
            color: "#111111",
            lineHeight: 1.1,
          }}
        >
          {message}
        </Typography>
      </Box>
    </Box>
  );
};

const AnimatedNotifications = () => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
      }}
    >
      <NotificationBox
        icon={wasap}
        title="Teradomus"
        message="Hola 👋, Has recibido una nueva reserva de cita desde tu perfil online."
        delay={500}
        position={{ top: "40%", right: "9%" }}
      />
      <NotificationBox
        icon={email}
        title="Depósito Teradomus"
        message="Depositamos $175.600 a tu cuenta por los pagos de la semana pasada."
        delay={1000}
        position={{ bottom: "20%", left: "20%" }}
      />
    </Box>
  );
};

export default AnimatedNotifications;
