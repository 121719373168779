// src/components/Skeleton/LoadingSkeleton.jsx
import React from "react";
import { Box, Skeleton } from "@mui/material";
import { isMobile } from "react-device-detect";

const LoadingSkeleton = () => {
  return (
    <Box
      sx={{
        bgcolor: "white",
        borderRadius: 2,
        p: 3,
        mb: 2,
        border: "1px solid #e5e7eb",
      }}
    >
      {isMobile ? (
        // Versión móvil del skeleton
        <Box>
          {/* Sección de información básica */}
          <Box sx={{ mb: 3 }}>
            <Box sx={{ display: "flex", alignItems: "center", mb: 1, gap: 1 }}>
              <Skeleton width="60%" height={28} />
              <Skeleton width={18} height={18} variant="circular" />
            </Box>
            <Skeleton width="40%" height={24} sx={{ mb: 1 }} />
            <Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 1 }}>
              <Skeleton width={100} height={20} />
              <Skeleton width={80} height={20} />
            </Box>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "200px",
                borderRadius: 1,
                mb: 3,
              }}
            />
          </Box>

          {/* Sección de tipos de servicio */}
          <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
            {[1, 2, 3].map((_, index) => (
              <Skeleton key={index} width={80} height={24} />
            ))}
          </Box>

          {/* Sección de servicios */}
          <Box sx={{ mb: 3 }}>
            <Skeleton width="80%" height={24} sx={{ mb: 2 }} />
            {[1, 2, 3].map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Skeleton width="60%" height={24} />
                <Skeleton width="20%" height={24} />
              </Box>
            ))}
          </Box>

          {/* Divisor */}
          <Skeleton
            sx={{
              height: 3,
              mb: 3,
            }}
          />

          {/* Sección de disponibilidad */}
          <Box sx={{ mb: 3 }}>
            <Skeleton width="80%" height={24} sx={{ mb: 2 }} />
          </Box>

          {/* Botón */}
          <Skeleton
            variant="rectangular"
            sx={{
              width: "100%",
              height: "45px",
              borderRadius: 1,
            }}
          />
        </Box>
      ) : (
        // Versión PC
        <Box sx={{ display: "flex", gap: 4 }}>
          {/* Left section skeleton */}
          <Box sx={{ width: "25%" }}>
            <Box sx={{ mb: 1 }}>
              <Skeleton width="70%" height={28} />
            </Box>
            <Skeleton width="40%" height={24} sx={{ mb: 1 }} />
            <Skeleton width="60%" height={24} sx={{ mb: 2 }} />
            <Skeleton
              variant="rectangular"
              sx={{
                width: "250px",
                height: "250px",
                borderRadius: 1,
              }}
            />
          </Box>

          {/* Middle section skeleton */}
          <Box sx={{ width: "40%" }}>
            <Box sx={{ display: "flex", gap: 2, mb: 3 }}>
              <Skeleton width={100} height={24} />
              <Skeleton width={100} height={24} />
              <Skeleton width={100} height={24} />
            </Box>
            <Skeleton width="80%" height={24} sx={{ mb: 2 }} />
            {[1, 2, 3].map((_, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Skeleton width="60%" height={24} />
                <Skeleton width="20%" height={24} />
              </Box>
            ))}
            <Skeleton width="40%" height={24} sx={{ mt: 1 }} />
          </Box>

          {/* Right section skeleton */}
          <Box sx={{ width: "35%" }}>
            <Skeleton width="100%" height={24} sx={{ mb: 2 }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: 3,
                gap: 2,
              }}
            >
              {[1, 2, 3].map((_, index) => (
                <Box key={index} sx={{ flex: 1, textAlign: "center" }}>
                  <Skeleton
                    width="80%"
                    height={24}
                    sx={{ mb: 1, mx: "auto" }}
                  />
                  <Skeleton
                    width="60%"
                    height={24}
                    sx={{ mb: 1, mx: "auto" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{
                      height: "40px",
                      borderRadius: 1,
                      mx: "auto",
                    }}
                  />
                </Box>
              ))}
            </Box>
            <Skeleton
              variant="rectangular"
              sx={{
                width: "100%",
                height: "45px",
                borderRadius: 1,
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default LoadingSkeleton;
