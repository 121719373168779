import React, { useState } from "react";
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from "@mui/material";
import kine1 from "../../../assets/profesionales/foto_andres_final.png";
import kine2 from "../../../assets/profesionales/foto_camila_final.png";
import kine3 from "../../../assets/profesionales/foto_daniel_final.png";
import kine4 from "../../../assets/profesionales/foto_natalia_final.png";
import { isMobile } from "react-device-detect";

const estiloComun = {
  fontFamily: "Quicksand-Medium",
};

const TestimonioImagen = ({ imagen, nombre, especialidad }) => (
  <Box
    sx={{
      position: "relative",
      height: "550px",
      borderRadius: "20px",
      overflow: "hidden",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
    }}
  >
    <img
      src={imagen}
      alt={nombre}
      style={{
        width: "100%",
        height: "100%",
        objectFit: "cover",
      }}
    />
    <Box
      sx={{
        position: "absolute",
        bottom: "16px",
        left: "50%",
        transform: "translateX(-50%)",
        width: "85%",
        backgroundColor: "white",
        borderRadius: "16px",
        p: 1.1,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.4)",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Quicksand-Bold",
          color: "#8E44AD",
          fontSize: "16px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginBottom: 0,
          lineHeight: 1.2,
        }}
      >
        {nombre}
      </Typography>
      <Typography
        sx={{
          ...estiloComun,
          color: "#666",
          fontSize: "14px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          marginTop: 0,
          lineHeight: 1.2,
        }}
      >
        {especialidad}
      </Typography>
    </Box>
  </Box>
);

const TestimonioTexto = ({ texto, nombre, especialidad }) => (
  <Card
    sx={{
      height: "268px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
      borderRadius: "20px",
    }}
  >
    <CardContent sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
      <Typography
        variant="body2"
        sx={{
          ...estiloComun,
          fontSize: "18px",
          color: "#333",
          flexGrow: 1,
        }}
      >
        "{texto}"
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{
          ...estiloComun,
          fontFamily: "Quicksand-Bold",
          color: "#8E44AD",
          fontSize: "16px",
          lineHeight: 1.2,
        }}
      >
        {nombre}
      </Typography>
      <Typography
        variant="body2"
        sx={{
          ...estiloComun,
          color: "#666",
          fontSize: "14px",
          lineHeight: 1.2,
          mb: -0.5,
        }}
      >
        {especialidad}
      </Typography>
    </CardContent>
  </Card>
);

export default function TestimoniosProfesionales() {
  const [mostrarTodos, setMostrarTodos] = useState(false);

  const testimonios = [
    {
      imagen: kine4,
      texto:
        "La plataforma web para la gestión de mis pacientes es muy intuitiva, sencilla y cuenta con todas las herramientas necesarias para organizar mis tratamientos",
      nombre: "Natalia Sandía",
      especialidad: "Kinesióloga",
    },
    {
      imagen: kine3,
      texto:
        "El hecho que puedas acceder a todas sus funcionalidades independiente de cuanto pagues, hace que puedas confiar y probar la plataforma en su totalidad desde el principio.",
      nombre: "Daniel Chinchón",
      especialidad: "Kinesiólogo",
    },
    {
      imagen: kine2,
      texto:
        "La comunicación con el equipo de Teradomus siempre ha sido buena. Responden rápido, son atentos y están siempre dispuestos a ayudar, lo que hace que la experiencia sea confiable y agradable.",
      nombre: "Camila López",
      especialidad: "Kinesióloga",
    },
    {
      imagen: kine1,
      texto:
        "Como recién egresado, es valioso contar con herramientas que me ayudan a crecer sin grandes costos. Si tengo periodos sin pacientes, sé que el gasto será menor.",
      nombre: "Andrés Cavieres",
      especialidad: "Kinesiólogo",
    },
  ];

  return (
    <Box sx={{ mt: isMobile ? 15 : 15, mb: isMobile ? 15 : 0 }}>
      <Typography
        variant={isMobile ? "h5" : "h4"}
        sx={{
          color: "#454545",
          fontSize: isMobile ? "20px" : "30px",
          fontFamily: "Quicksand-Bold",
        }}
      >
        Ellos validan la excelencia de nuestro servicio
      </Typography>
      <Typography
        sx={{
          ...estiloComun,
          mt: 2,
          mb: 3,
          color: "#454545",
          fontFamily: "Quicksand-Medium",
          fontSize: isMobile ? "16px" : "20px",
        }}
      >
        Los testimonios de los profesionales que contrataron Teradomus
      </Typography>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <TestimonioImagen {...testimonios[0]} />
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TestimonioTexto {...testimonios[0]} />
            </Grid>
            <Grid item xs={12}>
              <TestimonioTexto {...testimonios[3]} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <TestimonioImagen {...testimonios[3]} />
        </Grid>
        {(!isMobile || mostrarTodos) && (
          <>
            <Grid item xs={12} md={4}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TestimonioTexto {...testimonios[1]} />
                </Grid>
                <Grid item xs={12}>
                  <TestimonioTexto {...testimonios[2]} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} md={4}>
              <TestimonioImagen {...testimonios[1]} />
            </Grid>
            <Grid item xs={12} md={4}>
              <TestimonioImagen {...testimonios[2]} />
            </Grid>
          </>
        )}
      </Grid>
      {isMobile && !mostrarTodos && (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <Button
            variant="contained"
            onClick={() => setMostrarTodos(true)}
            sx={{
              backgroundColor: "#216BA8",
              color: "white",
              borderRadius: "30px",
              padding: "6px 64px",
              textTransform: "none",
              fontFamily: "Quicksand-Bold",
              fontSize: "14px",
              fontWeight: "normal",
              boxShadow: "none",
            }}
          >
            Ver más testimonios
          </Button>
        </Box>
      )}
    </Box>
  );
}
