import { Box } from '@mui/material';
import React from 'react';

const NotFound = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
      <h1>Error 404 - Página no encontrada</h1>
      <p>La página que estás buscando no existe.</p>
    </Box>
  );
};

export default NotFound;
