import felipeImage from "../../pages/profesionales/kinesiologos/Felipe_Larraguibel.png";
import sebastianChamorro from "../../pages/profesionales/kinesiologos/Sebastian-chamorro.png";
import javierMoraga from "../../pages/profesionales/kinesiologos/Javier_Moraga.png";
import sofiaRios from "../../pages/profesionales/kinesiologos/Sofia_Constanza_Ríos_Saavedra.png";
import abdiasMolina from "../../pages/profesionales/kinesiologos/Abdias_Molina.png";
import jessamineC from "../../pages/profesionales/kinesiologos/Jessamine_Castro.png";
import nicolasRivas from "../../pages/profesionales/kinesiologos/Nicolás_Rivas.png";
import tiareC from "../../pages/profesionales/kinesiologos/Tiare_Castro.png";
import tiareA from "../../pages/profesionales/kinesiologos/tiare_aceituno.png";
import cristobalG from "../../pages/profesionales/kinesiologos/cristobal_gomez.png";
import jonathanFloresEstay from "../../pages/profesionales/kinesiologos/jonathan_flores.png";
import marianoOsorioV from "../../pages/profesionales/kinesiologos/mariano_osorio.png";

const comunasData = {
  felipe: {
    url: "felipe-larraguibel",
    comun: "Felipe Larraguibel​",
    image: felipeImage,
    id_profesional_fantasma: "6dbc8182-62f0-11ef-8888-678d3c919e34",
  },
  pablo: {
    url: "pablo-larraguibel",
    comun: "Pablo Larrraguibel",
    image: felipeImage,
    id_profesional_fantasma: "6dbc7eeb-62f0-11ef-8888-9f5cfaee7d73",
  },
  sebastian: {
    url: "sebastian-chamorro",
    comun: "Sebastian Chamorro",
    image: sebastianChamorro,
    id_profesional_fantasma: "7a511bd9-8fb6-11ef-8888-67d48e399f52",
  },
  sebastiantest: {
    url: "sebastian-chamorro-test",
    comun: "Sebastian Chamorro",
    image: sebastianChamorro,
    id_profesional_fantasma: "d193cfd8-8fb6-11ef-8888-2c4a8a44b12a",
  },
  javierM: {
    url: "javier-moraga",
    comun: "Javier Moraga",
    image: javierMoraga,
    id_profesional_fantasma: "861ba019-9b93-11ef-8888-ee59238fb964",
  },
  sofiaR: {
    url: "sofia-rios-saavedra",
    comun: "Sofia Ríos Saavedra",
    image: sofiaRios,
    id_profesional_fantasma: "6db7c7ef-9b93-11ef-8888-d85d582b6175",
  },
  abdiasM: {
    url: "abdias-molina",
    comun: "Abdias Molina",
    image: abdiasMolina,
    id_profesional_fantasma: "f47d712d-9b92-11ef-8888-1928874464a2",
  },
  jessamineC: {
    url: "jessamine-castro",
    comun: "Jessamine Castro",
    image: jessamineC,
    id_profesional_fantasma: "7300c787-9bae-11ef-8888-5e2e747578b9",
  },
  nicolasRivas: {
    url: "nicolas-rivas-soto",
    comun: "Nicolás Rivas Soto",
    image: nicolasRivas,
    id_profesional_fantasma: "6b6eab51-9bae-11ef-8888-298bdc8ca62f",
  },
  tiareC: {
    url: "tiare-castro-correa",
    comun: "Tiare Castro Correa",
    image: tiareC,
    id_profesional_fantasma: "59f92e32-9bae-11ef-8888-d08448ef4211",
  },
  tiareA: {
    url: "tiare-aceituno-rubio",
    comun: "Tiare Aceituno Rubio",
    image: tiareA,
    id_profesional_fantasma: "2f6f70c1-a1df-11ef-8888-bcf8496ef3e1",
  },
  cristobalG: {
    url: "cristobal-gomez",
    comun: "Cristóbal Gómez",
    image: cristobalG,
    id_profesional_fantasma: "2f7052c4-a1df-11ef-8888-70305971c0c1",
  },
  marianoOsorioV: {
    url: "mariano-osorio",
    comun: "Mariano Osorio Villarroel",
    image: marianoOsorioV,
    id_profesional_fantasma: "e18c2970-a838-11ef-8888-dc9294ef724a",
  },
  jonathanF: {
    url: "jonathan-flores-estay", //www.teradomus.com/pro/jonathan-flores-estay
    comun: "Jonathan Flores Estay", //nombre del profesional
    image: jonathanFloresEstay, //imagen que importamos arriba
    id_profesional_fantasma: "2f7052c4-a1df-11ef-8888-70305971c0c1", //id del propesional para la API
  },
};

export default comunasData;
