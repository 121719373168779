import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';

import flex from "../../../assets/newiconos/flex.png";
import profe from "../../../assets/newiconos/profe.png";
import donde from "../../../assets/newiconos/donde.png";
import exclu from "../../../assets/newiconos/exclu.png";
import mejor from "../../../assets/newiconos/mejor.png";
import salud from "../../../assets/newiconos/salud.png";
import facil from "../../../assets/newiconos/facil.png";

const LOCAL_IMAGES = [
  flex,
  profe,
  donde,
  exclu,
  mejor,
  salud,
];
const SLIDE_SETTINGS = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  autoplay: true,
  arrows: false,
  autoplaySpeed: 4500,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Somos = () => {
  const [images, setImages] = useState([...LOCAL_IMAGES]);

  const handleAfterChange = (slideIndex) => {
    if (slideIndex === images.length - 3) {
      setImages((prevImages) => [...prevImages, ...LOCAL_IMAGES]);
    }
  };

  const renderImage = (image, index, title, description) => (
    <Container key={index}>
      <Box
        sx={{
          width: 'auto',
          height: '130px',
          mb: 14,
          display: 'flex',
          flexDirection: 'column',
          mt: 2,
        }}
      >
        <img src={image} style={{ width: '90px', height: '90px' }} alt={title} />
        <Typography sx={{ fontSize: { md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
          {title}<p></p>
        </Typography>
        <Typography sx={{ fontSize: { md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Light' }}>
          {description}
        </Typography>
      </Box>
    </Container>
  );

  const renderImages = () => {
    return images.map((image, index) => {
      switch (index) {
        case 0:
          return renderImage(flex, index, 'Flexibilidad de pago', "Paga tus atenciones de kinesiología hasta en 3 cuotas ¡Sin interés!");
        case 1:
          return renderImage(profe, index, 'Profesionales certificados', 'Todos nuestros profesionales están certificados por la Superintendencia de Salud.');
        case 2:
          return renderImage(donde, index, 'Cuando y donde quieras', 'Te atendemos de lunes a domingo en tu domicilio, reserva cuando quieras.');
        case 3:
          return renderImage(exclu, index, 'Dedicación exclusiva', 'Nuestros profesionales tendrán dedicación exclusiva para ti durante su visita.');
        case 4:
          return renderImage(facil, index, 'Fácil de pagar', 'Aceptamos todas las tarjetas, débito o crédito y de todos los bancos.');
        case 5:
          return renderImage(mejor, index, 'Los mejores prestadores', 'Seleccionamos a nuestros profesionales con altos estándares de calidad.');
        case 6:
          return renderImage(salud, index, 'Te ayudamos con tu ISAPRE y seguro', 'Puedes reembolsar con tu ISAPRE o Seguro, y si no sabes cómo, te ayudamos.');
        default:
          return null;
      }
    });
  };

  return (
    <Box sx={{ bgcolor: '#eff0f1' }}>
      <Container>
        <Box>
          <Typography sx={{ pt: 3, fontSize: { md: "20px", xs: "15px" }, fontFamily: 'Quicksand-Light' }}>
            En Teradomus tenemos
          </Typography>
          <Typography sx={{ fontSize: { md: "28px", xs: "18px" }, fontFamily: 'Quicksand-Bold' }}>
            Una nueva forma de cuidar tu salud
          </Typography>
        </Box>
        <Box>
          <Slider {...SLIDE_SETTINGS} afterChange={handleAfterChange}>
            {renderImages()}
          </Slider>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: { md: 2, xs: 3 }, pb: { md: 3, xs: 3 } }}>
        </Box>
      </Container>
    </Box>
  );
};

export default Somos;
