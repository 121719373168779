import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Button } from "@mui/material";
import { isMobile } from "react-device-detect";

const CustomCalendar = ({ disponibilidad, onDateChange }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(null);
  const [availableDates, setAvailableDates] = useState([]);

  useEffect(() => {
    if (disponibilidad) {
      setAvailableDates(Object.keys(disponibilidad));
    }
  }, [disponibilidad]);

  const daysInMonth = (date) => {
    return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
  };

  const startDay = (date) => {
    return new Date(date.getFullYear(), date.getMonth(), 1).getDay();
  };

  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  const isDateAvailable = (date) => {
    return availableDates.includes(formatDate(date));
  };

  const handleDateClick = (date) => {
    if (isDateAvailable(date)) {
      const formattedDate = formatDate(date);
      setSelectedDate(date);
      onDateChange(formattedDate, disponibilidad[formattedDate]);
    }
  };

  const renderCalendar = () => {
    const totalDays = daysInMonth(currentMonth);
    const startingDay = startDay(currentMonth);
    const days = [];

    for (let i = 0; i < (startingDay + 6) % 7; i++) {
      days.push(<Box key={`empty-${i}`} sx={{ width: "100%", height: 40 }} />);
    }

    for (let day = 1; day <= totalDays; day++) {
      const date = new Date(
        currentMonth.getFullYear(),
        currentMonth.getMonth(),
        day
      );
      const isAvailable = isDateAvailable(date);
      const isSelected =
        selectedDate && date.toDateString() === selectedDate.toDateString();

      days.push(
        <Box
          key={day}
          onClick={() => handleDateClick(date)}
          sx={{
            width: "100%",
            height: 40,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: isAvailable ? "pointer" : "not-allowed",
            borderRadius: "10%",
            backgroundColor: isSelected
              ? "#1e7aa8"
              : isAvailable
              ? "#b1dcf0"
              : "#e8e9ee",
            color: isSelected ? "#ffffff" : isAvailable ? "#333333" : "#999999",
            "&:hover": isAvailable
              ? { backgroundColor: "#1e7aa8", color: "#ffffff" }
              : {},
          }}
        >
          <Typography sx={{ fontFamily: "Quicksand-Bold" }}>{day}</Typography>
        </Box>
      );
    }

    return days;
  };

  const handlePrevMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() - 1, 1)
    );
  };

  const handleNextMonth = () => {
    setCurrentMonth(
      new Date(currentMonth.getFullYear(), currentMonth.getMonth() + 1, 1)
    );
  };

  return (
    <Box
      sx={{ borderRadius: 5, p: 2, pr: isMobile ? 1 : 5, pl: isMobile ? 1 : 5 }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Button onClick={handlePrevMonth} sx={{ minWidth: "auto" }}>
          &lt;
        </Button>
        <Typography
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "18px", md: "20px" },
            color: "#007FFF",
          }}
        >
          {currentMonth.toLocaleString("default", {
            month: "long",
            year: "numeric",
          })}
        </Typography>
        <Button onClick={handleNextMonth} sx={{ minWidth: "auto" }}>
          &gt;
        </Button>
      </Box>
      <Grid container spacing={1} columns={7}>
        {["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"].map((day) => (
          <Grid item key={day} xs={1}>
            <Typography
              align="center"
              sx={{ color: "#007FFF", fontFamily: "Quicksand-Bold" }}
            >
              {day}
            </Typography>
          </Grid>
        ))}
        {renderCalendar().map((day, index) => (
          <Grid item key={index} xs={1}>
            {day}
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default CustomCalendar;
