import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { Button, Box } from '@mui/material';
import { isMobile } from 'react-device-detect';

const SLIDE_SETTINGS = {
    dots: true,
    infinite: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 4500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
    ],
};

const Opiniones = () => {
    const sliderRef = React.useRef(null);
    const isDesktop = !isMobile;

    const handleMoreClick = () => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo((sliderRef.current.innerSlider.state.currentSlide + 2) % renderSlides().length);
        }
    };

    const renderContent = (title, description) => (
        <Container>
            <Box
                sx={{
                    width: 'auto',
                    height: '140px',
                    bgcolor: 'white',
                    borderRadius: '13px',
                    boxShadow: '8px 8px 8px 4px rgba(0, 0, 0, 0.16)',
                    display: 'flex',
                    flexDirection: 'column',
                    mb: 4, mt: 5, p: 5,
                }}
            >
                <Typography sx={{ textAlign: 'left', fontSize: { xs: '15px', lg: '18px' }, fontFamily: 'Quicksand-Bold', color: '#1e7aa8' }}>
                    <b>{title}</b>
                </Typography>
                <Typography sx={{ mt: 2, fontSize: { xs: '15px', lg: '18px' }, fontFamily: 'Quicksand-Light', lineHeight: 1.1 }}>
                    {description}
                </Typography>
            </Box>
        </Container>
    );

    const renderSlides = () => {
        return [
            renderContent(' Heraldo V. ⭐ ⭐ ⭐ ⭐ ⭐', 'Felicitaciones para la kinesióloga, muy profesional, se nota conocimiento de su trabajo y buen trato con el usuario.'),
            renderContent('Daniel G. ⭐ ⭐ ⭐ ⭐ ⭐', 'Nicolás inspira simpatía y a su vez mantiene buen nivel de profesionalismo.'),
            renderContent('Alejandra C. ⭐ ⭐ ⭐ ⭐ ⭐', 'Excelente servicio de Felipe. Muy profesional, excelente relación entre profesional de la salud y paciente.'),
            renderContent('Silvia R. ⭐ ⭐ ⭐ ⭐ ⭐ ', 'Todo bien con el tratamiento realizado por Sofía, es un 7.0 como profesional.'),
            renderContent('Juliana C. ⭐ ⭐ ⭐ ⭐ ⭐', 'El tratamiento excelente, estoy muy satisfecha con el servicio recibido de Paulett.'),
            renderContent('Daniel G. ⭐ ⭐ ⭐ ⭐ ⭐', 'Nicolás inspira simpatía y a su vez mantiene buen nivel de profesionalismo.')
        ];
    };

    return (
        <Box sx={{ mb: 4, mt: 1 }}>
            <Slider {...SLIDE_SETTINGS}>
                {renderSlides()}
            </Slider>
        </Box>
    );
};

export default Opiniones;
