import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid, Paper } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link } from "@material-ui/core";
import { Helmet } from 'react-helmet';

import Calefaccion from "../../assets/blog/sistemas_para_calefaccion_en_casas.jpg";
import Domicilio from "../../assets/blog/atencion_a_domicilio.jpg";
import Agendamiento from "../../assets/blog/agendamiento.jpg";
import Somos from "../../assets/blog/somos_teradomus.jpg";

export default function Blog() {

  const pageTitle = "Teradomos news: enterate de lo ultimo para tu salud";
  const pageDescription = "Explora el blog de Teradomus para descubrir las últimas innovaciones en rehabilitación y cuidado de la salud sin salir de tu hogar.";

  const renderArticleList = (title) => (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom sx={{ fontFamily: 'Quicksand-Bold' }}>
        {title}
      </Typography>
      <Box
        sx={{
          mb: { xs: 2, lg: 0, }, mt: { xs: 2, lg: 3, },
          mx: 'auto', display: 'block', bgcolor: 'background.paper', borderTop: '1px solid grey', width: '100%', height: '0',
        }}
      >
      </Box>
    </Paper>
  );

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/blog" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/blog" />
        <meta property="og:type" content="article" />
      </Helmet>
      <Container>
        <Grid container>
          <Grid item md={5} xs={12}>
            <Typography variant='h2' sx={{ mt: { md: 5, xs: 2 }, fontSize: { md: "24px", xs: "18px" }, fontFamily: 'Quicksand-Bold' }}>
              LO ULTIMO
            </Typography>
            <Link href="/blog/calefaccion"
              underline="none"
              color="inherit">
              <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: { md: 2, xs: 2 } }}>
                <img src={Agendamiento} style={{ width: 'auto', height: isMobile ? '180px' : '240px' }} alt='logo' />
              </Box>
            </Link>
          </Grid>
          <Grid item md={7} xs={12}>
            <Link href="/blog/agendamiento"
              underline="none"
              color="inherit">
              <Typography variant='h2' sx={{ mt: { md: 10, xs: 2 }, fontSize: { md: "24px", xs: "18px" }, fontFamily: 'Quicksand-Bold' }}>
                Agendamiento Inteligente en Teradomus: Tu rehabilitación, ahora se adapta a ti.
              </Typography>
              <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "18px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                En Teradomus, estamos siempre en busca de mejorar tu experiencia. Hoy te presentamos nuestra nueva funcionalidad: un sistema de agendamiento impulsado por inteligencia artificial, diseñado para hacerte la vida más fácil.
              </Typography>
              <Typography variant='h3' sx={{ color: '#1e7aa8', mt: { lg: 3, xs: 2 }, fontSize: { md: "18px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
                Pablo Larraguibel - Noviembre 13, 2023
              </Typography>
            </Link>
          </Grid>
          <Box
            sx={{
              mb: { xs: 7, lg: 10, }, mt: { xs: 7, lg: 10, },
              mx: 'auto', // Centrado horizontalmente con márgenes automáticos
              display: 'block', // Asegura que actúe como un bloque y permita el centrado
              bgcolor: 'background.paper', // Color de fondo (puedes ajustar esto como quieras)
              borderTop: '1px solid grey', // Aquí se crea la línea, con el color gris
              width: '100%', // Ancho al 90% del contenedor padre
              height: '0', // Altura mínima para que no ocupe espacio más que el borde
            }}
          >
          </Box>
          <Grid item md={2.5} xs={5.5}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: { md: 0, xs: 2 } }}>
              <img src={Somos} style={{ width: 'auto', height: isMobile ? '85px' : '125px' }} alt='logo' />
            </Box>
          </Grid>
          <Grid item md={3.5} xs={6.5}>
            <Link href="/blog/somos"
              underline="none"
              color="inherit">
              <Box sx={{ ml: { md: 1.5, xs: 2 } }}>
                <Typography variant='h2' sx={{ mt: { md: 0, xs: 1.5 }, fontSize: { md: "18px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
                  Teradomus, un equipo trabajando por tu rehabilitación.
                </Typography>
                <Typography variant='h3' sx={{ color: '#1e7aa8', mt: { lg: 3, xs: 2 }, fontSize: { md: "16px", xs: "12px" }, fontFamily: 'Quicksand-Regular' }}>
                  Felipe Larraguibel - Noviembre 13, 2023
                </Typography>
              </Box>
            </Link>
          </Grid>
          <Grid item md={2.5} xs={5.5}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: { md: 0, xs: 2 } }}>
              <img src={Calefaccion} style={{ width: 'auto', height: isMobile ? '85px' : '125px' }} alt='logo' />
            </Box>
          </Grid>
          <Grid item md={3.5} xs={6.5}>
            <Link href="/blog/calefaccion"
              underline="none"
              color="inherit">
              <Box sx={{ ml: { md: 1.5, xs: 2 } }}>
                <Typography variant='h2' sx={{ mt: { md: 0, xs: 1.5 }, fontSize: { md: "18px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
                  Calefacción Intradomiciliaria: Un Riesgo Invisible para la Salud Respiratoria.
                </Typography>
                <Typography variant='h3' sx={{ color: '#1e7aa8', mt: { lg: 3, xs: 2 }, fontSize: { md: "16px", xs: "12px" }, fontFamily: 'Quicksand-Regular' }}>
                  Felipe Larraguibel - Junio 16, 2023
                </Typography>
              </Box>
            </Link>
          </Grid>
          <Grid item md={2.5} xs={5.5}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: { md: 10, xs: 2 } }}>
              <img src={Domicilio} style={{ width: 'auto', height: isMobile ? '85px' : '125px' }} alt='logo' />
            </Box>
          </Grid>
          <Grid item md={3.5} xs={6.5}>
            <Link href="/blog/domicilio"
              underline="none"
              color="inherit">
              <Box sx={{ ml: { md: 1.5, xs: 2 } }}>
                <Typography variant='h2' sx={{ mt: { md: 10, xs: 1.5 }, fontSize: { md: "18px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
                  5 buenas razones para escoger la atención domiciliaria de salud.
                </Typography>
                <Typography variant='h3' sx={{ color: '#1e7aa8', mt: { lg: 3, xs: 2 }, fontSize: { md: "16px", xs: "12px" }, fontFamily: 'Quicksand-Regular' }}>
                  Felipe Larraguibel - Junio 16, 2023
                </Typography>
              </Box>
            </Link>
          </Grid>
        </Grid>
        <Box
          sx={{
            mb: { xs: 4, lg: 10, }, mt: { xs: 7, lg: 10, },
            mx: 'auto', display: 'block', bgcolor: 'background.paper', borderTop: '1px solid grey', width: '100%', height: '0',
          }}
        >
        </Box>
      </Container >
      <Container>
        <Grid container >
          <Grid item md={6} xs={12}>
            {renderArticleList('Artículos de Salud')}
            <Box sx={{ display: 'block', p: 2, fontFamily: 'Quicksand-Regular' }}>
              <Link href={`/blog/calefaccion`} underline="none" color="inherit" >
                <li>Calefacción Intradomiciliaria: Un Riesgo Invisible para la Salud Respiratoria.</li><p></p>
              </Link>
              <Link href={`/blog/domicilio`} underline="none" color="inherit" >
                <li>5 buenas razones para escoger la atención domiciliaria de salud.</li>
              </Link>
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            {renderArticleList('News Teradomus')}
            <Box sx={{ display: 'block', p: 2, fontFamily: 'Quicksand-Regular' }}>
              <Link href={`/blog/agendamiento`} underline="none" color="inherit" >
                <li>Agendamiento Inteligente en Teradomus: Tu rehabilitación, ahora se adapta a ti.</li><p></p>
              </Link>
              <Link href={`/blog/somos`} underline="none" color="inherit" >
                <li>Teradomus, un equipo trabajando por tu rehabilitación.</li>
              </Link>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}