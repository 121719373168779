import React, { useState, useCallback, useEffect } from "react";
import Autosuggest from "react-autosuggest";
import { Box, Typography } from "@mui/material";
import { debounce } from "lodash";

const AddressInput = ({
  onAddressChange,
  onComunaChange,
  disabled,
  direccion: direccionProp,
}) => {
  const [direccionLocal, setDireccionLocal] = useState(direccionProp || "");
  const [sugerencias, setSugerencias] = useState([]);
  const direccionCharactersRemaining = 100 - direccionLocal.length;

  useEffect(() => {
    if (direccionProp !== undefined) {
      setDireccionLocal(direccionProp);
    }
  }, [direccionProp]);

  const obtenerSugerencias = useCallback(
    debounce((value) => {
      if (disabled) return;

      const service = new window.google.maps.places.AutocompleteService();
      service.getPlacePredictions(
        {
          input: value,
          componentRestrictions: { country: "CL" },
        },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setSugerencias(
              predictions.map((prediction) => prediction.description)
            );
          }
        }
      );
    }, 300),
    [disabled]
  );

  const onDireccionChange = (event, { newValue }) => {
    if (disabled) return;
    setDireccionLocal(newValue);
    onAddressChange(newValue);
  };

  const onSugerenciasFetchRequested = ({ value }) => {
    if (!disabled) {
      obtenerSugerencias(value);
    }
  };

  const onSugerenciasClearRequested = () => {
    setSugerencias([]);
  };

  const handleDireccionBlur = () => {
    if (disabled) return;

    const autocompleteService =
      new window.google.maps.places.AutocompleteService();
    autocompleteService.getPlacePredictions(
      { input: direccionLocal },
      (predictions, status) => {
        if (
          status === window.google.maps.places.PlacesServiceStatus.OK &&
          predictions.length > 0
        ) {
          const placesService = new window.google.maps.places.PlacesService(
            document.createElement("div")
          );
          placesService.getDetails(
            { placeId: predictions[0].place_id },
            (place, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                const comunaComponent = place.address_components.find(
                  (component) =>
                    component.types.includes("administrative_area_level_3") ||
                    component.types.includes("locality")
                );
                if (comunaComponent) {
                  onComunaChange(comunaComponent.long_name);
                }
              }
            }
          );
        }
      }
    );
  };

  const renderSugerencia = (sugerencia) => (
    <div
      style={{
        cursor: disabled ? "not-allowed" : "pointer",
        padding: "7px 0px",
        position: "relative",
        opacity: disabled ? 0.6 : 1,
      }}
    >
      {sugerencia}
      <div
        style={{
          position: "absolute",
          bottom: 0,
          width: "90%",
          borderBottom: "1px solid #e0e0e0",
        }}
      />
    </div>
  );

  const inputProps = {
    placeholder: "Escribe tu dirección",
    value: direccionLocal,
    onChange: onDireccionChange,
    onBlur: handleDireccionBlur,
    disabled: disabled,
    maxLength: 100,
    style: {
      border: "none",
      outline: "none",
      background: "transparent",
      paddingLeft: "5px",
      fontFamily: "Quicksand-Regular",
      color: "#4F4F4F",
      fontSize: "16px",
      width: "97%",
      zIndex: 0,
      cursor: disabled ? "not-allowed" : "text",
      opacity: disabled ? 0.6 : 1,
    },
  };

  const theme = {
    suggestionsContainer: {
      position: "absolute",
      borderRadius: "4px",
      boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#fff",
      width: "100%",
      zIndex: 9999,
      opacity: disabled ? 0.6 : 1,
      pointerEvents: disabled ? "none" : "auto",
    },
    suggestion: {
      opacity: disabled ? 0.6 : 1,
    },
  };

  return (
    <>
      <Typography
        sx={{
          pt: 1,
          fontFamily:
            direccionLocal.trim() !== ""
              ? "Quicksand-Bold"
              : "Quicksand-Regular",
          fontSize: { xs: "16px", md: "20px", lg: "20px" },
          color: disabled
            ? "#9e9e9e"
            : direccionLocal.trim() !== ""
            ? "#2669A8"
            : "#4F4F4F",
        }}
      >
        {direccionLocal.trim() !== "" ? "Dirección" : "Dirección*"}
      </Typography>
      <Box
        sx={{
          borderRadius: 2,
          overflow: "hidden",
          backgroundImage: disabled
            ? "none"
            : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
          backgroundColor: disabled ? "#f5f5f5" : "transparent",
          opacity: disabled ? 0.6 : 1,
          p: 0.5,
        }}
      >
        <Autosuggest
          suggestions={sugerencias}
          onSuggestionsFetchRequested={onSugerenciasFetchRequested}
          onSuggestionsClearRequested={onSugerenciasClearRequested}
          getSuggestionValue={(sugerencia) => sugerencia}
          renderSuggestion={renderSugerencia}
          inputProps={inputProps}
          theme={theme}
        />
      </Box>
      {direccionCharactersRemaining <= 10 && (
        <Typography
          variant="caption"
          sx={{
            textAlign: "left",
            display: "block",
            color: direccionCharactersRemaining <= 0 ? "red" : "inherit",
          }}
        >
          {direccionCharactersRemaining} caracteres restantes
        </Typography>
      )}
    </>
  );
};

export default AddressInput;
