import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/system';

function FAQ() {
    const [expanded, setExpanded] = useState(null);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const questions = [
        {
            question: '¿Cómo funciona el servicio de kinesiología a domicilio de Teradomus?',
            answer: 'En Teradomus, nos adaptamos a tus necesidades. Puedes agendar tu tratamiento vía web o por teléfono. Asignamos al kinesiólogo que mejor se adapte a tus necesidades. Este profesional llegará a tu domicilio y realizará una evaluación inicial para comenzar el tratamiento desde el primer día. Continuaremos con tu recuperación durante todas las sesiones que necesites.',
        },
        {
            question: '¿Qué medidas de seguridad toma Teradomus para garantizar un servicio seguro?',
            answer: 'En Teradomus, nos preocupamos por brindar una atención segura y de calidad. Todos nuestros profesionales están certificados por la Superintendencia de Salud y pasan por un estricto sistema de selección que incluye la verificación de antecedentes de estudios y más. ',
        },
        {
            question: '¿Qué sucede si no estoy satisfecho con el servicio de Teradomus?',
            answer: 'Valoramos tu opinión. Al principio y final de tu tratamiento, podrás evaluar tu experiencia. Si tienes algún problema durante tu tratamiento, puedes contactarnos para realizar los ajustes que estimes convenientes.',
        },
        {
            question: '¿Cómo funciona el reembolso/cobertura con mi ISAPRE o seguro de salud en Teradomus?',
            answer: 'La boleta generada por el servicio incorpora lo necesario para que, junto con tu orden médica, puedas solicitar reembolso en todas las ISAPRES y Seguros. Si llegaras a necesitar ayuda en esta etapa, contáctanos. Te ayudaremos en todo el proceso.',
        },
        /*
            question: '¿Dónde ofrece Teradomus sus servicios de kinesiología a domicilio?',
            answer: 'Ofrecemos nuestros servicios en todas las comunas urbanas de Santiago (La Florida, Puente Alto, Pirque, Santiago, Cerrillos, Cerro Navia, Conchalí, El Bosque, Estación Central, Huechuraba, Independencia, La Cisterna, La Granja, La Pintana, La Reina, Las Condes, Lo Espejo, Lo Prado, Macul, Maipú, Pedro Aguirre Cerda, Peñalolén, Providencia, Pudahuel, Quinta Normal, Recoleta, Renca, San Joaquín, San Miguel, San Ramón, Vitacura, Ñuñoa, San José de Maipo, Lo Barnechea, San Bernardo, Quilicura), además de Valparaíso, Viña del Mar, Quilpué, Villa Alemana, Antofagasta, Concepción, Talcahuano, Chiguayante, San Pedro de La Paz, Penco y Hualpén.',
        },
        {
            question: '¿Qué tipos de kinesiología ofrece Teradomus?',
            answer: 'En Teradomus, ofrecemos una variedad de servicios de kinesiología, incluyendo kinesiología motora, respiratoria, neurológica, respiratoria infantil y para adultos mayores.',
        */
        {
            question: '¿Teradomus tiene convenio con FONASA?',
            answer: 'Por el momento, Teradomus no tiene convenio con FONASA.',
        },

    ];

    return (
        <>
            <Typography gutterBottom sx={{ fontFamily: 'Quicksand-Bold', fontSize: { lg: "28px", md: "26px", xs: "18px" }, mb: { xs: 3, lg: 5 } }}>
                Preguntas más frecuentes de nuestros pacientes
            </Typography>
            <Box sx={{ bgcolor: '#eff0f1' }}>
                {questions.map((q, index) => (
                    <Accordion key={index} expanded={expanded === index} onChange={handleChange(index)}
                        sx={{ bgcolor: '#eff0f1' }} // Agrega el bgcolor deseado al componente Accordion
                    >
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography sx={{
                                cursor: 'pointer',
                                fontFamily: 'Quicksand-Regular',
                                fontSize: { lg: "20px", md: "18px", xs: "15px" },
                                fontWeight: expanded === index ? 'bold' : 'normal',
                                color: expanded === index ? '#2669A8' : 'inherit',
                            }}
                            >
                                {q.question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography sx={{ fontFamily: 'Quicksand-Regular', fontSize: { lg: "17px", md: "15px", xs: "13px" }, marginLeft: '30px' }}>
                                {q.answer}</Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </>
    );
}

export default FAQ;
