import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import MenuCliente from "../../components/MenuCliente/MenuCliente";
import FormGroup from "@mui/material/FormGroup";
import TelWhat from "../../components/TelWhat/TelWhat";
import { Helmet } from "react-helmet";

export default function Direccion() {
  let history = useHistory();
  const location = useLocation();
  const {
    respuesta2,
    respuesta3,
    comuna,
    respuesta5,
    sessions,
    preciosessions,
    respuesta6,
    nombre,
    apellido,
    edad,
    telefono,
    tipo,
    direccion,
    motivo,
  } = location.state;
  const [textoVisible, setTextoVisible] = useState("");
  const [showBothBoxes, setShowBothBoxes] = useState(false);
  const [showBothBoxes2, setShowBothBoxes2] = useState(true); // o false, dependiendo de tu preferencia inicial
  const [consultaType, setConsultaType] = useState(""); // Esta variable mantendrá el valor de la consulta.

  const comunas = [
    "Antofagasta",
    "Valparaíso",
    "Concón",
    "Viña del Mar",
    "Quilpué",
    "Villa Alemana",
    "Concepción",
    "Chiguayante",
    "Penco",
    "San Pedro de La Paz",
    "Talcahuano",
    "Tomé",
    "Santiago",
    "Cerrillos",
    "Cerro Navia",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "Ñuñoa",
    "Pedro Aguirre Cerda",
    "Peñalolén",
    "Providencia",
    "Pudahuel",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "San Joaquín",
    "San Miguel",
    "San Ramón",
    "Vitacura",
    "Puente Alto",
    "San Bernardo",
    "Hualpén",
    "La Serena",
    "Coquimbo",
    "Puerto Montt",
    "Puerto Varas",
    "Temuco",
    "Padre Las Casas",
  ];

  let newText = tipo;
  let updatedTextseciones = replaceBoldContent(respuesta2, newText);

  function replaceBoldContent(originalText, replacementText) {
    return originalText.replace(/\*\*(.*?)\*\*/, `**${replacementText}**`);
  }

  useEffect(() => {
    setShowBothBoxes(comunas.includes(comuna));
  }, [comuna]);

  useEffect(() => {
    setShowBothBoxes2(!comunas.includes(comuna)); // Pone en 'true' si la comuna NO está en la lista
  }, [comuna]);

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = updatedTextseciones; // <--- Asignar respuesta1 a textoAMostrar

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Velocidad de escritura (ajusta según tus preferencias)

    return () => {
      clearInterval(interval);
    };
  }, [updatedTextseciones]);

  function parseTextWithBold(text) {
    const splitText = text.split("**");
    return splitText.map((part, index) => {
      if (index % 2 === 1) {
        // Si es un índice impar, es una variable y debe ir en negrita
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  }

  const [isBoxSelected, setIsBoxSelected] = useState(true);

  const [box1Text, setBox1Text] = useState("Kinesiologia a domicilio");
  const [box1Text1, setBox1Text1] = useState(
    "Un kinesiólogo certificado llegara a tu hogar a realizar el tratamiento."
  );
  const [box1Color, setBox1Color] = useState("white");
  const [box1TextColor, setBox1TextColor] = useState("white");

  const [box2Text, setBox2Text] = useState("Kinesiología telerehabilitación");
  const [box2Text2] = useState(
    "Un kinesiólogo certificado se conectará contigo por video llamada."
  );
  const [box2Color, setBox2Color] = useState("white");
  const [box2TextColor, setBox2TextColor] = useState("#aeaeaf");

  const handleBox1Click = () => {
    setBox1Color(
      "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
    );
    setBox1TextColor("white");
    setBox2Color("white");
    setBox2TextColor("#aeaeaf");
    setIsBoxSelected(true);
    setConsultaType("a domicilio"); // Actualización del estado consultaType
  };

  const handleBox2Click = () => {
    setBox2Color(
      "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
    );
    setBox2TextColor("white");
    setBox1Color("white");
    setBox1TextColor("#aeaeaf");
    setIsBoxSelected(false);
    setConsultaType("telerehabilitación"); // Actualización del estado consultaType
  };
  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription =
    "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/servicios"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/servicios"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <ScrollToTop />
      <MenuCliente />
      <Box
        sx={{
          height: { lg: "300px", xs: "25vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      ></Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -30, xs: -20 },
          margin: "0 auto", // Añadido para centrar horizontalmente
        }}
      >
        <FormGroup>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid Item xs="12" md="10" lg="10">
                <Typography
                  textAlign={"center"}
                  sx={{
                    color: "#2669A8",
                    mt: { xs: 1, lg: 3 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "20px", md: "20px", lg: "26px" },
                  }}
                >
                  Servicios disponibles
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mt: { xs: 3, lg: 3 },
                    mb: { xs: 3, lg: 6 },
                    fontFamily: "Quicksand-Regular",
                    fontSize: { xs: "16px", md: "20px", lg: "18px" },
                  }}
                >
                  {parseTextWithBold(textoVisible)}
                </Typography>

                <Container>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {showBothBoxes ? (
                      <>
                        <Grid item xs={12} lg={10}>
                          <Box
                            sx={{
                              display: "flex",
                              height: { xs: "100px", lg: "120px" },
                              width: { xs: "auto", lg: "auto" },
                              p: 2,
                              backgroundColor: isBoxSelected
                                ? "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
                                : box1Color,
                              backgroundImage: isBoxSelected
                                ? "linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))"
                                : box1Color !== "white"
                                ? box1Color
                                : undefined,
                              flexDirection: "column",
                              borderRadius: "13px",
                              mb: { xs: 3, lg: 4 },
                              boxShadow: "8px 8px 8px 2px rgba(0, 0, 0, 0.16)",
                              cursor: "pointer",
                              color: isBoxSelected ? "white" : box1TextColor,
                            }}
                            onClick={handleBox1Click}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Quicksand-Bold",
                                fontSize: {
                                  xs: "14px",
                                  md: "16px",
                                  lg: "20px",
                                },
                                color: box1TextColor,
                              }}
                            >
                              {box1Text}
                            </Typography>
                            <Typography
                              sx={{
                                mt: 1,
                                mb: 2,
                                fontFamily: "Quicksand-Regular",
                                fontSize: {
                                  xs: "14px",
                                  md: "16px",
                                  lg: "18px",
                                },
                                color: box1TextColor,
                              }}
                            >
                              {box1Text1}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12} lg={10}>
                          <Box
                            sx={{
                              display: "flex",
                              backgroundColor:
                                box2Color === "white" ? box2Color : undefined, // Si es blanco, usar backgroundColor
                              backgroundImage:
                                box2Color !== "white" ? box2Color : undefined, // Si no es blanco, usar backgroundImage
                              flexDirection: "column",
                              p: 2,
                              borderRadius: "13px",
                              width: { xs: "auto", lg: "auto" },
                              boxShadow: "8px 8px 8px 2px rgba(0, 0, 0, 0.16)",
                              height: { xs: "100px", lg: "120px" },
                            }}
                          >
                            <Typography
                              sx={{
                                fontFamily: "Quicksand-Bold",
                                fontSize: {
                                  xs: "14px",
                                  md: "16px",
                                  lg: "20px",
                                },
                                color: box2TextColor,
                              }}
                            >
                              {box2Text}
                            </Typography>
                            <Typography
                              sx={{
                                mt: 1,
                                mb: 0,
                                fontFamily: "Quicksand-Regular",
                                fontSize: {
                                  xs: "14px",
                                  md: "16px",
                                  lg: "18px",
                                },
                                color: box2TextColor,
                              }}
                            >
                              {box2Text2}
                            </Typography>
                            <Typography
                              sx={{
                                textAlign: "center",
                                mb: 2,
                                fontFamily: "Quicksand-Regular",
                                fontSize: {
                                  xs: "14px",
                                  md: "16px",
                                  lg: "18px",
                                },
                                color: box2TextColor,
                              }}
                            >
                              --- próximamente ---
                            </Typography>
                          </Box>
                        </Grid>
                      </>
                    ) : (
                      <Grid item xs={12} lg={10}>
                        <Box
                          sx={{
                            display: "flex",
                            backgroundColor:
                              box2Color === "white" ? box2Color : undefined, // Si es blanco, usar backgroundColor
                            backgroundImage:
                              box2Color !== "white" ? box2Color : undefined, // Si no es blanco, usar backgroundImage
                            flexDirection: "column",
                            p: 2,
                            borderRadius: "13px",
                            width: { xs: "auto", lg: "auto" },
                            boxShadow: "8px 8px 8px 2px rgba(0, 0, 0, 0.16)",
                            height: { xs: "100px", lg: "120px" },
                          }}
                        >
                          <Typography
                            sx={{
                              fontFamily: "Quicksand-Bold",
                              fontSize: { xs: "14px", md: "16px", lg: "20px" },
                              color: box2TextColor,
                            }}
                          >
                            {box2Text}
                          </Typography>
                          <Typography
                            sx={{
                              mt: 1,
                              mb: 0,
                              fontFamily: "Quicksand-Regular",
                              fontSize: { xs: "14px", md: "16px", lg: "18px" },
                              color: box2TextColor,
                            }}
                          >
                            {box2Text2}
                          </Typography>
                          <Typography
                            sx={{
                              textAlign: "center",
                              mb: 2,
                              fontFamily: "Quicksand-Regular",
                              fontSize: { xs: "14px", md: "16px", lg: "18px" },
                              color: box2TextColor,
                            }}
                          >
                            --- próximamente ---
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    <Typography
                      sx={{
                        mt: 3,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "14px", md: "16px", lg: "18px" },
                        color: box2TextColor,
                      }}
                    >
                      {showBothBoxes2 && (
                        <p>
                          Por el momento no contamos con servicios habilitados
                          en su localidad
                        </p>
                      )}
                    </Typography>
                  </Grid>
                </Container>
              </Grid>
            </Grid>
          </Container>
        </FormGroup>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { xs: 3, lg: 4 },
            mb: { xs: 3, lg: 4 },
          }}
        >
          <Button
            disabled={!isBoxSelected || !comunas.includes(comuna)}
            sx={{
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "14px", md: "16px", lg: "16px" },
              backgroundColor: !isBoxSelected ? "gray" : "#8753b1",
              "&:hover": {
                backgroundColor: !isBoxSelected ? "gray" : "#651d87",
              },
              borderRadius: 3,
            }}
            variant="contained"
            onClick={() => {
              history.push({
                pathname:
                  "/kinesiologia-a-domicilio/agenda-inteligente/calendario",
                state: {
                  respuesta3: respuesta3,
                  respuesta5: respuesta5,
                  respuesta6: respuesta6,
                  motivo: motivo,
                  sessions: sessions,
                  preciosessions: preciosessions,
                  comuna: comuna,
                  consulta: consultaType,
                  nombre: nombre,
                  apellido: apellido,
                  edad: edad,
                  telefono: telefono,
                  tipo: tipo,
                  direccion: direccion,
                },
              });
            }}
          >
            Continuar
          </Button>
        </Container>
      </Box>
      <Box
        sx={{
          width: { lg: "58%", xs: "85%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto", // Añadido para centrar horizontalmente
          padding: 2,
          mt: 6,
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={10}>
            <Box
              sx={{
                ml: { xs: 0, md: 2, lg: 2 },
              }}
            >
              <Typography
                sx={{
                  color: "#4F4F4F",
                  mb: { xs: 1, lg: 1 },
                  mt: { xs: 0, lg: 2 },
                  fontFamily: "Quicksand-Bold",
                  fontSize: { xs: "14px", md: "16px", lg: "20px" },
                }}
              >
                Así va tu orden:
              </Typography>
              <Typography
                sx={{
                  ml: 2,
                  color: "#4F4F4F",
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "13px", md: "16px", lg: "18px" },
                }}
              >
                <li>
                  {tipo} {consultaType}
                </li>
                <li>
                  N° de sesiones: {sessions}, desde $
                  {preciosessions
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  por sesión{" "}
                </li>
                <li>
                  Total a pagar desde $
                  {(sessions * preciosessions)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  hasta en 3 cuotas de $
                  {((sessions * 25000) / 3)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                  , sin intereses
                </li>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", pt: 9, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}
