import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { isMobile } from "react-device-detect";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";
import callApi from "../../services/callApi";

function IngresadaMercadoPago() {
  const ACCESS_TOKEN = process.env.REACT_APP_ACCESS_TOKEN;
  const location = useLocation();
  let history = useHistory();
  const [idPago, setIdPago] = useState(1);
  const [amount, setAmount] = useState(0);
  const [cantSession, setCantSesion] = useState(0);
  const [preference, setPreference] = useState(null);
  const [validate, setValidate] = useState(0);

  function validate_pay(idPago, preference) {
    if (idPago === 1) {
      return null;
    }
    const axios = require("axios");
    const paymentId = idPago;
    const accessToken = ACCESS_TOKEN;

    axios({
      method: "get",
      url: `https://api.mercadopago.com/v1/payments/${paymentId}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const paymentStatus = response.data.status;
        setAmount(response.data.transaction_amount);
        if (paymentStatus === "approved") {
          setValidate(1);
          putPayment(
            idPago,
            preference,
            response.data.transaction_amount,
            response.data.additional_info.items[0].quantity
          );
        } else {
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    setIdPago(searchParams.get("payment_id"));
    setPreference(searchParams.get("preference_id"));
    validate_pay(
      searchParams.get("payment_id"),
      searchParams.get("preference_id")
    );
    getPrePayment(searchParams.get("preference_id"));
  }, []);

  async function getPrePayment(preference) {
    if (preference === null) {
      return null;
    }

    const url = `/payment?preference=${preference}`;
    const Payment = await callApi(url);
    if (Payment) {
      setCantSesion(Payment);
    }
    return null;
  }

  async function putPayment(id_pago, preference, monto, quantity) {
    const putPayment = {
      id: preference,
      monto: monto,
      id_mercadopago: id_pago,
      id_preference: preference,
      cant_sesiones: quantity,
    };

    const url = `/puyment?id=${preference}`;
    await callApi(url, "post", putPayment);

    return null;
  }

  function renderValidate() {
    if (validate === 1 && cantSession !== 0) {
      if (amount === cantSession * 350) {
        return (
          <Box textAlign="center" sx={{ pb: 2, fontSize: 19 }}>
            <Typography class="row">
              ¡Gracias por confiar en nuestro servicio de atención a domicilio
              de kinesiología!
              <br></br>Tu pago de {cantSession} atenciones <b>fue verificado</b>
              .
            </Typography>
            <Typography class="row">
              <br></br>
              Nuestro equipo se pondrá en contacto en los proximos minutos.
            </Typography>
          </Box>
        );
      } else {
        return (
          <Box textAlign="center" sx={{ pb: 2, fontSize: 19 }}>
            <Typography class="row">
              ¡Gracias por confiar en nuestro servicio de atención a domicilio
              de kinesiología!
              <br></br>Tenemos una diferencia entre el pago enviado versus el
              cobrado en tu cuenta.
            </Typography>
            <Typography class="row">
              <br></br>
              Nuestro equipo se pondrá en contacto en los proximos minutos.
            </Typography>
          </Box>
        );
      }
    } else {
      return null;
    }
  }

  return (
    <>
      <ScrollToTop />
      <Grid container direction="row" justifyContent="center"></Grid>
      <Grid
        container
        spacing={2}
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        {renderValidate()}
      </Grid>
      <Box sx={{ textAlign: "center", pb: 2 }}>
        <Button
          sx={{ mt: 3, backgroundColor: "#443cc3", borderRadius: 5 }}
          onClick={() => {
            history.push({
              pathname: "/",
            });
          }}
          variant="contained"
        >
          Volver al inicio
        </Button>
      </Box>
    </>
  );
}
export default IngresadaMercadoPago;
