import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import logo from "../../assets/images/logo_png.png";
import { Link } from "@material-ui/core";

export default function Footer() {
  return (
    <Box sx={{ backgroundColor: "#e6e6e6", mt: 8 }}>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <Link href="https://teradomus.com">
              <Box
                component="img"
                sx={{
                  width: "25%",
                }}
                src={logo}
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: "auto", mt: 4 }}>
            <Typography variant="h6" color="#666565">
              <b>Te Ayudamos</b>
            </Typography>
            <Typography mt={3} variant="subtitle1" color="#666565">
              Llámanos +56 9 9690 6773
              <br></br>Escríbenos ayuda@teradomus.com
              <br></br>
              <Link
                href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/FAQ.pdf"
                underline="none"
                color="inherit"
              >
                Preguntas Frecuentes
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: "auto", mt: 4 }}>
            <Typography variant="h6" color="#666565">
              <b>Políticas</b>
            </Typography>
            <Typography mt={3} variant="subtitle1" color="#666565">
              <Link
                href="https://teradomus-storage-public.s3.amazonaws.com/NO_BORRAR/CONDICIONES_PRESTADORES.pdf"
                underline="none"
                color="inherit"
              >
                Términos y condiciones
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sx={{ mb: "auto", mt: 4 }}>
            <Typography variant="h6" color="#666565">
              <b>Atiende con Teradomus</b>
            </Typography>
            <Typography mt={3} variant="subtitle1" color="#666565">
              Postula aqui profesional de la salud
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}
