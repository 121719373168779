import React from 'react';
import Slider from "react-slick";
import { Typography } from '@mui/material';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const MyComponent = () => {
    const textPart1 = `Este plan es perfecto para aquellos adultos mayores que buscan mantener o mejorar su fuerza física, equilibrio y movilidad, al mismo tiempo que desean mantenerse mentalmente activos y socialmente conectados. `;

    const textPart2 = `Es especialmente beneficioso para aquellos que valoran su independencia, desean prevenir el deterioro cognitivo, y necesitan asistencia básica en sus rutinas de salud.`;

    const settings = {
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 7000,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <Slider {...settings}>
            <Typography
                sx={{
                    fontFamily: 'Quicksand-Light',
                    fontSize: { lg: "18px", md: "18px", xs: "16px" },
                    mt: { lg: 5, md: 4, xs: 2 },
                    mb: 2,
                    textAlign: 'justify'
                }}
            >
                {textPart1}
            </Typography>
            <Typography
                sx={{
                    fontFamily: 'Quicksand-Light',
                    fontSize: { lg: "18px", md: "18px", xs: "16px" },
                    mt: { lg: 5, md: 4, xs: 2 },
                    mb: 2,
                    textAlign: 'justify'
                }}
            >
                {textPart2}
            </Typography>
        </Slider>
    );
};

export default MyComponent;
