import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { isMobile } from "react-device-detect";

export default function Principal() {
  const isDesktop = !isMobile;

  return (
    <>
      <Container>
        <Box
          sx={{
            mt: { xs: 5, lg: 7 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              mb: 1,
              fontSize: { lg: "28px", md: "16px", xs: "20px" },
              fontFamily: "Quicksand-Bold",
              color: "#1e7aa8",
            }}
          >
            Precios
          </Typography>
          <Typography
            variant="h3"
            sx={{
              mb: { xs: 2, lg: 2 },
              mt: 1,
              fontSize: { lg: "18px", md: "16px", xs: "16px" },
              fontFamily: "Quicksand-Regular",
              color: "#1e7aa8",
            }}
          >
            Realiza tu reserva de sesiones kinesiológicas en paquetes con
            beneficios
          </Typography>
        </Box>
        <Box sx={{ width: { xs: "100%", lg: "80%" }, margin: "auto" }}>
          <Grid
            container
            spacing={4}
            textAlign="center"
            justifyContent="center"
          >
            <Grid item xs={12} lg={5}>
              <Box
                sx={{
                  width: { xs: "auto", lg: "auto" },
                  height: { xs: "100px", lg: "145px" },
                  borderRadius: 4,
                  mt: 3,
                  mb: { xs: 0, md: 5, lg: 3 },
                  boxShadow: "6px 6px 6px 4px rgba(0, 0, 0, 0.14)",
                  p: { xs: 1, lg: 1 },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    mt: 1,
                    fontSize: { lg: "20px", md: "16px", xs: "16px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                  }}
                >
                  Solicita 1 o más sesiones:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 0.2,
                    mt: { xs: 3, lg: 7.8 },
                    fontSize: { lg: "22px", md: "16px", xs: "16px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                  }}
                >
                  $25.000
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 1,
                    fontSize: { lg: "20px", md: "16px", xs: "14px" },
                    fontFamily: "Quicksand-Regular",
                    color: "#aeaeae",
                  }}
                >
                  por sesión
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} lg={5}>
              <Box
                sx={{
                  width: { xs: "auto", lg: "auto" },
                  height: { xs: "100px", lg: "145px" },
                  borderRadius: 4,
                  mt: 3,
                  mb: { xs: 0, md: 5, lg: 3 },
                  boxShadow: "6px 6px 6px 4px rgba(0, 0, 0, 0.14)",
                  p: { xs: 1, lg: 1 },
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    mt: 1,
                    fontSize: { lg: "20px", md: "16px", xs: "16px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                  }}
                >
                  Solicita y Paga 10 o más sesiones:
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 0.2,
                    mt: { xs: 3, lg: 7.8 },
                    fontSize: { lg: "22px", md: "16px", xs: "16px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                  }}
                >
                  $23.000
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 1,
                    fontSize: { lg: "20px", md: "16px", xs: "14px" },
                    fontFamily: "Quicksand-Regular",
                    color: "#aeaeae",
                  }}
                >
                  por sesión
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Typography
            variant="h3"
            sx={{
              mb: { xs: 2, lg: 10 },
              mt: 1,
              fontSize: { lg: "18px", md: "16px", xs: "16px" },
              fontFamily: "Quicksand-Regular",
              textAlign: "center",
            }}
          >
            Los días festivos, sábados y domingos tienen un recargo adicional de
            $5.000 por sesión.
          </Typography>
        </Box>
      </Container>
    </>
  );
}
