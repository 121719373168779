import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link } from "@material-ui/core";
import { Helmet } from 'react-helmet';


import Domicilio from "../../assets/blog/atencion_a_domicilio.jpg";


export default function BlogNoticia2() {
  const pageTitle = "Teradomos news: enterate de lo ultimo para tu salud";
  const pageDescription = "Descubre las ventajas de la atención domiciliaria de salud con Teradomus. Terapia personalizada y confort en tu propio hogar.";


  return (
    <>
          <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/blog/domicilio" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href="https://www.teradomus.com/blog/domicilio" />
      </Helmet>
      <Container>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Link href="/blog"
              underline="none"
              color="inherit">
              <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 2 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
                ← Volver al listado de artículos
              </Typography>
            </Link>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant='h2' sx={{ mt: { md: 4, xs: 2 }, fontSize: { md: "22px", xs: "18px" }, fontFamily: 'Quicksand-Bold' }}>
              5 buenas razones para escoger la atención domiciliaria de salud.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 6, xs: 2 } }}>
              <img src={Domicilio} style={{ width: 'auto', height: isMobile ? '175px' : '290px' }} alt='logo' />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant='h3' sx={{ color: '#1e7aa8', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              Junio 16, 2023
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              La atención domiciliaria es un servicio el cual ofrece directamente en el hogar de los pacientes que, debido a restricciones de movilidad o a su estado de salud, tienen dificultades para trasladarse a un centro médico. En Teradomus, estamos orgullosos de proporcionar el servicio de kinesiología a domicilio, servicio crucial y, a continuación, te presentamos las cinco principales razones para elegir la atención domiciliaria con nosotros.
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
              Terapia Personalizada
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              En un centro de salud convencional, un kinesiólogo puede tener a su cargo a un grupo de personas, lo que puede limitar el grado de atención personalizada que se puede proporcionar. Sin embargo, con la atención domiciliaria de Teradomus, el paciente recibe una terapia individualizada y la atención completa del terapeuta. Esto se traduce en un enfoque más centrado en las necesidades y desafíos específicos del paciente, permitiendo una resolución más rápida y eficiente de los problemas cotidianos. Además, este entorno favorece una relación estrecha y única entre el terapeuta y el paciente.            </Typography>
          </Grid>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Aumento de la Participación y el Compromiso del Paciente
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Cuando las actividades de cuidado y rehabilitación se llevan a cabo en el hogar del paciente, se vuelven más significativas. La atención domiciliaria integra las rutinas diarias del paciente en el plan de terapia, lo que aumenta su implicación y compromiso. Además, los pacientes se sienten más cómodos y participativos al usar sus propios objetos y espacios, lo que les resulta más significativo y familiar. Los familiares y cuidadores también pueden participar activamente en la terapia, convirtiéndose en un apoyo adicional en el proceso de rehabilitación.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Libertad en Tiempo y Lugar
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            La atención domiciliaria ofrece la comodidad de evitar los desplazamientos al centro de salud, que pueden ser especialmente desafiantes cuando se enfrentan a ciertas enfermedades. Esto proporciona a los pacientes una mayor flexibilidad y control sobre su horario, permitiéndoles vivir de acuerdo con sus propias rutinas y necesidades, sin tener que ajustarse a horarios impuestos por un centro.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Contexto Terapéutico Seguro
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            La atención domiciliaria se lleva a cabo en un entorno familiar y cómodo para el paciente. Este ambiente proporciona una mayor sensación de seguridad y permite una terapia más significativa, ya que se utilizan las rutinas y objetos cotidianos del paciente. Además, recibir atención en el propio hogar puede aumentar la confianza y autoestima del paciente durante un período en que su salud puede ser frágil.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Tratamiento adaptado a la realidad del paciente
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Cuando el profesional acude a domicilio tiene un mayor nivel de proximidad al contexto del paciente, elemento esencial para analizar y adaptar el entorno inmediato del paciente, ofreciendo soluciones que facilitan su vida diaria. Esto puede incluir la reorganización física del espacio vital para acomodar necesidades específicas, como en el caso de un paciente que sufre una hemiplejia. Este enfoque personalizado y adaptable permite un tratamiento más completo y personalizado del paciente que la que se podría realizar en un centro, permitiéndonos adaptar rápidamente la terapia a cambios inesperados.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Estamos seguros que estas razones son suficientes para que te animes a solicitar el servicio de kinesiología a domicilio que ofrecemos en <Link href="/"
              underline="none"
              color="Blue">Teradomus</Link>, estamos dispuestos a resolver cualquier duda o consulta relacionada con la solicitud de tu tratamiento ¡Nos vemos en Teradomus.com!
          </Typography>
        </Grid >
        <Link href="/blog"
          underline="none"
          color="inherit">
          <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 5 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
            ← Volver al listado de artículos
          </Typography>
        </Link>
      </Container >
    </>
  );
}