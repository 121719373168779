import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Container, Grid } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import MenuCliente from '../../components/MenuCliente/MenuCliente';
import TelWhat from '../../components/TelWhat/TelWhat';
import CuadroSelect2 from '../../components/CuadroSelect/CuadroSelect2';
import { Helmet } from 'react-helmet';


export default function TipoKines() {
  let history = useHistory();

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription = "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/tiposkines" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/tiposkines" />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex"/>
      </Helmet>
      <ScrollToTop />
      <MenuCliente />
      <Box
        sx={{
          height: { lg: '300px', xs: '25vh' },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
      </Box>
      <Box
        sx={{
          width: { lg: '90%', xs: '95%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          mt: { lg: -30, xs: -20 },
          margin: '0 auto', // Añadido para centrar horizontalmente
        }}
      >
        <Container >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid Item xs="12" md="8" lg="8">
              <Typography textAlign={"center"} sx={{
                color: '#2669A8', mt: { xs: 1, lg: 3 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "20px", md: '20px', lg: "26px" }
              }}>
                Tipo de kinesiología
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid container>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Motora"}
                texto={"Trabajaremos en fortalecer y recuperar tu sistema músculo-esquelético para potenciar tu motricidad, coordinación y capacidad de movimiento."}
                tipo={"Kinesiología Motora"} />
            </Grid>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Respiratoria"}
                texto={"Trabajaremos en recuperar el sistema respiratorio, con técnicas que mejoran la ventilación pulmonar."}
                tipo={"Kinesiología Respiratoria"} />
            </Grid>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Neurológica"}
                texto={"Trabajaremos en potenciar la movilidad que ha sido afectada por alteraciones del sistema nervioso."}
                tipo={"Kinesiología Neurologica"} />
            </Grid>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Neurológica Infantil"}
                texto={"Trabajaremos en potenciar la movilidad que ha sido afectada por alteraciones del sistema nervioso."}
                tipo={"Kinesiología Neurologica Infantil"} />
            </Grid>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Respiratoria Infantil"}
                texto={"Trabajaremos en recuperar el sistema respiratorio, con técnicas que mejoran la ventilación pulmonar en niños y niñas."}
                tipo={"Kinesiología Respiratoria Infantil"} />
            </Grid>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Deportiva"}
                texto={"Para deportistas, que buscan recuperarse de lesiones sufridas en el deporte o sus actividades favoritas."}
                tipo={"Kinesiología Deportiva"} />
            </Grid>
            <Grid
              md="4" xs="12" >
              <CuadroSelect2 titulo={"Kinesiología@Adulto mayor"}
                texto={"Orientado en recuperar la movilidad y aliviar las dolencias asociadas al envejecimiento."}
                tipo={"Kinesiología Geriatrica"} />
            </Grid>
          </Grid>
        </Container>
      </Box >
      <Box sx={{ mt: { xs: 5, lg: 8 }, mb: { xs: 5, lg: 10 }, textAlign: "center" }}>
        <TelWhat />
      </Box>
    </>
  );
}