import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles.module.scss";
import classNames from "classnames";


const propTypes = {
  isLoading: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  isLoading: true,
};

function Loader({ isLoading, className }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div className={classNames(styles.Loader, className)}>
      <CircularProgress className={styles.circularProgress}
        style={{ color: "#2596be" }} // Cambia el color aquí
      />
    </div>
  );
}

Loader.propTypes = propTypes;
Loader.defaultProps = defaultProps;
export default Loader;
