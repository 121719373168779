import React, { useEffect } from 'react';
import { useLocation, useHistory } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useMercadopago } from 'react-sdk-mercadopago';


export default function Petition() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [consulta, setConsulta] = React.useState(0);

  const handleChangeConsulta = (event) => {
    setConsulta(event.target.value);

    Preferences()
  };

  React.useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    console.log(queryString)

    if (urlParams.has('payment_id') === true) {
      let payment_id = urlParams.get('payment_id')
    } else {
      return null
    }
  }, []);

  function Preferences() {
    // const preference_id = await callApi(`/pre-payment?tipo=${consulta}`, "get");

    const Mercadopago = useMercadopago.v2('TEST-52f279df-adeb-47e2-adfd-be9ba9ec3268', {
      locale: 'es-CL'
    });

    useEffect(() => {
      if (Mercadopago) {
        Mercadopago.checkout({
          preference: {
            id: "1302887637-2368f3dc-b9fb-4390-aae8-d285ef4b4453"
          },
          render: {
            container: '.cho-container',
            label: 'Pagar con Mercadopago',
          }
        })
      }
    }, [Mercadopago])

    return (
      <div>
        <div class="cho-container" />
      </div>
    )
  }

  return (
    <>
      <FormControl fullWidth>
        <InputLabel id="opcionespago-label">Selecciona tu opción</InputLabel>
        <Select
          labelId="opcionespago-label"
          id="opcionespago"
          label="Opciones"
          onChange={handleChangeConsulta}
        >
          <MenuItem value={1}>Consulta Medica</MenuItem>
          <MenuItem value={2}>10 Sesiones</MenuItem>
        </Select>
      </FormControl>
      {Preferences()}
    </>
  );
}
