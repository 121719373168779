import React, { useState, useEffect, useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import TelWhat from '../../components/TelWhat/TelWhat';
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Helmet } from 'react-helmet';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';


import Planes from '../../components/Adulto/Planes/Planes';
import Planescarru from '../../components/Adulto/Planes/Planescarru';
import Incluye from '../../components/Adulto/Incluye';
import Incluyecarru from '../../components/Adulto/Incluyecarru';
import Saber from '../../components/Adulto/Saber';

import adultomayor from "../../assets/adulto/IMAGEN_HOME_PLANES_ADULTO_MAYOR.png";
import adultomayor2 from "../../assets/adulto/adulto2.jpg";
import wsp from "../../assets/newiconos/was.png"


export default function Adultomayor() {
  const theme = useTheme();

  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const pageTitle = "Teradomus: Planes Especializados para Adultos Mayores";
  const pageDescription = "Descubre nuestros planes personalizados para adultos mayores, diseñados para mejorar su calidad de vida a través del apoyo físico, social y de salud.";


  const [showPlanes, setShowPlanes] = useState(false);
  const planesRef = useRef(null);

  const handleVerPlanes = () => {
    setShowPlanes(!showPlanes);
  };

  useEffect(() => {
    if (showPlanes && planesRef.current) {
      planesRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [showPlanes]);

  useEffect(() => {
    const handleCloseOptions = () => {
      setShowPlanes(false);
    };

    // Detectar eventos de desplazamiento en el documento
    window.addEventListener('scroll', handleCloseOptions);

    return () => {
      window.removeEventListener('scroll', handleCloseOptions);
    };
  }, []);

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/planes-adulto-mayor" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/planes-adulto-mayor" />
        <meta property="og:type" content="article" />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Article",
            "headline": pageTitle,
            "description": pageDescription,
            "url": "https://www.teradomus.com/planes-adulto-mayor",
          })}
        </script>
      </Helmet>
      <Link href="https://api.whatsapp.com/send/?phone=%2B56996906773&text=Hola,%20amigos%20de%20Teradomus.%20Quiero%20agendar%20una%20cita%20con%20un%20profesional%20de%20la%20salud.%20¿Podrían%20ayudarme%20😊?&type=phone_number&app_absent=0" underline="none" color="inherit">
        <Button variant="contained" sx={{
          position: 'fixed',
          width: '70px', // Ajusta el ancho del botón según tus necesidades
          height: '70px',
          bottom: 16,
          right: 16,
          borderRadius: 50,
          bgcolor: "white",
          zIndex: 9999,
          p: isDesktop ? 0.01 : 2, '&:hover': {
            bgcolor: 'white', // Cambiar el color de fondo al pasar el mouse
          },
        }}>
          <img
            src={wsp}
            alt="whatsapp"
            style={{
              height: isDesktop ? '120px' : '80px',
            }}
          />
        </Button>
      </Link>
      <Container>
        <Grid item xs={0.5} lg={0.8} md={1.3}></Grid>
        <Grid item xs={11.5} lg={6.5} md={6.3}>
          <Box sx={{ mb: { xs: 0, md: 0, lg: 5 }, ml: { xs: 0, md: 0, lg: 9.5 } }}>
            <Typography variant='h1' sx={{
              mt: { lg: 7, md: 6, xs: 3 }, fontFamily: 'Quicksand-Bold', fontSize: { lg: "35px", md: "38px", xs: "26px" },
              color: '#1e7aa8', textAlign: isMobile ? 'center' : undefined,
            }}>
              Planes adulto mayor
            </Typography>
            <Typography sx={{ textAlign: isMobile ? 'center' : undefined, fontFamily: 'Quicksand-Regular', fontSize: { lg: "18px", md: "18px", xs: "14px" }, mt: { lg: 2, md: 4, xs: 2 }, mb: isDesktop ? -2 : 3 }}>
              Conoce nuestros servicios para el adulto mayor
            </Typography>
          </Box>
        </Grid>
        <Box sx={{
          borderRadius: 8,
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}>
          <Grid container>
            <Grid item xs={0.5} lg={0.8} md={1.3}></Grid>
            <Grid item xs={11} lg={6.5} md={6.3}>
              <Box sx={{ mb: { xs: 0, md: 0, lg: 4 } }}>
                <Typography sx={{ fontFamily: 'Quicksand-Bold', fontSize: { lg: "22px", md: "18px", xs: "16px" }, color: 'white', mt: { lg: 7, md: 4, xs: 4 }, mb: 3 }}>
                  El envejecimiento puede traer consigo una serie de desafiós. Nuestros planes para el adulto mayor están diseñados para abordarlos, proporcionando apoyo físico, social y de salud para mejorar la calidad de vida de los adultos mayores.
                </Typography>
                <Button
                  sx={{
                    fontFamily: 'Quicksand-Bold',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center', backgroundColor: isDesktop ? '#8753b1' : "white",
                    '&:hover': { backgroundColor: isDesktop ? '#651d87' : "white" }, margin: "auto",
                    borderRadius: 4, mt: 4, paddingTop: 0.5, mb: isDesktop ? 0 : 3,
                    paddingBottom: 0.5, color: isMobile ? '#1e7aa8' : undefined,
                    fontSize: { lg: "20px", md: "18px", xs: "16px" },
                    textTransform: 'none',
                  }}
                  onClick={handleVerPlanes}
                  variant="contained"
                >
                  Ver planes
                </Button>
              </Box>
            </Grid>
            <Grid item lg={4.7} md={3.4}>
              {isDesktop && (
                <Box sx={{
                  mr: { lg: 1 },
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  position: 'relative',
                }}
                >
                  <img src={adultomayor} style={{ width: 'auto', height: '350px', objectFit: 'cover' }}
                    alt="adulto mayor" />
                </Box>
              )}
            </Grid>
            <Grid item xs={0.5} lg={0.5} md={1.3}></Grid>
          </Grid>
        </Box>
      </Container>
      <Container>
        {isDesktop && (
          <Grid container>
            <Grid item lg={0.2} md={2.3}></Grid>
            <Grid item xs={12} lg={5} md={3.4}>
              <Box sx={{
                mt: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'hidden',
                position: 'relative',
              }}
              >
                <img src={adultomayor2} style={{ borderRadius: 10, width: '360px', height: '275px', objectFit: 'cover' }}
                  alt="adulto mayor" />
              </Box>
            </Grid>
            <Grid item xs={12} lg={6.1} md={6}>
              <Box>
                <Typography variant='h1' sx={{
                  color: '#1e7aa8',
                  mt: { lg: 9, md: 3, xs: 3 }, fontFamily: 'Quicksand-Bold',
                  fontSize: { lg: "35px", md: "38px", xs: "26px" },
                }}>
                  Planes adulto mayor
                </Typography>
                <Box sx={{ mt: { lg: 4, md: 3, xs: 2 }, width: "70px", height: "4.5px", bgcolor: '#1e7aa8', borderRadius: 4, }}></Box>
                <Typography sx={{
                  fontFamily: 'Quicksand-Light', fontSize: { lg: "20px", md: "18px", xs: "16px" }, mt: { lg: 4, md: 4, xs: 2 }, mb: 2, textAlign: 'justify'
                }}>
                  Nuestro servicio de adulto mayor ofrece tres planes de estimulación personalizados, todos realizados por técnicos y profesionales de salud con experiencia en geriatría. Estos planes están diseñados en base evidencia científica y pensados para satisfacer las necesidades individuales de cada adulto mayor, ya sea que necesiten apoyo en las dimensiones de su fuerza y movilidad, compañía y bienestar o todas las anteriores.
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
        {isMobile && (
          <Grid container>
            <Box
              sx={{
                borderRadius: 8,
                mt: 4,
                boxShadow: '2px 2px 1px 2px rgba(0, 0, 0, 0.14)', p: 2,
              }}
            >
              <Grid item xs={12} lg={5} md={3.4}>
                <Typography variant='h1' sx={{
                  textAlign: "center",
                  mt: { lg: 5, md: 3, xs: 3 }, fontFamily: 'Quicksand-Bold',
                  fontSize: { lg: "35px", md: "38px", xs: "26px" },
                }}>
                  Planes<span style={{ color: '#1e7aa8' }}> adulto mayor</span>
                </Typography>
                <Box sx={{
                  mt: 3,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  overflow: 'hidden',
                  position: 'relative',
                }}
                >
                  <img src={adultomayor2} style={{ borderRadius: 200, width: '250px', height: '250px', objectFit: 'cover' }}
                    alt="adulto mayor" />
                </Box>
              </Grid>
              <Grid item xs={12} lg={6.1} md={6}>
                <Box>
                  <Typography sx={{
                    fontFamily: 'Quicksand-Regular', fontSize: { lg: "20px", md: "18px", xs: "16px" },
                    mt: 4, mb: 2, textAlign: 'justify'
                  }}>
                    Nuestro servicio de adulto mayor ofrece tres planes de estimulación personalizados, todos realizados por técnicos y profesionales de salud con experiencia en geriatría. Estos planes están diseñados en base evidencia científica y pensados para satisfacer las necesidades individuales de cada adulto mayor, ya sea que necesiten apoyo en las dimensiones de su fuerza y movilidad, compañía y bienestar o todas las anteriores                  </Typography>
                </Box>
              </Grid>
            </Box>
          </Grid>
        )}
      </Container >
      {isDesktop && (
        <Box>
          <Incluye />
        </Box>
      )}
      {isMobile && (
        <Box sx={{ bgcolor: "#cdc0e2", paddingBottom: 3 }}>
          <Typography sx={{
            color: "white", textAlign: 'center', fontFamily: 'Quicksand-Bold', mt: 5, p: 2.5,
            fontSize: "20px", justifyContent: "center", alignItems: "center",
          }}>
            Los Planes incluyen
          </Typography>
          <Incluyecarru />
        </Box>
      )}
      <Container>
        <Grid container justifyContent="center" alignItems="center">
          <Grid item lg={11} md={7.7}>
            <Box ref={planesRef} sx={{ mt: { lg: 2, md: 4, xs: 6 }, textAlign: 'center' }}>
              <Typography
                sx={{
                  color: '#1e7aa8',
                  fontSize: { md: '28px', xs: '24px' },
                  fontFamily: 'Quicksand-Bold',
                }}
              >
                Planes adulto mayor
              </Typography>
            </Box>
            <Typography
              sx={{
                mt: 2, mb: { xs: 1, lg: 3 }, textAlign: 'center',
                fontSize: { md: '24px', xs: '16px' },
                fontFamily: 'Quicksand-Light',
              }}
            >
              Elige el plan que se acomode con tus expectativas y necesidades
            </Typography>
          </Grid>
        </Grid>
        {isDesktop && (
          <Planes />
        )}
        {isMobile && (
          <Planescarru />
        )}
        <Saber />
      </Container >
      <Box sx={{ mt: { xs: 8, lg: 9.5 }, mb: { xs: 1, lg: 2 }, textAlign: "center" }}>
        <TelWhat />
      </Box>
    </>
  );
}