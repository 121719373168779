import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Button, Container, Grid } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";
import MenuCliente from "../../components/MenuCliente/MenuCliente";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import FormGroup from "@mui/material/FormGroup";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApiWithJson from "../../services/callApiWithJson"; // Asegúrate de importar la nueva función
import Loader from "../../components/Loader";

export default function FormInteligente() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const [apiResult, setApiResult] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [telefono, setTelefono] = useState("+56");
  const [edad, setEdad] = React.useState("");
  const textoPredeterminado =
    "✨ Cuéntanos tu diagnostico o por qué necesitas kinesiología, ocuparemos nuestro modelo basado en inteligencia artificial para encontrar al profesional que mejor se adapte a ti.";
  const [text, setText] = useState(textoPredeterminado);
  const [editando, setEditando] = useState(false);
  const [haHechoClicEnTexto, setHaHechoClicEnTexto] = useState(false);
  const telefonoLength = "+56".length + 0; // Longitud del prefijo + longitud del número de teléfono

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription =
    "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  function handleChangeNombre(event) {
    setNombre(event.target.value);
  }
  function handleChangeApellido(event) {
    setApellido(event.target.value);
  }
  const handleChangeDiagnostico = (event) => {
    setText(event.target.value);
  };
  const handleChangeCelular = (event) => {
    const prefix = "+56";
    let inputValue = event.target.value;

    // Asegurarse de que el input comience con '+56'
    if (!inputValue.startsWith(prefix)) {
      inputValue = prefix + inputValue.replace(/\D/g, ""); // Agregar prefijo y eliminar caracteres no numéricos
    } else {
      inputValue = prefix + inputValue.slice(prefix.length).replace(/\D/g, ""); // Mantener prefijo y eliminar caracteres no numéricos del resto
    }

    // Limitar la longitud del número (sin contar el prefijo)
    const maxLength = 9; // Ajustar según sea necesario
    inputValue = inputValue.slice(0, prefix.length + maxLength);

    setTelefono(inputValue);
  };

  function handleChangeEdad(event) {
    const inputValue = event.target.value;
    const allowedCharacters = /^[0-9añoñosmesy\s]+$/i;

    if (inputValue.match(allowedCharacters) || inputValue === "") {
      setEdad(inputValue);
    }
  }

  const handleTextareaClick = () => {
    if (!editando) {
      // Cuando el usuario hace clic en el área de texto por primera vez, elimina el texto predeterminado
      setText("");
      setEditando(true);
      setHaHechoClicEnTexto(true); // Marca que el usuario ha hecho clic en el área de texto
    }
  };

  return (
    <>
      <Loader isLoading={isLoading} />
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/inicio"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/inicio"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <MenuCliente />
      <Box
        sx={{
          height: { lg: "300px", xs: "25vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      ></Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -30, xs: -20 },
          margin: "0 auto", // Añadido para centrar horizontalmente
        }}
      >
        <FormGroup>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid Item xs="12" md="8" lg="8">
                <Box sx={{ pt: 3 }}>
                  <Typography
                    textAlign={"center"}
                    sx={{
                      color: "#2669A8",
                      mt: { xs: 1, lg: 3 },
                      fontFamily: "Quicksand-Bold",
                      fontSize: { xs: "20px", md: "20px", lg: "26px" },
                    }}
                  >
                    Información del Paciente
                  </Typography>
                </Box>
                <Box sx={{ ml: 2, mt: 2 }}>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      Nombre paciente
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      id="name"
                      name="name"
                      onChange={handleChangeNombre}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      Apellido paciente
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      onChange={handleChangeApellido}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      Edad paciente
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      onChange={handleChangeEdad}
                      value={edad}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      Teléfono contacto
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "hidden",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                    }}
                  >
                    <input
                      name="telefono" // Agrega el atributo 'name'
                      value={telefono}
                      onChange={handleChangeCelular}
                      style={{
                        border: "none",
                        width: "100%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                        resize: "none",
                      }}
                    />
                  </Box>
                  <Grid item xs={12} lg={12}>
                    <Typography
                      sx={{
                        pt: 1,
                        fontFamily: "Quicksand-Regular",
                        fontSize: { xs: "16px", md: "20px", lg: "20px" },
                        color: "#4F4F4F",
                      }}
                    >
                      ¿Cuál es el motivo de la consulta?
                    </Typography>
                  </Grid>
                  <Box
                    sx={{
                      borderRadius: 2,
                      overflow: "auto",
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5,
                    }}
                  >
                    <TextareaAutosize
                      onClick={handleTextareaClick}
                      onChange={handleChangeDiagnostico}
                      value={text}
                      style={{
                        border: "none",
                        width: "96%",
                        outline: "none",
                        background: "transparent",
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                        resize: "none",
                        height: "120px",
                      }}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </FormGroup>
        <Container
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { xs: 3, lg: 5 },
            mb: { xs: 3, lg: 5 },
          }}
        >
          <Button
            sx={{
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "14px", md: "16px", lg: "16px" },
              backgroundColor: "#8753b1",
              "&:hover": { backgroundColor: "#651d87" },
              borderRadius: 3,
            }}
            variant="contained"
            disabled={
              nombre === "" ||
              apellido === "" ||
              telefono === "" ||
              edad === 0 ||
              text === ""
            }
            onClick={async () => {
              if (!haHechoClicEnTexto) {
                MySwal.fire("Escríbe tu motivo!", "", "info");
                return;
              }

              const formatoValido = /^[\d\s+]+$/.test(telefono);
              if (!formatoValido) {
                MySwal.fire("Ingresa un número telefónico válido", "", "info");
                return;
              }
              setIsLoading(true);
              setApiError(null);

              try {
                // Enviar la variable 'text' a tu función Lambda
                const result = await callApiWithJson("/enviar-texto", "post", {
                  text,
                  nombre,
                  apellido,
                  edad,
                  telefono,
                });

                // Guardar el resultado en el estado
                setApiResult(result);

                // Continuar con la navegación después de recibir la respuesta de la Lambda
                history.push({
                  pathname:
                    "/kinesiologia-a-domicilio/agenda-inteligente/tipokine",
                  state: {
                    apiResult: result,
                    nombre: nombre,
                    apellido: apellido,
                    edad: edad,
                    telefono: telefono,
                    motivo: text,
                  }, // Enviar el resultado a la página siguiente
                });
              } catch (error) {
                console.error("Error al llamar a la API:", error);
                setApiError("Hubo un error al llamar a la API.");
              }

              setIsLoading(false);
            }}
          >
            Continuar
          </Button>
        </Container>
      </Box>
    </>
  );
}
