import React from "react";
import { Box } from "@material-ui/core";
import { isMobile } from "react-device-detect";

function Exapande() {
  const videoUrl =
    "https://teradomus-storage-public.s3.amazonaws.com/terafelipe.mp4";

  return (
    <Box sx={{ textAlign: "center" }}>
      <video width={isMobile ? "360px" : "1000px"} height="auto" controls>
        <source src={videoUrl} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
}

export default Exapande;
