import React, { useRef } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';

import evaluacion from "../../assets/newiconos/evaluacion.png";
import visitas from "../../assets/newiconos/visitas.png";
import familiares from "../../assets/newiconos/familiares.png";

export default function Incluyea() {

    const planesRef = useRef(null);

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item lg={1} md={2.2}></Grid>
                    <Grid item xs={12} lg={11} md={6.3}>
                        <Box sx={{
                            ml: { lg: 8, md: 4, xs: 2 }, borderRadius: 4,
                            mt: { lg: 10, md: 3, xs: 2 }, width: "70px", height: "4.5px", bgcolor: '#1e7aa8'
                        }}></Box>
                        <Box ref={planesRef} sx={{ mt: { lg: 4, md: 4, xs: 2 }, ml: { lg: 8, md: 4, xs: 2 } }}>
                            <Typography sx={{ fontSize: { lg: "24px", md: "20px", xs: "16px" }, fontFamily: 'Quicksand-Bold' }}>
                                El plan Incluye
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Container >
            <Container>
                <Grid container>
                    <Grid item lg={0.81} md={2.3}></Grid>
                    <Grid item container xs={12} lg={10.4} md={7.7}>
                        <Box
                            sx={{
                                gap: "20px",
                                display: 'flex', borderRadius: 8,
                                justifyContent: 'space-between', mt: 4,
                                mb: { xs: 0, md: 0, lg: 10 },
                                boxShadow: '8px 8px 8px 8px rgba(0, 0, 0, 0.14)', p: 5,
                            }}
                        >
                            <Box sx={{ width: '33%', textAlign: 'left' }}>
                                <img src={evaluacion} style={{ width: '120px', height: '120px', display: 'block', margin: '0 auto' }} alt="evaluacion inicial" />
                                <Typography sx={{ textAlign: 'Left', color: '#1e7aa8', fontSize: { md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
                                    Evaluación inicial:
                                </Typography>
                                <Typography sx={{ fontSize: { md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                                    Comenzamos con un set de pruebas y evaluaciones detalladas que nos permite entender las necesidades y habilidades específicas del adulto mayor.
                                </Typography>
                            </Box>
                            <Box sx={{ width: '33%', textAlign: 'left' }}>
                                <img src={visitas} style={{ width: '120px', height: '120px', display: 'block', margin: '0 auto' }} alt="visitas periodicas" />
                                <Typography sx={{ textAlign: 'Left', color: '#1e7aa8', fontSize: { md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
                                    Visitas periódicas:
                                </Typography>
                                <Typography sx={{ fontSize: { md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                                    realizamos 1 a 2  visitas semanales, con una duración promedio de 1 hora por atención.                                </Typography>
                            </Box>
                            <Box sx={{ width: '33%', textAlign: 'left' }}>
                                <img src={familiares} style={{ width: '120px', height: '120px', display: 'block', margin: '0 auto' }} alt="Seguimiento con los familiares" />
                                <Typography sx={{ textAlign: 'left', color: '#1e7aa8', fontSize: { md: "18px", xs: "14px" }, fontFamily: 'Quicksand-Bold' }}>
                                    Seguimiento con familiares:
                                </Typography>
                                <Typography sx={{ fontSize: { md: "16px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
                                    Instancia mensual con los familiares del adulto mayor, para compartir los progresos y actividades realizadas.
                                </Typography>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
}