import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Grid } from '@mui/material';
import { useHistory } from "react-router-dom";

export default function CuadroSelect(props) {
  let history = useHistory();
  const tituloDividido = props.titulo.split('@');


  function renderButton() {
    return (
      <Button
        sx={{
          fontFamily: 'Quicksand-Bold',
          backgroundColor: '#8753b1',
          '&:hover': { backgroundColor: '#651d87' }, borderRadius: 2.5, marginBottom: 1, paddingTop: 0.5, paddingBottom: 0.5
        }}
        onClick={() => {
          history.push({
            pathname: "/kinesiologia-a-domicilio/agenda/atencion",
            state: { tipo: props.tipo },
          });
        }}
        variant="contained"
      >
        Agendar
      </Button>
    );
  }

  return (
    <Box
      sx={{
        boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
        border: '1px solid rgba(0, 0, 0, 0.04)',
        padding: 1.4,
        borderRadius: 5,
        margin: { xs: 1, md: 2 },
        height: '230px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
      }}
    >
      <Box
        sx={{
          alignItems: 'flex-start'
        }}
      >
        <Typography variant='h2' sx={{ fontSize: { xs: "17px", md: '18px', lg: "20px" }, fontFamily: 'Quicksand-Bold', mt: { xs: 2, md: 1, lg: 1 } }}>
          {tituloDividido[0]} <span style={{ color: '#2669A8' }}>{tituloDividido[1]}</span>
        </Typography>
        <Grid >
          <Typography variant='h3' sx={{lineHeight:1.5, fontSize: { xs: "15px", md: '18px', lg: "17px" }, fontFamily: 'Quicksand-Regular', mt: { xs: 2, md: 2.5, lg: 2 }, color: "#4F4F4F" }}>
            {props.texto}
          </Typography>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center" }}>
        {renderButton()}
      </Box>
    </Box>
  );
}