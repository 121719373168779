import * as React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Container, Grid } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import mas from "../../../assets/icons/mas.png";
import menos from "../../../assets/icons/menos.png";
import TelWhat from '../../../components/TelWhat/TelWhat';
import ScrollToTop from '../../../components/ScrollToTop/ScrollToTop';
import { Helmet } from 'react-helmet';

export function calculatePricePerSession(sessions) {
  if (sessions >= 1 && sessions <= 9) return 25000;
  if (sessions >= 10 && sessions <= 19) return 23000;
  if (sessions >= 20) return 23000;
  return 0; // o cualquier valor predeterminado, en caso de que haya algún error inesperado
}

export default function Form2() {
  let history = useHistory();
  const location = useLocation();
  const [tipo] = React.useState(location.state.tipo);
  const [sessions, setSessions] = React.useState(1);

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription = "Selecciona tu paquete de kinesiología a domicilio, elige tu fecha, registra tus datos y paga online. Fácil, seguro y a tu medida. Agenda tu bienestar hoy.";

  const preciosessions = calculatePricePerSession(sessions)

  function handleChangeSessionsMenos() {
    if (sessions === 1) {
      return null
    }
    setSessions(sessions - 1);
  };

  function handleChangeSessionsMas() {
    if (sessions === 20) {
      return null
    }
    setSessions(sessions + 1);
  };

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
      <Box
        sx={{
          height: { lg: '300px', xs: '15vh' },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
        <Container>
          <Box sx={{ ml: { xs: 0, lg: 14 }, color: "white", p: 3, fontFamily: 'Quicksand-Bold', fontSize: { xs: "16px", lg: "22px" } }}>
            <li>{tipo}</li>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          width: { lg: '60%', xs: '95%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          mt: { lg: -25, xs: -5 },
          margin: '0 auto', // Añadido para centrar horizontalmente
        }}
      >
        <Typography textAlign={"center"} sx={{
          color: '#2669A8', mt: { xs: 3, lg: 5 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "16px", md: '20px', lg: "26px" }
        }}>
          ¿Cuántas sesiones a domicilio necesitas?
        </Typography>
        <Box sx={{
          width: { lg: '45%', xs: '85%' }, margin: '0 auto', // Añadido para centrar horizontalmente
        }}>
          <Typography textAlign={"center"} sx={{
            mt: { xs: 2, lg: 4 }, fontFamily: 'Quicksand-Light', fontSize: { xs: "13px", md: '16px', lg: "18px" }
          }}>
            Solicita una sesión si no tienes orden médica y necesitas que te evaluemos
          </Typography>
        </Box>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Box sx={{
            border: '1px solid rgba(0, 0, 0, 0.2)', height: { xs: "100px", lg: "120px" },
            borderRadius: 3, mt: 4, width: { lg: "280px", xs: "170px" }, display: "flex", justifyContent: "center", alignItems: "center"
          }}>
            <Box
              component="img"
              sx={{
                mr: { xs: 8, lg: 15 },
                borderRadius: 2,
                width: "40px",
                '&:hover': {
                  filter: "brightness(70%)",
                }
              }}
              src={menos}
              onClick={() => { handleChangeSessionsMenos() }}
            />

            <Typography sx={{
              position: "absolute",
              color: '#2669A8', fontFamily: 'Quicksand-Bold', fontSize: { xs: "40px", md: '50px', lg: "60px" }
            }}>
              {sessions}
            </Typography>
            <Box
              component="img"
              sx={{
                ml: { xs: 8, lg: 15 },
                borderRadius: 2,
                width: "40px",
                '&:hover': {
                  filter: "brightness(70%)",
                }
              }}
              src={mas}
              onClick={() => { handleChangeSessionsMas() }}
            />
          </Box>
        </Grid>
        <Container sx={{
          display: 'flex',
          justifyContent: 'center', mt: { xs: 3, lg: 5 }, mb: { xs: 3, lg: 5 }
        }}>
          <Button
            sx={{
              fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", md: '16px', lg: "16px" },
              backgroundColor: '#8753b1',
              '&:hover': { backgroundColor: '#651d87' },
              borderRadius: 3
            }}
            variant="contained" onClick={() => {
              history.push({
                pathname: "/kinesiologia-a-domicilio/agenda/calendario",
                state: { tipo: tipo, sessions: sessions },
              });
            }}
          >
            Continuar
          </Button>
        </Container>
      </Box >
      <Box
        sx={{
          width: { lg: '58%', xs: '85%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto', // Añadido para centrar horizontalmente
          padding: 2, mt: 6

        }}
      >
        <Grid container>
          <Grid item xs={12} lg={9}>
            <Box sx={{
              ml: { xs: 0, md: 2, lg: 2 }
            }}>
              <Typography sx={{
                color: "#4F4F4F", mb: { xs: 1, lg: 1 },
                mt: { xs: 0, lg: 2 }, fontFamily: 'Quicksand-Bold',
                fontSize: { xs: "14px", md: '16px', lg: "20px" }
              }}>
                Así va tu orden:
              </Typography>
              <Typography sx={{ ml: 2, color: "#4F4F4F", fontFamily: 'Quicksand-Regular', fontSize: { xs: "13px", md: '16px', lg: "18px" } }}>
                <li>{tipo} a domicilio</li>
                <li>N° de sesiones: {sessions} </li>
                <li>Total a pagar desde ${(sessions * preciosessions).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} hasta en 12 cuotas de ${(sessions * 25000 / 12).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}, sin intereses</li>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", pt: 9, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}