import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import Loader from "../../../components/Loader";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../../services/callApi";
import TelWhat from "../../../components/TelWhat/TelWhat";
import ScrollToTop from "../../../components/ScrollToTop/ScrollToTop";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import { Helmet } from "react-helmet";
import TuOrden from "../../../components/TelWhat/TuOrden";

export default function Suscribe() {
  const comun = [
    "Antofagasta",
    "Valparaíso",
    "Concón",
    "Viña del Mar",
    "Quilpué",
    "Villa Alemana",
    "Concepción",
    "Chiguayante",
    "Penco",
    "San Pedro de La Paz",
    "Talcahuano",
    "Tomé",
    "Santiago",
    "Cerrillos",
    "Cerro Navia",
    "Conchalí",
    "El Bosque",
    "Estación Central",
    "Huechuraba",
    "Independencia",
    "La Cisterna",
    "La Florida",
    "La Granja",
    "La Pintana",
    "La Reina",
    "Las Condes",
    "Lo Barnechea",
    "Lo Espejo",
    "Lo Prado",
    "Macul",
    "Maipú",
    "Ñuñoa",
    "Pedro Aguirre Cerda",
    "Peñalolén",
    "Providencia",
    "Pudahuel",
    "Quilicura",
    "Quinta Normal",
    "Recoleta",
    "Renca",
    "San Joaquín",
    "San Miguel",
    "San Ramón",
    "Vitacura",
    "Puente Alto",
    "San Bernardo",
    "Hualpén",
    "La Serena",
    "Coquimbo",
  ];

  const comunas = comun.sort((a, b) => a.localeCompare(b));

  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const [identificacion, setIdentificacion] = React.useState("");
  const [nombre, setNombre] = React.useState("");
  const [apellido, setApellido] = React.useState("");
  const [nombreapplicant, setNombreapplicant] = React.useState("");
  const [apellidoapplicant, setApellidoapplicant] = React.useState("");
  const [antecedentes, setAntecedentes] = React.useState("");
  const [diagnostico, setDiagnostico] = React.useState("");
  const [telefono, setTelefono] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const [edad, setEdad] = React.useState(0);
  const [direccion, setDireccion] = React.useState("");
  const [depto, setDepto] = React.useState("");
  const [info, setInfo] = React.useState("");
  const [comuna, setComuna] = React.useState(0);
  const [days] = React.useState(location.state.dates);
  const [hourss] = React.useState(location.state.hourss);
  const [tipo] = React.useState(location.state.tipo);
  const [sessions] = React.useState(location.state.sessions);

  const ranges = [
    { label: "0 a 6 años", value: "0-6" },
    { label: "7 a 17 años", value: "7-17" },
    { label: "18 a 39 años", value: "18-39" },
    { label: "40 a 59 años", value: "40-59" },
    { label: "60 a 79 años", value: "60-79" },
    { label: "80 o más años", value: "80+" },
  ];

  function handleChangeNombre(event) {
    setNombre(event.target.value);
  }

  function handleChangeApellido(event) {
    setApellido(event.target.value);
  }

  function handleChangeNombreapplicant(event) {
    setNombreapplicant(event.target.value);
  }

  function handleChangeApellidoapplicant(event) {
    setApellidoapplicant(event.target.value);
  }
  function handleChangeAntecedentes(event) {
    setAntecedentes(event.target.value);
  }

  function handleChangeDiagnostico(event) {
    setDiagnostico(event.target.value);
  }

  function handleChangeIdentificacion(event) {
    setIdentificacion(event.target.value);
  }

  function handleChangeCelular(event) {
    setTelefono(event.target.value);
  }

  function handleChangeCorreo(event) {
    setCorreo(event.target.value);
  }

  function handleChangeEdad(event) {
    setEdad(event.target.value);
  }

  function handleChangeDireccion(event) {
    setDireccion(event.target.value);
  }

  function handleChangeCity(event, newValue) {
    setComuna(newValue);
  }

  function handleChangeDepto(event) {
    setDepto(event.target.value);
  }

  function handleChangeInfo(event) {
    setInfo(event.target.value);
  }

  async function handleCompleteTreatmentPayment() {
    if (
      nombre === "" ||
      apellido === "" ||
      identificacion === "" ||
      telefono === "" ||
      edad === 0 ||
      direccion === ""
    ) {
      MySwal.fire("Llena los campos obligatorios!", "", "info");
      return;
    }

    const formatoValido = /^[\d\s+]+$/.test(telefono);
    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return;
    }

    const all_data = {
      identificacion: identificacion,
      nombre: nombre,
      apellido: apellido,
      nombreapplicant: nombreapplicant,
      apellidoapplicant: apellidoapplicant,
      antecedentes: antecedentes,
      diagnostico: diagnostico,
      correo: correo,
      celular: telefono,
      edad: edad,
      direccion: direccion,
      comuna: comuna,
      depto: depto,
      info: info,
      dia: location.state.p_date,
      hora: hourss,
      especialidad: location.state.tipo,
      cantidad_sesiones: location.state.sessions,
      derivador: "teradomus",
    };

    setIsLoading(true);
    const result = await callApi("/receive-solicitud", "post", all_data);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      history.push({
        pathname: "/kinesiologia-a-domicilio/agenda/done",
        state: {
          tipo: location.state.tipo,
          sessions: location.state.sessions,
          dia: days,
          hora: hourss,
          pref: result.body.preference,
          price: result.body.price,
          habil: result.body.habil,
        },
      });
    } else {
      console.log(result);
      MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
    }
  }
  async function handleFirstSessionPayment() {
    if (
      nombre === "" ||
      apellido === "" ||
      identificacion === "" ||
      telefono === "" ||
      edad === 0 ||
      direccion === ""
    ) {
      MySwal.fire("Llena los campos obligatorios!", "", "info");
      return;
    }

    const formatoValido = /^[\d\s+]+$/.test(telefono);
    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return;
    }

    const all_data = {
      identificacion: identificacion,
      nombre: nombre,
      apellido: apellido,
      antecedentes: antecedentes,
      diagnostico: diagnostico,
      correo: correo,
      celular: telefono,
      edad: edad,
      direccion: direccion,
      comuna: comuna,
      depto: depto,
      info: info,
      dia: location.state.p_date,
      hora: hourss,
      especialidad: location.state.tipo,
      cantidad_sesiones: 1,
      derivador: "teradomus",
    };

    setIsLoading(true);
    const result = await callApi("/receive-solicitud", "post", all_data);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      history.push({
        pathname: "/kinesiologia-a-domicilio/agenda/done",
        state: {
          tipo: location.state.tipo,
          sessions: 1,
          dia: days,
          hora: hourss,
          pref: result.body.preference,
          price: result.body.price,
          habil: result.body.habil,
        },
      });
    } else {
      MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
    }
  }

  return (
    <>
      <ScrollToTop />
      <Loader isLoading={isLoading} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>Teradomus: Agenda kinesiología a domicilio</title>
        <meta
          name="description"
          content="Teradomus: Especialistas en kinisiología a domicilio y planes personalizados para el adulto mayor. Eleva tu bienestar y calidad de vida con nosotros."
        />
      </Helmet>
      <Box
        sx={{
          height: { lg: "300px", xs: "15vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
        <Container>
          <Box
            sx={{
              ml: { xs: 0, lg: 14 },
              color: "white",
              p: 3,
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "16px", lg: "22px" },
            }}
          >
            <li>{location.state.tipo}</li>
          </Box>
        </Container>
      </Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: { xs: 5, lg: 15 },
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -25, xs: -5 },
          margin: "0 auto",
        }}
      >
        <FormGroup>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid Item xs="12" md="8" lg="8">
              <Box sx={{ pt: 3 }}>
                <Typography
                  textAlign={"center"}
                  sx={{
                    color: "#2669A8",
                    mt: { xs: 1, lg: 3 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "20px", md: "20px", lg: "26px" },
                  }}
                >
                  Datos del Paciente
                </Typography>
              </Box>
              <Typography
                sx={{
                  ml: { xs: 1 },
                  color: "#2669A8",
                  mt: { xs: 2, lg: 5 },
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "17px", md: "20px", lg: "22px" },
                }}
              >
                <li>Identificación del paciente</li>
              </Typography>
              <Box sx={{ ml: 3 }}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 3,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "18px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    RUT*
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      id="rutInput"
                      name="rut"
                      onChange={handleChangeIdentificacion}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Nombres*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="name"
                    name="name"
                    onChange={handleChangeNombre}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Apellidos*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeApellido}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 2,
                      fontFamily: "Quicksand-Regular",
                      pb: 1,
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Rango etario*
                  </Typography>
                </Grid>
                <FormControl
                  sx={{ width: { xs: "60%", lg: "30%" } }}
                  size="small"
                >
                  <Select
                    id="outlined-age-range"
                    value={edad}
                    onChange={handleChangeEdad}
                    sx={{ color: "#4F4F4F", fontFamily: "Quicksand-Regular" }}
                  >
                    {ranges.map((range) => (
                      <MenuItem
                        key={range.value}
                        value={range.value}
                        sx={{
                          fontFamily: "Quicksand-Regular",
                          color: "#4F4F4F",
                        }}
                      >
                        {range.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <Box sx={{ ml: 3 }}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Diagnostico o motivo de agenda
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "auto",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    height: "50px",
                  }}
                >
                  <TextareaAutosize
                    onChange={handleChangeDiagnostico}
                    style={{
                      border: "none",
                      width: "96%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none", // Desactiva la opción de redimensionar manualmente el área de texto
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Antecedentes relevantes
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "auto",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    height: "50px",
                  }}
                >
                  <TextareaAutosize
                    onChange={handleChangeAntecedentes}
                    style={{
                      border: "none",
                      width: "96%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      resize: "none",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  ml: { xs: 1 },
                  color: "#2669A8",
                  mt: { xs: 2, lg: 5 },
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "17px", md: "20px", lg: "22px" },
                }}
              >
                <li>Identificación del solicitante</li>
              </Typography>
              <Box sx={{ ml: 3, mt: 1 }}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Nombres*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeNombreapplicant}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Apellidos*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeApellidoapplicant}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 2,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Teléfono*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeCelular}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Correo
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="email"
                    name="email"
                    onChange={handleChangeCorreo}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              </Box>
              <Typography
                sx={{
                  ml: { xs: 1 },
                  color: "#2669A8",
                  mt: { xs: 2, lg: 5 },
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "17px", md: "20px", lg: "22px" },
                }}
              >
                <li>Dirección del paciente</li>
              </Typography>
              <Box sx={{ ml: 3 }}>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 4,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Dirección*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="direccion"
                    name="direccion"
                    onChange={handleChangeDireccion}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Comuna*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    paddingLeft: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <Autocomplete
                    value={comuna}
                    onChange={handleChangeCity}
                    options={comunas}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                          style: {
                            border: "none",
                            outline: "none",
                            background: "transparent",
                            fontFamily: "Quicksand-Regular",
                            color: "#4F4F4F",
                            fontSize: "16px",
                          },
                        }}
                      />
                    )}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Departamento u otro
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeDepto}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Comentarios
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeInfo}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Typography
                  sx={{
                    mb: { xs: 3, lg: 4 },
                    pt: 4,
                    fontFamily: "Quicksand-Regular",
                    fontSize: { xs: "16px", md: "20px", lg: "20px" },
                    color: "#4F4F4F",
                  }}
                >
                  * Obligatorio
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </FormGroup>
      </Box>
      <Box
        sx={{
          width: { lg: "58%", xs: "85%" },
          backgroundColor: "white",
          borderRadius: 5,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto",
          padding: 2,
          mt: 6,
        }}
      >
        <Grid container>
          <TuOrden tipo={tipo} sessions={sessions} />
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              mt: { xs: 2 },
              p: 1,
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                lineHeight: { xs: 1.2, md: 1.1, lg: 0.8 }, // Ajusta este valor según tus necesidades
                mt: { xs: 1, lg: 0.5 },
                fontFamily: "Quicksand-Light",
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
              }}
            >
              Primera sesión:{" "}
              <span style={{ color: "#8546b1", fontFamily: "Quicksand-Bold" }}>
                <p></p>
                {location.state.dates} a las {location.state.hourss} horas
              </span>
            </Typography>
            <Typography
              sx={{
                mt: { xs: 1.5, lg: 1.5 },
                fontFamily: "Quicksand-Light",
                fontSize: { xs: "13px", md: "16px", lg: "16px" },
              }}
            >
              Las siguientes sesiones las podrás coordinar con el profesional en
              tu primera visita.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          gap: { xs: "15px", lg: "30px" },
          justifyContent: "center",
          mt: { xs: 7, lg: 8 },
          mb: { lg: 1 },
        }}
      >
        {" "}
        <Button
          sx={{
            fontFamily: "Quicksand-Bold",
            textTransform: "none",
            width: { xs: "160px", lg: "210px" },
            fontSize: { xs: "14px", md: "16px", lg: "18px" },
            borderRadius: 3,
            pl: { xs: 1, lg: 2 },
            pr: { xs: 1, lg: 2 },
          }}
          onClick={handleCompleteTreatmentPayment}
          variant="contained"
        >
          Pagar Tratamiento <br></br> Completo
        </Button>
        <Button
          sx={{
            fontFamily: "Quicksand-Bold",
            textTransform: "none",
            width: { xs: "160px", lg: "210px" },
            fontSize: { xs: "14px", md: "16px", lg: "18px" },
            borderRadius: 3,
            pl: { xs: 1, lg: 2 },
            pr: { xs: 1, lg: 2 },
          }}
          onClick={handleFirstSessionPayment}
          variant="contained"
        >
          Pagar la Primera <br></br> Sesión
        </Button>
      </Box>
      <Box sx={{ textAlign: "center", pt: 7, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}
