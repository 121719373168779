import axios from "axios";

async function apiCall(url, verb, payload) {
  const config = {
    headers: {
      Accept: `application/json`,
    },
  };

  const { REACT_APP_BASE_URL_PROD, REACT_APP_STAGE_API } = process.env;

  let apiUrl = REACT_APP_BASE_URL_PROD.concat(REACT_APP_STAGE_API);
  apiUrl = apiUrl.concat(url);

  function call() {
    switch (verb) {
      case "get":
        return axios.get(apiUrl, config);
      case "post":
        return axios.post(apiUrl, payload, config);
      default:
        return axios.get(apiUrl, config);
    }
  }

  try {
    const resp = await call();
    const { data } = resp;
    return data;
  } catch (error) {
    return error;
  }
}

export default apiCall;
