import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import { Button, Grid } from "@mui/material";
import { useLocation, useHistory } from "react-router-dom";
import { Container } from "@material-ui/core";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Box from "@mui/material/Box";
import HourSelector2 from "../../components/Hora/Hora1";
import { useEffect } from "react";
import TelWhat from "../../components/TelWhat/TelWhat";
import { Helmet } from "react-helmet";

registerLocale("es", es);

export default function Calendario() {
  let history = useHistory();
  const location = useLocation();
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedValue, setSelectedValue] = useState(null);
  const {
    respuesta3,
    respuesta5,
    sessions,
    preciosessions,
    comuna,
    consulta,
    respuesta6,
    nombre,
    apellido,
    edad,
    telefono,
    tipo,
    direccion,
    motivo,
  } = location.state;
  const [textoVisible, setTextoVisible] = useState("");

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription =
    "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  let newText = tipo;
  let updatedTextseciones = replaceBoldContent(respuesta3, newText);

  function replaceBoldContent(originalText, replacementText) {
    return originalText.replace(/\*\*(.*?)\*\*/, `**${replacementText}**`);
  }

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = updatedTextseciones; // <--- Asignar respuesta1 a textoAMostrar

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Velocidad de escritura (ajusta según tus preferencias)

    return () => {
      clearInterval(interval);
    };
  }, [updatedTextseciones]);

  const handleSelectHour = (hour) => {
    setSelectedValue(hour);
  };

  useEffect(() => {
    const handleStorageChange = () => {
      setSelectedDate(localStorage.getItem("selectedDate"));
    };

    const checkStorageChanges = setInterval(handleStorageChange, 500);

    return () => {
      clearInterval(checkStorageChanges);
    };
  }, []);

  function renderButtomAfter() {
    if (selectedValue == null || localStorage.getItem("selectedDate") == null) {
      return (
        <Button
          disabled
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", lg: "16px" },
            backgroundColor: "#8753b1",
            "&:hover": { backgroundColor: "#651d87" },
            borderRadius: 3,
          }}
          onClick={() => {
            history.push({
              pathname: "/atencion",
            });
          }}
          variant="contained"
        >
          Continuar
        </Button>
      );
    } else {
      return (
        <Button
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: { xs: "14px", md: "16px", lg: "16px" },
            backgroundColor: "#8753b1",
            "&:hover": { backgroundColor: "#651d87" },
            borderRadius: 3,
          }}
          onClick={() => {
            history.push({
              pathname:
                "/kinesiologia-a-domicilio/agenda-inteligente/solicitar",
              state: {
                dates: selectedDate,
                hourss: selectedValue,
                p_date: localStorage.getItem("p_date"),
                respuesta5: respuesta5,
                respuesta6: respuesta6,
                comuna: comuna,
                direccion: direccion,
                sessions: sessions,
                preciosessions: preciosessions,
                consulta: consulta,
                motivo: motivo,
                nombre: nombre,
                apellido: apellido,
                edad: edad,
                telefono: telefono,
                tipo: tipo,
              },
            });
          }}
          variant="contained"
        >
          Continuar
        </Button>
      );
    }
  }

  function parseTextWithBold(text) {
    const splitText = text.split("**");
    return splitText.map((part, index) => {
      if (index % 2 === 1) {
        // Si es un índice impar, es una variable y debe ir en negrita
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  }

  return (
    <>
      <ScrollToTop />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/calendario"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/calendario"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box
        sx={{
          height: { lg: "300px", xs: "25vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      ></Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "96%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -30, xs: -20 },
          margin: "0 auto", // Añadido para centrar horizontalmente
        }}
      >
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid Item xs="12" md="10" lg="10">
              <Typography
                textAlign={"center"}
                sx={{
                  color: "#2669A8",
                  mt: { xs: 1, lg: 3 },
                  fontFamily: "Quicksand-Bold",
                  fontSize: { xs: "20px", md: "20px", lg: "26px" },
                }}
              >
                Escoge fecha y hora de tu primera sesión
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  mb: { xs: 2, lg: 2 },
                  mt: { xs: 1, lg: 3 },
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "16px", md: "20px", lg: "18px" },
                }}
              >
                {parseTextWithBold(textoVisible)}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Container>
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs="12" lg="7.1">
              <Box sx={{ backgroundColor: "#f3f6fa", borderRadius: 5, mt: 2 }}>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 43,
                  }}
                >
                  <date-picker />
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    pb: 2,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: { xs: "10px", lg: "16px" },
                  }}
                >
                  <Box
                    textAlign="center"
                    sx={{
                      display: "inline-block",
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#b0dcf0",
                    }}
                  ></Box>
                  <Typography
                    textAlign="center"
                    sx={{
                      mr: { xs: 1, lg: 2 },
                      fontFamily: "Quicksand-Bold",
                      fontSize: { xs: "12px", md: "16px", lg: "16px" },
                    }}
                  >
                    Disponible
                  </Typography>
                  <Box
                    textAlign="center"
                    sx={{
                      display: "inline-block",
                      width: "15px",
                      height: "15px",
                      backgroundColor: "#1e7aa8",
                    }}
                  ></Box>
                  <Typography
                    textAlign="center"
                    sx={{
                      mr: { xs: 1, lg: 2 },
                      fontFamily: "Quicksand-Bold",
                      fontSize: { xs: "12px", md: "16px", lg: "16px" },
                    }}
                  >
                    Seleccionado
                  </Typography>
                </Box>
              </Box>
              <Typography
                textAlign="left"
                sx={{
                  mt: { xs: 2, lg: 2 },
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "13px", md: "16px", lg: "16px" },
                }}
              >
                Primera sesión:{" "}
                <span
                  style={{ color: "#8546b1", fontFamily: "Quicksand-Bold" }}
                >
                  {selectedDate}
                </span>
              </Typography>
              <Typography
                textAlign="left"
                sx={{
                  mt: { xs: 1, lg: 1 },
                  fontFamily: "Quicksand-Light",
                  fontSize: { xs: "13px", md: "16px", lg: "16px" },
                }}
              >
                Selecciona la hora de preferencia para la sesión
              </Typography>
            </Grid>
            <Grid item xs={12} lg={7.1}>
              <Box
                sx={{
                  mt: 2,
                }}
              >
                <HourSelector2 onSelectHour2={handleSelectHour} />
              </Box>
            </Grid>
          </Grid>
        </Container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mt: { xs: 3, lg: 5 },
            mb: { xs: 3, lg: 5 },
          }}
        >
          {renderButtomAfter()}
        </Box>
      </Box>
      <Box
        sx={{
          width: { lg: "58%", xs: "85%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto", // Añadido para centrar horizontalmente
          padding: 2,
          mt: 6,
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                ml: { xs: 0, md: 2, lg: 2 },
              }}
            >
              <Typography
                sx={{
                  color: "#4F4F4F",
                  mb: { xs: 1, lg: 1 },
                  mt: { xs: 0, lg: 2 },
                  fontFamily: "Quicksand-Bold",
                  fontSize: { xs: "14px", md: "16px", lg: "20px" },
                }}
              >
                Así va tu orden:
              </Typography>
              <Typography
                sx={{
                  ml: 2,
                  color: "#4F4F4F",
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "13px", md: "16px", lg: "18px" },
                }}
              >
                <li>
                  {tipo} {consulta}
                </li>
                <li>
                  N° de sesiones: {sessions}, desde $
                  {preciosessions
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  por sesión{" "}
                </li>
                <li>
                  Total a pagar desde $
                  {(sessions * preciosessions)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  hasta en 3 cuotas de $
                  {((sessions * 25000) / 3)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                  , sin intereses
                </li>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              mt: { xs: 2 },
              p: 1,
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                lineHeight: { xs: 1.2, md: 1.1, lg: 1 }, // Ajusta este valor según tus necesidades
                mt: { xs: 1, lg: 0.5 },
                fontFamily: "Quicksand-Bold",
                color: "#4F4F4F",
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
              }}
            >
              Primera sesión:{" "}
              <span style={{ color: "#8546b1", fontFamily: "Quicksand-Bold" }}>
                <p></p>
                {selectedDate} a las {selectedValue} horas
              </span>
            </Typography>
            <Typography
              sx={{
                mt: { xs: 1.5, lg: 1.5 },
                fontFamily: "Quicksand-Light",
                fontSize: { xs: "13px", md: "16px", lg: "16px" },
                lineHeight: { xs: 1.2, md: 1.1, lg: 1 },
              }}
            >
              Las siguientes sesiones las podrás coordinar con el profesional en
              tu primera visita.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", pt: 9, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}
