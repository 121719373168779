import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Grid } from "@mui/material";

const Pasos = () => {
  return (
    <Box
      sx={{
        margin: '0 auto',
        maxWidth: { xs: '100%', md: "100%" },
        backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        color: 'white',
        borderRadius: 3,
        paddingTop: "20px",
        paddingBottom: "1px",
      }}
    >
      <Typography sx={{ fontSize: { md: "26px", xs: "22px" }, textAlign: 'center', fontFamily: 'Quicksand-Bold' }}>
        Nuestro servicio en 3 Pasos
      </Typography>
      <Grid container>
        <Grid item xs={12} md={4}>
          <Box sx={{ mb: { md: -3, xs: -5 }, mt: { md: -4, xs: -3 }, display: 'flex', flexDirection: 'row', }}>
            <Typography sx={{ fontFamily: 'Quicksand-Bold', marginRight: 1, ml: 6, fontSize: { md: "140px", xs: "130px" } }}>1</Typography>
            <Typography sx={{ mr: { md: 1, xs: 2 }, ml: 1, fontFamily: 'Quicksand-Light', mt: 6, fontSize: { md: "16px", xs: "16px" } }}>
              <b>Agenda tu tratamiento</b>
              <br />
              Cuéntanos tu disponibilidad y síntomas. Asignaremos al kinesiólogo ideal para tu recuperación             </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ mb: { md: -3, xs: -5 }, mt: { md: -4, xs: 0 }, display: 'flex', flexDirection: 'row', }}>
            <Typography sx={{ fontFamily: 'Quicksand-Bold', marginRight: 1, ml: 4, fontSize: { md: "140px", xs: "130px" } }}>2</Typography>
            <Typography sx={{ mr: { md: 1, xs: 2 }, fontFamily: 'Quicksand-Light', mt: 6, fontSize: { md: "16px", xs: "16px" } }}>
              <b>Llegamos a tu hogar</b>
              <br />
              Tu kinesiólogo evaluara e iniciara el tratamiento desde su primera visita.
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box sx={{ mb: { md: -3 }, mt: { md: -4, xs: 0 }, mr: { md: 4, xs: 0 }, display: 'flex', flexDirection: 'row', }}>
            <Typography sx={{ fontFamily: 'Quicksand-Bold', marginRight: { md: 2, xs: 2 }, ml: { md: 0, xs: 4 }, fontSize: { md: "140px", xs: "130px" } }}>3</Typography>
            <Typography sx={{ mr: { md: 1, xs: 2 }, fontFamily: 'Quicksand-Light', mt: 6, fontSize: { md: "16px", xs: "16px" } }}>
              <b>Seguimos tu tratamiento​</b>
              <br />
              Continuamos con tu recuperación en sesiones de 45 minutos, las veces que lo necesites.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Pasos;
