import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, Container, Typography } from '@material-ui/core';

import evaluacion from "../../assets/newiconos/evaluacion.png";
import visitas from "../../assets/newiconos/visitas.png";
import familiares from "../../assets/newiconos/familiares.png";

const LOCAL_IMAGES = [evaluacion, visitas, familiares];

const SLIDE_SETTINGS = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 7000,
};

const CarouselMove = () => {
    const [images, setImages] = useState([...LOCAL_IMAGES]);

    const handleAfterChange = (slideIndex) => {
        if (slideIndex === images.length - 2) {
            setImages((prevImages) => [...prevImages, ...LOCAL_IMAGES]);
        }
    };

    const renderImage = (image, index, altText, title, description) => (
        <Container key={index}>
            <Box
                sx={{
                    width: 'auto',
                    height: '190px',
                    textAlign: 'center',
                    bgcolor: 'white',
                    borderRadius: '13px',
                    boxShadow: '2px 2px 2px 2px rgba(0, 0, 0, 0.16)',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center', mb: 1,
                }}
            >
                <img src={image} alt={altText} style={{ width: '70px', height: '70px', marginTop: '3px' }} />
                <Typography style={{ fontSize: '17px', fontFamily: 'Quicksand-Bold', color: '#1e7aa8' }}>
                    <b>{title}</b>
                </Typography>
                <Typography style={{
                    marginTop: 7, textAlign: "left", fontSize: '15px', textAlign: "justify",
                    fontFamily: 'Quicksand-Regular', lineHeight: 1.3, paddingLeft: 10, paddingRight: 10,
                }}>
                    {description}
                </Typography>
            </Box>
        </Container>
    );

    const renderImages = () => {
        return images.map((image, index) => {
            if (index === 0) {
                return renderImage(evaluacion, index, 'chequeo', 'Evaluación inicial', 'Comenzamos con un set de pruebas y evaluaciones detalladas que nos permite entender las necesidades y habilidades específicas del adulto mayor.');
            } else if (index === 2) {
                return renderImage(visitas, index, 'Kine a domicilio', 'Visitas periódicas', 'Realizamos 1 a 2 visitas semananel. Con una duración promedio de 3 horas en cada atención.');
            } else if (index === 3) {
                return renderImage(familiares, index, 'chequeo familiares', 'Seguimiento con los familiares', 'Instancia mensual con los familiares del adulto mayor, para compartir los progresos y actividades realizadas.');
            }
        });
    };

    return (
        <Slider {...SLIDE_SETTINGS} afterChange={handleAfterChange}>
            {renderImages()}
        </Slider>
    );
};

export default CarouselMove;
