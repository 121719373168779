import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export function calculatePricePerSession(sessions) {
  if (sessions >= 1 && sessions <= 9) return 25000;
  if (sessions >= 10 && sessions <= 19) return 23000;
  if (sessions >= 20) return 23000;
  return 0; // o cualquier valor predeterminado, en caso de que haya algún error inesperado
}

const TuOrden = ({ tipo, sessions }) => { // Destructuring para acceder a las props directamente

  const pricePerSession = calculatePricePerSession(sessions);
  const totalPrice = sessions * pricePerSession;

  return (
    <Grid item xs={12} lg={7}>
      <Box sx={{
        ml: { xs: 0, md: 2, lg: 2 }
      }}>
        <Typography sx={{
          color: "#4F4F4F", mb: { xs: 1, lg: 1 },
          mt: { xs: 0, lg: 2 }, fontFamily: 'Quicksand-Bold',
          fontSize: { xs: "14px", md: '16px', lg: "20px" }
        }}>
          Así va tu orden:
        </Typography>
        <Typography sx={{ ml: 2, color: "#4F4F4F", fontFamily: 'Quicksand-Regular', fontSize: { xs: "13px", md: '16px', lg: "18px" } }}>
          <li>{tipo} a domicilio</li>
          <li>N° de sesiones: {sessions} </li>
          <li>Total a pagar desde ${totalPrice.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} hasta en 12 cuotas de ${(totalPrice / 12).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}, sin intereses</li>
        </Typography>
      </Box>
    </Grid>
  );
};

export default TuOrden;
