import React from "react";
import { Box, Typography, Card, CardContent, Rating } from "@mui/material";
import { Star } from "@material-ui/icons";

const EstrellasPuntuacion = ({ puntuacion, centered = false }) => {
  return (
    <Box display="flex" justifyContent={centered ? "center" : "flex-start"}>
      <Rating
        value={puntuacion}
        readOnly
        precision={0.1}
        emptyIcon={<Star style={{ opacity: 0.55 }} fontSize="inherit" />}
      />
    </Box>
  );
};

const EvaluacionesSection = ({ evaluaciones }) => {
  if (!evaluaciones || !evaluaciones.comentarios) return null;

  return (
    <Box id="evaluaciones-section" sx={{ mt: 4 }}>
      <Typography
        sx={{
          fontFamily: "Quicksand-Bold",
          fontSize: { md: "26px", xs: "18px" },
          mb: 3,
        }}
      >
        Evaluaciones
      </Typography>

      {/* Promedio general - Nuevo diseño */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
          mb: 3,
        }}
      >
        <Typography
          sx={{
            fontFamily: "Quicksand-Bold",
            fontSize: "48px",
            lineHeight: 1,
            color: "#000000",
          }}
        >
          {parseFloat(evaluaciones.promedio).toFixed(1)}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
          <EstrellasPuntuacion
            puntuacion={parseFloat(evaluaciones.promedio)}
            centered={false}
          />
          <Typography
            sx={{
              fontFamily: "Quicksand-Regular",
              fontSize: "14px",
              color: "#666666",
            }}
          >
            Basado en {evaluaciones.cantidad} evaluaciones
          </Typography>
        </Box>
      </Box>

      {/* Lista de comentarios */}
      {evaluaciones.comentarios.map((comentario, index) => (
        <Card
          key={index}
          sx={{
            mb: 2,
            borderRadius: "16px",
          }}
        >
          <CardContent>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 1,
              }}
            >
              <EstrellasPuntuacion puntuacion={parseFloat(comentario.nota)} />
              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  fontSize: "14px",
                  color: "text.secondary",
                }}
              >
                {comentario.tiempo_relativo}
              </Typography>
            </Box>
            {comentario.comentario && (
              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  fontSize: "14px",
                  mt: 1,
                }}
              >
                {comentario.comentario}
              </Typography>
            )}
            <Typography
              sx={{
                fontFamily: "Quicksand-Regular",
                fontSize: "14px",
                color: "text.secondary",
                mt: 1,
              }}
            >
              {comentario.nombre}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default EvaluacionesSection;
