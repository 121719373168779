import * as React from "react";
import { useHistory } from "react-router-dom";
import { Container } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import logo from "../../assets/images/IMAGEN - Prestador.png";
import Loader from "../../components/Loader";
import callApi from "../../services/callApi";
import { Helmet } from "react-helmet";

function Home() {
  let history = useHistory();
  const MySwal = withReactContent(Swal);
  const [Name, setName] = React.useState("");
  const [Apellido, setApellido] = React.useState("");
  const [Email, setEmail] = React.useState("");
  const [Celular, setCelular] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const pageTitle =
    "Trabaja con Teradomus: Inscripción para Kinesiólogos Profesionales";
  const pageDescription =
    "Regístrate en Teradomus y ofrece tus servicios de kinesiología a domicilio. Flexibilidad, bajas comisiones y acceso a una amplia red de pacientes.";

  async function handleSubmit(event) {
    event.preventDefault();

    if (Name === "" || Apellido === "") {
      MySwal.fire("Debes ingresar un nombre y apellido", "", "info");

      return null;
    }
    const formatoValido = /^[\d\s+]+$/.test(Celular);
    const correoValido = /^\S+@\S+\.\S+$/.test(Email);
    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return null;
    }

    if (!correoValido) {
      MySwal.fire("Ingresa un correo electrónico válido", "", "info");
      return null;
    }

    const all_data = {
      nombre: Name,
      apellido: Apellido,
      correo: Email,
      celular: Celular,
    };
    console.log(all_data);

    setIsLoading(true);
    const result = await callApi("/pre-register", "post", all_data);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      all_data["pre_id"] = result?.body;
      history.push({
        pathname: "/suscribe",
        state: { detail: all_data },
      });
    } else {
      console.log(result);
    }
  }

  function handleChangeName(event) {
    setName(event.target.value);
  }

  function handleChangeApellido(event) {
    setApellido(event.target.value);
  }

  function handleChangeEmail(event) {
    setEmail(event.target.value);
  }

  function handleChangeCelular(event) {
    setCelular(event.target.value);
  }
  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/inscripcion" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/inscripcion"
        />
      </Helmet>
      <Loader isLoading={isLoading} />
      <Container>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={7}>
            <Typography variant="h6" sx={{ fontSize: "20", pl: 5 }}>
              Realiza tus atenciones Kinesiológicas a domicilio <br></br> con{" "}
              <b>Teradomus</b>, regístrate hoy.
            </Typography>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                backgroundColor: "#dbe1e1",
                borderRadius: 6,
                mr: 5,
              }}
            >
              <form onSubmit={handleSubmit}>
                <Box sx={{ m: 5 }}>
                  <Box component="form" display="flex" sx={{ mb: 3, pt: 6 }}>
                    <TextField
                      sx={{ mr: 2, backgroundColor: "white" }}
                      id="outlined-name"
                      label="Nombre"
                      variant="outlined"
                      size="small"
                      onChange={handleChangeName}
                    />
                    <TextField
                      sx={{ ml: 2, backgroundColor: "white" }}
                      id="outlined-apellido"
                      label="Apellido"
                      variant="outlined"
                      size="small"
                      onChange={handleChangeApellido}
                    />
                  </Box>
                  <TextField
                    sx={{ backgroundColor: "white", width: "100%" }}
                    id="outlined-basic"
                    label="Correo"
                    variant="outlined"
                    size="small"
                    onChange={handleChangeEmail}
                    required
                  />
                  <TextField
                    sx={{ mt: 3, backgroundColor: "white", width: "100%" }}
                    id="outlined-num"
                    label="+56 - Celular"
                    variant="outlined"
                    size="small"
                    onChange={handleChangeCelular}
                    required
                  />
                  <Box textAlign="center">
                    <Button
                      sx={{
                        mt: 4,
                        mb: 4,
                        maxWidth: "200px",
                        backgroundColor: "#41758b",
                      }}
                      variant="contained"
                      type="submit"
                    >
                      Inscríbete
                    </Button>
                  </Box>
                </Box>
              </form>
            </Box>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: "auto", mt: 4 }}>
            <Typography variant="h6" color="#666565">
              <b>Atiende cuando y donde quieras</b>
            </Typography>
            <Typography mt={3} variant="subtitle1" color="#666565">
              con Teradomus te evitas recorrer grandes distancias, tu eliges en
              que comuna atiendes, cuando trabajas y decides si tomas o no cada
              atención, porque tú eres quien está al mando.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: "auto", mt: 4 }}>
            <Typography variant="h6" color="#666565">
              <b>Comisiones competitivas</b>
            </Typography>
            <Typography mt={3} variant="subtitle1" color="#666565">
              Comisiones competitivas, siempre sabrás cuánto ganarás por cada
              visita antes de aceptar o rechazar un tratamiento. Además, te
              depositamos tus ganancias cada quince días.
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} sx={{ mb: "auto", mt: 4 }}>
            <Typography variant="h6" color="#666565">
              <b>Olvídate de buscar pacientes</b>
            </Typography>
            <Typography mt={3} variant="subtitle1" color="#666565">
              Nosotros nos preocupamos de encontrarte pacientes y tu te encargas
              de elegir a quienes atiendes, así de simple.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <Grid item xs={12} md={12}>
        <Box
          component="img"
          sx={{
            pt: 3,
            width: "100%",
          }}
          src={logo}
        />
      </Grid>
    </>
  );
}

export default Home;
