import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Grid, Skeleton } from "@mui/material";
import { useLocation } from "react-router-dom";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import logoImage from "../../assets/newiconos/Teradomus-gris.png";
import callApi from "../../services/callApi";

export default function Ingresada() {
  const location = useLocation();

  const [appointmentData, setAppointmentData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAppointmentDetails();
  }, []);

  async function getAppointmentDetails() {
    if (!location.state || !location.state.id_intencion) {
      console.error("No id_intencion found in location state");
      setLoading(false);
      return;
    }

    try {
      const response = await callApi(
        `/agenda/agendarExitoNoPago?id_intencion=${location.state.id_intencion}`
      );
      setAppointmentData(response.data);
    } catch (error) {
      console.error("Error fetching appointment details:", error);
    } finally {
      setLoading(false);
    }
  }

  const formatDate = (dateString) => {
    if (!dateString) return "";

    const date = new Date(dateString + "T00:00:00");
    const days = [
      "domingo",
      "lunes",
      "martes",
      "miércoles",
      "jueves",
      "viernes",
      "sábado",
    ];
    const months = [
      "enero",
      "febrero",
      "marzo",
      "abril",
      "mayo",
      "junio",
      "julio",
      "agosto",
      "septiembre",
      "octubre",
      "noviembre",
      "diciembre",
    ];

    const dayName = days[date.getDay()];
    const day = date.getDate().toString().padStart(2, "0");
    const month = months[date.getMonth()];
    return `${dayName} ${day} de ${month}`;
  };

  const textseciones = appointmentData
    ? "✨ Gracias por su reserva, hemos enviando un correo de confirmación al correo del paciente y del profesional."
    : "";

  const [textoVisible, setTextoVisible] = useState("");

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = textseciones;

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 25);
    return () => {
      clearInterval(interval);
    };
  }, [textseciones]);

  const transformModalidad = (modalidad) => {
    switch (modalidad) {
      case "consultorio":
        return "Consulta presencial";
      case "domicilio":
        return "Consulta a domicilio";
      case "online":
        return "Consulta online";
      default:
        return modalidad;
    }
  };

  return (
    <>
      <ScrollToTop />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <Grid Item xs={12} md={10}>
          <img
            src={logoImage}
            alt="Teradomus Logo"
            style={{
              width: isMobile ? "150px" : "200px",
              height: "auto",
              paddingTop: "15px",
              display: "block",
              marginLeft: "auto",
              marginRight: "auto",
              padding: 15,
            }}
          />
          <Box
            sx={{
              width: isMobile ? "95%" : "40%",
              mx: "auto",
              bgcolor: "#fbfafe",
              boxShadow: 1,
              marginTop: 2,
              borderRadius: 2,
              p: isMobile ? 2 : 3,
              paddingLeft: isMobile ? 0 : 10,
              paddingRight: isMobile ? 0 : 10,
            }}
          >
            <Typography sx={{ ml: 2, fontFamily: "Quicksand-Light" }}>
              Agendaste con:
            </Typography>
            {loading ? (
              <>
                <Skeleton
                  animation="wave"
                  height={30}
                  width="80%"
                  sx={{ ml: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  width="60%"
                  sx={{ ml: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={30}
                  width="70%"
                  sx={{ ml: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  width="40%"
                  sx={{ ml: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={20}
                  width="50%"
                  sx={{ ml: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={25}
                  width="30%"
                  sx={{ ml: 2 }}
                />
                <Skeleton
                  animation="wave"
                  height={25}
                  width="50%"
                  sx={{ ml: 2 }}
                />
              </>
            ) : appointmentData ? (
              <>
                <Typography
                  sx={{
                    ml: 2,
                    mt: -0.5,
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "20px", md: "20px", lg: "22px" },
                  }}
                >
                  {appointmentData.profesional_nombre_plataforma}
                </Typography>
                <Typography
                  sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
                >
                  {appointmentData.profesional_profesion}
                </Typography>
                <Typography
                  sx={{ ml: 2, fontFamily: "Quicksand-Light", mt: 1 }}
                >
                  El servicio de:
                </Typography>
                <Typography
                  sx={{
                    ml: 2,
                    mt: -0.5,
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "20px", md: "20px", lg: "22px" },
                  }}
                >
                  {appointmentData.servicio_nombre}
                </Typography>
                <Typography
                  sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
                >
                  {appointmentData.servicio_precio}
                </Typography>
                <Typography
                  sx={{ ml: 2, fontFamily: "Quicksand-Regular", mt: -0.5 }}
                >
                  {transformModalidad(appointmentData.servicio_modalidad)}
                </Typography>
                <Typography
                  sx={{
                    ml: 2,
                    color: "#8546b1",
                    fontFamily: "Quicksand-Bold",
                    mt: -0.5,
                  }}
                >
                  {formatDate(appointmentData.fecha_agenda)} -{" "}
                  {appointmentData.hora_agenda}
                </Typography>
              </>
            ) : (
              <Typography sx={{ ml: 2, fontFamily: "Quicksand-Regular" }}>
                No se pudo cargar la información de la cita.
              </Typography>
            )}
          </Box>
          <Typography
            textAlign={"center"}
            sx={{
              color: "#007FFF",
              mt: { xs: 1, lg: 3 },
              fontFamily: "Quicksand-Bold",
              fontSize: { xs: "20px", md: "20px", lg: "26px" },
            }}
          >
            Reserva ingresada
          </Typography>
        </Grid>
        <Box
          sx={{
            width: { lg: "50%", xs: "95%" },
            backgroundColor: "white",
            height: isMobile ? "150px" : "110px",
            borderRadius: 3,
            boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
            border: "1px solid rgba(0, 0, 0, 0.04)",
            mt: { lg: 3, xs: 2 },
            margin: "0 auto",
            mb: 5,
          }}
        >
          <Typography
            variant="body1"
            textAlign={"center"}
            sx={{
              mb: 3,
              mt: { xs: 3, lg: 3 },
              fontFamily: "Quicksand-Regular",
              fontSize: { xs: "16px", md: "20px", lg: "18px" },
            }}
          >
            {textoVisible}
          </Typography>
        </Box>
      </Grid>
    </>
  );
}
