import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import Typography from "@mui/material/Typography";

const HourSelector = ({ availableHours, onSelectHour }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    setSelectedValue(null);
    onSelectHour(null);
  }, [availableHours]);

  const handleChange = (value) => {
    setSelectedValue(value);
    onSelectHour(value);
  };

  return (
    <Grid
      container
      sx={{ width: "110%", justifyContent: "left", alignItems: "center" }}
    >
      {availableHours.map((hour, index) => (
        <Grid item key={index} sx={{ m: 0.5 }}>
          <Button
            className={selectedValue === hour ? "selected-option" : ""}
            sx={{
              width: "auto",
              padding: "4px 6px",
              minWidth: "unset",
              border: "1px solid #1e7aa8",
              borderRadius: { xs: 2, lg: 2 },
              backgroundColor: selectedValue === hour ? "#1e7aa8" : "",
              color: selectedValue === hour ? "white" : "black",
              "&:hover": {
                backgroundColor: selectedValue === hour ? "#1e7aa8" : "#b1dcf0",
              },
            }}
            onClick={() => handleChange(hour)}
          >
            <Typography
              sx={{ fontSize: { xs: "13px", lg: "16px" }, fontWeight: 500 }}
            >
              {hour}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};

export default HourSelector;
