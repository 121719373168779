import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Container } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { isMobile } from 'react-device-detect';

import paulet from '../../assets/Recursos/v2_Paulett_Jamett.png';
import agustin from '../../assets/Recursos/v2_Agustin_Beltran.png'
import natalia from '../../assets/Recursos/v2_Natali_Sandia.png'
import felipe from '../../assets/Recursos/v2_Felipe_Larraguibel.png'
import camila from '../../assets/Recursos/v2_Camila_Lopez.png'
import kinesiologo1 from '../../assets/Recursos/v2_Felipe_Larraguibel.png'

const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]]; // Intercambia elementos
    }
    return array;
};

const SLIDE_SETTINGS = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: false,
    autoplaySpeed: 4500,
    responsive: [
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
            },
        },
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            },
        },
    ],
};
const useStyles = makeStyles({
    arrow: {
        '& .slick-prev:before, & .slick-next:before': {
            color: '#1e7aa8',
        },
    },
});

const Nosotros = () => {
    const classes = useStyles();
    const isDesktop = !isMobile;

    const renderContent = (title, description, imageSrc, nombre) => (
        <Container>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                position: 'relative', height: { xs: '275px', lg: '250px' },
                zIndex: 2,  // Esto asegura que el contenedor esté encima de la imagen.
            }}>
                <img src={imageSrc} alt={title} style={{
                    width: isDesktop ? '93%' : '105%',
                    height: 'auto', backgroundSize: 'cover',
                    borderRadius: '8px',
                    position: 'absolute',  // Hace que la imagen esté posicionada absolutamente.
                    right: isDesktop ? -20 : -15,  // Alinea la imagen a la derecha.
                    zIndex: 1,  // Hace que la imagen esté detrás del contenedor.
                    transform: isDesktop ? 'translateY(25%)' : 'translateY(2%)'  // Centra la imagen verticalmente.
                }} />
            </Box>
            <Box sx={{ position: isDesktop ? 'relative' : 'relative', zIndex: 2, ml: { xs: 0, lg: 15 }, mt: { xs: -38, lg: -20 }, textAlign: isDesktop ? 'left' : 'center', color: isDesktop ? "white" : '#1e7aa8' }}>
                <Typography sx={{ mt: { xs: 0, lg: 6 }, mb: { xs: 0, lg: 9 }, fontSize: { xs: '20px', lg: '30px' }, fontFamily: 'Quicksand-Bold', }}>
                    {isDesktop ? 'Nuestros profesionales' : ''}
                </Typography>
            </Box>
            <Box
                sx={{
                    width: { xs: '180px', lg: '400px' },
                    height: isDesktop ? '140px' : 'auto',
                    bgcolor: 'white', position: 'relative',
                    zIndex: 2,
                    borderRadius: '13px',
                    boxShadow: '6px 6px 6px 6px rgba(0, 0, 0, 0.16)',
                    display: 'flex',
                    flexDirection: 'column',
                    mb: { xs: 9, lg: 9 }, mt: { xs: 13, lg: 5 }, p: { xs: 1, lg: 5 }, ml: { xs: -1.5, lg: 4 }
                }}
            >
                <Typography sx={{ fontSize: { xs: '14px', lg: '16px' }, fontFamily: 'Quicksand-Light', lineHeight: 1.1 }}>
                    {isDesktop ? description : ''}
                </Typography>
                <Typography sx={{ mt: { xs: 0, lg: 3 }, fontSize: { xs: '14px', lg: '16px' }, fontFamily: 'Quicksand-Light', lineHeight: 1.1 }}>
                    {nombre}
                </Typography>
                <Typography sx={{ textAlign: 'left', fontSize: { xs: '15px', lg: '18px' }, fontFamily: 'Quicksand-Bold', color: '#1e7aa8' }}>
                    <b>{title}</b>
                </Typography>
            </Box>
        </Container>
    );

    const renderSlides = () => {
        const contents = [
            renderContent('Kinesióloga', 'Apasionada de la kinesiología musculoesquelética con experiencia en acelerar la recuperación y promover el bienestar del paciente, comprometida con tratamientos efectivos, empáticos e individualizados.', paulet, 'Paulett Jamett'),
            renderContent('Kinesiólogo', 'Kinesiólogo experimentado en neurorehabilitación, geriatría, rehabilitación musculoesquelética y reintegro deportivo. Comprometido con el bienestar y la recuperación efectiva de sus pacientes, enfocado en brindar tratamientos individualizados.', agustin, 'Agustín Beltrán'),
            renderContent('Kinesióloga', 'Kinesióloga especializada en neurorehabilitación y kinesioterapia respiratoria, dedicada al bienestar y recuperación de los pacientes, enfocada en tratamientos efectivos y empáticos según sus necesidades.', natalia, 'Natalia Sandia'),
            renderContent('Kinesiólogo', 'Kinesiólogo con experiencia en traumatología, geriatría y neurología, dedicado al bienestar integral del paciente, enfocado en educación, bienestar biopsicosocial y trabajo multidisciplinario.', felipe, 'Felipe Larraguibel'),
            renderContent('Kinesióloga', 'Kinesióloga con experiencia en áreas respiratoria y musculoesquelética, apasionada por entregar tratamientos efectivos y empáticos, acorde a las expectativas y necesidades de cada paciente.', camila, 'Camila Lopez'),
        ];

        return shuffleArray(contents);
    };

    return (
        <Box sx={{ width: { xs: 'auto', lg: '80%' }, height: 'auto', margin: 'auto' }}>
            <Slider {...SLIDE_SETTINGS} className={classes.arrow}>
                {renderSlides()}
            </Slider>
        </Box>
    );
};

export default Nosotros;
