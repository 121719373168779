import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import FormGroup from "@mui/material/FormGroup";
import { Grid } from "@mui/material";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import callApi from "../../services/callApi";
import TelWhat from "../../components/TelWhat/TelWhat";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import { Helmet } from "react-helmet";
import Loader from "../../components/Loader";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { parse, format } from "date-fns";
import { es } from "date-fns/locale";

export default function Solicitar() {
  let history = useHistory();
  const location = useLocation();
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = React.useState(false);
  const [identificacion, setIdentificacion] = React.useState("");
  const [telefono, setTelefono] = useState(location.state.telefono);
  const [edad, setEdad] = React.useState(location.state.edad);
  const [diagnostico, setDiagnostico] = React.useState("");
  const [correo, setCorreo] = React.useState("");
  const {
    comuna,
    respuesta5,
    respuesta6,
    consulta,
    tipo,
    motivo,
    preciosessions,
  } = location.state;
  const [days] = React.useState(location.state.dates);
  const [hourss] = React.useState(location.state.hourss);
  const [sessions] = useState(location.state.sessions);
  const [textoVisible, setTextoVisible] = useState("");
  const [nombre, setNombre] = useState(location.state.nombre);
  const [apellido, setApellido] = useState(location.state.apellido);
  const [direccion, setDireccion] = useState(location.state.direccion);
  const [depto, setDepto] = React.useState("");
  const [codigoPromocional, setCodigoPromocional] = useState("");
  const [precioPorSesion, setPrecioPorSesion] = useState(preciosessions);
  const [codigoAplicado, setCodigoAplicado] = useState(false);
  const [codigoValido, setCodigoValido] = useState(true);
  const parsedDate = parse(days, "EEEE dd 'de' MMMM yyyy", new Date(), {
    locale: es,
  });
  const formattedDate = format(parsedDate, "dd/MM/yyyy");

  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription =
    "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  function handleChangeNombre(event) {
    const inputValue = event.target.value;
    setNombre(event.target.value);
  }
  function handleChangeApellido(event) {
    const inputValue = event.target.value;
    setApellido(event.target.value);
  }

  function handleChangeDiagnostico(event) {
    setCodigoPromocional(event.target.value);
  }
  function handleChangeIdentificacion(event) {
    let inputValue = event.target.value.replace(/[^\dKk]/g, "");
    if (inputValue.length > 1) {
      const rutParteNumerica = inputValue.slice(0, -1);
      const rutDigitoVerificador = inputValue.slice(-1);

      inputValue =
        rutParteNumerica.replace(/\B(?=(\d{3})+(?!\d))/g, ".") +
        "-" +
        rutDigitoVerificador;
    }

    setIdentificacion(inputValue);
  }
  function handleChangeCorreo(event) {
    setCorreo(event.target.value);
  }

  function handleChangeDireccion(event) {
    setDireccion(event.target.value);
  }

  function handleChangeDepto(event) {
    setDepto(event.target.value);
  }

  const handleChangeCelular = (event) => {
    const prefix = "+56 ";
    let inputValue = event.target.value;

    if (!inputValue.startsWith(prefix)) {
      inputValue = prefix;
    }
    inputValue = inputValue.replace(/[^\d+ ]/g, "");

    let plainNumbers = inputValue.slice(prefix.length).replace(/\D/g, "");

    let formattedNumbers = plainNumbers;
    if (plainNumbers.length > 2) {
      formattedNumbers =
        plainNumbers.substring(0, 1) +
        " " +
        plainNumbers.substring(1, 5) +
        " " +
        plainNumbers.substring(5);
    }
    inputValue = prefix + formattedNumbers.trim();
    if (inputValue.length > prefix.length + 11) {
      inputValue = inputValue.substring(0, prefix.length + 11);
    }

    setTelefono(inputValue);
  };

  function handleChangeEdad(event) {
    const inputValue = event.target.value;
    const allowedCharacters = /^[0-9añoñosmes\s]+$/i;

    if (inputValue.match(allowedCharacters) || inputValue === "") {
      setEdad(inputValue);
    }
  }
  function aplicarCodigoPromocional() {
    if (codigoAplicado) {
      return;
    }

    const codigoIngresado = codigoPromocional.toLowerCase();

    const codigosDescuento5000 = ["buhochile", "laaraucana"];

    const codigosDescuento2500 = [
      "sm6041",
      "ds0071",
      "bm4465",
      "hz5090",
      "fs8288",
      "dm9574",
      "ts5233",
      "rh2035",
      "ma1480",
      "ga9791",
      "lb4922",
      "nh2499",
      "gr5582",
      "sa1030",
      "jm5654",
      "fa8618",
      "mr5660",
      "rf4336",
      "sa6097",
      "aj9421",
      "gj9421",
      "lg6447",
      "mv7854",
      "ab8868",
      "el5780",
      "ip8750",
      "vt0054",
      "mr5677",
      "cv3138",
      "cr2584",
      "hb8489",
      "yv6078",
      "mj6265",
      "rb6944",
      "nd4316",
      "di8489",
      "jc8983",
      "hm7230",
      "mt8185",
      "en6874",
      "fr6677",
      "jc7010",
      "pd0615",
      "nk3886",
      "rp8015",
      "gb5429",
      "eb4497",
      "jl2714",
      "jl4920",
      "fi0335",
      "sd3208",
      "me7049",
      "aq8844",
      "ag7637",
      "bh0710",
      "jj4613",
      "jm5558",
      "lc8826",
      "ov8772",
      "jc6391",
      "hv5386",
      "sr6448",
      "ch8417",
      "ac4622",
      "ms1167",
      "gf7479",
      "eb2713",
      "km5405",
      "bs6321",
      "ma0736",
      "lc3253",
      "jh5150",
      "ss536",
      "jr5724",
      "cs1861",
      "at6136",
      "hm0852",
      "pf6996",
      "aa1609",
      "dc7175",
      "bq6194",
      "cb4803",
      "mf5451",
      "ai2104",
      "ea7535",
      "pd2964",
      "fc7862",
      "sl6303",
      "rr6345",
      "mg2452",
      "hc7179",
      "jl1513",
      "ms3946",
      "mp3422",
      "ml7357",
      "im6308",
      "ig4580",
      "fs5803",
      "nm4663",
      "jb6045",
      "rd3036",
      "cm0102",
      "nd1433",
      "rm4257",
      "em7974",
      "sa1301",
      "cb9142",
      "sl3033",
      "lp6718",
      "da4257",
    ];

    let descuento = 0;

    if (codigosDescuento5000.includes(codigoIngresado)) {
      descuento = 5000;
    } else if (codigosDescuento2500.includes(codigoIngresado)) {
      descuento = 2500;
    }

    if (descuento > 0) {
      let nuevoPrecioPorSesion = precioPorSesion - descuento;
      if (nuevoPrecioPorSesion < 0) {
        nuevoPrecioPorSesion = 0;
      }
      setPrecioPorSesion(nuevoPrecioPorSesion);
      setCodigoAplicado(true);
      setCodigoValido(true);
    } else {
      setCodigoValido(false);
    }
  }

  async function handleCompleteTreatmentPayment() {
    if (
      nombre === "" ||
      apellido === "" ||
      identificacion === "" ||
      telefono === "" ||
      correo === "" ||
      edad === 0
    ) {
      MySwal.fire("Llena los campos obligatorios!", "", "info");
      return;
    }

    const formatoValido = /^[\d\s+]+$/.test(telefono);
    const correoValido = /^\S+@\S+\.\S+$/.test(correo);
    if (!formatoValido) {
      MySwal.fire("Ingresa un número telefónico válido", "", "info");
      return null;
    }

    if (!correoValido) {
      MySwal.fire("Ingresa un correo electrónico válido", "", "info");
      return null;
    }
    const all_data = {
      identificacion: identificacion,
      nombre: nombre,
      apellido: apellido,
      antecedentes: diagnostico,
      diagnostico: motivo,
      correo: correo,
      celular: telefono,
      edad: edad,
      direccion: direccion,
      comuna: comuna,
      depto: depto,
      info: "info",
      dia: formattedDate,
      hora: hourss,
      especialidad: respuesta5,
      cantidad_sesiones: location.state.sessions,
      codigo: codigoPromocional,
      derivador: "teradomus-ia",
    };

    setIsLoading(true);
    const result = await callApi("/receive-solicitud", "post", all_data);
    setIsLoading(false);

    if (result?.statusCode === 200) {
      history.push({
        pathname: "/kinesiologia-a-domicilio/agenda-inteligente/ingresada",
        state: {
          tipo: respuesta5,
          sessions: location.state.sessions,
          dia: days,
          hora: hourss,
          pref: result.body.preference,
          price: result.body.price,
          habil: result.body.habil,
          nombre: nombre,
          apellido: apellido,
          tipo: tipo,
          preciosessions: precioPorSesion,
          consulta: consulta,
        },
      });
    } else {
      console.log(result);
      MySwal.fire("Algo Falló... intenta nuevamente!", "", "error");
    }
  }

  let newText = tipo;
  let updatedTextseciones = replaceBoldContent(respuesta6, newText);

  function replaceBoldContent(originalText, replacementText) {
    const matches = originalText.match(/\*\*(.*?)\*\*/g);
    if (matches && matches.length >= 2) {
      return originalText.replace(matches[1], `**${replacementText}**`);
    }
    return originalText;
  }

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = updatedTextseciones; // <--- Asignar respuesta1 a textoAMostrar

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Velocidad de escritura (ajusta según tus preferencias)

    return () => {
      clearInterval(interval);
    };
  }, [updatedTextseciones]);

  function parseTextWithBold(text) {
    const splitText = text.split("**");
    return splitText.map((part, index) => {
      if (index % 2 === 1) {
        // Si es un índice impar, es una variable y debe ir en negrita
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  }

  return (
    <>
      <ScrollToTop />
      <Loader isLoading={isLoading} />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link
          rel="canonical"
          href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/solicitar"
        />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/solicitar"
        />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Box
        sx={{
          height: { lg: "300px", xs: "25vh" },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      ></Box>
      <Box
        sx={{
          width: { lg: "60%", xs: "95%" },
          backgroundColor: "white",
          borderRadius: { xs: 5, lg: 15 },
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.04)",
          mt: { lg: -30, xs: -20 },
          margin: "0 auto",
        }}
      >
        <FormGroup>
          <Container>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Grid Item xs="12" md="10" lg="10">
                <Typography
                  textAlign={"center"}
                  sx={{
                    color: "#2669A8",
                    mt: { xs: 1, lg: 3 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { xs: "20px", md: "20px", lg: "26px" },
                  }}
                >
                  Solicitar agenda
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    mb: { xs: 2, lg: 2 },
                    mt: { xs: 1, lg: 3 },
                    fontFamily: "Quicksand-Regular",
                    fontSize: { xs: "16px", md: "20px", lg: "18px" },
                  }}
                >
                  {parseTextWithBold(textoVisible)}
                </Typography>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 3,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "18px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    RUT paciente*
                  </Typography>
                  <Box
                    sx={{
                      borderRadius: 2, // Ajusta el valor según tu preferencia
                      overflow: "hidden", // Para recortar los bordes redondeados del TextField
                      backgroundImage:
                        "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                      p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                    }}
                  >
                    <input
                      id="rutInput"
                      name="rut"
                      type="text"
                      value={identificacion}
                      onChange={handleChangeIdentificacion}
                      style={{
                        border: "none", // Quitamos el borde nativo del input
                        width: "100%", // Ajusta el ancho del input según tus necesidades
                        outline: "none", // Quitamos el contorno al seleccionar el input
                        background: "transparent", // Hacemos el fondo transparente
                        fontFamily: "Quicksand-Regular",
                        color: "#4F4F4F",
                        fontSize: "16px",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Nombre paciente*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="name"
                    name="name"
                    onChange={handleChangeNombre}
                    value={nombre}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Apellido paciente*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeApellido}
                    value={apellido}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Edad paciente*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeEdad}
                    value={edad}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Teléfono contacto*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                  }}
                >
                  <input
                    name="telefono" // Agrega el atributo 'name'
                    value={telefono}
                    onChange={handleChangeCelular}
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Correo*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="email"
                    name="email"
                    onChange={handleChangeCorreo}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Dirección*
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    id="direccion"
                    value={direccion}
                    name="direccion"
                    onChange={handleChangeDireccion}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Depto/Oficina/Otros
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2, // Ajusta el valor según tu preferencia
                    overflow: "hidden", // Para recortar los bordes redondeados del TextField
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5, // Añade un espacio interno al Box para evitar que el texto toque los bordes
                  }}
                >
                  <input
                    onChange={handleChangeDepto}
                    style={{
                      border: "none", // Quitamos el borde nativo del input
                      width: "100%", // Ajusta el ancho del input según tus necesidades
                      outline: "none", // Quitamos el contorno al seleccionar el input
                      background: "transparent", // Hacemos el fondo transparente
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                    }}
                  />
                </Box>
                <Grid item xs={12} lg={12}>
                  <Typography
                    sx={{
                      pt: 1,
                      fontFamily: "Quicksand-Regular",
                      fontSize: { xs: "16px", md: "20px", lg: "20px" },
                      color: "#4F4F4F",
                    }}
                  >
                    Código promocional
                  </Typography>
                </Grid>
                <Box
                  sx={{
                    borderRadius: 2,
                    overflow: "hidden",
                    backgroundImage:
                      "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
                    p: 0.5,
                    display: "flex",
                    alignItems: "center",
                    borderColor: codigoValido ? "inherit" : "red", // Cambiar el borde a rojo si el código es inválido
                  }}
                >
                  <TextareaAutosize
                    onChange={handleChangeDiagnostico} // Modificamos el manejador de cambios
                    value={codigoPromocional} // Asignamos el valor del estado al campo
                    style={{
                      border: "none",
                      width: "100%",
                      outline: "none",
                      background: "transparent",
                      fontFamily: "Quicksand-Regular",
                      color: "#4F4F4F",
                      fontSize: "16px",
                      resize: "none",
                    }}
                  />
                  <Button
                    onClick={aplicarCodigoPromocional}
                    disabled={codigoAplicado} // Deshabilitar el botón después de aplicar un código
                    variant="contained"
                    sx={{
                      fontFamily: "Quicksand-Bold",
                      textTransform: "none",
                      width: "20%",
                      p: 0.1,
                      fontSize: { xs: "14px", md: "16px", lg: "18px" },
                      borderRadius: 3,
                    }}
                  >
                    Aplicar
                  </Button>
                </Box>
                {!codigoValido && (
                  <Typography
                    sx={{
                      color: "red",
                      fontSize: "14px",
                      mb: 2,
                      mt: "8px", // Ajusta la separación si es necesario
                    }}
                  >
                    Código promocional inválido. Por favor, ingresa un código
                    válido.
                  </Typography>
                )}
                {codigoValido && codigoAplicado && (
                  <Typography
                    sx={{
                      color: "green", // Puedes cambiar el color a tu preferencia
                      fontSize: "14px",
                      mb: 2,
                      mt: "8px", // Ajusta la separación si es necesario
                    }}
                  >
                    Código válido. Se ha aplicado el descuento.
                  </Typography>
                )}
                <Typography
                  sx={{
                    mb: { xs: 3, lg: 4 },
                    pt: { xs: 1, lg: 3 },
                    fontFamily: "Quicksand-Regular",
                    fontSize: { xs: "16px", md: "20px", lg: "20px" },
                    color: "#4F4F4F",
                  }}
                >
                  * Obligatorio
                </Typography>
              </Grid>
            </Grid>
          </Container>
        </FormGroup>
      </Box>
      <Box
        sx={{
          width: { lg: "58%", xs: "85%" },
          backgroundColor: "white",
          borderRadius: 3,
          boxShadow: "5px 5px 6px rgba(0, 0, 0, 0.14)",
          border: "1px solid rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "0 auto", // Añadido para centrar horizontalmente
          padding: 2,
          mt: 6,
        }}
      >
        <Grid container>
          <Grid item xs={12} lg={7}>
            <Box
              sx={{
                ml: { xs: 0, md: 2, lg: 2 },
              }}
            >
              <Typography
                sx={{
                  color: "#4F4F4F",
                  mb: { xs: 1, lg: 1 },
                  mt: { xs: 0, lg: 2 },
                  fontFamily: "Quicksand-Bold",
                  fontSize: { xs: "14px", md: "16px", lg: "20px" },
                }}
              >
                Así va tu orden:
              </Typography>
              <Typography
                sx={{
                  ml: 2,
                  color: "#4F4F4F",
                  fontFamily: "Quicksand-Regular",
                  fontSize: { xs: "13px", md: "16px", lg: "18px" },
                }}
              >
                <li>
                  {tipo} {consulta}
                </li>
                <li>
                  N° de sesiones: {sessions}, desde $
                  {precioPorSesion
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  por sesión{" "}
                </li>
                <li>
                  Total a pagar desde $
                  {(sessions * precioPorSesion)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}{" "}
                  hasta en 3 cuotas de $
                  {((sessions * 25000) / 3)
                    .toFixed(0)
                    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.")}
                  , sin intereses
                </li>
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            lg={5}
            sx={{
              mt: { xs: 2 },
              p: 1,
              border: "1px solid rgba(0, 0, 0, 0.2)",
              borderRadius: 4,
            }}
          >
            <Typography
              sx={{
                color: "#4F4F4F",
                lineHeight: { xs: 1.2, md: 1.1, lg: 1 }, // Ajusta este valor según tus necesidades
                mt: { xs: 1, lg: 0.5 },
                fontFamily: "Quicksand-Bold",
                fontSize: { xs: "14px", md: "16px", lg: "16px" },
              }}
            >
              Primera sesión:{" "}
              <span style={{ color: "#8546b1", fontFamily: "Quicksand-Bold" }}>
                <p></p>
                {location.state.dates} a las {location.state.hourss} horas
              </span>
            </Typography>
            <Typography
              sx={{
                mt: { xs: 1.5, lg: 1.5 },
                fontFamily: "Quicksand-Light",
                fontSize: { xs: "13px", md: "16px", lg: "16px" },
                lineHeight: { xs: 1.3, md: 1.1, lg: 1 },
              }}
            >
              Las siguientes sesiones las podrás coordinar con el profesional en
              tu primera visita.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          gap: { xs: "15px", lg: "30px" },
          justifyContent: "center",
          mt: { xs: 7, lg: 8 },
          mb: { lg: 1 },
        }}
      >
        <Button
          sx={{
            fontFamily: "Quicksand-Bold",
            textTransform: "none",
            width: { xs: "160px", lg: "210px" },
            fontSize: { xs: "14px", md: "16px", lg: "18px" },
            borderRadius: 3,
            pl: { xs: 1, lg: 2 },
            pr: { xs: 1, lg: 2 },
          }}
          onClick={handleCompleteTreatmentPayment}
          variant="contained"
        >
          Solicitar
        </Button>
      </Box>
      <Box sx={{ textAlign: "center", pt: 7 }}>
        <TelWhat />
      </Box>
    </>
  );
}
