import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Link } from "@material-ui/core";

import logo from "../../../assets/images/Teradomus_blanco_2.png";
import wsp from "../../../assets/newiconos/was.png";

const area = ["motora", "respiratoria", "neurológica", "adulto mayor"];

const options = ["Motora", "Respiratoria", "Neurológica", "Adulto mayor"];

function Inicio(props) {
  const { comu } = props;
  const [text, setText] = useState("");
  const [optionsOpen, setOptionsOpen] = useState(false);
  const history = useHistory();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));

  useEffect(() => {
    let currentIndex = 0;

    setText(area[currentIndex]);

    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % area.length;
      setText(area[currentIndex]);
    }, 1500);

    return () => clearInterval(intervalId);
  }, []);

  const handleAgendaOnline = () => {
    history.push("/kinesiologia-a-domicilio/agenda-inteligente/inicio");
  };

  const handleAgendarPorTelefono = () => {
    window.location.href = "tel:56996906773";
  };

  const handleShowOptions = () => {
    if (isDesktop) {
      setOptionsOpen(true);
    }
  };

  const handleHideOptions = () => {
    if (isDesktop) {
      setOptionsOpen(false);
    }
  };

  const handleToggleOptions = () => {
    if (!isDesktop) {
      setOptionsOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const handleCloseOptions = () => {
      setOptionsOpen(false);
    };

    // Detectar eventos de desplazamiento en el documento
    window.addEventListener("scroll", handleCloseOptions);

    return () => {
      window.removeEventListener("scroll", handleCloseOptions);
    };
  }, []);

  return (
    <Box>
      <Link
        href="https://api.whatsapp.com/send/?phone=%2B56996906773&text=Hola,%20amigos%20de%20Teradomus.%20Quiero%20agendar%20una%20cita%20con%20un%20profesional%20de%20la%20salud.%20¿Podrían%20ayudarme%20😊?&type=phone_number&app_absent=0"
        underline="none"
        color="inherit"
      >
        <Button
          variant="contained"
          sx={{
            position: "fixed",
            width: "70px", // Ajusta el ancho del botón según tus necesidades
            height: "70px",
            bottom: 16,
            right: 16,
            borderRadius: 50,
            bgcolor: "white",
            zIndex: 9999,
            p: isDesktop ? 0.01 : 2,
            "&:hover": {
              bgcolor: "white", // Cambiar el color de fondo al pasar el mouse
            },
          }}
        >
          <img
            src={wsp}
            alt="whatsapp"
            style={{
              height: isDesktop ? "120px" : "80px",
            }}
          />
        </Button>
      </Link>
      <Container>
        <Grid container>
          <Grid item lg={1} md={2.3}></Grid>
          <Grid item xs={12} lg={6.5} md={6.3}>
            <Box>
              <Typography
                variant="h1"
                sx={{
                  mt: { lg: 9, md: 6, xs: 3 },
                  fontFamily: "Quicksand-Bold",
                  fontSize: { lg: "46px", md: "38px", xs: "26px" },
                  color: "#1e7aa8",
                }}
              >
                <b>
                  Kinesiología a domicilio <br />
                  {comu && `en ${comu}`}
                </b>
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Quicksand-Regular",
                  fontSize: { lg: "24px", md: "22px", xs: "16px" },
                  color: "#1e7aa8",
                  mt: { lg: 4, md: 4, xs: 4 },
                  mb: 2,
                }}
              >
                Encontrar quien te atienda puede ser difícil,
                <b> en Teradomus nos adaptamos a ti</b>
              </Typography>
              <Box
                sx={{
                  mt: 5,
                  display: "flex",
                  gap: { lg: "35px", md: "15px", xs: "10px" },
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    paddingLeft: { lg: 3, md: 1, xs: 0.5 },
                    paddingRight: { lg: 3, md: 3, xs: 0.5 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "24px", md: "22px", xs: "15px" },
                    textTransform: "none",
                    borderRadius: 9,
                    backgroundColor: "#8753b1",
                    "&:hover": { backgroundColor: "#651d87" },
                  }}
                  onClick={handleAgendaOnline}
                >
                  Agenda Online
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    paddingLeft: { lg: 3, md: 1, xs: 0.5 },
                    paddingRight: { lg: 3, md: 3, xs: 0.5 },
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "24px", md: "22px", xs: "15px" },
                    textTransform: "none",
                    borderRadius: 9,
                    backgroundColor: "#8753b1",
                    "&:hover": { backgroundColor: "#651d87" },
                  }}
                  onClick={handleAgendarPorTelefono}
                >
                  Agendar por teléfono
                </Button>
              </Box>
              <Box sx={{ height: { xs: "60px" } }}>
                <Typography
                  sx={{
                    fontFamily: "Quicksand-Regular",
                    fontSize: { lg: "24px", md: "22px", xs: "16px" },
                    color: "#1e7aa8",
                    mt: 5,
                    whiteSpace: "pre-line",
                  }}
                >
                  Atendemos a todas las edades y contamos con kinesiólogos
                  especializados en las áreas <b>{text}</b>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Inicio;
