import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link } from "@material-ui/core";
import { Helmet } from 'react-helmet';


import Calefaccion from "../../assets/blog/sistemas_para_calefaccion_en_casas.jpg";


export default function BlogNoticia() {
  const pageTitle = "Teradomos news: enterate de lo ultimo para tu salud";
  const pageDescription = "Teradomus te informa sobre los riesgos de la calefacción intradomiciliaria para la salud respiratoria y ofrece alternativas más seguras y eficientes.";


  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/blog/calefaccion" />
        <meta property="og:type" content="article" />
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/blog/calefaccion" />
      </Helmet>
      <Container>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Link href="/blog"
              underline="none"
              color="inherit">
              <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 2 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
                ← Volver al listado de artículos
              </Typography>
            </Link>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant='h2' sx={{ mt: { md: 4, xs: 2 }, fontSize: { md: "22px", xs: "18px" }, fontFamily: 'Quicksand-Bold' }}>
              Calefacción Intradomiciliaria: Un Riesgo Invisible para la Salud Respiratoria.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 6, xs: 2 } }}>
              <img src={Calefaccion} style={{ width: 'auto', height: isMobile ? '175px' : '290px' }} alt='calefaccion noticia' />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant='h3' sx={{ color: '#1e7aa8', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              Junio 17, 2023
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              El hogar es el lugar donde buscamos refugio, comodidad y seguridad. Sin embargo, a menudo olvidamos que incluso en nuestros propios espacios, existen factores de riesgo para nuestra salud. Uno de esos peligros es la contaminación intradomiciliaria, particularmente relacionada con los métodos de calefacción que utilizamos durante los meses de invierno.
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              En la época más fría del año, buscamos calor y confort en nuestros hogares. Pero debemos tener cuidado, los sistemas de calefacción tradicionales, como la quema de leña, parafina o el uso de artefactos a gas, pueden generar contaminantes que afectan la calidad del aire de nuestros hogares. Estos incluyen monóxido de carbono, partículas finas, óxidos de nitrógeno y otros compuestos que pueden perjudicar nuestra salud, teniendo efectos incluso cancerígenos o potenciadores de mutaciones en el embarazo.
            </Typography>
          </Grid>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Las enfermedades respiratorias son una de las principales consecuencias directas de esta contaminación intradomiciliaria. La exposición a largo plazo a estos contaminantes puede desencadenar o agravar condiciones como el asma, la bronquitis crónica y otras enfermedades pulmonares obstructivas crónicas (EPOC). También se ha demostrado que aumenta la susceptibilidad a infecciones respiratorias, incluyendo la neumonía, especialmente en niños y personas mayores.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Afortunadamente, existen alternativas más seguras y saludables para mantener nuestros hogares a una temperatura adecuada, que por cierto la Organización mundial de la salud recomienda que sea de unos 20°c aproximadamente. Estas alternativas pueden ser aparatos de calefacción eléctrica, como los calefactores de ventiladores, tubos incandescentes, infra roja u otras. Los que no generan emisiones de combustión y son más seguros.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Si bien estos métodos suelen ser menos económicos que los demás es importante recalcar el uso de estrategias para ser más eficientes con el uso de la energía y así disminuir los gastos en calefacción. La utilización de buen aislante en las paredes y techos, sellar posibles fugas entre los cantos de puertas y ventanas con cintas “burletes”, abrir cortinas y ventanas durante las horas de sol y cerrarlas por la tarde-noche para mantener el calor, son algunos consejos que puedes adoptar, estas estrategias no solo te permitirán economizar en el uso de métodos de calefacción convencionales, sino que también te ayudará a reducir tu huella de carbono y a crear un hogar más saludable y sostenible.
          </Typography>
          <Typography variant='h3' sx={{ mb: 4, textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            En la siguiente tabla desarrollada por el equipo de Teradomus te mostramos los métodos de calefacción y sus niveles de contaminación intradomiciliaria, en base a un estudio realizado por el centro nacional del medio ambiente (CENMA U. de Chile)
          </Typography>
          <table>
            <thead>
              <tr>
                <th>Tipo de calefacción</th>
                <th>Nivel de riesgo</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Calefactor eléctrico</td>
                <td>😊</td>
              </tr>
              <tr>
                <td>Estufa a gas</td>
                <td>😷</td>
              </tr>
              <tr>
                <td>Estufa parafina laser</td>
                <td>😷😷😷</td>
              </tr>
              <tr>
                <td>Estufa parafina convencional</td>
                <td>😷😷😷😷😷😷</td>
              </tr>
              <tr>
                <td>Estufa a leña tipo Bosca</td>
                <td>😷😷😷😷😷😷😷😷😷😷</td>
              </tr>
            </tbody>
          </table>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 4, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Además, les dejamos algunos consejos y tips para mantener un invierno seguro y saludable:
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            - Ventilación adecuada: Asegúrate de que tu hogar esté correctamente ventilado, incluso en invierno. Esto ayuda a diluir y eliminar los contaminantes del aire.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            - Mantenimiento de sistemas de calefacción: Los sistemas de calefacción deben revisarse y mantenerse regularmente para asegurarse de que funcionen de manera eficiente y segura.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            - Considera sistemas de calefacción más limpios: Si es posible, considera cambiar a sistemas de calefacción que generen menos contaminantes, como calefacción eléctrica o estufas de gas modernas.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            - Implementa estrategias de eficiencia: Utiliza la energía del sol y aísla bien tu hogar.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            - Consulta a profesionales: Si tienes alguna preocupación sobre la calidad del aire en tu hogar o experimentas síntomas de enfermedades respiratorias, no dudes en buscar ayuda médica y solicitar el servicio de kinesiología respiratoria en Teradomus.com
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            En Teradomus, comprendemos la importancia de vivir en un ambiente saludable. Nuestro equipo de profesionales está disponible para asesorarte sobre los riesgos asociados con la contaminación intradomiciliaria y las enfermedades respiratorias. Si tienes algún síntoma o enfermedad respiratoria que necesite tratamiento kinesiológico no dudes en contactarnos.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            ¡En <Link href="/"
              underline="none"
              color="Blue">Teradomus</Link>, tu salud y bienestar son nuestra máxima prioridad!
          </Typography>
        </Grid >
        <Link href="/blog"
          underline="none"
          color="inherit">
          <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 5 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
            ← Volver al listado de artículos
          </Typography>
        </Link>
      </Container >
    </>
  );
}