import React, { useState } from 'react';
import { Grid, Button, IconButton, useMediaQuery } from '@mui/material';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import Typography from '@mui/material/Typography';

const options = [
  { label: '08:00', value: '08:00' },
  { label: '08:30', value: '08:30' },
  { label: '09:00', value: '09:00' },
  { label: '09:30', value: '09:30' },
  { label: '10:00', value: '10:00' },
  { label: '10:30', value: '10:30' },
  { label: '11:00', value: '11:00' },
  { label: '11:30', value: '11:30' },
  { label: '12:00', value: '12:00' },
  { label: '12:30', value: '12:30' },
  { label: '13:00', value: '13:00' },
  { label: '13:30', value: '13:30' },
  { label: '14:00', value: '14:00' },
  { label: '14:30', value: '14:30' },
  { label: '15:00', value: '15:00' },
  { label: '15:30', value: '15:30' },
  { label: '16:00', value: '16:00' },
  { label: '16:30', value: '16:30' },
  { label: '17:00', value: '17:00' },
  { label: '17:30', value: '17:30' },
  { label: '18:00', value: '18:00' },
  { label: '18:30', value: '18:30' },
  { label: '19:00', value: '19:00' },
  { label: '19:30', value: '19:30' },
  { label: '20:00', value: '20:00' },
];

const HourSelector = ({ onSelectHour }) => {
  const [startIndex, setStartIndex] = useState(0);
  const isDesktop = useMediaQuery('(min-width: 768px)'); // Media query para dispositivos de escritorio
  const itemsPerPage = isDesktop ? 7 : 5; // Determina el número de elementos por página según el tamaño de pantalla
  const [selectedValue, setSelectedValue] = useState(null);

  const handleNext = () => {
    const newIndex = startIndex + 1;
    if (newIndex < options.length) {
      setStartIndex(newIndex);
    }
  };

  const handleChange = (value) => {
    setSelectedValue(value);
    onSelectHour(value);
  };

  const handlePrev = () => {
    const newIndex = startIndex - 1;
    if (newIndex >= 0) {
      setStartIndex(newIndex);
    }
  };

  const visibleOptions = options.slice(startIndex, startIndex + itemsPerPage);

  return (
    <Grid container sx={{ width: '110%',justifyContent: 'left', alignItems: 'center', }}>
      <IconButton
        disabled={startIndex === 0}
        onClick={handlePrev}
        sx={{ml:{xs:-2, lg:0}, color: '#1e7aa8', '&:hover': { backgroundColor: 'transparent' } }}
      >
        <ArrowBack />
      </IconButton>
      {visibleOptions.map((option, index) => (
        <Grid key={index} sx={{}} >
          <Button
            className={selectedValue === option.value ? 'selected-option' : ''}
            sx={{width: '45px', 
              padding: '4px 6px',
              minWidth: 'unset',
              marginLeft: { xs: '2.5px', lg: '5px' },
              marginRight: { xs: '2.5px', lg: '5px' },
              border: '1px solid #1e7aa8',
              borderRadius: { xs: 2, lg: 2 },
              backgroundColor: selectedValue === option.value ? '#1e7aa8' : '',
              color: selectedValue === option.value ? 'white' : 'black',
              '&:hover': {
                backgroundColor: selectedValue === option.value ? '#1e7aa8' : '',
              },
            }}
            onClick={() => handleChange(option.value)}
          >
            <Typography sx={{
              fontSize: { xs: '13px', md: '14px', lg: '16px' },
              fontFamily: 'Quicksand-Regular',
            }}>{option.label}</Typography>
          </Button>
        </Grid>
      ))}
      <IconButton
        disabled={startIndex + itemsPerPage >= options.length}
        onClick={handleNext}
        sx={{ color: '#1e7aa8', '&:hover': { backgroundColor: 'transparent' } }}
      >
        <ArrowForward />
      </IconButton>
    </Grid>
  );
};

export default HourSelector;
