import React, { useState, useEffect } from "react";
import {
  Typography,
  Button,
  TextField,
  Box,
  styled,
  useTheme,
} from "@mui/material";
import { keyframes } from "@emotion/react";
import { isMobile } from "react-device-detect";
import callApi from "../../../services/callApi";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import Loader from "../../../components/Loader";

const TabButton = styled(Button)(({ theme, active }) => ({
  color: active ? theme.palette.primary.main : theme.palette.text.secondary,
  borderBottom: active ? `2px solid ${theme.palette.primary.main}` : "none",
  borderRadius: 0,
  textTransform: "none",
  "&:hover": {
    backgroundColor: "transparent",
    color: theme.palette.text.primary,
  },
}));

const WhiteTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    backgroundColor: "white",
    borderRadius: 10,
    fontSize: isMobile ? "14px" : "18px",
    fontFamily: "Quicksand-Regular",
  },
  "& .MuiInputLabel-root": {
    fontSize: isMobile ? "14px" : "18px",
    fontFamily: "Quicksand-Regular",
  },
});

const colorChange = keyframes`
  0% { background-color: #3686B1; }
  50% { background-color: #8F58B4; }
  100% { background-color: #3686B1; }
`;

const AnimatedButton = styled(Button)(({ theme }) => ({
  color: "white",
  animation: `${colorChange} 2s infinite`,
}));

const ContactFormComponent = () => {
  const MySwal = withReactContent(Swal);
  const [contactMethod, setContactMethod] = useState("whatsapp");
  const [name, setName] = useState("");
  const [contactInfo, setContactInfo] = useState("");
  const theme = useTheme();
  const [isLoading, setIsLoading] = useState(false);

  const handleContactInfoChange = (e) => {
    const value = e.target.value;
    if (contactMethod === "whatsapp") {
      let formattedValue = value.replace(/[^\d+]/g, "");
      if (formattedValue.startsWith("+")) {
        formattedValue = formattedValue.slice(0, 13);
      } else {
        formattedValue = formattedValue.slice(0, 11);
      }
      setContactInfo(formattedValue);
    } else {
      setContactInfo(value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name || !contactInfo) {
      MySwal.fire({
        title: "Por favor, complete todos los campos",
        icon: "warning",
        customClass: {
          container: "my-swal",
        },
      });
      return;
    }

    setIsLoading(true);
    try {
      const apiData = {
        nombre: name,
        [contactMethod]: contactInfo,
      };

      const result = await callApi(
        "/teradomus/landingPageFormularioInicial",
        "post",
        apiData
      );

      setIsLoading(false);

      if (result?.statusCode === 200) {
        MySwal.fire({
          title: "¡Gracias! Nos pondremos en contacto pronto.",
          icon: "success",
          customClass: {
            container: "my-swal",
          },
        });
      } else {
        throw new Error("Respuesta inesperada del servidor");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error al enviar el formulario:", error);
      MySwal.fire({
        title:
          "Hubo un error al enviar el formulario. Por favor, intente nuevamente.",
        icon: "error",
        customClass: {
          container: "my-swal",
        },
      });
    }
  };

  useEffect(() => {
    setContactInfo("");
  }, [contactMethod]);

  return (
    <>
      <Loader
        isLoading={isLoading}
        style={{
          position: "fixed",
          zIndex: 9999,
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "auto",
          color: "#454545",
        }}
      >
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            mb: 2,
            mt: 15,
            fontSize: isMobile ? "20px" : "30px",
            fontFamily: "Quicksand-Bold",
          }}
        >
          ¡Queremos ser tu aliado!
        </Typography>
        <Typography
          sx={{
            mb: 2,
            fontFamily: "Quicksand-Regular",
            fontSize: isMobile ? "16px" : "20px",
          }}
        >
          Nos ocupamos de tus tareas administrativas para que puedas enfocarte
          en lo más importante
        </Typography>
        <Typography
          variant={isMobile ? "h5" : "h4"}
          sx={{
            mb: 1,
            fontFamily: "Quicksand-Bold",
            fontSize: isMobile ? "18px" : "24px",
          }}
        >
          Hablemos hoy
        </Typography>
        <Typography
          sx={{
            mb: 1,
            fontFamily: "Quicksand-Regular",
            fontSize: isMobile ? "16px" : "20px",
          }}
        >
          ¿por dónde conversamos?
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            mt: 3,
          }}
        >
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              mb: 2,
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <TabButton
              onClick={() => setContactMethod("whatsapp")}
              active={contactMethod === "whatsapp"}
            >
              WhatsApp
            </TabButton>
            <TabButton
              onClick={() => setContactMethod("email")}
              active={contactMethod === "email"}
            >
              E-mail
            </TabButton>
          </Box>
          <Box
            component="form"
            onSubmit={handleSubmit}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              maxWidth: "600px",
              width: "100%",
            }}
          >
            <WhiteTextField
              fullWidth
              variant="outlined"
              label="Nombre"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Box sx={{ position: "relative" }}>
              <WhiteTextField
                fullWidth
                variant="outlined"
                type={contactMethod === "email" ? "email" : "tel"}
                label={
                  contactMethod === "email"
                    ? "Correo electrónico"
                    : "Número de WhatsApp"
                }
                value={contactInfo}
                onChange={handleContactInfoChange}
                inputProps={{
                  inputMode: contactMethod === "whatsapp" ? "numeric" : "email",
                }}
              />
              <AnimatedButton
                type="submit"
                sx={{
                  position: "absolute",
                  right: 8,
                  top: "50%",
                  transform: "translateY(-50%)",
                  borderRadius: 2,
                  textTransform: "none",
                }}
              >
                ✨ Conversemos
              </AnimatedButton>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ContactFormComponent;
