import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Container, Grid } from '@mui/material';
import { isMobile } from 'react-device-detect';
import { Link } from "@material-ui/core";
import { Helmet } from 'react-helmet';


import Agendamiento from "../../assets/blog/agendamiento.jpg";
import Agendamiento2 from "../../assets/blog/formulario_inteligente.gif";
import Agendamiento3 from "../../assets/blog/solicitud_cargando.gif";
import Agendamiento4 from "../../assets/blog/bot_inteligente.gif";


export default function BlogAgendamiento() {
  const pageTitle = "Teradomos news: enterate de lo ultimo para tu salud";
  const pageDescription = "Descubre el sistema de agendamiento inteligente de Teradomus. Un enfoque personalizado para tu rehabilitación, impulsado por inteligencia artificial.";


  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/blog/agendamiento" />
        <meta property="og:type" content="article" />
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/blog/agendamiento" />
      </Helmet>
      <Container>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Link href="/blog"
              underline="none"
              color="inherit">
              <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 2 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
                ← Volver al listado de artículos
              </Typography>
            </Link>
            <Typography variant='h3' sx={{ lineHeight: '8px', mt: { lg: 4, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              News Teradomus <p>
                Noviembre 13, 2023
              </p>
              Pablo Larraguibel, CEO Teradomus
            </Typography>
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography variant='h2' sx={{ mt: { md: 4, xs: 4 }, fontSize: { md: "22px", xs: "16px" }, fontFamily: 'Quicksand-Bold' }}>
              Agendamiento Inteligente en Teradomus: Tu rehabilitación, ahora se adapta a ti.
            </Typography>
          </Grid>
          <Grid item md={6} xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'flex-end', mt: { md: 4, xs: 3 } }}>
              <img src={Agendamiento} style={{ width: 'auto', height: isMobile ? '170px' : '290px' }} alt='logo' />
            </Box>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 3 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              En Teradomus, estamos siempre en busca de mejorar tu experiencia. Hoy te presentamos nuestra nueva funcionalidad: un sistema de agendamiento impulsado por inteligencia artificial, diseñado para hacerte la vida más fácil.
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
              ¿Cómo funciona? Aquí te lo explicamos:
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              El modelo de inteligencia artificial de Teradomus te asistirá en cada paso, utilizando la información que proporciones para seleccionar al kinesiólogo más adecuado según tus preferencias y necesidades específicas. Esta asistencia personalizada garantiza que tu experiencia de rehabilitación sea lo más efectiva y cómoda posible.
            </Typography>
            <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
              <span style={{ fontFamily: 'Quicksand-Bold' }}>1</span>. Información Básica: Comienza por darnos algunos detalles: tu nombre, edad, diagnóstico y síntomas.
            </Typography>
          </Grid>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            <span style={{ fontFamily: 'Quicksand-Bold' }}>2</span>. Selección Personalizada: Nuestro modelo de inteligencia artificial evaluará tu información para proponerte la especialidad de kinesiología más adecuada a tu diagnóstico y síntomas.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            <span style={{ fontFamily: 'Quicksand-Bold' }}>3</span>. Tus Preferencias: Indica el número de sesiones que necesitas y cuéntanos dónde vives.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            <span style={{ fontFamily: 'Quicksand-Bold' }}>4</span>. Modalidad de Sesión: Elige entre rehabilitación a domicilio o telerehabilitación, según tu preferencia.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            <span style={{ fontFamily: 'Quicksand-Bold' }}>5</span>. Elige tu Fecha: Selecciona la fecha para tu primera sesión.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            <span style={{ fontFamily: 'Quicksand-Bold' }}>6</span>. Detalles Finales: Completa los últimos datos y estarás listo para iniciar tu rehabilitación. En este momento, nuestro modelo de inteligencia artificial estará coordinando los últimos detalles con los kinesiólogos que mejor se adaptan a tu diagnóstico y preferencias. En unos minutos, nuestro equipo se pondrá en contacto contigo para presentarte al profesional ideal para ti y coordinar el pago de tu primera atención.
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Una Experiencia Simplificada
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Además, este nuevo sistema refleja nuestro compromiso contigo. Queremos que acceder a una rehabilitación de calidad sea un proceso sencillo y centrado en tus necesidades. Con el agendamiento inteligente, damos un paso más hacia una atención más personalizada y eficiente, dejando atrás las complicaciones de siempre, en donde te hacen a ti responsable de encontrar al profesional adecuado para tu tratamiento, el centro de atención que te quede más cerca o adecuarte a la disponibilidad horaria de quien te atenderá. ¡En Teradomus nos adaptamos a ti!
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 6, xs: 4 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Bold' }}>
            Te Invitamos a Probarlo
          </Typography>
          <Typography variant='h3' sx={{ textAlign: 'justify', mt: { lg: 3, xs: 2 }, fontSize: { md: "17px", xs: "13px" }, fontFamily: 'Quicksand-Regular' }}>
            Anímate a usar nuestro nuevo sistema de agendamiento y descubre lo fácil que es organizar tus sesiones de rehabilitación. En Teradomus, tu comodidad y bienestar son nuestra prioridad
          </Typography>
          <Box sx={{ display: isMobile ? undefined : 'flex', justifyContent: 'center', gap: isMobile ? undefined : '100px', margin: '0 auto' }}>
            <Grid item md={3} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 6, xs: 3 } }}>
                <img src={Agendamiento2} style={{ width: 'auto', height: isMobile ? '300px' : '280px' }} alt='logo' />
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 6, xs: 3 } }}>
                <img src={Agendamiento3} style={{ width: 'auto', height: isMobile ? '300px' : '280px' }} alt='logo' />
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'center', mt: { md: 6, xs: 3 } }}>
                <img src={Agendamiento4} style={{ width: 'auto', height: isMobile ? '300px' : '280px' }} alt='logo' />
              </Box>
            </Grid>
          </Box>
        </Grid >
        <Link href="/blog"
          underline="none"
          color="inherit">
          <Typography variant='h2' sx={{ color: '#1e7aa8', mt: { md: 5, xs: 5 }, fontSize: { md: "16px", xs: "15px" }, fontFamily: 'Quicksand-Rold' }}>
            ← Volver al listado de artículos
          </Typography>
        </Link>
      </Container >
    </>
  );
}