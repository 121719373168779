import React from "react";
import { Box, Typography } from "@mui/material";

const alertStyles = {
  display: "flex",
  alignItems: "center",
  gap: "8px",
  padding: "8px",
  borderRadius: "4px",
  fontSize: "14px",
  fontFamily: "Quicksand-Regular",
  marginTop: "8px",
};

const alertConfigs = {
  datos_existentes: {
    backgroundColor: "#E3F2FD",
    color: "#0D47A1",
    icon: "✓",
    getMessage: (nombre) =>
      `Bienvenido de nuevo ${
        nombre || ""
      }, tus datos están listos para agendar`,
  },
  paciente_nuevo: {
    backgroundColor: "#E3F2FD",
    color: "#0D47A1",
    icon: "✓",
    getMessage: () => "Identificación válida, ingrese sus datos para agendar ",
  },
  rut_invalido: {
    backgroundColor: "#FFEBEE",
    color: "#C62828",
    icon: "⚠",
    getMessage: () => "Identificación inválida. Por favor intenta nuevamente",
  },
};

const CustomAlert = ({ type, nombre }) => {
  if (!type) return null;

  const config = alertConfigs[type];
  if (!config) return null;

  return (
    <Box
      sx={{
        ...alertStyles,
        backgroundColor: config.backgroundColor,
        color: config.color,
      }}
    >
      <span>{config.icon}</span>
      <Typography
        sx={{
          fontFamily: "Quicksand-Regular",
          fontSize: "14px",
        }}
      >
        {config.getMessage(nombre)}
      </Typography>
    </Box>
  );
};

export default CustomAlert;
