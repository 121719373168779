import React, { useRef } from "react";
import Slider from "react-slick";
import { Box, Typography, IconButton } from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const CarouselBox = ({ title }) => (
  <Box
    sx={{
      position: "relative",
      boxShadow: "2px 4px 6px rgba(0, 0, 0, 0.2)",
      borderRadius: 4,
      p: 0.25,
      background: "linear-gradient(45deg, #3f51b5, #9c27b0)",
      m: 1,
      "&::before": {
        content: '""',
        position: "absolute",
        top: 4,
        right: 4,
        bottom: 4,
        left: 4,
        borderRadius: 3.5,
        background: "white",
      },
    }}
  >
    <Box
      sx={{
        position: "relative",
        height: 150,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        p: 2,
      }}
    >
      <Typography
        variant="h6"
        sx={{
          color: "#333",
          fontWeight: "bold",
          fontFamily: "Quicksand-Bold",
          fontSize: "18px",
        }}
      >
        {title}
      </Typography>
    </Box>
  </Box>
);

const SimpleCarouselPC = ({ features }) => {
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  return (
    <Box sx={{ position: "relative" }}>
      <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
        <Typography
          sx={{
            fontFamily: "Quicksand-Regular",
            fontSize: "20px",
            color: "#454545",
            flexGrow: 1,
            mt: 2,
          }}
        >
          Somos tu copiloto administrativo
        </Typography>
        <IconButton onClick={goToPrev} sx={{ mr: 1 }}>
          <ArrowBack />
        </IconButton>
        <IconButton onClick={goToNext}>
          <ArrowForward />
        </IconButton>
      </Box>
      <Box sx={{ ml: 10, mt: 3 }}>
        <Slider ref={sliderRef} {...settings}>
          {features.map((feature, index) => (
            <CarouselBox key={index} title={feature.title} />
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default SimpleCarouselPC;
