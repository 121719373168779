import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import CarouselMove from "../../components/CarouselMove/CarouselMoveComunas";
import ScrollToTop from "../../components/ScrollToTop/ScrollToTop";
import Inicio from "../../components/NewHome/Inicio/Inicio";
import Pasos from "../../components/NewHome/Pasos/Pasos";
import { Button } from "@mui/material";
import Somos from "../../components/NewHome/Somos/Somos";
import Precios from "../../components/NewHome/Precios";
import Precioscelu from "../../components/NewHome/Precioscelu";
import Nosotros from "../../components/Principal/Nosotros";
import Faq from "../../components/Faq/Faq";
import Linea from "../../components/Linea/Linea";
import { Helmet } from "react-helmet";
import { Container, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { Fade } from "react-reveal";

import comunasData from "../../components/ComunasPag/ComunasPag";
import kinesiologia from "../../assets/newiconos/kine_a_domicilio.png";
import kine from "../../assets/newiconos/kine-a_domicilio.png";
import Colmena from "../../assets/Recursos/isapres_colmena.png";
import Masvida from "../../assets/Recursos/isapre_masvida.png";
import Banmedica from "../../assets/Recursos/isapre_banmedica.png";
import Cruzblanca from "../../assets/Recursos/isapre_cruzblanca.png";
import Consalud from "../../assets/Recursos/isapre_consalud.png";
import Opiniones from "../../components/Principal/Opiniones";

function HomeClienteComunas() {
  const { comunaUrl } = useParams();

  const getComunaInfo = (url) => {
    return Object.values(comunasData).find((comuna) => comuna.url === url);
  };

  const comunaInfo = getComunaInfo(comunaUrl);

  const pageTitle = `Teradomus: Kinesiología a domicilio en ${comunaInfo.comun}`;
  const pageDescription = `Kinesiológica a Domicilio en ${comunaInfo.comun}: Tratamientos integral para tus lesiones, con la comodidad de recibirlos en tu hogar.`;

  const isMobile = () => {
    return window.innerWidth <= 768;
  };
  const planesRef = useRef(null);
  const [showPlanes, setShowPlanes] = useState(false);

  const handleVerPlanes = () => {
    setShowPlanes(!showPlanes);
  };

  useEffect(() => {
    if (showPlanes && planesRef.current) {
      planesRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showPlanes]);

  const handleRedirect = () => {
    if (isMobile()) {
      return (
        <>
          <Box
            ref={planesRef}
            sx={{
              backgroundImage: `
      url(${kine})
    `,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "multiply",
            }}
          >
            <ScrollToTop />
            <Inicio comu={comunaInfo.comun} />
            <Box style={{ overflowX: "hidden" }}>
              <CarouselMove region={comunaInfo.region} />
            </Box>
          </Box>
          <Fade bottom duration={650} delay={400}>
            <Box
              sx={{
                bgcolor: "#ededed",
                mt: 7,
                height: { xs: "220px", lg: "250px" },
              }}
            >
              <Container>
                <Typography
                  variant="h2"
                  sx={{
                    mb: 1,
                    fontSize: { lg: "24px", md: "22px", xs: "20px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                    textAlign: "center",
                    paddingTop: 2,
                  }}
                >
                  ¿Qué dicen nuestros pacientes?
                </Typography>
                <Typography
                  variant="h3"
                  sx={{
                    mb: 5,
                    mt: 2,
                    fontSize: { lg: "22px", md: "22px", xs: "18px" },
                    fontFamily: "Quicksand-Regular",
                    color: "#1e7aa8",
                    textAlign: "center",
                  }}
                >
                  Sus experiencias y satisfacción nos respaldan.
                </Typography>
              </Container>
            </Box>
          </Fade>
          <Container>
            <Fade bottom duration={650} delay={400}>
              <Box sx={{ mt: { xs: -17, lg: -20 } }}>
                <Opiniones />
              </Box>
            </Fade>
            <Fade bottom duration={650} delay={400}>
              <Box sx={{ mt: 10 }}>
                <Precioscelu />
              </Box>
              <Button
                sx={{
                  fontFamily: "Quicksand-Bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#8753b1",
                  "&:hover": { backgroundColor: "#651d87" },
                  margin: "auto",
                  borderRadius: 4,
                  mt: 1,
                  paddingTop: 0.5,
                  mb: 10,
                  paddingBottom: 0.5,
                  paddingLeft: 5.5,
                  paddingRight: 5.5,
                  fontSize: { lg: "20px", md: "18px", xs: "16px" },
                  textTransform: "none",
                }}
                onClick={handleVerPlanes}
                variant="contained"
              >
                Agendar
              </Button>
            </Fade>
          </Container>
          <Fade bottom duration={650} delay={400}>
            <Pasos />
          </Fade>
          <Fade bottom duration={650} delay={400}>
            <Box
              sx={{
                display: "flex",
                mt: 10,
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                mb: 7,
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mb: 5,
                  fontSize: { lg: "24px", md: "16px", xs: "16px" },
                  fontFamily: "Quicksand-Bold",
                  paddingTop: 2,
                  color: "#1e7aa8",
                  textAlign: "center",
                }}
              >
                Servicio a domicilio en +80 comunas en la Region Metropolitana,
                Coquimbo, Biobío, Valparaíso, Antofagasta, Maule, Los Lagos, La
                Araucanía, O'Higgins y Los Ríos
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src={Colmena}
                  style={{ height: "25px" }}
                  alt="Isapre Colmena"
                />
                <img
                  src={Masvida}
                  style={{ height: "25px" }}
                  alt="Isapre Colmena"
                />
                <img
                  src={Banmedica}
                  style={{ height: "25px" }}
                  alt="Isapre Colmena"
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <img
                  src={Cruzblanca}
                  style={{ height: "25px" }}
                  alt="Isapre Colmena"
                />
                <img
                  src={Consalud}
                  style={{ height: "25px" }}
                  alt="Isapre Colmena"
                />
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { lg: "18px", md: "16px", xs: "14px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                    textAlign: "center",
                  }}
                >
                  y más
                </Typography>
              </Box>
              <Container>
                <Box
                  sx={{
                    position: "relative",
                    zIndex: 2,
                    ml: { xs: 0, lg: 30 },
                    mt: { xs: 10, lg: -20 },
                    textAlign: "center",
                    color: "#1e7aa8",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: { xs: "18px", lg: "26px" },
                      fontFamily: "Quicksand-Bold",
                    }}
                  >
                    Nuestros profesionales
                  </Typography>
                </Box>
                <Box sx={{ mt: 1, mb: 5 }}>
                  <Nosotros />
                </Box>
              </Container>
              <Button
                sx={{
                  fontFamily: "Quicksand-Bold",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#8753b1",
                  "&:hover": { backgroundColor: "#651d87" },
                  margin: "auto",
                  borderRadius: 4,
                  paddingTop: 0.5,
                  mb: 7,
                  paddingBottom: 0.5,
                  paddingLeft: 5.5,
                  paddingRight: 5.5,
                  fontSize: { lg: "20px", md: "18px", xs: "16px" },
                  textTransform: "none",
                }}
                onClick={handleVerPlanes}
                variant="contained"
              >
                Agendar
              </Button>
            </Box>
          </Fade>
          <Fade bottom duration={650} delay={400}>
            <Somos />
          </Fade>
          <Fade bottom duration={650} delay={400}>
            <Box
              sx={{
                mb: { xs: 10, lg: 7 },
                mt: { xs: 10, lg: 7 },
                textAlign: "center",
              }}
            >
              <Linea />
            </Box>
            <Container>
              <Container>
                <Faq />
              </Container>
            </Container>
          </Fade>
        </>
      );
    } else {
      return (
        <>
          <Box
            ref={planesRef}
            sx={{
              height: "620px",
              backgroundImage: `
                url(${kinesiologia})
              `,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "multiply",
            }}
          >
            <ScrollToTop />
            <Inicio comu={comunaInfo.comun} />
          </Box>
          <Fade bottom duration={650} delay={400}>
            <Container>
              <Box sx={{ mt: { xs: 0, md: -15 } }}>
                <Pasos />
              </Box>
            </Container>
          </Fade>
          <Fade bottom duration={650} delay={400}>
            <Box
              sx={{
                bgcolor: "#ededed",
                mt: 20,
                height: { xs: "220px", lg: "250px" },
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mb: 1,
                  fontSize: { lg: "28px", md: "22px", xs: "20px" },
                  fontFamily: "Quicksand-Bold",
                  color: "#1e7aa8",
                  textAlign: "center",
                  paddingTop: 2,
                }}
              >
                ¿Qué dicen nuestros pacientes?
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  mb: 5,
                  mt: 2,
                  fontSize: { lg: "24px", md: "22px", xs: "20px" },
                  fontFamily: "Quicksand-Regular",
                  color: "#1e7aa8",
                  textAlign: "center",
                }}
              >
                Sus experiencias y satisfacción nos respaldan.
              </Typography>
            </Box>
          </Fade>
          <Container>
            <Fade bottom duration={650} delay={400}>
              <Box sx={{ mt: { xs: -20, lg: -20 } }}>
                <Opiniones />
              </Box>
            </Fade>
            <Fade bottom duration={650} delay={400}>
              <Box sx={{ mt: 2 }}>
                <Precios />
                <Button
                  sx={{
                    fontFamily: "Quicksand-Bold",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#8753b1",
                    "&:hover": { backgroundColor: "#651d87" },
                    margin: "auto",
                    borderRadius: 4,
                    mt: 5,
                    paddingTop: 0.5,
                    mb: 5,
                    paddingBottom: 0.5,
                    paddingLeft: 5.5,
                    paddingRight: 5.5,
                    fontSize: { lg: "20px", md: "18px", xs: "16px" },
                    textTransform: "none",
                  }}
                  onClick={handleVerPlanes}
                  variant="contained"
                >
                  Agendar
                </Button>
              </Box>
            </Fade>
            <Fade bottom duration={650} delay={400}>
              <Nosotros />
            </Fade>
          </Container>
          <Fade bottom duration={650} delay={400}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h2"
                sx={{
                  mb: 5,
                  mt: { xs: 4, lg: 20 },
                  fontSize: { lg: "24px", md: "16px", xs: "16px" },
                  fontFamily: "Quicksand-Bold",
                  paddingTop: 2,
                  color: "#1e7aa8",
                  textAlign: "center",
                }}
              >
                Servicio a domicilio en +80 comunas en la Region Metropolitana,
                <br></br> Coquimbo, Biobío, Valparaíso, Antofagasta, Maule, Los
                Lagos, La Araucanía, O'Higgins y Los Ríos
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <img
                  src={Colmena}
                  style={{ height: "45px" }}
                  alt="Isapre Colmena"
                />
                <img
                  src={Masvida}
                  style={{ height: "45px" }}
                  alt="Isapre Colmena"
                />
                <img
                  src={Banmedica}
                  style={{ height: "45px" }}
                  alt="Isapre Colmena"
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "30px",
                }}
              >
                <img
                  src={Cruzblanca}
                  style={{ height: "45px" }}
                  alt="Isapre Colmena"
                />
                <img
                  src={Consalud}
                  style={{ height: "45px" }}
                  alt="Isapre Colmena"
                />
                <Typography
                  variant="h2"
                  sx={{
                    fontSize: { lg: "18px", md: "16px", xs: "14px" },
                    fontFamily: "Quicksand-Bold",
                    color: "#1e7aa8",
                    textAlign: "center",
                  }}
                >
                  y más
                </Typography>
              </Box>
            </Box>
            <Button
              sx={{
                fontFamily: "Quicksand-Bold",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#8753b1",
                "&:hover": { backgroundColor: "#651d87" },
                margin: "auto",
                borderRadius: 4,
                mt: 5,
                paddingTop: 0.5,
                mb: 15,
                paddingBottom: 0.5,
                paddingLeft: 5.5,
                paddingRight: 5.5,
                fontSize: { lg: "20px", md: "18px", xs: "16px" },
                textTransform: "none",
              }}
              onClick={handleVerPlanes}
              variant="contained"
            >
              Agendar
            </Button>
          </Fade>
          <Fade bottom duration={650} delay={400}>
            <Box sx={{ mt: 8, mb: 14 }}>
              <Somos />
            </Box>
            <Box
              sx={{
                mb: { xs: 5, lg: 7 },
                mt: { xs: 5, lg: 7 },
                textAlign: "center",
              }}
            >
              <Linea />
            </Box>
          </Fade>
          <Fade bottom duration={650} delay={400}>
            <Container>
              <Container>
                <Faq />
              </Container>
            </Container>
          </Fade>
        </>
      );
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta
          property="og:url"
          content={`https://www.teradomus.com/kinesiologia-a-domicilio/${comunaInfo.url}`}
        />
        <meta property="og:type" content="article" />
        <link
          rel="canonical"
          href={`https://www.teradomus.com/kinesiologia-a-domicilio/${comunaInfo.url}`}
        />
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "http://schema.org",
            "@type": "Article",
            headline: pageTitle,
            description: pageDescription,
            url: `https://www.teradomus.com/kinesiologia-a-domicilio/${comunaInfo.url}`,
          })}
        </script>
      </Helmet>
      {handleRedirect()}
    </>
  );
}

export default HomeClienteComunas;
