import React from "react";
import { Select, MenuItem } from "@mui/material";

const baseSelectStyles = {
  height: "30px",
  width: "100%",
  fontFamily: "Quicksand-Regular",
  fontSize: "16px",
  backgroundColor: "transparent",
  border: "none",
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiSelect-select": {
    paddingTop: "8px",
    paddingBottom: "8px",
  },
};

const disabledStyles = {
  pointerEvents: "none",
  color: "#9e9e9e",
  "&.Mui-disabled": {
    backgroundColor: "#f5f5f5",
    opacity: 0.6,
    "& .MuiSelect-select": {
      WebkitTextFillColor: "#9e9e9e",
      cursor: "not-allowed",
    },
  },
  "& .MuiSelect-icon": {
    opacity: 0.6,
    color: "#9e9e9e",
  },
};

const enabledStyles = {
  color: "#4F4F4F",
  "& .MuiSelect-select": {
    cursor: "pointer",
  },
  "& .MuiSelect-icon": {
    opacity: 1,
    color: "#4F4F4F",
  },
};

const StyledSelect = ({ value, onChange, options, placeholder, disabled }) => (
  <Select
    value={value}
    onChange={onChange}
    displayEmpty
    disabled={disabled}
    sx={{
      ...baseSelectStyles,
      ...(disabled ? disabledStyles : enabledStyles),
    }}
  >
    <MenuItem value="" disabled>
      {placeholder}
    </MenuItem>
    {options.map((option) => (
      <MenuItem
        key={option.value}
        value={option.value}
        sx={{
          fontFamily: "Quicksand-Regular",
          "&.Mui-disabled": {
            opacity: 0.6,
          },
        }}
      >
        {option.label}
      </MenuItem>
    ))}
  </Select>
);

export default StyledSelect;
