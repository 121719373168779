import React from 'react';
import { Box } from '@material-ui/core';

function Video() {
    const videoUrl = 'https://teradomus-storage-public.s3.amazonaws.com/Tera2.mp4';

    return (
        <Box sx={{ textAlign: "center" }}>
            <video width="360" height="720" controls>
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Box>
    );
}

export default Video;