import React from "react";
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import imagenEjemplo from "../../../assets/imagenPrueba.jpg";

const CenteredImageContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  height: "100vh",
  width: "100vw",
});

const ResponsiveImage = styled("img")({
  maxWidth: "100%",
  maxHeight: "calc(100% - 100px)",
  objectFit: "contain",
});

const Title = styled(Typography)({
  marginBottom: "20px",
  fontWeight: "bold",
  textAlign: "center",
});

const CenteredImage = () => {
  return (
    <CenteredImageContainer>
      <Title variant="h4" component="h1">
        Ejemplo Imagen Perfil Profesional
      </Title>
      <ResponsiveImage
        src={imagenEjemplo}
        alt="Ejemplo de perfil profesional"
      />
    </CenteredImageContainer>
  );
};

export default CenteredImage;
