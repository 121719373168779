import React, { useState, useEffect } from "react";
import { Typography, IconButton, Menu, MenuItem, Box } from "@mui/material";
import { Container, Grid } from "@mui/material";
import { Link } from "@material-ui/core";
import { useTheme } from "@mui/material/styles";
import { isMobile } from "react-device-detect";
import MenuIcon from "@mui/icons-material/Menu";

import logo from "../../assets/logo.png";

const estiloComun = {
  fontFamily: "Quicksand-Bold",
};

export default function MenuProfesiones({ scrollToPricing }) {
  const [optionsOpen, setOptionsOpen] = useState(false);
  const isDesktop = !isMobile;
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [showServices, setShowServices] = useState(false);
  const tema = useTheme();

  const abrirMenu = (evento) => {
    setMenuAnchor(evento.currentTarget);
  };

  const cerrarMenu = () => {
    setMenuAnchor(null);
    setShowServices(false);
  };

  const handleShowOptions = () => {
    if (isDesktop) {
      setOptionsOpen(true);
    }
  };

  const handleHideOptions = () => {
    if (isDesktop) {
      setOptionsOpen(false);
    }
  };

  const handleToggleOptions = () => {
    if (!isDesktop) {
      setOptionsOpen((prevState) => !prevState);
    }
  };

  useEffect(() => {
    const handleCloseOptions = () => {
      setOptionsOpen(false);
    };

    window.addEventListener("scroll", handleCloseOptions);

    return () => {
      window.removeEventListener("scroll", handleCloseOptions);
    };
  }, []);

  return (
    <>
      <Grid container>
        <Grid item lg={2} md={2} xs={5}>
          <Box sx={{ mt: 2.2, mb: 1.3 }}>
            <Link href="/" underline="none" color="inherit">
              <img
                src={logo}
                alt="Logo teradomus"
                style={{
                  height: isDesktop ? "40px" : "30px",
                }}
              />
            </Link>
          </Box>
        </Grid>
        {isMobile ? (
          <>
            <Grid
              item
              xs={7}
              container
              justifyContent="flex-end"
              alignItems="center"
            >
              <IconButton
                size="large"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={abrirMenu}
              >
                <MenuIcon sx={{ color: "#aeaeae" }} />
              </IconButton>
            </Grid>
            <Menu
              anchorEl={menuAnchor}
              open={Boolean(menuAnchor)}
              onClose={cerrarMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem onClick={handleToggleOptions} sx={estiloComun}>
                Servicios
                {optionsOpen && (
                  <Box sx={{ ml: 2 }}>
                    <MenuItem onClick={cerrarMenu} sx={estiloComun}>
                      <Link
                        href="/kinesiologia-a-domicilio"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Para Pacientes
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={cerrarMenu} sx={estiloComun}>
                      <Link
                        href="/pro"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Para Profesionales
                      </Link>
                    </MenuItem>
                    {/*
                    <MenuItem onClick={cerrarMenu} sx={estiloComun}>
                      <Link
                        href="https://saludtech.cl/widget/accounts/441/professions"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Atención Médica
                      </Link>
                    </MenuItem>
                    */}
                  </Box>
                )}
              </MenuItem>
              <MenuItem
                onClick={() => {
                  cerrarMenu();
                  scrollToPricing();
                }}
                sx={estiloComun}
              >
                Pricing
              </MenuItem>
              <MenuItem onClick={cerrarMenu} sx={estiloComun}>
                <Link
                  href="/blog"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  Blog
                </Link>
              </MenuItem>
            </Menu>
          </>
        ) : (
          <>
            <Grid item lg={6.8} md={4.5} xs={2}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mt: 3,
                }}
              ></Box>
            </Grid>
            <Grid item lg={1.3} md={1.7} xs={2.5}>
              <Typography
                sx={{
                  textAlign: "center",
                  mt: 3,
                  fontFamily: "Quicksand-Bold",
                  fontSize: { lg: "24px", md: "24px", xs: "18px" },
                  color: "#aeaeae",
                  cursor: "pointer",
                  "&:hover": { color: "#aeaeae" },
                }}
                onClick={handleToggleOptions}
                onMouseEnter={handleShowOptions}
                onMouseLeave={handleHideOptions}
              >
                Servicios
              </Typography>
              {optionsOpen && (
                <Box
                  sx={{
                    position: "absolute",
                    zIndex: 1,
                    display: "flex",
                    mt: -1.3,
                    justifyContent: "center",
                    flexDirection: "column",
                    alignItems: "center",
                    padding: isDesktop ? "20px" : "10px",
                    bgcolor: {
                      xs: "rgba(255, 255, 255, 0.9)",
                      md: "rgba(255, 255, 255, 0.6)",
                    },
                    borderRadius: "13px",
                    width: { xs: "130px", md: "180px", lg: "210px" },
                  }}
                  onMouseEnter={handleShowOptions}
                  onMouseLeave={handleHideOptions}
                >
                  <Box
                    sx={{
                      textAlign: "left",
                      width: "100%",
                      margin: "auto",
                      p: 0.5,
                    }}
                  >
                    <Typography
                      variant="h1"
                      sx={{
                        mt: 1,
                        fontSize: { lg: "20px", md: "20px", xs: "15px" },
                        fontFamily: "Quicksand-Regular",
                        "&:hover": {
                          fontFamily: "Quicksand-Bold",
                          color: "#2669A8",
                        },
                      }}
                    >
                      <Link
                        href="/"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Para Pacientes
                      </Link>
                    </Typography>
                    <Typography
                      variant="h1"
                      sx={{
                        mt: 2,
                        mb: 2,
                        fontSize: { lg: "20px", md: "20px", xs: "15px" },
                        fontFamily: "Quicksand-Regular",
                        "&:hover": {
                          fontFamily: "Quicksand-Bold",
                          color: "#2669A8",
                        },
                      }}
                    >
                      <Link
                        href="/pro"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Para Profesionales
                      </Link>
                    </Typography>
                    {/*
                    <Typography
                      variant="h1"
                      sx={{
                        mt: 2,
                        mb: 2,
                        fontSize: { lg: "20px", md: "20px", xs: "15px" },
                        fontFamily: "Quicksand-Regular",
                        "&:hover": {
                          fontFamily: "Quicksand-Bold",
                          color: "#2669A8",
                        },
                      }}
                    >
                      <Link
                        href="https://saludtech.cl/widget/accounts/441/professions"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        Atención Médica
                      </Link>
                    </Typography>
                    */}
                  </Box>
                </Box>
              )}
            </Grid>
            <Grid item lg={1.2} md={1.7} xs={2.5}>
              <Typography
                sx={{
                  textAlign: "center",
                  mt: 3,
                  fontFamily: "Quicksand-Bold",
                  fontSize: { lg: "24px", md: "24px", xs: "18px" },
                  color: "#aeaeae",
                  cursor: "pointer",
                }}
                onClick={scrollToPricing}
              >
                Pricing
              </Typography>
            </Grid>
            <Grid item lg={0.7} md={1.7} xs={2.5}>
              <Link href="/blog" underline="none" color="inherit">
                <Typography
                  sx={{
                    textAlign: "right",
                    mt: 3,
                    fontFamily: "Quicksand-Bold",
                    fontSize: { lg: "24px", md: "24px", xs: "18px" },
                    color: "#aeaeae",
                  }}
                >
                  Blog
                </Typography>
              </Link>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}
