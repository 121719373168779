import axios from "axios";

async function callApiWithJson(url, verb, payload) {
  const { REACT_APP_BASE_URL_PROD, REACT_APP_STAGE_API } = process.env;

  let apiUrl = REACT_APP_BASE_URL_PROD.concat(REACT_APP_STAGE_API);
  apiUrl = apiUrl.concat(url);

  const config = {
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  };

  payload = JSON.stringify(payload);

  function call() {
    switch (verb) {
      case "get":
        return axios.get(apiUrl, config);
      case "post":
        return axios.post(apiUrl, payload, config);
      default:
        return axios.get(apiUrl, config);
    }
  }

  try {
    const resp = await call();
    const { data } = resp;
    return data;
  } catch (error) {
    console.error("Error with the API call:", error);
    throw error;
  }
}

export default callApiWithJson;
