import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Container, Grid } from "@material-ui/core";

function SuscribeDone() {
  return (
    <>
      <Container>
        <Grid
          container
          spacing={2}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                borderRadius: 6,
                mt: 8,
                mb: 10,
              }}
            >
              <Grid item>
                <Box textAlign="center" sx={{ pt: 4, mb: 2 }}>
                  <Typography variant="h5" xs={{ fontWeight: "bold" }}>
                    Estamos revisando tu información
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box textAlign="justify" sx={{ m: 5, fontSize: 19 }}>
                  <Typography class="row" variant="text">
                    Hemos recibido tus datos y documentos en Teradomus. Si tu
                    especialidades, comunas y preferencias se ajustan con lo que
                    estamos buscando, te contactaremos para seguir adelante.
                  </Typography>
                </Box>
                <Box textAlign="justify" sx={{ m: 5, fontSize: 19 }}>
                  <Typography class="row" variant="text">
                    No te preocupes, si ahora no hay una coincidencia, podríamos
                    llamarte más adelante. Vamos agregando nuevos profesionales
                    a nuestro equipo poco a poco, según lo que nuestros
                    pacientes necesiten.
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box textAlign="justify" sx={{ m: 5, pb: 5, fontSize: 19 }}>
                  <Typography class="row">
                    Si tienes dudas escríbenos a nuestro WhatsApp +56 9 9690
                    6773 o al correo ayuda@teradomus.com
                  </Typography>{" "}
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default SuscribeDone;
