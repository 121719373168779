import React, { useState } from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  CircularProgress,
  Checkbox,
} from "@mui/material";
import CustomAlert from "./CustomAlert";

const RutValidationGrid = ({
  rut,
  handleChangeRut,
  isRutValidated,
  formatoRut,
  validateRut,
  isValidating,
  isValidRut,
  handleCheckboxChange,
  validationResult,
}) => {
  const [showPassportCheck, setShowPassportCheck] = useState(false);

  const handlePassportClick = () => {
    if (!showPassportCheck && !isRutValidated) {
      setShowPassportCheck(true);
    }
  };

  return (
    <Grid item xs={12} lg={12}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 1,
        }}
      >
        <Typography
          component="span"
          sx={{
            fontFamily: isValidRut(rut)
              ? "Quicksand-Bold"
              : "Quicksand-Regular",
            fontSize: { xs: "16px", md: "20px", lg: "20px" },
            color: isValidRut(rut) ? "#2669A8" : "#4F4F4F",
          }}
        >
          {isValidRut(rut) ? "RUT" : "RUT*"}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            cursor: isRutValidated ? "not-allowed" : "pointer",
            opacity: isRutValidated ? 0.6 : 1,
          }}
          onClick={handlePassportClick}
        >
          <Typography
            sx={{
              fontFamily: !formatoRut ? "Quicksand-Bold" : "Quicksand-Regular",
              color: isRutValidated
                ? "#9e9e9e"
                : !formatoRut
                ? "#8753b1"
                : "#9e9e9e",
              fontSize: "14px",

              marginRight: showPassportCheck ? 1 : 0,
              pointerEvents: isRutValidated ? "none" : "auto",
            }}
          >
            Pasaporte
          </Typography>
          {showPassportCheck && (
            <Checkbox
              checked={!formatoRut}
              onChange={(e) => !isRutValidated && handleCheckboxChange(e)}
              name="esPasaporte"
              disabled={isRutValidated}
              sx={{
                color: isRutValidated ? "#9e9e9e" : "#8753b1",
                "&.Mui-checked": {
                  color: isRutValidated ? "#9e9e9e" : "#8753b1",
                },
                cursor: isRutValidated ? "not-allowed" : "pointer",
              }}
            />
          )}
        </Box>
      </Box>

      <Box sx={{ display: "flex", gap: 1 }}>
        <Box
          sx={{
            flex: 1,
            borderRadius: 2,
            overflow: "hidden",
            backgroundImage: isRutValidated
              ? "none"
              : "linear-gradient(to bottom, #e7eff5, #f2ebf6)",
            backgroundColor: isRutValidated ? "#f5f5f5" : "transparent",
            p: 0.5,
          }}
        >
          <input
            id="rut"
            name="rut"
            onChange={handleChangeRut}
            value={rut}
            disabled={isRutValidated}
            maxLength={formatoRut ? 12 : 25}
            style={{
              border: "none",
              width: "100%",
              outline: "none",
              background: "transparent",
              fontFamily: "Quicksand-Regular",
              color: "#4F4F4F",
              fontSize: "16px",
              cursor: isRutValidated ? "not-allowed" : "text",
              opacity: isRutValidated ? 0.6 : 1,
            }}
            placeholder={formatoRut ? "Ej: 12.345.678-9" : "Ej: Pasaporte123"}
          />
        </Box>
        <Button
          onClick={validateRut}
          variant="contained"
          disabled={
            formatoRut
              ? rut.length < 9 || isValidating || isRutValidated
              : rut.length < 1 || isValidating || isRutValidated
          }
          sx={{
            backgroundColor: "#8753b1",
            "&:hover": { backgroundColor: "#651d87" },
            fontFamily: "Quicksand-Bold",
            fontSize: "14px",
            height: "34px",
            minWidth: "100px",
            textTransform: "none",
            position: "relative",
            "&:disabled": {
              backgroundColor: isRutValidated ? "#8753b1" : "#cccccc",
              color: isRutValidated ? "white" : "#666666",
            },
          }}
        >
          {isValidating ? (
            <CircularProgress
              size={20}
              sx={{
                color: "white",
                position: "absolute",
                left: "50%",
                marginLeft: "-10px",
              }}
            />
          ) : isRutValidated ? (
            "OK"
          ) : (
            "Validar"
          )}
        </Button>
      </Box>

      <CustomAlert
        type={
          validationResult?.validacion === "rut_valido"
            ? validationResult.paciente === "paciente_registrado"
              ? "datos_existentes"
              : "paciente_nuevo"
            : validationResult?.validacion === "rut_invalido"
            ? "rut_invalido"
            : null
        }
        nombre={validationResult?.datos_paciente?.nombres}
      />
    </Grid>
  );
};

export default RutValidationGrid;
