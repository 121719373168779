import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Container, Grid } from '@mui/material';
import { useLocation, useHistory } from "react-router-dom";
import ScrollToTop from '../../components/ScrollToTop/ScrollToTop';
import MenuCliente from '../../components/MenuCliente/MenuCliente';
import Mapa from '../../components/Mapa';
import TelWhat from '../../components/TelWhat/TelWhat';
import { Helmet } from 'react-helmet';

export default function Direccion() {
  let history = useHistory();
  const location = useLocation();
  const [location2, setLocation2] = useState(null);
  const { respuesta1, respuesta2, respuesta3, respuesta5, respuesta6, nombre, apellido, edad, telefono, tipo, motivo } = location.state;
  const [sessions] = useState(location.state.sessions);
  const [preciosessions] = useState(location.state.preciosessions);

  const [selectedComuna, setSelectedComuna] = useState('');
  const [query, setQuery] = useState('');
  const [textoVisible, setTextoVisible] = useState('');

  const handleLocationAndComuna = (selectedLocation, comuna, formattedAddress) => {
    setLocation2(selectedLocation);
    setSelectedComuna(comuna); // Aquí estableces la comuna en el estado
    setQuery(formattedAddress);
  };

  let newText = tipo;
  let updatedTextseciones = replaceBoldContent(respuesta1, newText);

  function replaceBoldContent(originalText, replacementText) {
    return originalText.replace(/\*\*(.*?)\*\*/, `**${replacementText}**`);
  }

  useEffect(() => {
    let currentIndex = 0;
    let textoAMostrar = updatedTextseciones; // <--- Asignar respuesta1 a textoAMostrar

    const interval = setInterval(() => {
      if (currentIndex <= textoAMostrar.length) {
        setTextoVisible(textoAMostrar.slice(0, currentIndex));
        currentIndex++;
      } else {
        clearInterval(interval);
      }
    }, 30); // Velocidad de escritura (ajusta según tus preferencias)

    return () => {
      clearInterval(interval);
    };
  }, [updatedTextseciones]);

  function parseTextWithBold(text) {
    const splitText = text.split('**');
    return splitText.map((part, index) => {
      if (index % 2 === 1) {  // Si es un índice impar, es una variable y debe ir en negrita
        return <strong key={index}>{part}</strong>;
      }
      return part;
    });
  }
  const pageTitle = "Teradomus: Agenda kinesiología a domicilio";
  const pageDescription = "Kinesiología a Domicilio: Tratamientos integrales para tus lesiones, en la comodidad de tu hogar.";

  return (
    <>
      <ScrollToTop />
      <MenuCliente />
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <link rel="canonical" href="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/direccion" />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <meta property="og:url" content="https://www.teradomus.com/kinesiologia-a-domicilio/agenda-inteligente/direccion" />
        <meta property="og:type" content="article" />
        <meta name="robots" content="noindex" />
        <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBUmobsMdC3r-tMGmldJbSdwVNFAg6KRV8&libraries=places"
          async defer></script>
      </Helmet>
      <Box
        sx={{
          height: { lg: '300px', xs: '25vh' },
          backgroundImage: `linear-gradient(to right, rgba(30, 122, 168, 0.9), rgba(133, 70, 173, 0.9))`,
        }}
      >
      </Box>
      <Box
        sx={{
          width: { lg: '60%', xs: '95%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.04)',
          mt: { lg: -30, xs: -20 },
          margin: '0 auto', // Añadido para centrar horizontalmente
        }}
      >
        <Container >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid Item xs="12" md="10" lg="10">
              <Typography textAlign={"center"} sx={{
                color: '#2669A8', mt: { xs: 1, lg: 3 }, fontFamily: 'Quicksand-Bold', fontSize: { xs: "20px", md: '20px', lg: "26px" }
              }}>
                Ingresa tu dirección
              </Typography>
              <Typography variant="body1" sx={{
                mb: 3,
                mt: { xs: 3, lg: 3 }, fontFamily: 'Quicksand-Regular', fontSize: { xs: "16px", md: '20px', lg: "18px" }
              }}>
                {parseTextWithBold(textoVisible)}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        <Box sx={{ margin: '0 auto', width: { xs: 'auto', lg: '87%' } }}>
          <Mapa onLocationAndComunaSelect={handleLocationAndComuna} />
        </Box>
        <Container sx={{
          display: 'flex',
          justifyContent: 'center', mt: { xs: 3, lg: 5 }, mb: { xs: 3, lg: 5 }
        }}>
          <Button
            sx={{
              fontFamily: 'Quicksand-Bold', fontSize: { xs: "14px", md: '16px', lg: "16px" },
              backgroundColor: '#8753b1',
              '&:hover': { backgroundColor: '#651d87' },
              borderRadius: 3
            }}
            variant="contained" onClick={() => {
              history.push({
                pathname: "/kinesiologia-a-domicilio/agenda-inteligente/servicios",
                state: {
                  comuna: selectedComuna, preciosessions: preciosessions,
                  respuesta2: respuesta2, sessions: sessions,
                  respuesta3: respuesta3, motivo: motivo,
                  respuesta5: respuesta5, tipo: tipo, direccion: query,
                  respuesta6: respuesta6, nombre: nombre, apellido: apellido, edad: edad, telefono: telefono
                }
              });
            }}
          >
            Continuar
          </Button>
        </Container>
      </Box >
      <Box
        sx={{
          width: { lg: '58%', xs: '85%' },
          backgroundColor: 'white',
          borderRadius: 3,
          boxShadow: '5px 5px 6px rgba(0, 0, 0, 0.14)',
          border: '1px solid rgba(0, 0, 0, 0.08)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: '0 auto', // Añadido para centrar horizontalmente
          padding: 2, mt: 6

        }}
      >
        <Grid container>
          <Grid item xs={12} lg={10}>
            <Box sx={{
              ml: { xs: 0, md: 2, lg: 2 }
            }}>
              <Typography sx={{
                color: "#4F4F4F", mb: { xs: 1, lg: 1 },
                mt: { xs: 0, lg: 2 }, fontFamily: 'Quicksand-Bold',
                fontSize: { xs: "14px", md: '16px', lg: "20px" }
              }}>
                Así va tu orden:
              </Typography>
              <Typography sx={{ ml: 2, color: "#4F4F4F", fontFamily: 'Quicksand-Regular', fontSize: { xs: "13px", md: '16px', lg: "18px" } }}>
                <li>{tipo}</li>
                <li>N° de sesiones: {sessions}, desde ${(preciosessions).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} por sesión </li>
                <li>Total a pagar desde ${(sessions * preciosessions).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')} hasta en 3 cuotas de ${(sessions * 25000 / 3).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')}, sin intereses</li>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ textAlign: "center", pt: 9, pb: 2 }}>
        <TelWhat />
      </Box>
    </>
  );
}